import { useMutation, useQuery } from "@apollo/client";
import { DataLoader } from "components";
import { UPDATE_USER_MUTATION } from "graphql/mutations";
import { FIND_ONE_USER_QUERY } from "graphql/queries/findOneUser";
import {
  findOneUser,
  findOneUserVariables,
} from "graphql/__generated-types__/findOneUser";
import {
  updateUser,
  updateUserVariables,
} from "graphql/__generated-types__/updateUser";
import { adminMenuRoutes } from "navigation/routes";
import { useNavigate, useParams } from "react-router-dom";
import { IUserForm, UserForm } from "../components";
import { toast } from "react-toastify";

interface IAdminUserEditParams {
  id: string;
}

const AdminUsersEdit = () => {
  const params = useParams<keyof IAdminUserEditParams>();
  const navigate = useNavigate();
  const { loading: userLoading, data: userData } = useQuery<
    findOneUser,
    findOneUserVariables
  >(FIND_ONE_USER_QUERY, {
    variables: {
      input: {
        id: +params.id,
      },
    },
  });

  const [updateUser, { loading: updateUserLoading }] = useMutation<
    updateUser,
    updateUserVariables
  >(UPDATE_USER_MUTATION, {
    onCompleted: (updateUserData) => {
      if (updateUserData && updateUserData.updateUser.ok)
        navigate(adminMenuRoutes.users.path);
      else toast.error(updateUserData.updateUser.error);
    },
    refetchQueries: [
      {
        query: FIND_ONE_USER_QUERY,
        variables: { input: { id: +params.id } },
      },
    ],
    /* update: (cache, result) => {
        const {
          data: {
            updateUser: { ok, error, user },
          },
        } = result;
        if (ok) {
          const fragmentId = `User:${+params.id}`;
          cache.modify({
            id: fragmentId,
            fields: {
              name() {
                return user.name;
              },
              surname() {
                return user.surname;
              },
              middlename() {
                return user.middlename;
              },
              avatar() {
                return user.avatar;
              },
              position() {
                return cache.writeFragment({
                  data: user.position,
                  fragmentName: "PositionParts",
                });
              },
              office() {
                return user.office;
              },
              department() {
                return cache.writeFragment({
                  data: user.department,
                  fragmentName: "DepartmentParts",
                });
              },
              email() {
                return user.email;
              },
              phone() {
                return user.phone;
              },
              telegram() {
                return user.telegram;
              },
              birthdate() {
                return user.birthdate;
              },
              isActive() {
                return user.isActive;
              },
              isRemote() {
                return user.isRemote;
              },
            },
          });
          navigate("/admin/users/");
        } else console.error(error);
      },*/
  });

  const onSubmit = (data: IUserForm) => {
    updateUser({
      variables: {
        input: {
          ...data,
          id: +params.id,
          birthdate: new Date(data?.birthdate).setUTCHours(0, 0, 0, 0),
        },
      },
    });
  };

  return !userLoading && !updateUserLoading && userData?.findOneUser?.user ? (
    <UserForm userData={userData} onSubmit={onSubmit} />
  ) : (
    <DataLoader />
  );
};

export default AdminUsersEdit;
