import {gql} from "@apollo/client";

export const DELETE_BUYING_FORMAT_MUTATION = gql`
  mutation deleteBuyingFormat($input: DeleteBuyingFormatInput!) {
    deleteBuyingFormat(input: $input) {
      ok
      error
    }
  }
`;
