import { useNavigate } from "react-router";
import { useMutation } from "@apollo/client";
import {
  createDepartmentPlan,
  createDepartmentPlanVariables,
} from "../../../../graphql/__generated-types__/createDepartmentPlan";
import { CREATE_DEPARTMENT_PLAN_MUTATION } from "../../../../graphql/mutations";
import { serviceMenuRoutes } from "../../../../navigation/routes";
import { toast } from "react-toastify";
import { DepartmentPlanForm, IDepartmentPlanForm } from "../components";
import { useMe } from "../../../../hooks";
import { DEPARTMENT_PLAN_FRAGMENT } from "../../../../graphql/fragments";

const DepartmentPlanCreate = ({ onClose }) => {
  const { data: userData } = useMe();
  const navigate = useNavigate();
  const [createDepartmentPlan] = useMutation<
    createDepartmentPlan,
    createDepartmentPlanVariables
  >(CREATE_DEPARTMENT_PLAN_MUTATION, {
    onCompleted: (createDepartmentPlanData) => {
      if (
        createDepartmentPlanData &&
        createDepartmentPlanData.createDepartmentPlan.ok
      ) {
        navigate(serviceMenuRoutes.departmentPlans.path);
      } else {
        toast.error(createDepartmentPlanData.createDepartmentPlan.error);
      }
    },
    update(cache, { data: { createDepartmentPlan } }) {
      if (createDepartmentPlan?.departmentPlan?.id) {
        const newDepartmentPlanRef = cache.writeFragment({
          // @ts-ignore
          id: cache.identify(createDepartmentPlan?.departmentPlan),
          data: createDepartmentPlan?.departmentPlan,
          fragment: DEPARTMENT_PLAN_FRAGMENT,
          fragmentName: "DepartmentPlanParts",
        });
        cache.modify({
          id: "ROOT_QUERY",
          fields: {
            findAllDepartmentPlans(prev) {
              return {
                ...prev,
                departmentPlans: [
                  newDepartmentPlanRef,
                  ...prev.departmentPlans,
                ],
              };
            },
          },
        });
      }
    },
  });

  const onSubmit = async (data: IDepartmentPlanForm) => {
    if (!!userData?.me?.user?.headOfDepartment?.length) {
      // Руководитель отдел(ов)
    } else if (!userData?.me?.user?.subordinates.length) {
      data.userId = userData?.me?.user?.id;
      data.departmentId = userData?.me?.user?.department?.id;
    }
    const responseData = await createDepartmentPlan({
      variables: {
        input: {
          ...data,
        },
      },
    });
    if (responseData.data.createDepartmentPlan.ok) {
      onClose(true);
    }
  };

  return <DepartmentPlanForm onSubmit={onSubmit} onClose={onClose} />;
};

export default DepartmentPlanCreate;
