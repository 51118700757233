import { gql } from "@apollo/client";
import { CLIENT_FRAGMENT } from "../fragments";

export const FIND_ONE_CLIENT_QUERY = gql`
  query findOneClient($input: FindOneClientInput!) {
    findOneClient(input: $input) {
      ok
      error
      client {
        ...ClientParts
      }
    }
  }
  ${CLIENT_FRAGMENT}
`;
