import { StoreObject, useMutation } from "@apollo/client";
import { BUYING_CASHBACK_RULE_FRAGMENT } from "graphql/fragments";
import { UPDATE_BUYING_CASHBACK_RULE_MUTATION } from "graphql/mutations/updateBuyingCashbackRule";
import { BuyingCashbackRuleParts } from "graphql/__generated-types__/BuyingCashbackRuleParts";
import {
  updateBuyingCashbackRule,
  updateBuyingCashbackRuleVariables,
} from "graphql/__generated-types__/updateBuyingCashbackRule";
import { cashbackRulesRoutes } from "navigation/routes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataLoader } from "../../../../components";
import { BuyingCashbackRuleForm, IBuyingCashbackRuleForm } from "../components";

interface IBuyingCashbackRuleEditProps {
  buyingCashbackRule: BuyingCashbackRuleParts;
  onClose: () => void;
}

const BuyingCashbackRuleEdit: React.FC<IBuyingCashbackRuleEditProps> = ({
  buyingCashbackRule,
  onClose,
}) => {
  const navigate = useNavigate();

  const [updateBuyingCashbackRule] = useMutation<
    updateBuyingCashbackRule,
    updateBuyingCashbackRuleVariables
  >(UPDATE_BUYING_CASHBACK_RULE_MUTATION, {
    onCompleted: (data) => {
      if (data?.updateBuyingCashbackRule?.ok) {
        navigate(cashbackRulesRoutes.buyingCashbackRules.path);
      } else {
        toast.error(data?.updateBuyingCashbackRule?.error);
      }
    },
    update(cache, { data: { updateBuyingCashbackRule } }) {
      if (updateBuyingCashbackRule?.buyingCashbackRule?.id) {
        cache.writeFragment({
          id: cache.identify(
            updateBuyingCashbackRule?.buyingCashbackRule as unknown as StoreObject
          ),
          data: updateBuyingCashbackRule.buyingCashbackRule,
          fragment: BUYING_CASHBACK_RULE_FRAGMENT,
          fragmentName: "BuyingCashbackRuleParts",
        });
      }
    },
  });

  const onSubmit = async (data: IBuyingCashbackRuleForm) => {
    const response = await updateBuyingCashbackRule({
      variables: {
        input: {
          ...data,
          id: +buyingCashbackRule.id,
          fallbackCashbackPerc: data?.fallbackCashbackPerc / 100,
          cashbackIntervals: data?.cashbackIntervals?.map((interval) => ({
            validFrom: interval?.validFrom,
            validTo: interval?.validTo,
            minTurnover: +interval?.minTurnover,
            maxTurnover: +interval?.maxTurnover,
            cashbackPerc: +interval?.cashbackPerc / 100,
          })),
        },
      },
    });
    if (response?.data?.updateBuyingCashbackRule?.ok) {
      onClose();
    }
  };

  return buyingCashbackRule ? (
    <BuyingCashbackRuleForm
      buyingCashbackRule={buyingCashbackRule}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  ) : (
    <DataLoader />
  );
};

export default BuyingCashbackRuleEdit;
