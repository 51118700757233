import { UserParts } from "graphql/__generated-types__/UserParts";
import { classNames } from "utils";
import { PersonCard } from "./PersonCard";

export interface IPeopleListProps {
  people: UserParts[];
  wide?: boolean;
}

export const PeopleListContainer = ({ people, wide }: IPeopleListProps) => {
  return (
    <div className="lg:col-span-2">
      <ul
        className={classNames(
          wide ? "lg:grid-cols-4" : "lg:grid-cols-3",
          "space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8"
        )}
      >
        {people.map((person) => (
          <PersonCard key={person.id} person={person} />
        ))}
      </ul>
    </div>
  );
};
