import {gql} from "@apollo/client";
import {PBT_REPORT_FRAGMENT, } from "graphql/fragments";

export const FIND_ONE_PBT_REPORT_QUERY = gql`
  query findOnePbtReport($input: FindOnePbtReportInput!) {
    findOnePbtReport(input: $input) {
      ok
      error
      pbtReport {
        ...PbtReportParts
      }
    }
  }
  ${PBT_REPORT_FRAGMENT}
`;
