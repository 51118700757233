import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  PrimaryButton,
  DataLoader,
  ReactSelectInput,
  LabeledTextInputWithTrailingAddon,
  LabeledDateInput,
  LabeledMonthInput,
} from "components";
import { conditionStatusMap } from "config/mappings";
import { CALCULATE_BUYING_CASHBACK_MUTATION } from "graphql/mutations/calculateBuyingCashback";
import { LIST_BUYING_CASHBACK_RULES_QUERY } from "graphql/queries";
import { BuyingCashbackTransactionParts } from "graphql/__generated-types__/BuyingCashbackTransactionParts";
import {
  calculateBuyingCashback,
  calculateBuyingCashbackVariables,
} from "graphql/__generated-types__/calculateBuyingCashback";
import {
  BuyingCashbackTransactionType,
  BuyingConditionType,
} from "graphql/__generated-types__/globalTypes";
import {
  listBuyingCashbackRules,
  listBuyingCashbackRulesVariables,
} from "graphql/__generated-types__/listBuyingCashbackRules";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { classNames } from "utils";

export interface IBuyingCashbackTransactionForm {
  clientId: number;
  vendorId: number;
  transactionType: BuyingCashbackTransactionType;
  turnover?: number;
  cashbackPeriod?: string;
  cashbackSum: number;
  buyingConditionType: BuyingConditionType;
  outcomeAfterIncome: boolean;
}

interface IBuyingCashbackTransactionFormProps {
  buyingCashbackTransaction?: BuyingCashbackTransactionParts;
  mode: BuyingCashbackTransactionType;
  editMode: boolean;
  onSubmit: (data: any) => void;
  onClose: () => void;
}

export const BuyingCashbackTransactionForm: React.FC<
  IBuyingCashbackTransactionFormProps
> = ({ buyingCashbackTransaction, mode, editMode, onSubmit, onClose }) => {
  const { handleSubmit, control, setValue, watch } =
    useForm<IBuyingCashbackTransactionForm>({
      mode: "onSubmit",
      shouldUnregister: true,
      defaultValues: buyingCashbackTransaction
        ? {
            ...buyingCashbackTransaction,
            clientId: buyingCashbackTransaction?.client?.id,
            vendorId: buyingCashbackTransaction?.vendor?.id,
          }
        : {
            clientId: null,
            vendorId: null,
            cashbackPeriod: new Date(
              new Date(new Date().setUTCDate(1)).setUTCHours(0, 0, 0, 0)
            ).toISOString(),
            cashbackSum: null,
            turnover: null,
            outcomeAfterIncome: false,
          },
    });

  const { data: cashbackRulesData, loading: cashbackRulesLoading } = useQuery<
    listBuyingCashbackRules,
    listBuyingCashbackRulesVariables
  >(LIST_BUYING_CASHBACK_RULES_QUERY, {
    variables: { input: {} },
  });

  const [calculateCashback] = useMutation<
    calculateBuyingCashback,
    calculateBuyingCashbackVariables
  >(CALCULATE_BUYING_CASHBACK_MUTATION, {
    variables: {
      input: {
        clientId: watch("clientId"),
        vendorId: watch("vendorId"),
        turnover: watch("turnover")
          ? parseFloat(
              watch("turnover")
                .toString()
                .replace(/[a-zA-Z]/g, "")
                .replace(/\s/g, "")
                .replace(/[,]/g, ".")
            )
          : 0,
        cashbackPeriod: watch("cashbackPeriod"),
        buyingConditionType: watch("buyingConditionType"),
      },
    },
    onCompleted: (data) => {
      if (data.calculateBuyingCashback.ok)
        setValue(
          "cashbackSum",
          Math.round(
            (data.calculateBuyingCashback.cashbackSum + Number.EPSILON) * 100
          ) / 100
        );
      else toast.error(data.calculateBuyingCashback.error);
    },
  });

  const label = buyingCashbackTransaction
    ? "Редактирование транзакции биллингового кэшбека"
    : "Добавление транзакции биллингового кэшбека";

  return !cashbackRulesLoading ? (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {label}
            </h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-12 gap-6">
              {/* Дата */}
              {mode === BuyingCashbackTransactionType.INCOME ? (
                <div className="col-span-3">
                  <Controller
                    control={control}
                    name="cashbackPeriod"
                    render={({ field, fieldState }) => (
                      <LabeledMonthInput
                        labelText="Дата"
                        field={field}
                        name={field.name}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </div>
              ) : (
                <div className="col-span-3">
                  <Controller
                    control={control}
                    name="cashbackPeriod"
                    render={({ field, fieldState }) => (
                      <LabeledDateInput
                        labelText="Дата"
                        field={field}
                        name={field.name}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </div>
              )}
              {/* Клиенты */}
              <div
                className={
                  mode === BuyingCashbackTransactionType.INCOME
                    ? "col-span-3"
                    : "col-span-4"
                }
              >
                <Controller
                  control={control}
                  name="clientId"
                  rules={{
                    required: "Нужно выбрать клиента",
                  }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={[
                        ...new Set(
                          cashbackRulesData?.listBuyingCashbackRules?.buyingCashbackRules?.map(
                            (bcr) => bcr?.client
                          )
                        ),
                      ]?.map((client) => {
                        return {
                          label: client?.title,
                          value: client?.id,
                        };
                      })}
                      defaultValue={field.value}
                      name="clientId"
                      value={field.value}
                      labelText="Клиент"
                      onSelect={(e) => {
                        setValue("clientId", e);
                      }}
                      placeholder="Выберите клиента из списка"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              {/* Площадки */}
              <div
                className={
                  mode === BuyingCashbackTransactionType.INCOME
                    ? "col-span-3"
                    : "col-span-4"
                }
              >
                <Controller
                  control={control}
                  name="vendorId"
                  rules={{
                    required: "Нужно выбрать площадку",
                  }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={[
                        ...new Set(
                          cashbackRulesData?.listBuyingCashbackRules?.buyingCashbackRules?.map(
                            (bcr) => bcr?.vendor
                          )
                        ),
                      ]?.map((vendor) => {
                        return {
                          label: vendor?.title,
                          value: vendor?.id,
                        };
                      })}
                      defaultValue={field.value}
                      name="vendorId"
                      value={field.value}
                      labelText="Площадка"
                      onSelect={(e) => {
                        setValue("vendorId", e);
                      }}
                      placeholder="Выберите площадку из списка"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              {/* Тип оплаты */}
              <div
                className={
                  mode === BuyingCashbackTransactionType.INCOME
                    ? "col-span-3"
                    : "col-span-4"
                }
              >
                <Controller
                  control={control}
                  name="buyingConditionType"
                  rules={{
                    required: "Нужно заполнить тип оплаты",
                  }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={Object.values(BuyingConditionType).map((cs) => {
                        return {
                          label: conditionStatusMap[cs],
                          value: cs,
                        };
                      })}
                      defaultValue={field.value}
                      name="buyingConditionType"
                      value={field.value}
                      labelText="Тип оплаты"
                      onSelect={(e) => setValue("buyingConditionType", e)}
                      placeholder="Тип оплаты"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              {/* Оборот */}
              {mode === BuyingCashbackTransactionType.INCOME && (
                <div className="col-span-3">
                  <Controller
                    control={control}
                    name="turnover"
                    render={({ field, fieldState }) => (
                      <LabeledTextInputWithTrailingAddon
                        labelText="Оборот"
                        addonText="руб."
                        value={field.value ?? 0}
                        defaultValue={field.value}
                        onBlur={(e) => {
                          field.onChange(
                            (
                              Math.round(
                                (parseFloat(
                                  e.target.value
                                    .replace(/[a-zA-Z]/g, "")
                                    .replace(/\s/g, "")
                                ) +
                                  Number.EPSILON) *
                                  100
                              ) / 100
                            ).toLocaleString()
                          );
                        }}
                        onFocus={(e) => {
                          field.onChange(
                            e.target.value
                              .replace(/[a-zA-Z]/g, "")
                              .replace(/\s/g, "")
                              .replace(/[,]/g, ".")
                          );
                        }}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        name={field.name}
                        fieldState={fieldState}
                        type="text"
                      />
                    )}
                  />
                </div>
              )}

              {/* Кнопка */}
              {mode === BuyingCashbackTransactionType.INCOME && (
                <div className="col-span-3 self-end text-center">
                  <PrimaryButton
                    type="button"
                    onClick={() => calculateCashback()}
                    disabled={
                      !watch("clientId") ||
                      !watch("vendorId") ||
                      !watch("turnover") ||
                      !watch("buyingConditionType") ||
                      !watch("cashbackPeriod")
                    }
                  >
                    Рассчитать кэшбек
                  </PrimaryButton>
                </div>
              )}

              {/* Кэшбек */}
              <div
                className={
                  mode === BuyingCashbackTransactionType.INCOME
                    ? "col-span-3"
                    : "col-span-4"
                }
              >
                <Controller
                  control={control}
                  name="cashbackSum"
                  render={({ field, fieldState }) => (
                    <LabeledTextInputWithTrailingAddon
                      labelText="Кэшбек"
                      addonText="руб."
                      value={field.value ?? "0"}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      step="0.01"
                    />
                  )}
                />
              </div>

              {/* Кнопка */}
              {mode === BuyingCashbackTransactionType.INCOME && (
                <div
                  className={classNames(
                    "col-span-3 self-center text-center",
                    editMode ? "invisible" : "visible"
                  )}
                >
                  <Controller
                    control={control}
                    name="outcomeAfterIncome"
                    render={({ field }) => (
                      <>
                        <label className="block text-sm font-medium text-gray-700">
                          Списать сразу
                        </label>
                        <div className="mt-1 block rounded-md self-center">
                          <input
                            type="checkbox"
                            onClick={() => {
                              field.onChange(!field.value);
                            }}
                            checked={field.value}
                            defaultChecked={false}
                            className={classNames(
                              field.value ? "bg-red-600" : "bg-gray-200",
                              "inline-flex flex-shrink-0 w-9 h-9 text-red-600 bg-gray-100 rounded border-gray-300 focus:ring-red-500 dark:focus:ring-red-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            )}
                          />
                        </div>
                      </>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-x-3">
        <Button type="button" onClick={() => onClose()}>
          Отмена
        </Button>
        <PrimaryButton type="submit">Сохранить</PrimaryButton>
      </div>
    </form>
  ) : (
    <DataLoader />
  );
};
