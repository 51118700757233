import {useForm} from "react-hook-form";
import {useRef} from "react";
import {ExclamationIcon} from "@heroicons/react/outline";
import {Dialog} from "@headlessui/react";
import {PrimaryButton} from "../../../../components";


interface ICaseDeleteFormProps {
  onSubmit: () => void;
  onClose: () => void;
}

export const CaseDeleteForm: React.FC<ICaseDeleteFormProps> = ({onSubmit, onClose}) => {
  const {handleSubmit} = useForm({
    mode: "onSubmit",
    shouldUnregister: true,
  });

  const cancelButtonRef = useRef(null);

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div
            className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              Удаление загрузки сотрудника
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Вы уверены, что хотите удалить данный кейс?
                Данное действие не может быть отменено.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <PrimaryButton
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Удалить
        </PrimaryButton>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => onClose()}
          ref={cancelButtonRef}
        >
          Нет
        </button>
      </div>
    </form>
  )
};
