import { gql } from "@apollo/client";
import { BUYING_CASHBACK_RULE_FRAGMENT } from "graphql/fragments";

export const UPDATE_BUYING_CASHBACK_RULE_MUTATION = gql`
  mutation updateBuyingCashbackRule($input: UpdateBuyingCashbackRuleInput!) {
    updateBuyingCashbackRule(input: $input) {
      ok
      error
      buyingCashbackRule {
        ...BuyingCashbackRuleParts
      }
    }
  }
  ${BUYING_CASHBACK_RULE_FRAGMENT}
`;
