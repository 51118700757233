import { vendorIconMapForMissingCertificates } from "config/mappings";
import { VendorParts } from "graphql/__generated-types__/VendorParts";
import { classNames } from "utils";

interface IMissingCertificateInline {
    missingCertificate?: VendorParts;
}

const MissingCertificateInline: React.FC<IMissingCertificateInline> = ({ missingCertificate }) => {
  return (
    <div className="flex justify-between items-center w-full">
      <div className="flex items-center gap-4">
        <img className="h-4 w-4" src={`/services/${vendorIconMapForMissingCertificates[missingCertificate?.title]?.icon}`} alt={vendorIconMapForMissingCertificates[missingCertificate?.title]?.title}/>
        <span>
            {vendorIconMapForMissingCertificates[missingCertificate?.title]?.title}
        </span>
      </div>
      <div>
        <span
          className={classNames(
            "bg-yellow-100 text-yellow-800",
            "w-28 inline-flex justify-center items-center px-2.5 py-0.5 rounded-full text-xs font-medium "
          )} 
        >
          { "Отсуствует" }
        </span>
      </div>
    </div>
  );
};

export default MissingCertificateInline;
