import { Menu, Transition } from "@headlessui/react";
import { usefullIconsMap } from "config/mappings";
import { Fragment } from "react";
import { classNames } from "utils";
import "pages/Home/components/HomepageStyles.css"
import { UserRole } from "graphql/__generated-types__/globalTypes";
import { useMe } from "hooks";

const Sidebar = () => {

  const user = useMe();
    
  const socials = [
    {
      title: 'Facebook',
      url: 'https://www.facebook.com/Artics',
    },
    {
      title: 'VK',
      url: 'https://vk.com/articsru',
    },
    {
      title: 'Instagram',
      url: 'https://www.instagram.com/artics_internet_solutions/',
    },
  ];
  const chats = [
    {
      title: 'Афишал',
      icon: 'Afishal',
      url: 'https://t.me/+xC07Mu5RNKNlMmYy',
      description: 'Афишал'
    },
    {
      title: 'Техподдержка',
      icon: 'Techsupport',
      url: 'https://t.me/+ylzbKjTcWrA4OWEy',
      description: 'Техподдержка'
    },
    {
      title: 'Барахолка',
      icon: 'FleaMarket',
      url: 'https://t.me/+V7H-2dwlMio0Zjcy',
      description: 'Барахолка'
    },
    {
      title: 'Вопросы по офису МСК',
      icon: 'MSKQA',
      url: 'https://t.me/+Nn0WJC1ueEpkMTBi',
      description: 'Вопросы по офису МСК'
    },
    {
      title: 'Вопросы по офису СПБ',
      icon: 'SPBQA',
      url: 'https://t.me/+EnblhxTAPvhlOGRi',
      description: 'Вопросы по офису СПБ'
    },
    {
      title: 'Артикс Новаторы',
      icon: 'Novators',
      url: 'https://t.me/+aFwQRStkY-85YTli',
      description: 'Артикс Новаторы'
    },
  ];
  const channels = [
    {
      title: 'Артикс Новости',
      icon: 'ANews',
      url: 'https://t.me/+3yHyCgnChP1kNDJi',
      description: 'Артикс Новости'
    },
    {
      title: 'Смена',
      icon: 'BuroSmena',
      url: 'https://t.me/burosmena',
      description: 'Смена'
    },
    {
      title: 'TrueDigital',
      icon: 'TrueDigital',
      url: 'https://t.me/truedigitalclub',
      description: 'TrueDigital'
    },
  ];

    return (
      <div id="sidebar" className={`flex items-top max-h-screen lg:ml-0 w-0 maxhsm:invisible mr-10 lg:visible lg:w-4/12
      ${(user?.data?.me?.user?.roles.includes(UserRole.PR_ADMIN) || user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) 
        ? 'mt-78'
        : 'mt-5'}
      `}>
        <div className="fixed maxhsm:w-0 lg:w-4/12">
                {/*Наши соц. сети*/}
                <div>
                <h3 className="text-center md:text-xs xl:text-sm 2xl:text-base font-medium text-gray-900">Наши соц. сети</h3>
                <dl className="grid gap-0 grid-cols-3 grid-row-2">
                    {socials.map((social) => ( 
                    <div key={social.title} className="overflow-hidden flex-auto text-center md:text-xs xl:text-sm 2xl:text-base rounded-lg px-2 py-2 sm:p-2 w-auto">
                        <a target='_blank' href={social?.url} rel="noreferrer">
                          <img className="aspect-4-3:h-8 aspect-5-4:h-10 aspect-16-10:h-16 aspect-16-9:h-12 hsm:h-10 hmd:h-12 hxl:h-14 h2xl:h-16 rounded-full m-auto" src={`/services/${usefullIconsMap[social.title].icon}`} alt="" />
                          <dt>{social?.title}</dt>
                        </a>
                    </div>
                    ))}
                </dl>
                </div>
                {/*Наши соц. сети*/}
                {/*Наши чаты*/}
                <div>
                <h3 className="text-center md:text-xs xl:text-sm 2xl:text-base font-medium text-gray-900">Наши чаты</h3>
                <dl className="grid gap-0 grid-cols-3 grid-row-2">
                    {chats.map((chat) => ( 
                    <div key={chat.title} className="overflow-hidden flex-auto text-center md:text-xs xl:text-sm 2xl:text-base rounded-lg px-2 py-2 sm:p-2 w-auto">
                        <a target='_blank' href={chat.url} title={chat?.description} rel="noreferrer" >
                          <img className="aspect-4-3:h-8 aspect-5-4:h-10 aspect-16-10:h-16 aspect-16-9:h-12 hsm:h-8 hmd:h-12 hxl:h-14 h2xl:h-16 rounded-full m-auto" src={`/services/${usefullIconsMap[chat.icon].icon}`} alt={chat.description} />
                          <dt className="md:text-xs xl:text-sm 2xl:text-base">{chat.title}</dt>
                        </a>
                    </div>
                    ))}
                </dl>
                </div>
                {/*Наши чаты*/}
                {/*Наши каналы*/}
                <div>
                <h3 className="text-center md:text-xs xl:text-sm 2xl:text-base font-medium text-gray-900">Наши каналы</h3>
                <dl className="grid grid-rows-1 gap-0 sm:grid-cols-3">
                    {channels.map((channel) => ( 
                    <div key={channel.title} className="overflow-hidden flex-auto text-center md:text-xs xl:text-sm 2xl:text-base rounded-lg px-2 py-2 sm:p-2 w-auto">
                        <a target='_blank' href={channel.url} title={channel?.description} rel="noreferrer">
                          <img className="aspect-4-3:h-8 aspect-5-4:h-10 aspect-16-10:h-16 aspect-16-9:h-12 hsm:h-8 hmd:h-12 hxl:h-14 h2xl:h-16 rounded-full m-auto" src={`/services/${usefullIconsMap[channel.icon].icon}`} alt={channel.description} />
                          <dt className="md:text-xs xl:text-sm 2xl:text-base">{channel.title}</dt>
                        </a>
                    </div>
                    ))}
                </dl>
                </div>
                {/*Наши каналы*/}
                {/* Полезные ссылки?? */}
                <div className="text-center">
                <hr className=" my-2 border-black hlg:text-base hmd:text-sm hxl:text-lg font-medium text-gray-900"></hr>
                <dl className="mt-3 grid grid-rows-2 sm:gap-1 md:gap-3 grid-cols-3 w-auto">
                    {/* Правила офис МСК */}
                    <div className="overflow-hidden flex-auto rounded-lg bg-white px-2 py-2 shadow hsm:p-2 md:text-xs xl:text-sm 2xl:text-base">
                        <a target='_blank' href='https://wiki.artics.ru/doc/pravila-ofisa-v-moskve-VPgOpZM6WU' rel="noreferrer">
                              <img className="aspect-4-3:h-4 aspect-5-4:h-6 aspect-16-10:h-8 aspect-16-9:h-7 hsm:h-4 hmd:h-6 hxl:h-7 h2xl:h-8 m-auto" src={`/services/${usefullIconsMap["MSKQA_bottom"].icon}`} alt='' />
                          <dt className="md:text-xs xl:text-sm 2xl:text-base">Правила офиса МСК+ пароли Wifi</dt>
                        </a>
                    </div>
                    {/* Правила офис МСК */}
                    {/* Забронировать рабочее место */}
                    <div className="overflow-hidden flex-auto rounded-lg bg-white px-2 py-2 shadow hsm:p-2">
                        <a target='_blank' href='http://map.artics.ru/Artics/#' rel="noreferrer">
                              <img className="aspect-4-3:h-4 aspect-5-4:h-6 aspect-16-10:h-8 aspect-16-9:h-7 hsm:h-4 hmd:h-6 hxl:h-7 h2xl:h-8 m-auto" src={`/services/${usefullIconsMap["Book_bottom"].icon}`} alt='' />
                          <dt className="md:text-xs xl:text-sm 2xl:text-base">Забронировать рабочее место</dt>
                        </a>
                    </div>
                    {/* Забронировать рабочее место */}
                    {/* Этика переписки */}
                    <div className="overflow-hidden flex-auto rounded-lg bg-white px-2 py-2 shadow hsm:p-2">
                        <a target='_blank' href='https://wiki.artics.ru/doc/delovaya-perepiska-1Kovgk4sJM' rel="noreferrer">
                              <img className="aspect-4-3:h-4 aspect-5-4:h-6 aspect-16-10:h-8 aspect-16-9:h-7 hsm:h-4 hmd:h-6 hxl:h-7 h2xl:h-8 m-auto" src={`/services/${usefullIconsMap["Etiquette_bottom"].icon}`} alt='' />
                          <dt className="md:text-xs xl:text-sm 2xl:text-base">Этика переписки</dt>
                        </a>
                    </div>
                    {/* Этика переписки */}
                    {/* Правила офиса СПБ + пароли Wifi */}
                    <div className="overflow-hidden flex-auto rounded-lg bg-white px-2 py-2 shadow hsm:p-2">
                        <a target='_blank' href='https://wiki.artics.ru/doc/pravila-ofisa-v-sankt-peterburge-BiOIjx9acP' rel="noreferrer">
                              <img className="aspect-4-3:h-4 aspect-5-4:h-6 aspect-16-10:h-8 aspect-16-9:h-7 hsm:h-4 hmd:h-6 hxl:h-7 h2xl:h-8 m-auto" src={`/services/${usefullIconsMap["SPBQA_bottom"].icon}`} alt='' />
                          <dt className="md:text-xs xl:text-sm 2xl:text-base">Правила офиса СПБ + пароли Wifi</dt>
                        </a>
                    </div>
                    {/* Правила офиса СПБ + пароли Wifi */}
                    {/* Рабочие инструменты< */}
                        <Menu as="div" className="overflow-hidden flex-auto rounded-lg bg-white px-2 py-2 shadow hsm:p-2">
                        <div>
                            <Menu.Button className="">
                              <img className="aspect-4-3:h-4 aspect-5-4:h-6 aspect-16-10:h-8 aspect-16-9:h-7 hsm:h-4 hmd:h-6 hxl:h-7 h2xl:h-8 m-auto" src={`/services/${usefullIconsMap["Tools_bottom"].icon}`} alt='' />
                              <dt className="md:text-xs xl:text-sm 2xl:text-base">Рабочие инструменты</dt>
                            </Menu.Button>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="-mt-12 absolute right-0 z-10 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                                <Menu.Item>
                                {({ active }) => (
                                    <a
                                    target='_blank' href="https://123.artics.ru"
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block px-4 py-2 text-sm'
                                    )} rel="noreferrer"
                                    >
                                    <img src={`/services/${usefullIconsMap["123"].icon}`} className="h-8 w-8 rounded-full mx-auto" alt=""/>
                                    Артикс.Цифры
                                    </a>
                                )}
                                </Menu.Item>
                                <Menu.Item>
                                {({ active }) => (
                                    <a
                                    target='_blank' href="https://passwork.artics.ru/"
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block px-4 py-2 text-sm'
                                    )} rel="noreferrer"
                                    >
                                    <img src={`/services/${usefullIconsMap["pw"].icon}`} className="h-8 w-8 rounded-full mx-auto" alt=""/>
                                    Пароли
                                    </a>
                                )}
                                </Menu.Item>
                            </div>
                            </Menu.Items>
                        </Transition>
                        </Menu>
                    {/* Рабочие инструменты< */}
                    {/* ПКак называть рабочие чаты */}
                    <div className="overflow-hidden flex-auto text-center rounded-lg bg-white px-2 py-2 shadow hsm:p-2">
                        <a target='_blank' href='https://wiki.artics.ru/doc/rabochie-chaty-v-telegram-JVekPqi0p6' rel="noreferrer">
                          <img className="aspect-4-3:h-4 aspect-5-4:h-6 aspect-16-10:h-8 aspect-16-9:h-7 hsm:h-4 hmd:h-6 hxl:h-7 h2xl:h-8 m-auto" src={`/services/${usefullIconsMap["Naming_bottom"].icon}`} alt='' />
                          <dt className="md:text-xs xl:text-sm 2xl:text-base">Как называть рабочие чаты</dt>
                        </a>
                    </div>
                    {/* Как называть рабочие чаты */}
                </dl>
                </div>
                {/* Полезные ссылки?? */}
            </div>
          </div>
    )
}
export default Sidebar;