import { gql } from "@apollo/client";
import { USER_FRAGMENT } from "graphql/fragments";

export const LIST_EMPLOYEE_POSITIONS_QUERY = gql`
  query listEmployeePositions($input: ListEmployeePositionsInput!) {
    listEmployeePositions(input: $input) {
      ok
      error
      positions {
        id
        title
        description
        employees {
          ...UserParts
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;
