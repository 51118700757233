import "dotenv/config";
import {useMutation} from "@apollo/client";
import { LOCALSTORAGE_TOKEN } from "config/constants";
import {LOGIN_MUTATION} from "graphql/mutations";
import {
  loginMutation,
  loginMutationVariables,
} from "graphql/__generated-types__/loginMutation";
import { YandexLogin } from "../../services/yandex-login";
import { authTokenVar, isLoggedInVar } from "services/apollo";
import artics_logo_square from "assets/aportal.png";
import { PrimaryButton } from "components";
import {CREATE_LOGINIP_MUTATION} from "../../graphql/mutations/createLoginIp";
import {createLoginIp, createLoginIpVariables} from "../../graphql/__generated-types__/createLoginIp";
import dotenv from "dotenv";
dotenv.config({path: "../../../.env" });

const Login = () => {
  const [loginMutation] = useMutation<loginMutation, loginMutationVariables>(
    LOGIN_MUTATION,
    {
      onCompleted: (loginMutationResult) => {
        const {
          login: { ok, error, token },
        } = loginMutationResult;
        if (ok && token) {
          localStorage.setItem(LOCALSTORAGE_TOKEN, token);
          authTokenVar(token);
          isLoggedInVar(true);
        } else {
          console.log(error);
        }
      },
    }
  );

  const getIp = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error(error);
      return null; // Handle the error gracefully
    }
  };

  const [createLoginIpMutation] = useMutation<createLoginIp, createLoginIpVariables> (
      CREATE_LOGINIP_MUTATION, {
        onCompleted: (createLoginIpMutationResult) => {
          const { createLoginIp: {ok, error}
          } = createLoginIpMutationResult;
          if (ok) {

          } else {
            console.log(error);
          }
        }
      })

  const yandexLogin = (userData: any) => {
    if (userData?.access_token)
      loginMutation({
        variables: {loginInput: {accessToken: userData.access_token}},
      }).then(async response => {
        if (process.env.REACT_APP_EMAILS) {
          const email = response.data.login.me.email;
          const emails: string[] = process.env.REACT_APP_EMAILS.split(";");
          if (response.data.login.token && emails.includes(email)) {
            const ip = await getIp();
            createLoginIpMutation({
                  variables: {input: {ip: ip, email: email}},
                }
            ).then().catch(console.log)
          }
        }
      })
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 flex flex-col items-center">
        <div className="flex justify-center items-center">
          <img className="h-18" src={artics_logo_square} alt="Workflow" />
        </div>
        <div>
          <YandexLogin
            clientID="3a0d7e71f7024d7d8e2ff79609aaab6a"
            onSuccess={yandexLogin}
            className="w-full flex flex-col items-center justify-center"
          >
            <PrimaryButton>Войти в аккаунт</PrimaryButton>
          </YandexLogin>
        </div>
      </div>
    </div>
  );
};

export default Login;
