import { useNavigate } from "react-router-dom";
import { StoreObject, useMutation } from "@apollo/client";
import {
  createSeller,
  createSellerVariables,
} from "../../../../graphql/__generated-types__/createSeller";
import { adminMenuRoutes } from "../../../../navigation/routes";
import { toast } from "react-toastify";
import { SELLER_FRAGMENT } from "../../../../graphql/fragments";
import { CREATE_SELLER_MUTATION } from "../../../../graphql/mutations";
import { AdminSellerForm, ISellerForm } from "../components";

const AdminSellerCreate = ({ onClose }) => {
  const navigate = useNavigate();

  const [createSeller] = useMutation<createSeller, createSellerVariables>(
    CREATE_SELLER_MUTATION,
    {
      onCompleted: (createSellerData) => {
        if (createSellerData?.createSeller?.ok) {
          navigate(adminMenuRoutes.sellers.path);
        } else {
          toast.error(createSellerData?.createSeller?.error);
        }
      },
      update(cache, { data: { createSeller } }) {
        if (createSeller?.seller?.id) {
          const newSellerRef = cache.writeFragment({
            id: cache.identify(createSeller?.seller as unknown as StoreObject),
            data: createSeller?.seller,
            fragment: SELLER_FRAGMENT,
            fragmentName: "SellerParts",
          });
          cache.modify({
            id: "ROOT_QUERY",
            fields: {
              listSellers(prev) {
                return {
                  ...prev,
                  sellers: [newSellerRef, ...prev.sellers],
                };
              },
            },
          });
        }
      },
    }
  );

  const onSubmit = async (data: ISellerForm) => {
    const responseData = await createSeller({
      variables: {
        input: {
          legalnameIds: data.legalnameIds,
          title: data.title,
        },
      },
    });
    if (responseData?.data?.createSeller?.ok) {
      onClose(true);
    }
  };

  return <AdminSellerForm onSubmit={onSubmit} onClose={onClose} />;
};

export default AdminSellerCreate;
