import { useQuery } from "@apollo/client";
import {
  Button,
  PrimaryButton,
  AvatarInput,
  LabeledDateInput,
  LabeledTextInput,
  LabeledTextInputWithAddon,
  LabeledToggleInput,
  SingleSelectInput,
  DataLoader,
  ReactSelectInput,
} from "components";
import { userGradesMap } from "config/mappings";
import {
  FIND_ALL_USERS_QUERY,
  LIST_EMPLOYEE_POSITIONS_QUERY,
} from "graphql/queries";
import { LIST_EMPLOYEE_DEPARTMENTS_SERVICE_QUERY } from "graphql/queries/listEmployeeDepartmentsForService";
import {
  findAllUsers,
  findAllUsersVariables,
} from "graphql/__generated-types__/findAllUsers";
import { findOneUser } from "graphql/__generated-types__/findOneUser";
import { EmployeeGrade, Office } from "graphql/__generated-types__/globalTypes";
import { listEmployeeDepartmentsForService, listEmployeeDepartmentsForServiceVariables } from "graphql/__generated-types__/listEmployeeDepartmentsForService";

import {
  listEmployeePositions,
  listEmployeePositionsVariables,
} from "graphql/__generated-types__/listEmployeePositions";
import { UserParts } from "graphql/__generated-types__/UserParts";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { prepareOptionsForSelect } from "utils";

export interface IUserForm
  extends Pick<
    UserParts,
    | "name"
    | "surname"
    | "middlename"
    | "gender"
    | "birthdate"
    | "employmentDate"
    | "isActive"
    | "isRemote"
    | "avatar"
    | "email"
    | "googleEmail"
    | "phone"
    | "telegram"
    | "office"
    | "priorityInsideDepartment"
  > {
  positionId: number;
  employeeHeadId: number;
  departmentId: number;
  grades: EmployeeGrade[];
}

interface IUserFormProps {
  userData: findOneUser | null;
  onSubmit: (data: any) => void;
}

export const UserForm: React.FC<IUserFormProps> = ({ userData, onSubmit }) => {
  const navigate = useNavigate();

  const { data: positionsData, loading: positionsLoading } = useQuery<
    listEmployeePositions,
    listEmployeePositionsVariables
  >(LIST_EMPLOYEE_POSITIONS_QUERY, {
    variables: {
      input: {
        includeUserData: false,
      },
    },
  });

  const { data: departmentsData, loading: departmentsLoading } = useQuery<
    listEmployeeDepartmentsForService,
    listEmployeeDepartmentsForServiceVariables
  >(LIST_EMPLOYEE_DEPARTMENTS_SERVICE_QUERY, {
    variables: {
      input: {
        includeUserData: false,
      },
    },
  });

  const { data: employeesData, loading: employeesLoading } = useQuery<
    findAllUsers,
    findAllUsersVariables
  >(FIND_ALL_USERS_QUERY, {
    variables: {
      input: {
        departmentIds: [],
        isActive: true,
      },
    },
  });

  const { handleSubmit, control, setValue } = useForm<IUserForm>({
    mode: "onSubmit",
    shouldUnregister: true,
    defaultValues: userData?.findOneUser?.user
      ? {
          ...userData.findOneUser.user,
          positionId: userData.findOneUser.user.position?.id,
          departmentId: userData.findOneUser.user.department?.id,
          employeeHeadId: userData.findOneUser.user.employeeHead?.id,
          grades: userData?.findOneUser?.user?.grades?.map((grade) => EmployeeGrade[grade]),
        }
      : {
          isActive: true,
          isRemote: false,
          office: Office.MSK,
          grades: [],
        },
  });

  /*
  const legalnamesOptions =
  legalnamesData &&
  legalnamesData?.listLegalnames?.legalnames?.map((p) => {
    return {
      label: `${p?.title}`,
      value: p?.id,
    };
  });
  */

  const gradeOptions = 
  userData &&
  userData?.findOneUser?.user?.grades.map((grade) => {
    return {
      label: userGradesMap[grade],
      value: grade,
    };
  })

  return !positionsLoading && !departmentsLoading && !employeesLoading ? (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Личные данные
            </h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="surname"
                  rules={{ required: "Нужно заполнить фамилию" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Фамилия"
                      autoComplete="family-name"
                      value={field.value || ""}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="name"
                  rules={{ required: "Нужно заполнить имя" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Имя"
                      autoComplete="given-name"
                      value={field.value || ""}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="middlename"
                  render={({ field }) => (
                    <LabeledTextInput
                      labelText="Отчество"
                      autoComplete="additional-name"
                      value={field.value || ""}
                      onChange={field.onChange}
                      name={field.name}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  rules={{ required: "Нужно заполнить пол сотрудника" }}
                  name="gender"
                  render={({ field, fieldState }) => (
                    <SingleSelectInput
                      labelText="Пол"
                      placeholder="Выберите пол"
                      options={[
                        { label: "Мужской", value: "M" },
                        { label: "Женский", value: "F" },
                      ]}
                      defaultValue={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  rules={{ required: "Нужно заполнить день рождения" }}
                  name="birthdate"
                  render={({ field, fieldState }) => (
                    <LabeledDateInput
                      labelText="День рождения"
                      field={field}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  rules={{ required: "Нужно заполнить дату трудоустройства" }}
                  name="employmentDate"
                  render={({ field, fieldState }) => (
                    <LabeledDateInput
                      labelText="Дата трудоустройства"
                      field={field}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="avatar"
                  //rules={{ required: "Нужно загрузить фото" }}
                  render={({ field, fieldState }) => (
                    <AvatarInput
                      labelText="Фото"
                      avatar={field.value}
                      field={field}
                      name={field.name}
                      alt={
                        userData
                          ? `${userData?.findOneUser.user.name} ${userData?.findOneUser.user.surname}`
                          : null
                      }
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="isActive"
                  render={({ field }) => (
                    <LabeledToggleInput
                      labelText="Работает сейчас"
                      checked={field.value}
                      field={field}
                      name={field.name}
                      onChange={(e) => {
                        setValue("isActive", !!e);
                      }}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="isRemote"
                  render={({ field }) => (
                    <LabeledToggleInput
                      labelText="Работает удаленно"
                      checked={field.value}
                      field={field}
                      name={field.name}
                      onChange={(e) => {
                        setValue("isRemote", !!e);
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Контактная информация
            </h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="space-y-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-3">
                  <Controller
                    control={control}
                    name="email"
                    rules={{
                      required: "Нужно заполнить рабочий email",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9.-]+(@artics[.]|@wizeo[.])((ru)|(com))$/,
                        message: "Указан неверный или некорпоративный email",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <LabeledTextInput
                        labelText="Рабочий email"
                        autoComplete="email"
                        value={field.value || ""}
                        onChange={field.onChange}
                        name={field.name}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </div>
                <div className="col-span-3">
                  <Controller
                    control={control}
                    name="googleEmail"
                    rules={{
                      pattern: {
                        value: /^[a-zA-Z0-9.-]+(@gmail[.])((com))$/,
                        message: "Указан неверный или некорпоративный email",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <LabeledTextInput
                        labelText="Альтернативный email"
                        autoComplete="email"
                        value={field.value || ""}
                        onChange={field.onChange}
                        name={field.name}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <Controller
                    control={control}
                    name="phone"
                    rules={{ required: "Нужно указать номер телефона" }}
                    render={({ field, fieldState }) => (
                      <LabeledTextInput
                        labelText="Телефон"
                        autoComplete="phone"
                        value={field.value || ""}
                        onChange={field.onChange}
                        name={field.name}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <Controller
                    control={control}
                    name="telegram"
                    rules={{
                      pattern: {
                        value: /^[^@]+$/,
                        message: "Удалите лишний символ @",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <LabeledTextInputWithAddon
                        labelText="Telegram"
                        addonText="@"
                        value={field.value || ""}
                        onChange={field.onChange}
                        name={field.name}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Должность и отдел
            </h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="space-y-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-2">
                  <Controller
                    control={control}
                    name="positionId"
                    rules={{ required: "Нужно указать должность" }}
                    render={({ field, fieldState }) => (
                      <SingleSelectInput
                        labelText="Должность"
                        options={
                          positionsData &&
                          prepareOptionsForSelect(
                            positionsData?.listEmployeePositions?.positions
                          )
                        }
                        fieldState={fieldState}
                        value={field.value}
                        onChange={(e) => field.onChange(+e.target.value)}
                        placeholder="Выберите должность из списка или введите название для поиска"
                      />
                    )}
                  />
                </div>
                <div className="col-span-6 sm:col-span-2">
                  <Controller
                    control={control}
                    name="employeeHeadId"
                    rules={{ required: "Нужно указать руководителя" }}
                    render={({ field, fieldState }) => (
                      <SingleSelectInput
                        labelText="Руководитель"
                        options={
                          employeesData &&
                          employeesData?.findAllUsers.users.map((user) => ({
                            label: `${user.surname} ${user.name}`,
                            value: user.id,
                          }))
                        }
                        fieldState={fieldState}
                        value={field.value}
                        onChange={(e) => field.onChange(+e.target.value)}
                        placeholder="Выберите руководителя из списка или введите ФИО для поиска"
                      />
                    )}
                  />
                </div>
                <div className="col-span-6 sm:col-span-2">
                  <Controller
                    control={control}
                    name="office"
                    rules={{ required: "Нужно указать офис" }}
                    render={({ field, fieldState }) => (
                      <SingleSelectInput
                        labelText="Офис"
                        placeholder="Выберите офис из списка"
                        options={[
                          { label: "Москва", value: "MSK" },
                          { label: "Санкт-Петербург", value: "SPB" },
                        ]}
                        defaultValue={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </div>
                <div className="col-span-6 sm:col-span-2">
                  <Controller
                    control={control}
                    name="departmentId"
                    rules={{ required: "Нужно указать отдел" }}
                    render={({ field, fieldState }) => (
                      <SingleSelectInput
                        options={
                          departmentsData &&
                          prepareOptionsForSelect(
                            departmentsData?.listEmployeeDepartmentsForService
                              ?.departments
                          )
                        }
                        defaultValue={field.value}
                        labelText="Отдел"
                        onChange={(e) => field.onChange(+e.target.value)}
                        placeholder="Выберите отдел из списка или введите название для поиска"
                        fieldState={fieldState}
                      />
                    )}
                  />
                </div>
                <div className="col-span-6 sm:col-span-2">
                  <Controller
                    control={control}
                    name="priorityInsideDepartment"
                    render={({ field, fieldState }) => (
                      <LabeledTextInput
                        labelText="Приоритет"
                        value={field.value ?? "0"}
                        onChange={(e) => {
                          const parsedValue = !isNaN(parseFloat(e.target.value))
                            ? parseFloat(e.target.value)
                            : "";
                          field.onChange(parsedValue);
                        }}
                        name={field.name}
                        fieldState={fieldState}
                        type="number"
                        step="1"
                      />
                    )}
                  />
                </div>
                <div className="col-span-6 sm:col-span-2">
                  <Controller
                  control={control}
                  name="grades"
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      name="grades"
                      id="grades"
                      isMulti={true}
                      options={Object.values(EmployeeGrade).map((e) => {
                        return { label: userGradesMap[e], value: e };
                      })}
                      value={field.value}
                      defaultValue={gradeOptions?.filter((option) =>
                        field?.value?.some((id) => id === option?.value)
                      )}
                      onSelect={(e) => {
                        setValue(
                          "grades",
                          e?.map((option) => option?.value)
                        );
                      }}
                      labelText="Грейды сотрудника"
                      placeholder="Выберите грейды из списка"
                      fieldState={fieldState}
                    />
                  )}
                />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-x-3">
        <Button type="button" onClick={() => navigate(-1)}>
          Отмена
        </Button>
        <PrimaryButton type="submit">Сохранить</PrimaryButton>
      </div>
    </form>
  ) : (
    <DataLoader />
  );
};
