import {gql} from "@apollo/client";

export const DELETE_LEGALNAME_MUTATION = gql`
  mutation deleteLegalname($input: DeleteLegalnameInput!) {
    deleteLegalname(input: $input) {
      ok
      error
    }
  }
`;
