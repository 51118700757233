import { StoreObject, useMutation } from "@apollo/client";
import { BUYING_TYPE_FRAGMENT } from "graphql/fragments";
import { UPDATE_BUYING_TYPE_MUTATION } from "graphql/mutations/updateBuyingType";
import { BuyingTypeParts } from "graphql/__generated-types__/BuyingTypeParts";
import {
  updateBuyingType,
  updateBuyingTypeVariables,
} from "graphql/__generated-types__/updateBuyingType";
import { adminMenuRoutes } from "navigation/routes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataLoader } from "../../../../components";
import { BuyingTypesForm, IBuyingTypesForm } from "../components";

interface IBuyingTypeEdit {
  buyingType: BuyingTypeParts;
  onClose: () => void;
}

const BuyingTypeEdit: React.FC<IBuyingTypeEdit> = ({ buyingType, onClose }) => {
  const navigate = useNavigate();

  const [updateBuyingType] = useMutation<
    updateBuyingType,
    updateBuyingTypeVariables
  >(UPDATE_BUYING_TYPE_MUTATION, {
    onCompleted: (updateBuyingTypeData) => {
      if (updateBuyingTypeData?.updateBuyingType?.ok) {
        navigate(adminMenuRoutes.buyingTypes.path);
      } else {
        toast.error(updateBuyingTypeData.updateBuyingType.error);
      }
    },
    update(cache, { data: { updateBuyingType } }) {
      if (updateBuyingType.buyingType && updateBuyingType.buyingType.id) {
        cache.writeFragment({
          id: cache.identify(
            updateBuyingType?.buyingType as unknown as StoreObject
          ),
          data: updateBuyingType.buyingType,
          fragment: BUYING_TYPE_FRAGMENT,
          fragmentName: "BuyingTypeParts",
        });
      }
    },
  });

  const onSubmit = async (data: IBuyingTypesForm) => {
    const responseData = await updateBuyingType({
      variables: {
        input: {
          ...data,
          id: +buyingType.id,
        },
      },
    });
    if (responseData.data.updateBuyingType.ok) {
      onClose();
    }
  };

  return buyingType ? (
    <BuyingTypesForm
      buyingType={buyingType}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  ) : (
    <DataLoader />
  );
};

export default BuyingTypeEdit;
