import { faWindows } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

interface IFormProps {
  keyword: string;
}

interface ISearchBarProps {
  value?: string;
  hideKbTooltip?: boolean;
}

export const SearchBar = ({ value, hideKbTooltip }: ISearchBarProps) => {
  const [focused, setFocused] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<IFormProps>();
  const navigate = useNavigate();
  const onSearchSubmit = () => {
    const { keyword } = getValues();
    navigate({
      pathname: "/team/search",
      search: `?keyword=${keyword}`,
    });
  };
  return (
    <form onSubmit={handleSubmit(onSearchSubmit)}>
      <label
        htmlFor="search"
        className="block text-sm font-medium text-gray-700"
      >
        Поиск по сотрудникам
      </label>
      <div className="mt-1 relative flex items-center">
        <input
          {...register("keyword", { required: true, minLength: 3 })}
          type="search"
          id="search"
          defaultValue={value}
          placeholder="ФИО, должность, email, телефон, telegram"
          className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
          aria-invalid={errors.keyword ? true : false}
          aria-describedby="keyword-error"
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
        {errors.keyword && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
        {!errors.keyword && !focused && !hideKbTooltip && (
          <div className="hidden md:flex absolute inset-y-0 right-0 py-1.5 pr-1.5">
            <kbd className="inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
              {window.navigator.userAgent.includes("Mac") ? (
                "⌘K"
              ) : (
                <>
                  <FontAwesomeIcon icon={faWindows} className="mr-1" />K
                </>
              )}
            </kbd>
          </div>
        )}
      </div>
      {errors.keyword && (
        <p className="mt-2 text-sm text-red-600" id="keyword-error">
          Введите минимум 3 символа
        </p>
      )}
    </form>
  );
};
