import { useQuery } from "@apollo/client";
import { useMe } from "../../../hooks";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  formatFullName,
  formatMonth,
  formatPercent,
  formatSums,
} from "../../../utils";
import {
  AvatarCell,
  DataLoader,
  DoubleRowCell,
  PrimaryButton,
  Modal,
  SelectColumnFilter,
  Table,
  SingleRowCell,
} from "../../../components";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import DepartmentPlanEdit from "./edit";
import DepartmentPlanDelete from "./delete";
import {
  findAllDepartmentPlans,
  findAllDepartmentPlansVariables,
} from "../../../graphql/__generated-types__/findAllDepartmentPlans";
import { FIND_ALL_DEPARTMENT_PLANS_QUERY } from "../../../graphql/queries";
import DepartmentPlanCreate from "./create";
import { DepartmentPlanParts } from "graphql/__generated-types__/DepartmentPlanParts";
import { LayoutButtonsContext } from "../ServiceLayout";
import { CellProps, FooterProps } from "react-table";
import { Helmet } from "react-helmet-async";

const DepartmentPlan = () => {
  const { setLayoutButtons } = useContext(LayoutButtonsContext);

  const hideEditingForm = useCallback(() => {
    setIsEditing(false);
    resetDepartmentPlan();
  }, []);

  const clickAddButton = useCallback(() => {
    hideEditingForm();
    setIsAdding(true);
  }, [hideEditingForm]);

  useEffect(() => {
    setLayoutButtons(
      <div className="flex gap-x-2">
        <PrimaryButton
          icon={PlusIcon({
            className: "h-4 w-4",
            "aria-hidden": true,
          })}
          id="planAddButton"
          onClick={() => clickAddButton()}
        >
          Добавить
        </PrimaryButton>
      </div>
    );
    return () => {
      setLayoutButtons(null);
    };
  }, [clickAddButton, setLayoutButtons]);

  const { data: userData } = useMe();
  const [skipColumns] = useState(["Вертикаль", "Проект"]);
  const [showDepartmentPlanModal, setShowDepartmentPlanModal] = useState(false);
  const [currentDepartmentPlan, setCurrentDepartmentPlan] =
    useState<DepartmentPlanParts>(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const resetDepartmentPlan = () => setCurrentDepartmentPlan(null);

  const hideAddingForm = useCallback(
    (reOpen = false) => {
      setIsAdding(false);
      resetDepartmentPlan();
      if (reOpen) {
        clickAddButton();
      }
    },
    [clickAddButton]
  );

  const clickEditButton = useCallback(
    (departmentPlan: DepartmentPlanParts) => {
      hideAddingForm();
      hideEditingForm();
      setIsEditing(true);
      setCurrentDepartmentPlan(departmentPlan);
      const planAddButton = document.getElementById("planAddButton");
      if (planAddButton) {
        planAddButton.scrollIntoView();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    [hideAddingForm, hideEditingForm]
  );

  const clickDeleteButton = useCallback(
    (departmentPlan: DepartmentPlanParts) => {
      setShowDepartmentPlanModal(!showDepartmentPlanModal);
      setCurrentDepartmentPlan(departmentPlan);
    },
    [showDepartmentPlanModal]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Месяц",
        accessor: (d: DepartmentPlanParts) =>
          d.monthYear ? formatMonth(d.monthYear) : null,
        Cell: (props: CellProps<DepartmentPlanParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Отдел",
        accessor: (d: DepartmentPlanParts) =>
          d.department?.title || "Отдел не указан",
        Cell: (props: CellProps<DepartmentPlanParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Направление",
        accessor: (d: DepartmentPlanParts) =>
          d.project?.client?.industry?.title || "Направление не указано",
        Cell: (props: CellProps<DepartmentPlanParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Сотрудник",
        accessor: (d: DepartmentPlanParts) => formatFullName(d.user),
        Cell: (props: any) => (
          <AvatarCell
            value={props.row.original.user?.surname}
            name={props.row.original.user?.name}
            avatar={props.row.original.user?.avatar}
            subtitle={props.row.original.user?.position?.title}
          />
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Юр. лицо",
        accessor: (d: DepartmentPlanParts) =>
          d.legalname?.title || "Юр. лицо не указано",
        Cell: (props: CellProps<DepartmentPlanParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Проект-Вертикаль",
        accessor: (d: DepartmentPlanParts) =>
          d.vertical?.title || "Вертикаль не указана",
        Cell: (props: any) => (
          <DoubleRowCell
            value={props.cell.value}
            firstRow={props.row.original.project?.title || "Проект не указан"}
          />
        ),
      },
      {
        accessor: (d: DepartmentPlanParts) =>
          d.project?.title || "Проект не указан",
        Header: "Проект",
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        accessor: (d: DepartmentPlanParts) =>
          d.vertical?.title || "Вертикаль не указана",
        Header: "Вертикаль",
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Площадка",
        accessor: (d: DepartmentPlanParts) =>
          d.vendor?.title || "Площадка не указана",
        Cell: (props: CellProps<DepartmentPlanParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Вероятность бюджета",
        accessor: (d: DepartmentPlanParts) =>
          formatPercent(d.budgetProbability),
        Cell: (props: CellProps<DepartmentPlanParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Оборот",
        accessor: "turnover",
        Cell: (props: CellProps<DepartmentPlanParts, string | number>) => (
          <SingleRowCell>
            {formatSums(props.row.original.turnover)}
          </SingleRowCell>
        ),
        Footer: (info: FooterProps<DepartmentPlanParts>) => {
          const total = useMemo(
            () => info.rows.reduce((sum, row) => row.values.turnover + sum, 0),
            [info.rows]
          );
          return <>Всего: {formatSums(total)}</>;
        },
      },
      {
        Header: "Маржа",
        accessor: "margin",
        Cell: (props: CellProps<DepartmentPlanParts, string | number>) => (
          <SingleRowCell>{formatSums(props.row.original.margin)}</SingleRowCell>
        ),
        Footer: (info: FooterProps<DepartmentPlanParts>) => {
          const total = useMemo(
            () => info.rows.reduce((sum, row) => row.values.margin + sum, 0),
            [info.rows]
          );
          return <>Всего: {formatSums(total)}</>;
        },
      },
      {
        Header: "Действие",
        Cell: (props: CellProps<DepartmentPlanParts>) => (
          <div className="flex gap-x-2">
            <PrimaryButton
              icon={PencilIcon({
                className: "h-4 w-4",
                "aria-hidden": true,
              })}
              onClick={() => clickEditButton(props.row.original)}
            ></PrimaryButton>

            <PrimaryButton
              icon={TrashIcon({
                className: "h-4 w-4",
                "aria-hidden": true,
              })}
              disabled={isAdding || isEditing}
              onClick={() => clickDeleteButton(props.row.original)}
            ></PrimaryButton>
          </div>
        ),
      },
    ],
    [clickDeleteButton, clickEditButton, isAdding, isEditing]
  );
  const input = {
    userIds: [],
    departmentsIds: [],
  };

  if (!!userData?.me?.user?.headOfDepartment?.length) {
    input.departmentsIds = userData.me.user.headOfDepartment.map((e) => e.id);
    input.userIds = [userData.me.user.id];
  } else if (!!userData?.me?.user?.subordinates.length) {
    input.userIds = userData.me.user.subordinates
      .map((e) => e.id)
      .concat([userData.me.user.id]);
  } else {
    input.userIds = [userData.me.user.id];
  }

  const { loading, data: departmentPlansData } = useQuery<
    findAllDepartmentPlans,
    findAllDepartmentPlansVariables
  >(FIND_ALL_DEPARTMENT_PLANS_QUERY, {
    variables: {
      input: {
        ...input,
      },
    },
  });

  const departmentPlans = useMemo(
    () => departmentPlansData?.findAllDepartmentPlans?.departmentPlans,
    [departmentPlansData?.findAllDepartmentPlans?.departmentPlans]
  );

  return !loading ? (
    <>
      <Helmet>
        <title> Планы | Артикс Портал</title>
      </Helmet>
      {(isAdding || isEditing) && (
        <div className="mb-2">
          {isAdding && <DepartmentPlanCreate onClose={hideAddingForm} />}
          {isEditing && (
            <DepartmentPlanEdit
              onClose={hideEditingForm}
              departmentPlan={currentDepartmentPlan}
            />
          )}
        </div>
      )}

      {showDepartmentPlanModal && (
        <Modal
          show={showDepartmentPlanModal}
          showFn={setShowDepartmentPlanModal}
        >
          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <DepartmentPlanDelete
              departmentPlan={currentDepartmentPlan}
              closeModal={setShowDepartmentPlanModal}
            />
          </div>
        </Modal>
      )}
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {departmentPlans && (
                <Table
                  columns={columns}
                  data={departmentPlans || []}
                  skipColumns={skipColumns}
                  initialState={{
                    pageSize: 20,
                    pageIndex: 0,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};

export default DepartmentPlan;
