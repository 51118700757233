import { gql } from "@apollo/client";
import { DEPARTMENT_FRAGMENT } from "graphql/fragments";

export const FIND_EMPLOYEE_DEPARTMENT_QUERY = gql`
  query findEmployeeDepartment($input: FindEmployeeDepartmentInput!) {
    findEmployeeDepartment(input: $input) {
      ok
      error
      department {
        ...DepartmentParts
      }
    }
  }
  ${DEPARTMENT_FRAGMENT}
`;
