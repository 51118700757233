import { useMutation, useQuery } from "@apollo/client";
import { DataLoader } from "components";
import { UPDATE_EMPLOYEE_POSITION_MUTATION } from "graphql/mutations";
import { FIND_EMPLOYEE_POSITION_QUERY } from "graphql/queries";
import {
  findEmployeePosition,
  findEmployeePositionVariables,
} from "graphql/__generated-types__/findEmployeePosition";
import {
  updateEmployeePosition,
  updateEmployeePositionVariables,
} from "graphql/__generated-types__/updateEmployeePosition";
import { adminMenuRoutes } from "navigation/routes";
import { useNavigate, useParams } from "react-router-dom";
import { EmployeePositionsForm } from "../components";
import { toast } from "react-toastify";

interface IAdminPositionsEditParams {
  id: string;
}

const AdminPositionsEdit = () => {
  const params = useParams<keyof IAdminPositionsEditParams>();
  const navigate = useNavigate();
  const { loading: positionLoading, data: positionData } = useQuery<
    findEmployeePosition,
    findEmployeePositionVariables
  >(FIND_EMPLOYEE_POSITION_QUERY, {
    variables: {
      input: {
        id: +params.id,
      },
    },
  });

  const [updateEmployeePosition, { loading: updateEmployeePositionLoading }] =
    useMutation<updateEmployeePosition, updateEmployeePositionVariables>(
      UPDATE_EMPLOYEE_POSITION_MUTATION,
      {
        onCompleted: (updateEmployeePositionData) => {
          if (
            updateEmployeePositionData &&
            updateEmployeePositionData.updateEmployeePosition.ok
          )
            navigate(adminMenuRoutes.positions.path);
          else
            toast.error(
              updateEmployeePositionData.updateEmployeePosition.error
            );
        },
        refetchQueries: [
          {
            query: FIND_EMPLOYEE_POSITION_QUERY,
            variables: { input: { id: +params.id } },
          },
        ],
      }
    );

  const onSubmit = (data) => {
    updateEmployeePosition({
      variables: {
        input: {
          ...data,
          id: +params.id,
        },
      },
    });
  };

  return !positionLoading &&
    !updateEmployeePositionLoading &&
    positionData?.findEmployeePosition?.position ? (
    <EmployeePositionsForm positionData={positionData} onSubmit={onSubmit} />
  ) : (
    <DataLoader />
  );
};

export default AdminPositionsEdit;
