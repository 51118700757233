import { useMutation, useQuery } from "@apollo/client";
import { DataLoader } from "components";
import { UPDATE_EMPLOYEE_DEPARTMENT_MUTATION } from "graphql/mutations";
import { FIND_EMPLOYEE_DEPARTMENT_QUERY } from "graphql/queries";
import {
  findEmployeeDepartment,
  findEmployeeDepartmentVariables,
} from "graphql/__generated-types__/findEmployeeDepartment";
import {
  updateEmployeeDepartment,
  updateEmployeeDepartmentVariables,
} from "graphql/__generated-types__/updateEmployeeDepartment";
import { adminMenuRoutes } from "navigation/routes";
import { useNavigate, useParams } from "react-router-dom";
import { EmployeeDepartmentsForm } from "../components/EmployeeDepartmentsForm";
import { toast } from "react-toastify";

interface IAdminDepartmentsEditParams {
  id: string;
}

const AdminDepartmentsEdit = () => {
  const params = useParams<keyof IAdminDepartmentsEditParams>();
  const navigate = useNavigate();
  const { loading: departmentLoading, data: departmentData } = useQuery<
    findEmployeeDepartment,
    findEmployeeDepartmentVariables
  >(FIND_EMPLOYEE_DEPARTMENT_QUERY, {
    variables: {
      input: {
        id: +params.id,
      },
    },
  });

  const [
    updateEmployeeDepartment,
    { loading: updateEmployeeDepartmentLoading },
  ] = useMutation<updateEmployeeDepartment, updateEmployeeDepartmentVariables>(
    UPDATE_EMPLOYEE_DEPARTMENT_MUTATION,
    {
      onCompleted: (updateEmployeeDepartmentData) => {
        if (
          updateEmployeeDepartmentData &&
          updateEmployeeDepartmentData.updateEmployeeDepartment.ok
        )
          navigate(adminMenuRoutes.departments.path);
        else
          toast.error(
            updateEmployeeDepartmentData.updateEmployeeDepartment.error
          );
      },
      refetchQueries: [
        {
          query: FIND_EMPLOYEE_DEPARTMENT_QUERY,
          variables: { input: { id: +params.id } },
        },
      ],
    }
  );

  const onSubmit = (data) => {
    updateEmployeeDepartment({
      variables: {
        input: {
          ...data,
          id: +params.id,
        },
      },
    });
  };

  return !departmentLoading &&
    !updateEmployeeDepartmentLoading &&
    departmentData?.findEmployeeDepartment?.department ? (
    <EmployeeDepartmentsForm
      departmentData={departmentData}
      onSubmit={onSubmit}
    />
  ) : (
    <DataLoader />
  );
};

export default AdminDepartmentsEdit;
