import { gql } from "graphql.macro";
import {
  BUYING_FORMAT_FRAGMENT,
  BUYING_TYPE_FRAGMENT,
  SELLER_FRAGMENT,
  VENDOR_FRAGMENT,
} from "../fragments";

export const LIST_VENDORS_QUERY = gql`
  query listVendors($input: ListVendorsInput!) {
    listVendors(input: $input) {
      ok
      error
      vendors {
        ...VendorParts
        sellers {
          ...SellerParts
        }
        buyingFormats {
          ...BuyingFormatParts
        }
        buyingTypes {
          ...BuyingTypeParts
        }
      }
    }
  }
  ${VENDOR_FRAGMENT}
  ${SELLER_FRAGMENT}
  ${BUYING_FORMAT_FRAGMENT}
  ${BUYING_TYPE_FRAGMENT}
`;
