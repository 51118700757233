import {gql} from "@apollo/client";
import {DEPARTMENT_PLAN_FRAGMENT} from "graphql/fragments";

export const UPDATE_DEPARTMENT_PLAN_MUTATION = gql`
  mutation updateDepartmentPlan($input: UpdateDepartmentPlanInput!) {
    updateDepartmentPlan(input: $input) {
      ok
      error
      departmentPlan {
        ...DepartmentPlanParts
      }
    }
  }
  ${DEPARTMENT_PLAN_FRAGMENT}
`;
