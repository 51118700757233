import { useMutation } from "@apollo/client";
import { DeleteModal } from "components";
import { DELETE_BUYING_CASHBACK_RULE_MUTATION } from "graphql/mutations";
import { BuyingCashbackRuleParts } from "graphql/__generated-types__/BuyingCashbackRuleParts";
import {
  deleteBuyingCashbackRule,
  deleteBuyingCashbackRuleVariables,
} from "graphql/__generated-types__/deleteBuyingCashbackRule";
import { cashbackRulesRoutes } from "navigation/routes";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

interface IBuyingCashbackRuleDeleteProps {
  show: boolean;
  showFn: React.Dispatch<React.SetStateAction<boolean>>;
  buyingCashbackRule: BuyingCashbackRuleParts;
}

const BuyingCashbackRuleDelete: React.FC<IBuyingCashbackRuleDeleteProps> = ({
  show,
  showFn,
  buyingCashbackRule,
}) => {
  const navigate = useNavigate();
  const [deleteBuyingCashbackRule] = useMutation<
    deleteBuyingCashbackRule,
    deleteBuyingCashbackRuleVariables
  >(DELETE_BUYING_CASHBACK_RULE_MUTATION, {
    onCompleted: (data) => {
      if (data?.deleteBuyingCashbackRule?.ok) {
        navigate(cashbackRulesRoutes.buyingCashbackRules.path);
      } else {
        toast.error(data?.deleteBuyingCashbackRule?.error);
      }
    },
    update(cache, { data: { deleteBuyingCashbackRule } }) {
      if (deleteBuyingCashbackRule?.ok) {
        cache.evict({ id: cache.identify(buyingCashbackRule as any) });
        cache.gc();
      }
    },
  });

  const onSubmit = async () => {
    await deleteBuyingCashbackRule({
      variables: {
        input: {
          id: buyingCashbackRule.id,
        },
      },
    });
    showFn(false);
  };

  return (
    <DeleteModal
      show={show}
      showFn={showFn}
      headerText={"Удаление условия кэшбека"}
      text={"Вы уверены, что хотите удалить условие кэшбека?"}
      onSubmit={onSubmit}
      onClose={() => showFn(false)}
    />
  );
};
export default BuyingCashbackRuleDelete;
