import { gql } from "@apollo/client";
import { CATEGORY_FRAGMENT } from "graphql/fragments";

export const FIND_ALL_CATEGORIES_QUERY = gql`
  query findAllCategories($input: ListCategoriesInput!) {
    findAllCategories(input: $input) {
      ok
      error
      categories {
          ...CategoryParts
      }
    }
  }
  ${CATEGORY_FRAGMENT}
`;
