import { useMe } from "../../../../hooks";
import { useNavigate } from "react-router";
import { useMutation } from "@apollo/client";
import {
  createPmReport,
  createPmReportVariables,
} from "../../../../graphql/__generated-types__/createPmReport";
import { CREATE_PM_REPORT_MUTATION } from "../../../../graphql/mutations";
import { serviceMenuRoutes } from "../../../../navigation/routes";
import { toast } from "react-toastify";
import { PM_REPORT_FRAGMENT } from "../../../../graphql/fragments";
import { PmReportForm } from "../components";

const PmReportCreate = ({ onClose }) => {
  const { data: userData } = useMe();
  const navigate = useNavigate();

  const [createPmReport] = useMutation<createPmReport, createPmReportVariables>(
    CREATE_PM_REPORT_MUTATION,
    {
      onCompleted: (createPmReportData) => {
        if (createPmReportData?.createPmReport?.ok) {
          navigate(serviceMenuRoutes.pmReports.path);
        } else {
          toast.error(createPmReportData?.createPmReport?.error);
        }
      },
      update(cache, { data: { createPmReport } }) {
        if (createPmReport?.pmReport?.id) {
          const newPmReportRef = cache.writeFragment({
            // @ts-ignore
            id: cache.identify(createPmReport?.pmReport),
            data: createPmReport?.pmReport,
            fragment: PM_REPORT_FRAGMENT,
            fragmentName: "PmReportParts",
          });
          cache.modify({
            id: "ROOT_QUERY",
            fields: {
              findAllPmReports(prev) {
                return {
                  ...prev,
                  pmReports: [newPmReportRef, ...prev.pmReports],
                };
              },
            },
          });
        }
      },
    }
  );

  const onSubmit = async (data: any) => {
    if (!!userData?.me?.user?.headOfDepartment?.length) {
      // Руководитель отдел(ов)
    } else if (!userData?.me?.user?.subordinates.length) {
      data.userId = userData?.me?.user?.id;
    }
    const responseData = await createPmReport({
      variables: {
        input: {
          ...data,
        },
      },
    });
    if (responseData?.data?.createPmReport?.ok) {
      onClose(true);
    }
  };

  return <PmReportForm onSubmit={onSubmit} onClose={onClose} />;
};
export default PmReportCreate;
