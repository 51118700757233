import { useMutation } from "@apollo/client";
import {
  deletePbtReport,
  deletePbtReportVariables,
} from "../../../../graphql/__generated-types__/deletePbtReport";
import { DELETE_PBT_REPORT_MUTATION } from "../../../../graphql/mutations/deletePbtReport";
import { toast } from "react-toastify";
import { PbtReportDeleteForm } from "../components";

const PbtReportDelete = ({ pbtReport, closeModal }) => {
  const [deletePbtReport] = useMutation<
    deletePbtReport,
    deletePbtReportVariables
  >(DELETE_PBT_REPORT_MUTATION, {
    onCompleted: (deletePbtReportData) => {
      if (deletePbtReportData && deletePbtReportData.deletePbtReport.ok) {
        window.location.reload();
      } else {
        toast.error(deletePbtReportData.deletePbtReport.error);
      }
    },
    update(cache, { data: { deletePbtReport } }) {
      if (deletePbtReport?.ok) {
        cache.evict({ id: cache.identify(pbtReport) });
        cache.gc();
      }
    },
  });

  const onSubmit = async () => {
    await deletePbtReport({
      variables: {
        input: {
          id: pbtReport.id,
        },
      },
    });
    closeModal();
  };
  const onClose = () => {
    closeModal();
  };

  return <PbtReportDeleteForm onSubmit={onSubmit} onClose={onClose} />;
};
export default PbtReportDelete;
