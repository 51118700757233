import { useMutation } from "@apollo/client";
import {
  deletePbtCalculator,
  deletePbtCalculatorVariables,
} from "../../../../graphql/__generated-types__/deletePbtCalculator";
import { serviceMenuRoutes } from "../../../../navigation/routes";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { DELETE_PBT_CALCULATOR_MUTATION } from "graphql/mutations/deletePbtCalculator";
import { PbtCalculatorDeleteForm } from "../components";
const PbtCalculatorDelete = ({ pbtCalculator, closeModal }) => {
  const navigate = useNavigate();
  const [deletePbtCalculator] = useMutation<
    deletePbtCalculator,
    deletePbtCalculatorVariables
  >(DELETE_PBT_CALCULATOR_MUTATION, {
    onCompleted: (deletePbtCalculatorData) => {
      if (
        deletePbtCalculatorData &&
        deletePbtCalculatorData.deletePbtCalculator.ok
      ) {
        navigate(serviceMenuRoutes?.pbtCalculators?.path);
      } else {
        toast.error(deletePbtCalculatorData?.deletePbtCalculator?.error);
      }
    },
    update(cache, { data: { deletePbtCalculator } }) {
      if (deletePbtCalculator?.ok) {
        cache.evict({ id: cache.identify(pbtCalculator) });
        cache.gc();
      }
    },
  });

  const onSubmit = async () => {
    await deletePbtCalculator({
      variables: {
        input: {
          id: +pbtCalculator.id,
        },
      },
    });
    closeModal();
  };
  const onClose = () => {
    closeModal();
  };

  return <PbtCalculatorDeleteForm onSubmit={onSubmit} onClose={onClose} />;
};
export default PbtCalculatorDelete;
