import useHotkeys from "@reecelucas/react-use-hotkeys";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "@apollo/client";
import {
  findAllUsers,
  findAllUsersVariables,
} from "../../../../graphql/__generated-types__/findAllUsers";
import { FIND_ALL_USERS_QUERY } from "../../../../graphql/queries";
import {
  Button,
  CasePictureInput,
  CasePresentationInput,
  DataLoader,
  LabeledTextareaInput,
  LabeledTextInput,
  LabeledToggleInput,
  ReactSelectInput,
  PrimaryButton,
} from "../../../../components";
import React, { useState } from "react";
import { formatFullName } from "../../../../utils";
import { CaseParts } from "graphql/__generated-types__/CaseParts";

interface ICaseFormProps {
  caseData?: CaseParts;
  onSubmit: (data: any) => void;
  onClose: () => void;
}

export interface ICaseForm {
  title: string;
  description?: string;
  picture: string;
  presentation: string;
  participantsIds: number[];
  externalLink: boolean;
  isArchive: boolean;
}

export const CaseForm: React.FC<ICaseFormProps> = ({
  caseData,
  onSubmit,
  onClose,
}) => {
  useHotkeys(["Escape"], () => {
    onClose();
  });
  const label = caseData ? "Редактирование кейса" : "Добавление кейса";

  const { data: employeesData, loading: employeesLoading } = useQuery<
    findAllUsers,
    findAllUsersVariables
  >(FIND_ALL_USERS_QUERY, {
    variables: {
      input: {
        departmentIds: [],
        isActive: true,
      },
    },
  });

  const [externalPresentationLink, setExternalPresentationLink] =
    useState<boolean>(
      !caseData?.presentation?.includes("storage.googleapis.com")
    );

  const { handleSubmit, control, setValue } = useForm<ICaseForm>({
    mode: "onSubmit",
    shouldUnregister: true,
    defaultValues: caseData
      ? {
          ...caseData,
          participantsIds: caseData?.participants.map(
            (participant) => participant.id
          ),
          externalLink: externalPresentationLink,
        }
      : {
          title: "",
          description: "",
          participantsIds: [],
          externalLink: !externalPresentationLink,
          isArchive: false,
        },
  });

  const employeesOptions =
    employeesData &&
    employeesData.findAllUsers.users.map((user) => ({
      label: formatFullName(user),
      value: user?.id,
    }));

  return !employeesLoading ? (
    <form className="space-y-6 py-3" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {label}
            </h3>
          </div>

          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-5">
                <Controller
                  control={control}
                  name="title"
                  rules={{ required: "Нужно заполнить название кейса" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Название"
                      value={field.value || ""}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-1">
                <Controller
                  control={control}
                  name="isArchive"
                  render={({ field }) => (
                    <LabeledToggleInput
                      labelText="Архив"
                      checked={field.value}
                      field={field}
                      name={field.name}
                      onChange={(e) => {
                        setValue("isArchive", !!e);
                      }}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 ">
                <Controller
                  control={control}
                  name="participantsIds"
                  rules={{ required: "Нужно заполнить участников" }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      isMulti={true}
                      options={employeesOptions}
                      defaultValue={employeesOptions?.filter((option) =>
                        field?.value?.some((id) => id === option?.value)
                      )}
                      name="participantsIds"
                      value={field.value}
                      labelText="Участники"
                      closeMenuOnSelect={false}
                      onSelect={(e) => {
                        setValue(
                          "participantsIds",
                          e?.map((option) => option?.value)
                        );
                      }}
                      placeholder="Выберите сотрудников из списка"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6">
                <Controller
                  control={control}
                  name="description"
                  render={({ field, fieldState }) => (
                    <LabeledTextareaInput
                      labelText="Описание кейса"
                      value={field.value || ""}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6">
                <Controller
                  control={control}
                  name="picture"
                  rules={{ required: "Нужно загрузить картинку кейса" }}
                  render={({ field, fieldState }) => (
                    <CasePictureInput
                      labelText="Картинка кейса"
                      picture={field.value}
                      field={field}
                      name={field.name}
                      alt={null}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-2">
                <Controller
                  control={control}
                  name="externalLink"
                  render={({ field, fieldState }) => (
                    <LabeledToggleInput
                      labelText="Внешняя ссылка"
                      checked={field.value}
                      field={field}
                      onChange={(e) => {
                        setValue("externalLink", !!e);
                        setExternalPresentationLink(!!e);
                        setValue("presentation", null);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              {externalPresentationLink ? (
                <div className="col-span-6 sm:col-span-4">
                  <Controller
                    control={control}
                    rules={{
                      required: "Нужно заполнить ссылку на презентацию",
                    }}
                    name="presentation"
                    render={({ field, fieldState }) => (
                      <LabeledTextInput
                        labelText="Ссылка на презентацию"
                        value={field.value || ""}
                        onChange={field.onChange}
                        name={field.name}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </div>
              ) : (
                <div className="col-span-6 sm:col-span-4">
                  <Controller
                    control={control}
                    rules={{ required: "Нужно загрузить презентацию" }}
                    name="presentation"
                    render={({ field, fieldState }) => (
                      <CasePresentationInput
                        labelText="Презентация кейса"
                        presentation={field.value}
                        field={field}
                        name={field.name}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-x-3">
        <Button type="button" onClick={() => onClose()}>
          Отмена
        </Button>
        <PrimaryButton type="submit">Сохранить</PrimaryButton>
      </div>
    </form>
  ) : (
    <DataLoader />
  );
};
