import { StoreObject, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { BUYING_CASHBACK_TRANSACTION_FRAGMENT } from "graphql/fragments";
import {
  BuyingCashbackTransactionForm,
  IBuyingCashbackTransactionForm,
} from "../components";
import {
  createBuyingCashbackTransaction,
  createBuyingCashbackTransactionVariables,
} from "graphql/__generated-types__/createBuyingCashbackTransaction";
import { CREATE_BUYING_CASHBACK_TRANSACTION_MUTATION } from "graphql/mutations/createBuyingCashbackTransaction";
import moment from "moment";
import "moment/locale/ru";
import { BuyingCashbackTransactionType } from "graphql/__generated-types__/globalTypes";
import { serviceMenuRoutes } from "navigation/routes";
import { useNavigate } from "react-router-dom";
moment.locale("ru");

interface IBuyingCashbackTransactionCreateProps {
  onClose: () => void;
  mode: BuyingCashbackTransactionType;
  editMode: boolean;
}

const BuyingCashbackCreate: React.FC<IBuyingCashbackTransactionCreateProps> = ({
  onClose,
  mode,
  editMode,
}) => {
  const navigate = useNavigate();

  const [createBuyingCashbackTransaction] = useMutation<
    createBuyingCashbackTransaction,
    createBuyingCashbackTransactionVariables
  >(CREATE_BUYING_CASHBACK_TRANSACTION_MUTATION, {
    onCompleted: (data) => {
      if (data?.createBuyingCashbackTransaction?.ok) {
        navigate(serviceMenuRoutes.buyingCashbackTransactions.path);
      } else {
        toast.error(data?.createBuyingCashbackTransaction?.error);
      }
    },
    update(cache, { data: { createBuyingCashbackTransaction } }) {
      if (createBuyingCashbackTransaction?.buyingCashbackTransaction?.id) {
        const newBuyingCashbackTransactionRef = cache.writeFragment({
          id: cache.identify(
            createBuyingCashbackTransaction?.buyingCashbackTransaction as unknown as StoreObject
          ),
          data: createBuyingCashbackTransaction?.buyingCashbackTransaction,
          fragment: BUYING_CASHBACK_TRANSACTION_FRAGMENT,
          fragmentName: "BuyingCashbackTransactionParts",
        });
        cache.modify({
          id: "ROOT_QUERY",
          fields: {
            listBuyingCashbackTransactions(prev) {
              return {
                ...prev,
                buyingCashbackTransactions: [
                  newBuyingCashbackTransactionRef,
                  ...prev.buyingCashbackTransactions,
                ],
              };
            },
          },
        });
      }
    },
  });

  const onSubmit = async (data: IBuyingCashbackTransactionForm) => {
    const response = await createBuyingCashbackTransaction({
      variables: {
        input: {
          ...data,
          cashbackPeriod: moment.utc(moment(data.cashbackPeriod)).format(),
          transactionType: mode,
          turnover: data.turnover 
          ? parseFloat(data.turnover?.toString().replace(/[a-zA-Z]/g, '').replace(/\s/g, '').replace(/[,]/g, '.'))
          : null,
        },
      },
    });
    if (response?.data?.createBuyingCashbackTransaction?.ok) {
      onClose();
    }
  };
  return (
    <BuyingCashbackTransactionForm
      editMode={editMode}
      mode={mode}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default BuyingCashbackCreate;
