import {Fragment, useEffect} from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import ArticsLogo from "assets/aportal.png";
import {
  activityRoutes,
  adminMenuRoutes,
  mainMenuRoutes,
  serviceMenuRoutes,
} from "navigation/routes";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AvatarTemplate from "assets/user-template.png";
import { useMe } from "hooks";
import { classNames } from "utils";
import { logoutUser } from "services/apollo";
import {
  PopoverNotificationCenter,
  NotificationBell,
  IMessage,
} from "@novu/notification-center";
import {useMutation} from "@apollo/client";
import dotenv from "dotenv";
import {createLoginIp, createLoginIpVariables} from "../graphql/__generated-types__/createLoginIp";
import {CREATE_LOGINIP_MUTATION} from "../graphql/mutations/createLoginIp";
dotenv.config({path: "../../../.env" });

export const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: userData, loading: userLoading } = useMe();

  const [createLoginIpMutation] = useMutation<createLoginIp, createLoginIpVariables>(
      CREATE_LOGINIP_MUTATION, {
        onCompleted: (createLoginIpMutationResult) => {
          const {
            createLoginIp: {ok, error}
          } = createLoginIpMutationResult;
          if (ok) {

          } else {
            console.log(error);
          }
        }
      })

  useEffect(() => {
    async function fetchIpAndMutate(email: string) {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();

            createLoginIpMutation({
              variables: { input: { ip: data.ip, email: email } },
            })
                .then()
                .catch(console.error);
          }
        catch (error) {
        console.error(error);
      }
    }
    if (process.env.REACT_APP_EMAILS) {
      const email = userData.me.user.email;
      const emails = process.env.REACT_APP_EMAILS.split(";");

      if (userData && userData.me && emails.includes(email)) {
        fetchIpAndMutate(email);
      }
    }
  }, [userData]);

  const onNotificationClick = (notification: IMessage) => {
    navigate(notification.cta.data.url);
  };

  const checkAdminMenuAccess = Object.entries(adminMenuRoutes).some(
    ([_, { roles }]) =>
      userData?.me?.user?.roles?.some((r) => roles.includes(r))
  );

  const profile = [
    {
      title: "Выйти из аккаунта",
      onClick: () => {
        logoutUser();
        navigate("/");
      },
    },
  ];

  return !userLoading ? (
    <Disclosure as="div" className="bg-white shadow fixed w-full z-30">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex ">
                <div
                  id="ArticsLogo"
                  className="flex-shrink-0 flex items-center"
                >
                  <img className="h-8" src={ArticsLogo} alt="Artics" />
                </div>

                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {Object.entries(mainMenuRoutes).map(
                    ([_, { path, title, link }], itemIdx) => (
                      <Fragment key={itemIdx}>
                        {link ? (
                          <a
                            href={link}
                            className={"border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {title}
                          </a>
                        ) : (
                          <Link
                            to={path}
                            className={classNames(
                              location.pathname.includes(path)
                                ? "border-red-500 text-gray-900 "
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                              "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                            )}
                          >
                            {title}
                          </Link>
                        )}
                      </Fragment>
                    )
                  )}
                  <Menu
                    as="span"
                    className={classNames(
                      location.pathname.includes("/activity/")
                        ? "border-red-500 text-gray-900 "
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                    )}
                  >
                    {({ open }) => (
                      <>
                        <Menu.Button className="">
                          <span>Активности</span>
                        </Menu.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="absolute top-16 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            {Object.entries(activityRoutes).filter(([_, {hidden}]) => !hidden).map(
                              ([_, { path, title }], itemIdx) => (
                                <div key={`${itemIdx}_div`}>
                                  <Menu.Item key={itemIdx}>
                                    {({ active }) => (
                                      <Link
                                        key={path}
                                        to={path}
                                        className={classNames(
                                          active ? "bg-gray-100" : "",
                                          "block px-4 py-2 text-sm text-gray-700"
                                        )}
                                      >
                                        {title}
                                      </Link>
                                    )}
                                  </Menu.Item>
                                </div>
                              )
                            )}
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                  <Menu
                    as="span"
                    className={classNames(
                      location.pathname.includes("/service/")
                        ? "border-red-500 text-gray-900 "
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                    )}
                  >
                    {({ open }) => (
                      <>
                        <Menu.Button className="hidden">
                          <span>Сервисы</span>
                        </Menu.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="absolute top-16 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            {Object.entries(serviceMenuRoutes).map(
                              ([_, { path, title, link, roles }], itemIdx) => (
                                <div key={`${itemIdx}_div`}>
                                  {userData?.me?.user?.roles?.some((r) =>
                                    roles.includes(r)
                                  ) && (
                                    <Menu.Item key={itemIdx}>
                                      {({ active }) =>
                                        link ? (
                                          <a
                                            href={link}
                                            className={classNames(
                                              active ? "bg-gray-100" : "",
                                              "block px-4 py-2 text-sm text-gray-700"
                                            )}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {title}
                                          </a>
                                        ) : (
                                          <Link
                                            key={path}
                                            to={path}
                                            className={classNames(
                                              active ? "bg-gray-100" : "",
                                              "block px-4 py-2 text-sm text-gray-700"
                                            )}
                                          >
                                            {title}
                                          </Link>
                                        )
                                      }
                                    </Menu.Item>
                                  )}
                                </div>
                              )
                            )}
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                  {checkAdminMenuAccess && (
                    <Menu
                      as="span"
                      className={classNames(
                        location.pathname.includes("/admin/")
                          ? "border-red-500 text-gray-900 "
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                      )}
                    >
                      {({ open }) => (
                        <>
                          <Menu.Button className="hidden">
                            <span>Справочники</span>
                          </Menu.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="absolute top-16 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              {Object.entries(adminMenuRoutes).map(
                                ([_, { path, title, link, roles }], itemIdx) => (
                                  <div key={`adminMenu${itemIdx}`}>
                                    {userData?.me?.user?.roles?.some((r) =>
                                      roles.includes(r)
                                    ) && (
                                      <Menu.Item key={itemIdx}>
                                        {({ active }) =>
                                            link ? (
                                                <a
                                                    href={link}
                                                    className={classNames(
                                                        active ? "bg-gray-100" : "",
                                                        "block px-4 py-2 text-sm text-gray-700"
                                                    )}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                  {title}
                                                </a>
                                            ) : (
                                                <Link
                                                    key={path}
                                                    to={path}
                                                    className={classNames(
                                                        active ? "bg-gray-100" : "",
                                                        "block px-4 py-2 text-sm text-gray-700"
                                                    )}
                                                >
                                                  {title}
                                                </Link>
                                            )
                                        }
                                      </Menu.Item>
                                    )}
                                  </div>
                                )
                              )}
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  )}
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center justify-center">
                <div className="bg-white flex items-center justify-center rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                  <PopoverNotificationCenter
                    onNotificationClick={onNotificationClick}
                    colorScheme="light"
                  >
                    {({ unseenCount }) => (
                      <NotificationBell unseenCount={unseenCount} />
                    )}
                  </PopoverNotificationCenter>
                </div>

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                          <span className="sr-only">Меню пользователя</span>
                          <img
                            className="h-8 w-8 rounded-full object-cover"
                            src={userData?.me?.user?.avatar || AvatarTemplate}
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {profile.map((item, idx) => (
                            <Menu.Item key={idx}>
                              {({ active }) => (
                                <span
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                  )}
                                  onClick={item.onClick}
                                >
                                  {item.title}
                                </span>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500">
                  <span className="sr-only">Открыть меню</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="pt-2 pb-3 space-y-1 flex flex-col">
              {Object.entries(mainMenuRoutes).map(
                ([_, { path, title, link }], itemIdx) => (
                  <Fragment key={itemIdx}>
                    {link ? (
                      <a
                        href={link}
                        className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {title}
                      </a>
                    ) : (
                      <Link
                        to={path}
                        className={classNames(
                          location.pathname.includes(path)
                            ? "border-red-500 text-gray-900 "
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                        )}
                      >
                        {title}
                      </Link>
                    )}
                  </Fragment>
                )
              )}
              <Menu
                as="span"
                className={classNames(
                  location.pathname.includes("/activity/")
                    ? "border-red-500 text-gray-900 "
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                )}
              >
                {({ open }) => (
                  <>
                    <Menu.Button className="">
                      <span>Активности</span>
                    </Menu.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="absolute top-16 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        {Object.entries(activityRoutes).map(
                          ([_, { path, title }], itemIdx) => (
                            <div key={`${itemIdx}_div`}>
                              <Menu.Item key={itemIdx}>
                                {({ active }) => (
                                  <Link
                                    key={path}
                                    to={path}
                                    target="_blank"
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    {title}
                                  </Link>
                                )}
                              </Menu.Item>
                            </div>
                          )
                        )}
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
              <Menu
                as="span"
                className={classNames(
                  location.pathname.includes("/service/")
                    ? "border-red-500 text-gray-900 "
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                )}
              >
                {({ open }) => (
                  <>
                    <Menu.Button className="hidden">
                      <span>Сервисы</span>
                    </Menu.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="absolute top-16 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        {Object.entries(serviceMenuRoutes).map(
                          ([_, { path, title, roles }], itemIdx) => (
                            <div key={`${itemIdx}_div`}>
                              {userData?.me?.user?.roles?.some((r) =>
                                roles.includes(r)
                              ) && (
                                <Menu.Item key={itemIdx}>
                                  {({ active }) => (
                                    <Link
                                      key={path}
                                      to={path}
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      {title}
                                    </Link>
                                  )}
                                </Menu.Item>
                              )}
                            </div>
                          )
                        )}
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
              {checkAdminMenuAccess && (
                <Menu
                  as="span"
                  className={classNames(
                    location.pathname.includes("/admin/")
                      ? "border-red-500 text-gray-900 "
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                  )}
                >
                  {({ open }) => (
                    <>
                      <Menu.Button className="hidden">
                        <span>Справочники</span>
                      </Menu.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="absolute top-16 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {Object.entries(adminMenuRoutes).map(
                            ([_, { path, title }], itemIdx) => (
                              <Menu.Item key={itemIdx}>
                                {({ active }) => (
                                  <Link
                                    key={path}
                                    to={path}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    {title}
                                  </Link>
                                )}
                              </Menu.Item>
                            )
                          )}
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              )}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full object-cover"
                    src={userData?.me?.user?.avatar || AvatarTemplate}
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {`${userData?.me?.user?.name} ${userData?.me?.user?.surname}`}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {userData?.me?.user?.email}
                  </div>
                </div>
                <div className="ml-auto flex items-center justify-center flex-shrink-0 bg-white rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                  <PopoverNotificationCenter
                    onNotificationClick={onNotificationClick}
                    colorScheme="light"
                  >
                    {({ unseenCount }) => (
                      <NotificationBell unseenCount={unseenCount} />
                    )}
                  </PopoverNotificationCenter>
                </div>
              </div>
              <div className="mt-3 space-y-1">
                {profile.map((item, idx) => (
                  <span
                    key={idx}
                    className="block px-4 py-2 text-base font-medium cursor-pointer text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                    onClick={item.onClick}
                  >
                    {item.title}
                  </span>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  ) : null;
};
