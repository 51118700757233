import { useQuery } from "@apollo/client";
import {
  PencilIcon,
  PlusIcon,
  TableIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import {
  DataLoader,
  PrimaryButton,
  SecondaryButton,
  SelectColumnFilter,
  SingleRowCell,
  StatusPill,
  Table,
} from "components";
import { actSidesRoleStatusMap } from "config/mappings";
import { FIND_ALL_INVOICES_QUERY } from "graphql/queries/findAllInvoices";
import {
  findAllInvoices,
  findAllInvoicesVariables,
} from "graphql/__generated-types__/findAllInvoices";
import { UserRole } from "graphql/__generated-types__/globalTypes";
import { InvoiceParts } from "graphql/__generated-types__/InvoiceParts";
import { useMe } from "hooks";
import { LayoutButtonsContext } from "pages/Admin/AdminLayout";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { CellProps } from "react-table";
import { classNames, formatDate } from "utils";
import InvoiceCreate from "./create";
import InvoiceDelete from "./delete";
import InvoiceEdit from "./edit";
import InvoiceExcel from "./excel";

const Checkbox = ({ id, isChecked, onClick }) => (
  <input
    type="checkbox"
    id={id}
    checked={isChecked}
    onChange={() => onClick()}
    className={classNames(
      "h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
    )}
  />
);

const Invoices = () => {
  const { setLayoutButtons } = useContext(LayoutButtonsContext);

  const user = useMe();

  const hideEditingForm = useCallback(() => {
    setIsEditing(false);
    setCurrentInvoice(null);
  }, []);

  const clickAddButton = useCallback(() => {
    hideEditingForm();
    setIsAdding(true);
  }, [hideEditingForm]);

  useEffect(() => {
    setLayoutButtons(
      <div className="flex gap-x-2">
        <SecondaryButton
          onClick={() => {
            clickExcelButton();
          }}
          icon={TableIcon({
            className: "h-4 w-4",
            "aria-hidden": true,
          })}
        >
          Эскпорт
        </SecondaryButton>
        {(user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
          user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
          <PrimaryButton
            icon={PlusIcon({
              className: "h-4 w-4",
              "aria-hidden": true,
            })}
            id="invoiceAddButton"
            onClick={() => clickAddButton()}
          >
            Добавить
          </PrimaryButton>
        )}
      </div>
    );
    return () => {
      setLayoutButtons(null);
    };
  }, [clickAddButton, setLayoutButtons, user?.data?.me?.user?.roles]);

  const [showExcelInvoiceModal, setShowExcelInvoiceModal] = useState(false);
  const [showDeleteInvoiceModal, setShowDeleteInvoiceModal] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState<InvoiceParts>(null);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const hideAddingForm = useCallback(
    (reOpen = false) => {
      setIsAdding(false);
      setCurrentInvoice(null);
      reOpen && clickAddButton();
    },
    [setIsAdding, setCurrentInvoice, clickAddButton]
  );

  const clickDeleteButton = useCallback(
    (invoice: InvoiceParts) => {
      setShowDeleteInvoiceModal(!showDeleteInvoiceModal);
      setCurrentInvoice(invoice);
    },
    [setShowDeleteInvoiceModal, setCurrentInvoice, showDeleteInvoiceModal]
  );

  const clickExcelButton = () => {
    setShowExcelInvoiceModal(true);
  };

  const clickEditButton = useCallback(
    (invoice: InvoiceParts) => {
      hideAddingForm();
      hideEditingForm();
      setIsEditing(true);
      setCurrentInvoice(invoice);
      const invoiceAddButton = document.getElementById("invoiceAddButton");
      if (invoiceAddButton) {
        invoiceAddButton.scrollIntoView();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    [hideAddingForm, hideEditingForm, setIsEditing, setCurrentInvoice]
  );

  const { loading, data: invoiceData } = useQuery<
    findAllInvoices,
    findAllInvoicesVariables
  >(FIND_ALL_INVOICES_QUERY, {
    variables: {
      input: {},
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: "ЮЛ исполнителя",
        accessor: (d: InvoiceParts) =>
          d?.finalContract?.contractorLegalname?.title ?? "",
        Filter: SelectColumnFilter,
        filter: "exact",
        disableGlobalFilter: true,
      },
      {
        Header: " ",
        accessor: (d: InvoiceParts) => d?.id,
        Cell: (props: CellProps<InvoiceParts, string>) => (
          <Checkbox
            key={props.cell.value}
            id={props.cell.value}
            isChecked={document
              ?.getElementById(props.cell.value)
              ?.getAttribute("isChecked")}
            onClick={() => {
              document
                .getElementById(props.cell.value)
                .setAttribute(
                  "isChecked",
                  document
                    .getElementById(props.cell.value)
                    .getAttribute("isChecked") === "true"
                    ? "false"
                    : "true"
                );

              console.log(
                document
                  .getElementById(props.cell.value)
                  .getAttribute("isChecked")
              );
            }}
          ></Checkbox>
        ),
        disableGlobalFilter: true,
      },
      {
        Header: "Тип акта",
        accessor: (d: InvoiceParts) =>
          d?.initialContract ? "Изначальный Акт" : "Акт",
        Cell: (props: CellProps<InvoiceParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        disableGlobalFilter: true,
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "ID Доходного договора/ДС",
        accessor: (d: InvoiceParts) =>
          d?.finalContract?.contractNumber &&
          d?.finalContract?.customerLegalname?.title &&
          d?.invoiceNumber
            ? `${d?.invoiceNumber} | ${d?.finalContract?.contractNumber} | ${d?.finalContract?.customerLegalname?.title}`
            : "Данные отсутсвуют в БД",
        Cell: (props: CellProps<InvoiceParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        disableGlobalFilter: true,
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Дата добавления акта",
        accessor: (d: InvoiceParts) => formatDate(d?.createdAt),
        Cell: (props: CellProps<InvoiceParts, string>) => (
          <SingleRowCell>
            {props?.cell?.value ? props?.cell?.value : "Дата не указана"}
          </SingleRowCell>
        ),
        disableGlobalFilter: true,
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Договор / Клиент",
        accessor: (d: InvoiceParts) =>
          d?.finalContract?.contractNumber &&
          d?.finalContract?.customerLegalname?.title
            ? `${d?.finalContract?.contractNumber} | ${d?.finalContract?.customerLegalname?.title}`
            : "Данные отсутсвуют в БД",
        Cell: (props: CellProps<InvoiceParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        disableGlobalFilter: true,
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Номер акта",
        accessor: (d: InvoiceParts) => d?.invoiceNumber,
        Cell: (props: CellProps<InvoiceParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Дата акта",
        accessor: (d: InvoiceParts) => d?.invoiceDate,
        Cell: (props: CellProps<InvoiceParts, string>) => (
          <SingleRowCell>
            {props?.cell?.value
              ? formatDate(props?.cell?.value)
              : "Дата не указана"}
          </SingleRowCell>
        ),
        disableGlobalFilter: true,
      },
      {
        Header: "Дата начала оказания услуг",
        accessor: (d: InvoiceParts) => d?.serviceStartDate,
        Cell: (props: CellProps<InvoiceParts, string>) => (
          <SingleRowCell>
            {props?.cell?.value
              ? formatDate(props?.cell?.value)
              : "Дата не указана"}
          </SingleRowCell>
        ),
        disableGlobalFilter: true,
      },
      {
        Header: "Дата окончания оказания услуг",
        accessor: (d: InvoiceParts) => d?.serviceEndDate,
        Cell: (props: CellProps<InvoiceParts, string>) => (
          <SingleRowCell>
            {props?.cell?.value
              ? formatDate(props?.cell?.value)
              : "Дата не указана"}
          </SingleRowCell>
        ),
        disableGlobalFilter: true,
      },
      {
        Header: "Роль заказчика",
        accessor: (d: InvoiceParts) =>
          d?.customerRole
            ? actSidesRoleStatusMap[d?.customerRole]
            : "Роль заказчика не указана",
        Cell: (props: CellProps<InvoiceParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
        disableGlobalFilter: true,
      },
      {
        Header: "Роль исполнителя",
        accessor: (d: InvoiceParts) =>
          d?.contractorRole
            ? actSidesRoleStatusMap[d?.contractorRole]
            : "Роль исполнителя не указана",
        Cell: (props: CellProps<InvoiceParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
        disableGlobalFilter: true,
      },
      {
        Header: "Стоимость услуг по доходному договору",
        accessor: (d: InvoiceParts) =>
          //Стоимость услуг по ДД???
          d?.invoiceSumAmount ? d?.invoiceSumAmount : "Стоимость не указана",
        Cell: (props: CellProps<InvoiceParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Признак 'НДС' по доходному договору",
        accessor: (d: InvoiceParts) => {
          return d?.finalContract?.isVatIncluded ? "Да" : "Нет";
        },
        Cell: (props: CellProps<InvoiceParts, "Да" | "Нет">) => (
          <StatusPill
            isPositive={props.row.original.finalContract?.isVatIncluded}
            value={
              props.row.original.finalContract?.isVatIncluded ? "Да" : "Нет"
            }
          />
        ),
        id: "fContract_isVatIncluded",
      },
      {
        Header: "Изначальный договор",
        accessor: (d: InvoiceParts) =>
          d?.initialContract
            ? `${d?.initialContract?.contractNumber} | ${d?.initialContract?.customerLegalname?.title}`
            : "",
        Cell: (props: CellProps<InvoiceParts, string>) => (
          <SingleRowCell>{props?.cell?.value}</SingleRowCell>
        ),
        disableGlobalFilter: true,
      },
      {
        Header: "Действие",
        Cell: (props: CellProps<InvoiceParts>) => (
          <div className="flex gap-x-2">
            {user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
            user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN) ? (
              <>
                <PrimaryButton
                  icon={PencilIcon({
                    className: "h-4 w-4",
                    "aria-hidden": true,
                  })}
                  disabled={isAdding}
                  onClick={() => clickEditButton(props.row.original)}
                ></PrimaryButton>

                <PrimaryButton
                  icon={TrashIcon({
                    className: "h-4 w-4",
                    "aria-hidden": true,
                  })}
                  disabled={isAdding || isEditing}
                  onClick={() => clickDeleteButton(props.row.original)}
                ></PrimaryButton>
              </>
            ) : (
              <SingleRowCell>Н/Д</SingleRowCell>
            )}
          </div>
        ),
      },
    ],
    [
      clickDeleteButton,
      clickEditButton,
      isAdding,
      isEditing,
      user?.data?.me?.user?.roles,
    ]
  );

  const invoices = useMemo(
    () => invoiceData?.findAllInvoices?.invoices,
    [invoiceData?.findAllInvoices?.invoices]
  );

  return !loading ? (
    <>
      <Helmet>
        <title> Акты | Артикс Портал</title>
      </Helmet>
      {(isAdding || isEditing) && (
        <div className="mb-2">
          {isAdding &&
            (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
              user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
              <InvoiceCreate onClose={hideAddingForm} />
            )}
          {isEditing &&
            (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
              user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
              <InvoiceEdit onClose={hideEditingForm} invoice={currentInvoice} />
            )}
        </div>
      )}

      {showDeleteInvoiceModal &&
        (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
          user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
          <InvoiceDelete
            invoice={currentInvoice}
            closeModal={setShowDeleteInvoiceModal}
          />
        )}

      {showExcelInvoiceModal &&
        (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
          user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
          <InvoiceExcel
            link="https://docs.google.com/spreadsheets/d/1_B-VGedcTZl9w5ntpO3WIdXEIwgvO51pvuxrh4Bc-TI/edit?usp=sharing"
            show={showExcelInvoiceModal}
            showFn={setShowExcelInvoiceModal}
            closeModal={setShowExcelInvoiceModal}
            post_url={"invoices"}
          />
        )}

      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <Table
                columns={columns}
                skipColumns={["ЮЛ исполнителя"]}
                data={invoices}
                initialState={{
                  pageSize: 20,
                  pageIndex: 0,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};

export default Invoices;
