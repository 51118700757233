import { useMutation } from "@apollo/client";
import {
  deleteBuyingCondition,
  deleteBuyingConditionVariables,
} from "../../../../graphql/__generated-types__/deleteBuyingCondition";
import { serviceMenuRoutes } from "../../../../navigation/routes";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { BuyingConditionDeleteForm } from "../components";
import { DELETE_BUYING_CONDITION_MUTATION } from "graphql/mutations/deleteBuyingCondition";

const BuyingConditionDelete = ({ buyingCondition, closeModal }) => {
  const navigate = useNavigate();
  const [deleteBuyingCondition] = useMutation<
    deleteBuyingCondition,
    deleteBuyingConditionVariables
  >(DELETE_BUYING_CONDITION_MUTATION, {
    onCompleted: (deleteBuyingConditionData) => {
      if (
        deleteBuyingConditionData &&
        deleteBuyingConditionData.deleteBuyingCondition.ok
      ) {
        navigate(serviceMenuRoutes.buyingConditions.path);
      } else {
        toast.error(deleteBuyingConditionData.deleteBuyingCondition.error);
      }
    },
    update(cache, { data: { deleteBuyingCondition } }) {
      if (deleteBuyingCondition?.ok) {
        cache.evict({ id: cache.identify(buyingCondition) });
        cache.gc();
      }
    },
  });

  const onSubmit = async () => {
    await deleteBuyingCondition({
      variables: {
        input: {
          id: buyingCondition.id,
        },
      },
    });
    closeModal();
  };
  const onClose = () => {
    closeModal();
  };

  return <BuyingConditionDeleteForm onSubmit={onSubmit} onClose={onClose} />;
};
export default BuyingConditionDelete;
