import { useQuery } from "@apollo/client";
import {
  Button,
  DataLoader,
  LabeledTextInput,
  PrimaryButton,
  ReactSelectInput,
} from "components";
import { FIND_ALL_CATEGORIES_QUERY } from "graphql/queries/findAllCategories";
import {
  findAllCategories,
  findAllCategoriesVariables,
} from "graphql/__generated-types__/findAllCategories";
import { listVendors_listVendors_vendors } from "graphql/__generated-types__/listVendors";
import { Controller, useForm } from "react-hook-form";

interface IVendorFormProps {
  vendor?: listVendors_listVendors_vendors;
  onSubmit: (data: any) => void;
  onClose: () => void;
}

export interface IVendorForm {
  sellerIds?: number[];
  vendorId?: number;
  title: string;
  category1Id: number;
  category2Id: number;
  category3Id: number;
  buyingFormatIds?: number[];
  buyingTypeIds?: number[];
  isOrdNeeded: boolean;
}

export const AdminVendorForm: React.FC<IVendorFormProps> = ({
  vendor,
  onSubmit,
  onClose,
}) => {
  const label = vendor ? "Редактирование площадки" : "Добавление площадки";

  const { handleSubmit, control, setValue } = useForm<IVendorForm>({
    mode: "onSubmit",
    shouldUnregister: true,
    defaultValues: vendor
      ? {
          ...vendor,
          vendorId: vendor?.id,
          title: vendor?.title,
          sellerIds: vendor?.sellers?.map((seller) => seller.id),
          buyingTypeIds: vendor?.buyingTypes?.map((bt) => bt.id),
          buyingFormatIds: vendor?.buyingFormats?.map((bf) => bf.id),
          category1Id: vendor?.category1?.id,
          category2Id: vendor?.category2?.id,
          category3Id: vendor?.category3?.id,
          isOrdNeeded: vendor?.isOrdNeeded,
        }
      : {
        title: null,
        sellerIds: [],
        buyingTypeIds: [],
        buyingFormatIds: [],
        category1Id: null,
        category2Id: null,
        category3Id: null,
        isOrdNeeded: false,
      },
  });

  const { data: categoryData, loading: categoryLoading } = useQuery<
    findAllCategories,
    findAllCategoriesVariables
  >(FIND_ALL_CATEGORIES_QUERY, {
    variables: {
      input: {
        keyword: "",
      },
    },
  });

  return !categoryLoading ? (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {label}
            </h3>
          </div>

          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-4 sm:col-span-4 lg:col-span-4">
                <Controller
                  control={control}
                  name="title"
                  rules={{ required: "Нужно заполнить наименования площадки" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Наименование площадки"
                      value={field.value === null ? "" : field.value}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-2 sm:col-span-2 lg:col-span-2">
                <Controller
                  control={control}
                  name="isOrdNeeded"
                  rules={{
                    required: "Нужно указать признак передачи в ОРД",
                  }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={ 
                        // Тут именно ReactSelectInput - потому что постановщик задачи топает ножкой и очень хочет такой мусор видеть вместо обычного тоггла
                        // Тут именно цифры, потому что false воспринимает родительский компонент как незаполненный элемент - т.е. нельзя поставить нет. required не даст
                        [
                          {
                          label: "Да",
                          value: 1,
                          },
                          {
                          label: "Нет",
                          value: 0,
                          },
                        ]
                      }
                      name="isOrdNeeded"
                      defaultValue={field.value}
                      value={field.value}
                      labelText="Необходимо ли передавать в ОРД"
                      onSelect={(e) => setValue("isOrdNeeded", e)}
                      placeholder="Необходимо ли передавать в ОРД?"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                <Controller
                  control={control}
                  name="category1Id"
                  rules={{
                    required: "Нужно заполнить категорию 1",
                  }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={
                        categoryData &&
                        categoryData?.findAllCategories?.categories?.map(
                          (c1) => {
                            return {
                              label: c1?.title,
                              value: c1?.id,
                            };
                          }
                        )
                      }
                      name="category1Id"
                      defaultValue={field.value}
                      value={field.value}
                      labelText="Категория 1"
                      onSelect={(e) => setValue("category1Id", e)}
                      placeholder="Выберите категорию 1 из списка"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                <Controller
                  control={control}
                  name="category2Id"
                  rules={{
                    required: "Нужно заполнить категорию 2",
                  }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={
                        categoryData &&
                        categoryData?.findAllCategories?.categories?.map(
                          (c2) => {
                            return {
                              label: c2?.title,
                              value: c2?.id,
                            };
                          }
                        )
                      }
                      name="category2Id"
                      defaultValue={field.value}
                      value={field.value}
                      labelText="Категория 2"
                      onSelect={(e) => setValue("category2Id", e)} 
                      placeholder="Выберите категорию 2 из списка"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                <Controller
                  control={control}
                  name="category3Id"
                  rules={{
                    required: "Нужно заполнить категорию 3",
                  }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={
                        categoryData &&
                        categoryData?.findAllCategories?.categories?.map(
                          (c3) => {
                            return {
                              label: c3?.title,
                              value: c3?.id,
                            };
                          }
                        )
                      }
                      name="category3Id"
                      defaultValue={field.value}
                      value={field.value}
                      labelText="Категория 3"
                      onSelect={(e) => setValue("category3Id", e)} 
                      placeholder="Выберите категорию 3 из списка"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-x-3">
        <Button type="button" onClick={() => onClose()}>
          Отмена
        </Button>
        <PrimaryButton type="submit">Сохранить</PrimaryButton>
      </div>
    </form>
  ) : (
    <DataLoader />
  );
};
