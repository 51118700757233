import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createBuyingCondition,
  createBuyingConditionVariables,
} from "../../../../graphql/__generated-types__/createBuyingCondition";
import { serviceMenuRoutes } from "../../../../navigation/routes";
import { IBuyingConditionForm, BuyingConditionForm } from "../components";
import { BUYING_CONDITION_FRAGMENT } from "../../../../graphql/fragments";
import { CREATE_BUYING_CONDITION_MUTATION } from "graphql/mutations/createBuyingCondition";

const BuyingConditionCreate = ({ onClose }) => {
  const navigate = useNavigate();
  const [createBuyingCondition] = useMutation<
    createBuyingCondition,
    createBuyingConditionVariables
  >(CREATE_BUYING_CONDITION_MUTATION, {
    onCompleted: (createBuyingConditionData) => {
      if (createBuyingConditionData?.createBuyingCondition?.ok) {
        navigate(serviceMenuRoutes.buyingConditions.path);
      } else {
        toast.error(createBuyingConditionData?.createBuyingCondition?.error);
      }
    },
    update(cache, { data: { createBuyingCondition } }) {
      if (createBuyingCondition?.buyingCondition?.id) {
        const newBuyingConditionRef = cache.writeFragment({
          // @ts-ignore
          id: cache.identify(createBuyingCondition?.buyingCondition),
          data: createBuyingCondition?.buyingCondition,
          fragment: BUYING_CONDITION_FRAGMENT,
          fragmentName: "BuyingConditionParts",
        });
        cache.modify({
          id: "ROOT_QUERY",
          fields: {
            findAllBuyingConditions(prev) {
              return {
                ...prev,
                buyingConditions: [
                  newBuyingConditionRef,
                  ...prev.buyingConditions,
                ],
              };
            },
          },
        });
      }
    },
  });

  const onSubmit = async (data: IBuyingConditionForm) => {
    const responseData = await createBuyingCondition({
      variables: {
        input: {
          ...data,
          clientDiscount: data.clientDiscount / 100,
          agencyDiscount: data.agencyDiscount / 100,
          moneyback: data.moneyback / 100,
          bonus: data.bonus / 100,
          discountAfter: data.discountAfter / 100,
          discountBefore: data.discountBefore / 100,
        },
      },
    });
    if (responseData?.data?.createBuyingCondition?.ok) {
      onClose(true);
    }
  };
  return (
    <BuyingConditionForm
      buyingCondition={null}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default BuyingConditionCreate;
