import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataLoader } from "../../../../../components";
import { IPostForm, PostForm } from "../components";
import { PostParts } from "graphql/__generated-types__/PostParts";
import { updatePost, updatePostVariables } from "graphql/__generated-types__/updatePost";
import { UPDATE_POST_MUTATION } from "graphql/mutations/updatePost";
import { POST_FRAGMENT } from "graphql/fragments";

interface IPostEditProps {
  post: PostParts;
  onClose: () => void;
  path: string;
}

const PostEdit: React.FC<IPostEditProps> = ({
  post,
  onClose, 
  path,
}) => {
  const navigate = useNavigate();

  const [updatePost] = useMutation<
    updatePost,
    updatePostVariables
  >(UPDATE_POST_MUTATION, {
    onCompleted: (updatePostData) => {
      if (updatePostData?.updatePost?.ok) {
        navigate(path);
      } else {
        toast.error(updatePostData?.updatePost?.error);
      }
    },
    update(cache, { data: { updatePost } }) {
      if (updatePost?.post.id) {
        cache.writeFragment({
          // @ts-ignore
          id: cache.identify(updatePost?.post),
          data: updatePost?.post,
          fragment: POST_FRAGMENT,
          fragmentName: "PostParts",
        });
      }
    },
  });

  const onSubmit = async (data: IPostForm) => {
    const responseData = await updatePost({
      variables: {
        input: {
          ...data,
          id: post.id,
        },
      },
    });
    if (responseData?.data?.updatePost?.ok) {
      onClose();
    }
  };

  return post ? (
    <PostForm
      post={post}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  ) : (
    <DataLoader />
  );
};

export default PostEdit;
