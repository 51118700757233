import { gql } from "@apollo/client";
import { BUYING_FORMAT_FRAGMENT } from "graphql/fragments";

export const FIND_ALL_BUYING_FORMAT_QUERY = gql`
  query findAllBuyingFormats($input: FindAllBuyingFormatsInput!) {
    findAllBuyingFormats(input: $input) {
      ok
      error
      buyingFormats {
          ...BuyingFormatParts
      }
      totalPages
      totalResults
    }
  }
  ${BUYING_FORMAT_FRAGMENT}
`;
