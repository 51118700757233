import { useMe } from "../../../hooks";
import { useQuery } from "@apollo/client";
import {
  findAllPmReports,
  findAllPmReportsVariables,
} from "../../../graphql/__generated-types__/findAllPmReports";
import { FIND_ALL_PM_REPORTS_QUERY } from "../../../graphql/queries";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  AvatarCell,
  DataLoader,
  DoubleRowCell,
  PrimaryButton,
  Modal,
  SelectColumnFilter,
  Table,
  SingleRowCell,
} from "../../../components";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import {
  formatFullName,
  formatMonth,
  formatPercent,
  formatSums,
} from "../../../utils";
import PmReportCreate from "./create";
import PmReportEdit from "./edit";
import PmReportDelete from "./delete";
import { LayoutButtonsContext } from "../ServiceLayout";
import { PmReportParts } from "graphql/__generated-types__/PmReportParts";
import { CellProps } from "react-table";
import { Helmet } from "react-helmet-async";

const PmReport = () => {
  const { setLayoutButtons } = useContext(LayoutButtonsContext);

  const resetPmReport = useCallback(() => setCurrentPmReport(null), []);

  const hideEditingForm = useCallback(() => {
    setIsEditing(false);
    resetPmReport();
  }, [resetPmReport]);

  const clickAddButton = useCallback(() => {
    hideEditingForm();
    setIsAdding(true);
  }, [hideEditingForm]);

  useEffect(() => {
    setLayoutButtons(
      <div className="flex gap-x-2">
        <PrimaryButton
          icon={PlusIcon({
            className: "h-4 w-4",
            "aria-hidden": true,
          })}
          id="pmReportAddButton"
          onClick={() => clickAddButton()}
        >
          Добавить
        </PrimaryButton>
      </div>
    );
    return () => {
      setLayoutButtons(null);
    };
  }, [clickAddButton, setLayoutButtons]);

  const { data: userData } = useMe();
  const [currentPmReport, setCurrentPmReport] = useState<PmReportParts>(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showPmReportModal, setShowPmReportModal] = useState(false);

  const hideAddingForm = useCallback(
    (reOpen = false) => {
      setIsAdding(false);
      resetPmReport();
      if (reOpen) {
        clickAddButton();
      }
    },
    [setIsAdding, resetPmReport, clickAddButton]
  );

  const clickEditButton = useCallback(
    (pmReportId) => {
      hideAddingForm();
      hideEditingForm();
      setCurrentPmReport(pmReportId);
      setIsEditing(true);
      const pmReportAddButton = document.getElementById("pmReportAddButton");
      if (pmReportAddButton) {
        pmReportAddButton.scrollIntoView();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    [hideAddingForm, hideEditingForm, setCurrentPmReport, setIsEditing]
  );

  const clickDeleteButton = useCallback(
    (pmReport) => {
      setCurrentPmReport(pmReport);
      setShowPmReportModal(!showPmReportModal);
    },
    [setCurrentPmReport, setShowPmReportModal, showPmReportModal]
  );

  const input = {
    userIds: [],
    departmentsIds: [],
  };

  if (!!userData?.me?.user?.headOfDepartment?.length) {
    input.departmentsIds = userData.me.user.headOfDepartment.map((e) => e.id);
    input.userIds = [userData.me.user.id];
  } else if (!!userData?.me?.user?.subordinates.length) {
    input.userIds = userData.me.user.subordinates
      .map((e) => e.id)
      .concat([userData.me.user.id]);
  } else {
    input.userIds = [userData.me.user.id];
  }

  const { loading, data: pmReportsData } = useQuery<
    findAllPmReports,
    findAllPmReportsVariables
  >(FIND_ALL_PM_REPORTS_QUERY, {
    variables: {
      input: {
        ...input,
      },
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: "Месяц",
        accessor: (d: PmReportParts) =>
          d.monthYear ? formatMonth(d.monthYear) : null,
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Сотрудник",
        accessor: (d: PmReportParts) => formatFullName(d.user),
        Cell: (props: any) => (
          <AvatarCell
            value={props.row.original.user?.surname}
            name={props.row.original.user?.name}
            avatar={props.row.original.user?.avatar}
            subtitle={props.row.original.user?.position?.title}
          />
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Клиент",
        accessor: (d: PmReportParts) =>
          d.project?.client?.title || "Клиент не указан",
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Направление",
        accessor: (d: PmReportParts) =>
          d.project?.client?.industry?.title || "Направление не указано",
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Проект-Вертикаль",
        accessor: (d: PmReportParts) =>
          d.vertical?.title || "Вертикаль не указана",
        Cell: (props: any) => (
          <DoubleRowCell
            value={props.cell.value}
            firstRow={props.row.original.project?.title || "Проект не указан"}
          />
        ),
      },

      {
        Header: "Проект",
        accessor: (d: PmReportParts) => d.project?.title || "Проект не указан",
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Вертикаль",
        accessor: (d: PmReportParts) =>
          d.vertical?.title || "Вертикаль не указана",
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Площадка",
        accessor: (d: PmReportParts) =>
          d.vendor?.title || "Площадка не указана",
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Дней от начала месяца",
        accessor: (d: PmReportParts) => d?.reportDay,
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Дней в месяце",
        accessor: (d: PmReportParts) => d?.buyingPeriod,
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Бюджет: МП, vat",
        accessor: (d: PmReportParts) => formatSums(d?.budgetPlanVat),
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Бюджет: Сумма из интерфейса, net",
        accessor: (d: PmReportParts) => formatSums(d?.budgetFactNet),
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "KPI: Тип",
        accessor: (d: PmReportParts) => d?.kpiType?.title || "Не указан",
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "KPI: МП",
        accessor: (d: PmReportParts) => d?.kpiPlan,
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "KPI: Факт",
        accessor: (d: PmReportParts) => d?.kpiFact,
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Условия для клиента: АК",
        accessor: (d: PmReportParts) =>
          formatPercent(d?.clientAgencyFeePerc) || "Нет данных",
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Условия для клиента: Скидка клиенту",
        accessor: (d: PmReportParts) =>
          formatPercent(d?.clientDiscountPerc) || "Нет данных",
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Условия для клиента: Система автоматизации",
        accessor: (d: PmReportParts) =>
          d?.clientAutomationSystemType || "Не указано",
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Условия для клиента: Система автоматизации %",
        accessor: (d: PmReportParts) =>
          formatPercent(d?.clientAutomationSystemPerc) || "Не указана",
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Условия для клиента: Вшитая наценка, %",
        accessor: (d: PmReportParts) =>
          formatPercent(d?.clientHiddenFeePerc) || "Не указано",
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Условия для клиента: НДС Клиента, %",
        accessor: (d: PmReportParts) =>
          formatPercent(d?.clientVatPerc) || "Не указано",
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Условия с площадкой: Возвратка, %",
        accessor: (d: PmReportParts) =>
          formatPercent(d?.vendorMoneybackPerc) || "Не указано",
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        accessor: (d: PmReportParts) =>
          formatPercent(d?.vendorDiscountPerc) || "Не указано",
        Header: "Условия с площадкой: Скидка, %",
      },
      {
        Header: "Условия с площадкой: НДС, %",
        accessor: (d: PmReportParts) =>
          formatPercent(d?.vendorVatPerc) || "Не указано",
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Маржа: МП, net",
        accessor: (d: PmReportParts) => formatSums(d?.mediaPlanMargin),
        Cell: (props: CellProps<PmReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Действие",
        Cell: (props: any) => (
          <div className="flex gap-x-2">
            <PrimaryButton
              icon={PencilIcon({
                className: "h-4 w-4",
                "aria-hidden": true,
              })}
              onClick={() => clickEditButton(props.row.original)}
            ></PrimaryButton>

            <PrimaryButton
              icon={TrashIcon({
                className: "h-4 w-4",
                "aria-hidden": true,
              })}
              disabled={isAdding || isEditing}
              onClick={() => clickDeleteButton(props.row.original)}
            ></PrimaryButton>
          </div>
        ),
      },
    ],
    [clickDeleteButton, clickEditButton, isAdding, isEditing]
  );

  const pmReports = useMemo(
    () => pmReportsData?.findAllPmReports?.pmReports,
    [pmReportsData?.findAllPmReports?.pmReports]
  );

  return !loading ? (
    <>
      <Helmet>
        <title> ПМ-отчеты | Артикс Портал</title>
      </Helmet>
      {(isAdding || isEditing) && (
        <div className="mb-2">
          {isAdding && <PmReportCreate onClose={hideAddingForm} />}
          {isEditing && (
            <PmReportEdit
              pmReport={currentPmReport}
              onClose={hideEditingForm}
            />
          )}
        </div>
      )}

      {showPmReportModal && (
        <Modal show={showPmReportModal} showFn={setShowPmReportModal}>
          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <PmReportDelete
              pmReport={currentPmReport}
              closeModal={setShowPmReportModal}
            />
          </div>
        </Modal>
      )}

      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {pmReports && (
                <Table
                  columns={columns}
                  data={pmReports || []}
                  skipColumns={["Вертикаль", "Проект"]}
                  initialState={{
                    pageSize: 20,
                    pageIndex: 0,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};
export default PmReport;
