import { ReactComponent as WheelSvg } from "../assets/wheel.svg";
import { ReactComponent as CalendarSvg } from "../assets/calendar.svg";
import { ReactComponent as ClockSvg } from "../assets/clock.svg";
import { ReactComponent as LocationSvg } from "../assets/location.svg";
import { ReactComponent as TickSvg } from "../assets/tick.svg";
import { ReactComponent as WalletSvg } from "../assets/wallet.svg";

export const RandomCoffeeRules = () => (
  <div className="flex flex-col px-8 my-8">
    <span className=" mb-4">
      <span className="font-bold text-xl ">Random Coffee</span>: проект, который
      помогает ближе познакомиться с коллегами
    </span>
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <div className="flex gap-2">
        <WheelSvg className="w-12 h-12 flex-shrink-0" />
        <div className="flex flex-col text-sm">
          <span className="font-bold">Рандомный коллега</span>
          <span className="font-light">
            Случайным образом вам будет назначен коллега, с которым предстоит
            приятно провести время и пообщаться на любые темы тет-а-тет за
            чашечкой кофе, а может и чего-то погорячее.
          </span>
        </div>
      </div>
      <div className="flex gap-2">
        <CalendarSvg className="w-12 h-12 flex-shrink-0" />
        <div className="flex flex-col text-sm">
          <span className="font-bold">Каждые 2 недели</span>
          <span className="font-light">
            У вас будет 2 недели, чтобы пообщаться. Это не разовая акция, а
            постоянный проект. Новый random coffee будет доступен после отметки
            о прохождении встречи либо спустя 2 недели, если встреча не
            состоялась. Продолжать можно до тех пор, пока создаются пары.
          </span>
        </div>
      </div>
      <div className="flex gap-2">
        <ClockSvg className="w-12 h-12 flex-shrink-0" />
        <div className="flex flex-col text-sm">
          <span>
            <span className="font-bold">15 минут на кофе</span> (или больше)
          </span>
          <span className="font-light">
            Проект не обязывает вас вести долгие беседы. Достаточно встретиться
            всего на 15 минут, но мы уверены, что вам захочется говорить как
            можно дольше!
          </span>
        </div>
      </div>
      <div className="flex gap-2">
        <WalletSvg className="w-12 h-12 flex-shrink-0" />
        <div className="flex flex-col text-sm">
          <span className="font-bold">Бюджет</span>
          <span className="font-light">
            Мы за то, чтобы вы приходили в офис и встречались лично, поэтому все
            кофемашины в вашем распорежении, либо, если встречаетесь онлайн, то
            заранее обсуждайте напитки. Если вы планируте встречу за пределами
            офиса, то затраты на кофе не компенсируются 🙂
          </span>
        </div>
      </div>
      <div className="flex gap-2">
        <LocationSvg className="w-12 h-12 flex-shrink-0" />
        <div className="flex flex-col text-sm">
          <span className="font-bold">Онлайн или офлайн</span>
          <span className="font-light">
            Расстояние не помеха. Если вы находитесь в одном городе – отдавайте
            предпочтение личной встрече, если в разных, то все
            online-инструменты в вашем распоряжении.
          </span>
        </div>
      </div>
      <div className="flex gap-2">
        <TickSvg className="w-12 h-12 flex-shrink-0" />
        <div className="flex flex-col text-sm">
          <span className="font-bold">Done</span>
          <span className="font-light">
            Не забудьте пометить, что встреча состоялась.
          </span>
        </div>
      </div>
    </div>
  </div>
);
