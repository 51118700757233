import { useQuery } from "@apollo/client";
import {
  CogIcon,
  MinusIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import {
  DataLoader,
  DoubleRowCell,
  PrimaryButton,
  SecondaryButton,
  SelectColumnFilter,
  SingleRowCell,
  Table,
} from "components";
import { LIST_BUYING_CASHBACK_TRANSACTIONS_QUERY } from "graphql/queries/listBuyingCashbackTransactions";
import { BuyingCashbackTransactionParts } from "graphql/__generated-types__/BuyingCashbackTransactionParts";
import {
  BuyingCashbackTransactionType,
  UserRole,
} from "graphql/__generated-types__/globalTypes";
import {
  listBuyingCashbackTransactions,
  listBuyingCashbackTransactionsVariables,
} from "graphql/__generated-types__/listBuyingCashbackTransactions";
import { useMe } from "hooks";
import { cashbackRulesRoutes } from "navigation/routes";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import { formatDate, formatMonth } from "utils";
import { LayoutButtonsContext } from "../ServiceLayout";
import BuyingCashbackTransactionCreate from "./create";
import BuyingCashbackTransactionDelete from "./delete";
import BuyingCashbackTransactionEdit from "./edit";

const BuyingCashbackTransaction = () => {
  const { setLayoutButtons } = useContext(LayoutButtonsContext);

  const [mode, setMode] = useState<BuyingCashbackTransactionType>(
    BuyingCashbackTransactionType.INCOME
  );

  const user = useMe();
  const navigate = useNavigate();

  const clickAddButton = useCallback((mode: BuyingCashbackTransactionType) => {
    hideEditingForm();
    setMode(mode);
    setIsAdding(true);
    setIsEditing(false);
  }, []);

  const hideEditingForm = () => {
    setIsEditing(false);
    setCurrentItem(null);
  };

  useEffect(() => {
    setLayoutButtons(
      <div className="flex gap-x-2">
        <PrimaryButton
          icon={PlusIcon({
            className: "h-4 w-4",
            "aria-hidden": true,
          })}
          id="addIncomeButton"
          onClick={() => {
            clickAddButton(BuyingCashbackTransactionType.INCOME);
          }}
        >
          Начислить
        </PrimaryButton>

        <PrimaryButton
          icon={MinusIcon({
            className: "h-4 w-4",
            "aria-hidden": true,
          })}
          id="addOutcomeButton"
          onClick={() => {
            clickAddButton(BuyingCashbackTransactionType.OUTCOME);
          }}
        >
          Списать
        </PrimaryButton>

        <SecondaryButton
          icon={CogIcon({
            className: "h-4 w-4",
            "aria-hidden": true,
          })}
          onClick={() =>
            navigate(cashbackRulesRoutes?.buyingCashbackRules?.path)
          }
        >
          Правила начисления кэшбека
        </SecondaryButton>
      </div>
    );

    return () => {
      setLayoutButtons(null);
    };
  }, [clickAddButton, navigate, setLayoutButtons]);

  const { loading, data: cashBackTransactionData } = useQuery<
    listBuyingCashbackTransactions,
    listBuyingCashbackTransactionsVariables
  >(LIST_BUYING_CASHBACK_TRANSACTIONS_QUERY, { variables: { input: {} } });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentItem, setCurrentItem] =
    useState<BuyingCashbackTransactionParts>(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const hideAddingForm = useCallback(() => {
    setIsAdding(false);
    setCurrentItem(null);
  }, []);

  const clickEditButton = useCallback(
    (buyingCashbackTransaction: BuyingCashbackTransactionParts) => {
      hideAddingForm();
      hideEditingForm();
      setMode(buyingCashbackTransaction.transactionType);
      setIsEditing(true);
      setCurrentItem(buyingCashbackTransaction);
      const addIncomeButton = document.getElementById("addIncomeButton");
      if (addIncomeButton) {
        addIncomeButton.scrollIntoView();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    [hideAddingForm]
  );

  const clickDeleteButton = useCallback(
    (buyingCashbackTransaction: BuyingCashbackTransactionParts) => {
      setShowDeleteModal(!showDeleteModal);
      setCurrentItem(buyingCashbackTransaction);
    },
    [showDeleteModal]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Системная дата действия",
        accessor: (d: BuyingCashbackTransactionParts) => d?.createdAt,
        Cell: (props: CellProps<BuyingCashbackTransactionParts>) => (
          <SingleRowCell>
            {formatDate(props.row.original.createdAt)}
          </SingleRowCell>
        ),
      },
      {
        Header: "Указанная дата списания/начисления",
        accessor: (d: BuyingCashbackTransactionParts) =>
          d?.cashbackPeriod ? formatMonth(d?.cashbackPeriod) : "Нет",
        Cell: (props: CellProps<BuyingCashbackTransactionParts>) => (
          <SingleRowCell>
            {props.row.original.transactionType ===
            BuyingCashbackTransactionType.INCOME
              ? props.row.original.cashbackPeriod
                ? formatMonth(props.row.original.cashbackPeriod)
                : "Нет"
              : props.row.original.cashbackPeriod
              ? formatDate(props.row.original.cashbackPeriod)
              : "Нет"}
          </SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Тип оплаты",
        accessor: (d: BuyingCashbackTransactionParts) =>
          d.transactionType === BuyingCashbackTransactionType.INCOME
            ? d?.buyingConditionType
              ? `Начисление / ${
                  d?.buyingConditionType === "PREPAID"
                    ? "Предоплата"
                    : "Постоплата"
                }`
              : "Начисление"
            : d?.buyingConditionType
            ? `Списание / ${
                d?.buyingConditionType === "PREPAID"
                  ? "Предоплата"
                  : "Постоплата"
              }`
            : "Списание",
        Cell: (props: CellProps<BuyingCashbackTransactionParts>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Клиент",
        accessor: (d: BuyingCashbackTransactionParts) => d.client?.title,
        Cell: (props: CellProps<BuyingCashbackTransactionParts>) => (
          <DoubleRowCell
            firstRow={props.cell.value}
            value={
              props?.row?.original?.clientSum
                ? `${props.row.original.clientSum} руб.`
                : `Новый итог`
            }
          />
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Площадка",
        accessor: (d: BuyingCashbackTransactionParts) => d.vendor?.title,
        Cell: (props: CellProps<BuyingCashbackTransactionParts>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Сумма кэшбека",
        accessor: (d: BuyingCashbackTransactionParts) =>
          d?.cashbackSum.toLocaleString(),
        Cell: (props: CellProps<BuyingCashbackTransactionParts>) => (
          <SingleRowCell>{props.cell.value} руб.</SingleRowCell>
        ),
        disableGlobalFilter: true,
      },
      {
        Header: "Действие",
        Cell: (props: CellProps<BuyingCashbackTransactionParts>) => (
          <div className="flex gap-x-2">
            {user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
            user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN) ? (
              <>
                <PrimaryButton
                  icon={PencilIcon({
                    className: "h-4 w-4",
                    "aria-hidden": true,
                  })}
                  disabled={isAdding}
                  onClick={() => clickEditButton(props.row.original)}
                ></PrimaryButton>

                <PrimaryButton
                  icon={TrashIcon({
                    className: "h-4 w-4",
                    "aria-hidden": true,
                  })}
                  disabled={isAdding || isEditing}
                  onClick={() => clickDeleteButton(props.row.original)}
                ></PrimaryButton>
              </>
            ) : (
              <SingleRowCell>Н/Д</SingleRowCell>
            )}
          </div>
        ),
      },
    ],
    [
      clickDeleteButton,
      clickEditButton,
      isAdding,
      isEditing,
      user?.data?.me?.user?.roles,
    ]
  );

  const cashbackTransactions = useMemo(
    () =>
      cashBackTransactionData?.listBuyingCashbackTransactions
        ?.buyingCashbackTransactions,
    [
      cashBackTransactionData?.listBuyingCashbackTransactions
        ?.buyingCashbackTransactions,
    ]
  );
  return !loading && cashbackTransactions ? (
    <>
      <Helmet>
        <title> Биллинговый кэшбек | Артикс Портал</title>
      </Helmet>
      {(isAdding || isEditing) && (
        <div className="mb-2">
          {isAdding && (
            <BuyingCashbackTransactionCreate
              editMode={isEditing}
              onClose={hideAddingForm}
              mode={mode}
            />
          )}
          {isEditing && (
            <BuyingCashbackTransactionEdit
              editMode={isEditing}
              onClose={hideEditingForm}
              buyingCashbackTransaction={currentItem}
            />
          )}
        </div>
      )}

      {showDeleteModal && (
        <BuyingCashbackTransactionDelete
          show={showDeleteModal}
          showFn={setShowDeleteModal}
          buyingCashbackTransaction={currentItem}
        />
      )}

      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <Table
                columns={columns}
                data={cashbackTransactions || []}
                initialState={{
                  pageSize: 20,
                  pageIndex: 0,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};

export default BuyingCashbackTransaction;
