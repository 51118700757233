import { Controller, useForm } from "react-hook-form";
import {
  Button,
  DataLoader,
  LabeledTextInput,
  PrimaryButton,
  ReactSelectInput,
} from "components";
import { useQuery } from "@apollo/client";
import {
  listLegalnames,
  listLegalnamesVariables,
} from "graphql/__generated-types__/listLegalnames";
import { LIST_LEGALNAMES_QUERY } from "graphql/queries";
import { SellerParts } from "graphql/__generated-types__/SellerParts";

interface ISellerFormProps {
  seller?: SellerParts;
  onSubmit: (data: any) => void;
  onClose: () => void;
}

export interface ISellerForm {
  title: string;
  legalnameIds: number[];
}

export const AdminSellerForm: React.FC<ISellerFormProps> = ({
  seller,
  onSubmit,
  onClose,
}) => {
  const label = seller
    ? "Редактирование данных о селлера"
    : "Добавление данных о селлере";

  const { handleSubmit, control, setValue } = useForm<ISellerForm>({
    mode: "onSubmit",
    shouldUnregister: true,
    defaultValues: seller
      ? {
          ...seller,
          legalnameIds: seller?.legalnames?.map((legalname) => legalname.id),
        }
      : {
          title: null,
          legalnameIds: [],
        },
  });

  const { data: legalnamesData, loading: legalnamesLoading } = useQuery<
    listLegalnames,
    listLegalnamesVariables
  >(LIST_LEGALNAMES_QUERY, {
    variables: {
      input: {
        keyword: "",
      },
    },
  });

  const legalnamesOptions =
    legalnamesData &&
    legalnamesData?.listLegalnames?.legalnames?.map((p) => {
      return {
        label: `${p?.title}`,
        value: p?.id,
      };
    });

  return !legalnamesLoading ? (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {label}
            </h3>
          </div>

          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                <Controller
                  control={control}
                  name="title"
                  rules={{ required: "Нужно заполнить наименования селлера" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Наименование селлера"
                      value={field.value === null ? "" : field.value}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                <Controller
                  control={control}
                  name="legalnameIds"
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      name="legalnameIds"
                      isMulti={true}
                      options={legalnamesOptions}
                      value={field.value}
                      defaultValue={legalnamesOptions?.filter((option) =>
                        field?.value?.some((id) => id === option?.value)
                      )}
                      onSelect={(e) => {
                        setValue(
                          "legalnameIds",
                          e?.map((option) => option?.value)
                        );
                      }}
                      labelText="ЮЛ"
                      placeholder="Выберите ЮЛ из списка"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-x-3">
        <Button type="button" onClick={() => onClose()}>
          Отмена
        </Button>
        <PrimaryButton type="submit">Сохранить</PrimaryButton>
      </div>
    </form>
  ) : (
    <DataLoader />
  );
};
