import {gql} from "@apollo/client";
import {PBT_REPORT_FRAGMENT} from "../fragments";

export const CREATE_PBT_REPORT_MUTATION = gql`
  mutation createPbtReport($input: CreatePbtReportInput!) {
    createPbtReport(input: $input) {
      ok
      error
      pbtReport {
        ...PbtReportParts
      }
    }
  }
  ${PBT_REPORT_FRAGMENT}
`;
