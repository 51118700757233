import {gql} from "@apollo/client";
import { CONTRACT_FRAGMENT } from "graphql/fragments";


export const CREATE_CONTRACT_MUTATION = gql`
  mutation createContract($input: CreateContractInput!) {
    createContract(input: $input) {
      ok
      error
      contract {
        ...ContractParts
      }
    }
  }
  ${CONTRACT_FRAGMENT}
`;