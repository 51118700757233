import moment from "moment";
import { VOTING_PERIOD } from "../config/constants";

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function formatDate(datetime: string) {
  return new Intl.DateTimeFormat("ru", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  }).format(Date.parse(datetime));
}

export function formatMonth(datetime: string) {
  return new Intl.DateTimeFormat("ru", {
    year: "numeric",
    month: "long",
  }).format(Date.parse(datetime));
}

export function formatPhone(phone: any) {
  if (!phone) return;
  if (typeof phone !== "string") phone = phone.toString();
  const cleanPhone = phone.replace(/[^0-9.]/g, "");
  if (cleanPhone.length === 11)
    return cleanPhone.replace(
      /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
      "+$1 ($2) $3-$4-$5"
    );
  return cleanPhone;
}

export function formatTelegram(nickname: string) {
  return `@${nickname.toLowerCase()}`;
}

export function prepareOptionsForSelect(arr) {
  return arr.map((a) => ({
    label: a.title,
    value: a.id,
  }));
}

export function formatFullName(user: any) {
  return `${user?.name} ${user?.surname}`;
}

export function formatPercent(value: number) {
  return `${new Intl.NumberFormat("ru-RU").format(
    Number.parseFloat(value.toFixed(2))
  )}%`;
}

export function formatFloatNumber(value: number) {
  if (!value) {
    return null;
  }
  return `${new Intl.NumberFormat("ru-Ru").format(
    Number.parseFloat(value.toFixed(2))
  )}`;
}

export function canVote(value: string) {
  if (!value) {
    return false;
  }
  const now = new Date();
  const startDateTime = new Date(value);
  const endDateTime = new Date(startDateTime.getTime() + VOTING_PERIOD * 60000);
  return now >= startDateTime && now < endDateTime;
}

export function formatSums(value: number) {
  if (value === null) {
    return value;
  }
  return `${new Intl.NumberFormat("ru-RU").format(
    Number.parseFloat(value.toFixed(2))
  )}`;
}

export function getTimeDiff(value: Date) {
  return moment(value).fromNow(true);
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
