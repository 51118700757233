import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, DataLoader, PrimaryButton } from "../../../components";
import { useNavigate, useParams } from "react-router";
import { activityRoutes } from "../../../navigation/routes";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { FIND_ONE_CASE_QUERY } from "../../../graphql/queries";
import { CREATE_VOTE_MUTATION } from "../../../graphql/mutations";
import { canVote } from "../../../utils";
import { CASE_FRAGMENT } from "../../../graphql/fragments";
import { vote, voteVariables } from "../../../graphql/__generated-types__/vote";
import {
  findOneCase,
  findOneCaseVariables,
} from "../../../graphql/__generated-types__/findOneCase";

const balls = Array.from({ length: 10 }, (v, k) => k + 1);
const criteria = [
  {
    value: "PROBLEM_DEPTH",
    text: "Насколько проблематика, поднятая в кейсе, является значительной, а задача, которую необходимо решить — сложной?",
    scale: [
      "10 – даже не представляю, как такое можно решить",
      "5 – ребята молодцы, но я бы сделал лучше",
      "1 – это же элементарно, джунам такое семечки",
    ],
  },
  {
    value: "UNORDINARY_IDEAS",
    text: "Насколько предложенное решение и гипотезы интересны, нестандартны?",
    scale: [
      "10 – никогда бы до такого не додумал(ась)ся",
      "5 – норм, но можно проще и эффективнее",
      "1 – с такими идеями даже на исповеди стыдно будет",
    ],
  },
  {
    value: "CAMPAIGNS_IMPLEMENTATION_QUALITY",
    text: "Насколько подробно и понятно представлена реализация, соответствует ли идее и задаче?",
    scale: [
      "10 – просто боги — идея и реализация одно целое",
      "5 – хорошая работа, но не больше",
      "1 – ничего не понял, какая-то ерунда",
    ],
  },
  {
    value: "ACHIEVED_RESULTS_GRADE",
    text: "Насколько достигнутые результаты впечатляют? Опережают рыночные бенчмарки, соответствуют ожиданиям клиента?",
    scale: [
      "10 – этот успех никому не повторить",
      "5 – в поставленные KPI в основном попали",
      "1 – отрицательный результат — тоже результат",
    ],
  },
  {
    value: "PRESENTATION_LEVEL",
    text: "Насколько понятно и интересно был подан контент как на слайдах, так и в устной презентации?",
    scale: [
      "10 – ребятам пора мутить курс по презентациям",
      "5 – все понятно, текст читается, картинки есть",
      "1 – здоровью Паршина причинен серьезный вред",
    ],
  },
];

export interface ICaseEditParams {
  id: string;
}

const Vote = () => {
  const navigate = useNavigate();
  const params = useParams<keyof ICaseEditParams>();

  const { loading: caseLoading, data: caseData } = useQuery<
    findOneCase,
    findOneCaseVariables
  >(FIND_ONE_CASE_QUERY, {
    variables: {
      input: {
        id: +params.id,
      },
    },
  });

  const [createVote] = useMutation<vote, voteVariables>(CREATE_VOTE_MUTATION, {
    onCompleted: (createVoteData) => {
      if (createVoteData?.vote?.ok) {
        navigate(activityRoutes.battleOfCases.path);
      } else {
        toast.error(createVoteData?.vote?.error);
      }
    },
    update(cache, { data: { vote } }) {
      if (vote?.ok) {
        cache.writeFragment({
          // @ts-ignore
          id: cache.identify(createVote?.battleCase),
          data: vote?.battleCase,
          fragment: CASE_FRAGMENT,
          fragmentName: "CaseParts",
        });
      }
    },
  });

  const onSubmit = async (data: any) => {
    if (!canVote(caseData?.findOneCase?.battleCase?.startDateTime)) {
      toast.error("Время голосования истекло");
      navigate(activityRoutes.battleOfCases.path);
      return;
    }
    await createVote({
      variables: {
        input: {
          ...data,
          caseId: +params.id,
        },
      },
    });
  };
  const { handleSubmit, control } = useForm({
    mode: "onSubmit",
    shouldUnregister: true,
  });

  return !caseLoading ? (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">
            Кейс: {caseData?.findOneCase?.battleCase?.title}
          </span>
        </h2>
      </div>
      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        {criteria.map((criterion, cIdx) => (
          <div
            key={`${criterion.value}`}
            className="relative bg-gray-50 pt-8 pb-1 px-4 sm:px-6 lg:pt-8 lg:pb-1 lg:px-8 rounded"
          >
            <div className="">
              <label className="text-xl font-medium text-gray-900">
                {criterion.text}
              </label>
              <div className="flow-root mt-6">
                <ul className="-my-5">
                  <li className="py-1" key={`${criterion.value}_li_-1`}>
                    <div className="relative focus-within:ring-2 focus-within:ring-red-500">
                      Шкала для сверки:
                    </div>
                  </li>
                  {criterion.scale.map((scale, idx) => (
                    <li className="px-2" key={`${criterion.value}_li_${idx}`}>
                      <div className="relative focus-within:ring-2 focus-within:ring-red-500">
                        {scale}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <fieldset className="flex flex-col items-center mt-4 py-5 w-full">
                <legend className="sr-only">Notification method</legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-5 gap-7">
                  <Controller
                    control={control}
                    rules={{ required: "Нужно выбрать оценку" }}
                    key={`controller_key_${criterion.value}_${cIdx}`}
                    name={`${criterion.value}`}
                    render={({ field, fieldState }) => (
                      <>
                        {balls.map((ball) => (
                          <div
                            className="space-x-2 space-y-1 sm:flex sm:items-center sm:space-y-0 sm:space-x-2"
                            key={`${criterion.value}_container_id_${ball}`}
                          >
                            <input
                              id={`${criterion.value}_id_${ball}`}
                              name={criterion.value}
                              type="radio"
                              value={ball}
                              onChange={(e) => field.onChange(+e.target.value)}
                              className="focus:ring-red-500 h-7 w-7 text-red-600 border-gray-300"
                            />
                            <label
                              htmlFor={`${criterion.value}_id_${ball}`}
                              className="block text-lg font-medium text-gray-700"
                            >
                              {ball}
                            </label>
                          </div>
                        ))}
                        {fieldState?.error?.message && (
                          <p className="mt-2 text-sm text-red-600">
                            {fieldState?.error?.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>
              </fieldset>
            </div>
          </div>
        ))}

        <div className="flex justify-center py-10 gap-x-3">
          <Button
            type="button"
            onClick={() => {
              navigate(activityRoutes.battleOfCases.path);
            }}
          >
            Отмена
          </Button>
          <PrimaryButton type="submit">Отправить</PrimaryButton>
        </div>
      </form>
    </div>
  ) : (
    <DataLoader />
  );
};
export default Vote;
