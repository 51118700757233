import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  listClients,
  listClientsVariables,
  listClients_listClients_clients,
} from "../../../graphql/__generated-types__/listClients";
import { LIST_CLIENTS_QUERY } from "../../../graphql/queries";
import {
  DataLoader,
  PrimaryButton,
  SingleRowCell,
  Table,
} from "../../../components";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import AdminClientCreate from "./create";
import AdminClientEdit from "./edit";
import AdminClientDelete from "./delete";
import { LayoutButtonsContext } from "../AdminLayout";
import { ClientParts } from "graphql/__generated-types__/ClientParts";
import { CellProps } from "react-table";
import { Helmet } from "react-helmet-async";
import { useMe } from "hooks";
import { UserRole } from "graphql/__generated-types__/globalTypes";

const AdminClients = () => {
  const { setLayoutButtons } = useContext(LayoutButtonsContext);

  const user = useMe();

  const hideEditingForm = useCallback(() => {
    setIsEditing(false);
    setCurrentClient(null);
  }, []);

  const clickAddButton = useCallback(() => {
    hideEditingForm();
    setIsAdding(true);
  }, [hideEditingForm]);

  useEffect(() => {
    setLayoutButtons(
      <div className="flex gap-x-2">
        {(user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
          user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
          <PrimaryButton
            icon={PlusIcon({
              className: "h-4 w-4",
              "aria-hidden": true,
            })}
            id="clientAddButton"
            onClick={() => clickAddButton()}
          >
            Добавить
          </PrimaryButton>
        )}
      </div>
    );
    return () => {
      setLayoutButtons(null);
    };
  }, [clickAddButton, setLayoutButtons, user?.data?.me?.user?.roles]);

  const [showDeleteClientModal, setShowDeleteClientModal] = useState(false);
  const [currentClient, setCurrentClient] = useState<ClientParts>(null);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const hideAddingForm = useCallback(
    (reOpen = false) => {
      setIsAdding(false);
      setCurrentClient(null);
      reOpen && clickAddButton();
    },
    [setIsAdding, setCurrentClient, clickAddButton]
  );

  const clickDeleteButton = useCallback(
    (client: ClientParts) => {
      setShowDeleteClientModal(!showDeleteClientModal);
      setCurrentClient(client);
    },
    [showDeleteClientModal]
  );

  const clickEditButton = useCallback(
    (client: ClientParts) => {
      hideAddingForm();
      hideEditingForm();
      setIsEditing(true);
      setCurrentClient(client);
      const clientAddButton = document.getElementById("clientAddButton");
      if (clientAddButton) {
        clientAddButton.scrollIntoView();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    [hideAddingForm, hideEditingForm, setIsEditing, setCurrentClient]
  );

  const { loading, data: clientsData } = useQuery<
    listClients,
    listClientsVariables
  >(LIST_CLIENTS_QUERY, {
    variables: {
      input: {
        includeIndustries: true,
        includeProjects: true,
      },
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: "Название клиента",
        accessor: (d: ClientParts) => d.title,
        Cell: (props: CellProps<ClientParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Проекты клиента",
        accessor: (d: listClients_listClients_clients) => d?.projects,
        Cell: (props: CellProps<ClientParts, string>) => (
          <SingleRowCell>
            {props.row.original?.projects && props.row.original?.projects.length
              ? props.row.original.projects.map((project) => (
                  <p>{project.title}</p>
                ))
              : null}
          </SingleRowCell>
        ),
      },
      {
        Header: "Направление клиента",
        accessor: (d: ClientParts) => d?.industry?.title || "Не указано",
        Cell: (props: CellProps<ClientParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Действие",
        Cell: (props: CellProps<ClientParts>) => (
          <div className="flex gap-x-2">
            {user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
            user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN) ? (
              <>
                <PrimaryButton
                  icon={PencilIcon({
                    className: "h-4 w-4",
                    "aria-hidden": true,
                  })}
                  disabled={isAdding}
                  onClick={() => clickEditButton(props.row.original)}
                ></PrimaryButton>

                <PrimaryButton
                  icon={TrashIcon({
                    className: "h-4 w-4",
                    "aria-hidden": true,
                  })}
                  disabled={isAdding || isEditing}
                  onClick={() => clickDeleteButton(props.row.original)}
                ></PrimaryButton>
              </>
            ) : (
              <SingleRowCell>Н/Д</SingleRowCell>
            )}
          </div>
        ),
      },
    ],
    [
      clickDeleteButton,
      clickEditButton,
      isAdding,
      isEditing,
      user?.data?.me?.user?.roles,
    ]
  );

  const clients = useMemo(
    () => clientsData?.listClients?.clients,
    [clientsData?.listClients?.clients]
  );

  return !loading ? (
    <>
      <Helmet>
        <title> Клиенты | Артикс Портал</title>
      </Helmet>
      {(isAdding || isEditing) && (
        <div className="mb-2">
          {isAdding &&
            (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
              user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
              <AdminClientCreate onClose={hideAddingForm} />
            )}
          {isEditing &&
            (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
              user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
              <AdminClientEdit
                client={currentClient}
                onClose={hideEditingForm}
              />
            )}
        </div>
      )}

      {showDeleteClientModal &&
        (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
          user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
          <AdminClientDelete
            show={showDeleteClientModal}
            showFn={setShowDeleteClientModal}
            client={currentClient}
            closeModal={setShowDeleteClientModal}
          />
        )}

      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <Table
                columns={columns}
                skipColumns={[]}
                data={clients}
                initialState={{
                  pageSize: 20,
                  pageIndex: 0,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};

export default AdminClients;
