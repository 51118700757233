import { gql } from "graphql.macro";
import { POST_FRAGMENT } from "graphql/fragments";

export const FIND_CLOSEST_POSTS_QUERY = gql`
  query findClosestPosts($input: FindClosestPostsInput!) {
    findClosestPosts(input: $input) {
      ok
      error
      posts {
        ...PostParts
      }
      postsType
    }
  }
  ${POST_FRAGMENT}
`;
