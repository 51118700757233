import { gql } from "@apollo/client";
import { POST_FRAGMENT } from "graphql/fragments";

export const FIND_MANY_POSTS_QUERY = gql`
  query findManyPosts($input: FindManyPostsInput!) {
    findManyPosts(input: $input) {
      ok
      error
      posts {
        ...PostParts
      }
      totalPages
      totalResults
    }
  }
  ${POST_FRAGMENT}
`;
