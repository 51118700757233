import { ApolloQueryResult, OperationVariables } from "@apollo/client";
import { PrimaryButton } from "components";
import { CREATE_RANDOM_COFFEE_MUTATION } from "graphql/mutations/createRandomCoffee";
import { createRandomCoffee } from "graphql/__generated-types__/createRandomCoffee";
import { useEffect, useState } from "react";
import RoulettePro from "react-roulette-pro";
import "react-roulette-pro/dist/index.css";
import { client } from "services/apollo";
import { getCurrentRandomCoffee } from "graphql/__generated-types__/getCurrentRandomCoffee";
import { UserParts } from "graphql/__generated-types__/UserParts";
import { RouletteDesign } from "./RouletteDesignPlugin";
import { RandomCoffeeRules } from "./RandomCoffeeRules";

interface INewRandomCoffeeProps {
  users: UserParts[];
  refetchCurrentRandomCoffee: (
    variables?: Partial<OperationVariables>
  ) => Promise<ApolloQueryResult<getCurrentRandomCoffee>>;
}

type TListElement = {
  id: number;
  image: string;
  text?: string;
};

export const NewRandomCoffee: React.FC<INewRandomCoffeeProps> = ({
  users,
  refetchCurrentRandomCoffee,
}) => {
  const [usersList, setUsersList] = useState<TListElement[]>([]);
  const [start, setStart] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [winnerIndex, setWinnerIndex] = useState(0);

  useEffect(() => {
    const usersList = users
      .filter(
        (user) => !["Горелик", "Маймаев", "Вайсман"].includes(user.surname)
      )
      .map((user) => ({
        id: user.id,
        image: user?.avatar ?? "assets/user-template.png",
      }));
    setUsersList([...usersList, ...usersList, ...usersList]);
  }, [users]);

  useEffect(() => {
    if (winnerIndex !== 0 && !start) {
      setStart(true);
    }
  }, [winnerIndex, start]);

  const handleStart = () => {
    setSpinning(true);
  };

  useEffect(() => {
    if (!spinning || !usersList.length) {
      return;
    }

    const prepare = async () => {
      const response = await client.mutate<createRandomCoffee>({
        mutation: CREATE_RANDOM_COFFEE_MUTATION,
      });
      let newWinnerIndex =
        usersList.findIndex(
          (item) =>
            item.id ===
            response?.data.createRandomCoffee?.randomCoffee?.invitee?.id
        ) +
        Math.floor(users.length / 2) -
        1;
      setWinnerIndex(newWinnerIndex);
      setStart(false);
    };

    prepare();
  }, [spinning, users, usersList]);

  const handleWinnerDefined = () => {
    refetchCurrentRandomCoffee();
    //setSpinning(false);
  };

  return (
    <div className="w-full h-screen flex flex-col justify-between items-center -mt-16 pt-16">
      <RandomCoffeeRules />
      <RoulettePro
        prizes={usersList}
        prizeIndex={winnerIndex}
        start={start}
        spinningTime={10}
        onPrizeDefined={handleWinnerDefined}
        options={{ stopInCenter: true }}
        designPlugin={RouletteDesign}
      />
      <PrimaryButton
        className="my-4 w-48 justify-center font-bold"
        onClick={handleStart}
      >
        Начать
      </PrimaryButton>
    </div>
  );
};
