import {gql} from "graphql.macro";
import {KPI_TYPE_FRAGMENT} from "../fragments";

export const LIST_KPI_TYPES_QUERY = gql`
  query listKpiTypes($input: ListKpiTypesInput!) {
    listKpiTypes(input: $input) {
      ok
      error
      kpiTypes {
        ...KpiTypeParts
      }
    }
  }
  ${KPI_TYPE_FRAGMENT}
`;
