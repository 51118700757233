import { useQuery } from "@apollo/client";
import {DataLoader, PrimaryButton} from "components";
import { FIND_ALL_USERS_QUERY } from "graphql/queries";
import {
  findAllUsers,
  findAllUsersVariables,
} from "graphql/__generated-types__/findAllUsers";
import { Helmet } from "react-helmet-async";
import {useSearchParams} from "react-router-dom";
import { PeopleListContainer, SearchBar } from "./components";
import {useState} from "react";
import {SearchedPerson} from "./components/SearchedPerson";

const SearchPage = () => {
  const [page, setPage] = useState(2);
  // const keyword = decodeURI(location.search.split("?keyword=")[1]);
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get('keyword');
  const {loading, data: usersData, fetchMore } = useQuery<
    findAllUsers,
    findAllUsersVariables
  >(FIND_ALL_USERS_QUERY, {
    variables: {
      input: {
        page: 1,
        take: 20,
        keyword,
        isActive: true,
        departmentIds: [],
        includeCertificates: true,
      },
    },
    fetchPolicy: "cache-first",
  });

  const loadMore = async () => {
    console.log("click")
    await fetchMore({
      variables: {
        input: {
          page: page,
          take: 20,
          keyword,
          isActive: true,
          departmentIds: [],
          includeCertificates: true,
        },
      },
    });
    setPage(prevState => prevState + 1);
  };

  return (
    <>
      <Helmet>
        <title>Поиск по сотрудникам | Артикс Портал</title>
      </Helmet>
      <div className="bg-white">
        {loading ? (
          <DataLoader />
        ) : (
          <>
            <div className="flex justify-center items-center pt-6">
              <div className="w-4/5 lg:w-1/2">
                <SearchBar value={keyword} />
              </div>
            </div>
            {usersData?.findAllUsers.users && (
              <>
                <div className="mx-auto max-w-7xl sm:px-6 sm:py-8 lg:px-8 lg:py-12 py-6 px-4 "
                >
                  <PeopleListContainer
                    people={usersData.findAllUsers.users}
                    wide={true}
                  />
                </div>
                {page < usersData.findAllUsers.totalPages&& (
                  <div className="flex justify-center pb-10">
                    <PrimaryButton
                      onClick={loadMore}
                    >
                      Загрузить еще
                    </PrimaryButton>
                  </div>
                )
                }
              </>
            )}
          </>
        )}
        <SearchedPerson/>
      </div>
    </>
  );
};

export default SearchPage;
