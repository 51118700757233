import { useMe } from "../../../../hooks";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  listClientProjects,
  listClientProjectsVariables,
} from "../../../../graphql/__generated-types__/listClientProjects";
import {
  FIND_ALL_USERS_QUERY,
  LIST_CLIENT_PROJECTS_QUERY,
  LIST_KPI_TYPES_QUERY,
  LIST_VENDORS_QUERY,
} from "../../../../graphql/queries";
import {
  Button,
  DataLoader,
  LabeledMonthInput,
  LabeledTextInput,
  LabeledTextInputWithTrailingAddon,
  PrimaryButton,
  ReactSelectInput,
} from "../../../../components";
import { AutomationSystemType } from "../../../../graphql/__generated-types__/globalTypes";
import {
  listKpiTypes,
  listKpiTypesVariables,
} from "../../../../graphql/__generated-types__/listKpiTypes";
import {
  listVendors,
  listVendorsVariables,
} from "../../../../graphql/__generated-types__/listVendors";
import {
  findAllUsers,
  findAllUsersVariables,
} from "../../../../graphql/__generated-types__/findAllUsers";
import { Controller, useForm } from "react-hook-form";
import { formatFullName } from "../../../../utils";
import { PmReportParts } from "graphql/__generated-types__/PmReportParts";

export interface IPmReportForm {
  monthYear: string;
  projectVerticalId: number;
  userId: number;
  vendorId: number;
  kpiTypeId?: number | null;
  reportDay: number;
  buyingPeriod: number;
  budgetPlanVat: number;
  budgetFactNet: number;
  kpiPlan: number;
  kpiFact: number;
  clientAgencyFeePerc: number;
  clientDiscountPerc: number;
  clientAutomationSystemType?: AutomationSystemType | null;
  clientAutomationSystemPerc: number;
  clientHiddenFeePerc: number;
  clientVatPerc: number;
  vendorMoneybackPerc: number;
  vendorDiscountPerc: number;
  vendorVatPerc: number;
}

interface IPmReportProps {
  pmReport?: PmReportParts;
  onSubmit: (data: any) => void;
  onClose: () => void;
}

export const PmReportForm: React.FC<IPmReportProps> = ({
  pmReport,
  onSubmit,
  onClose,
}) => {
  const { data: userData } = useMe();
  const [headOfDepartment] = useState(
    !!userData?.me?.user?.headOfDepartment?.length
  );
  const [groupHead] = useState(
    !headOfDepartment && !!userData?.me?.user?.subordinates?.length
  );

  const { data: kpiTypesData, loading: kpiTypesDataLoading } = useQuery<
    listKpiTypes,
    listKpiTypesVariables
  >(LIST_KPI_TYPES_QUERY, {
    variables: {
      input: {},
    },
  });

  const { data: projectsData, loading: projectsDataLoading } = useQuery<
    listClientProjects,
    listClientProjectsVariables
  >(LIST_CLIENT_PROJECTS_QUERY, {
    variables: {
      input: {
        includeClients: true,
        includeVerticals: true,
      },
    },
  });

  const { data: vendorsData, loading: vendorsLoading } = useQuery<
    listVendors,
    listVendorsVariables
  >(LIST_VENDORS_QUERY, {
    variables: {
      input: {},
    },
  });

  const { data: employeesData } = useQuery<findAllUsers, findAllUsersVariables>(
    FIND_ALL_USERS_QUERY,
    {
      variables: {
        input: {
          departmentIds: userData?.me?.user?.headOfDepartment?.map((e) => e.id),
          exceptEmployeeIds: [userData?.me?.user?.id],
        },
      },
      skip: !headOfDepartment,
    }
  );

  const currentDate = new Date();
  const { handleSubmit, control, setValue } = useForm<IPmReportForm>({
    mode: "onSubmit",
    shouldUnregister: true,
    defaultValues: pmReport
      ? {
          ...pmReport,
          projectVerticalId: pmReport.projectVerticalId,
          userId: pmReport.user.id,
          vendorId: pmReport.vendor.id,
          monthYear: new Date(pmReport.monthYear).toISOString(),
          kpiTypeId: pmReport.kpiType?.id || null,
        }
      : {
          monthYear: new Date(
            Date.UTC(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              1,
              0,
              0,
              0
            )
          ).toISOString(),
          projectVerticalId: null,
          userId: null,
          vendorId: null,
          kpiTypeId: null,
          reportDay: null,
          buyingPeriod: null,
          budgetPlanVat: null,
          budgetFactNet: null,
          kpiPlan: null,
          kpiFact: null,
          clientAgencyFeePerc: null,
          clientDiscountPerc: null,
          clientAutomationSystemPerc: null,
          clientHiddenFeePerc: null,
          clientVatPerc: null,
          vendorMoneybackPerc: null,
          vendorDiscountPerc: null,
          vendorVatPerc: null,
        },
  });

  return !projectsDataLoading && !kpiTypesDataLoading && !vendorsLoading ? (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Общая информация
            </h3>
          </div>

          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  rules={{ required: "Нужно заполнить дату" }}
                  name="monthYear"
                  render={({ field, fieldState }) => (
                    <LabeledMonthInput
                      labelText="Месяц"
                      field={field}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-4">
                <Controller
                  control={control}
                  name="projectVerticalId"
                  rules={{
                    required: "Нужно заполнить Клиент-Проект-Вертикаль",
                  }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={
                        projectsData &&
                        projectsData.listClientProjects.projects
                          .map((p) => {
                            return p.verticals?.map((v) => {
                              return {
                                label: `${p.client?.title} : ${p.title} : ${v?.title}`,
                                value: v.id,
                              };
                            });
                          })
                          .flat()
                      }
                      name="projectVerticalId"
                      defaultValue={field.value}
                      value={field.value}
                      labelText="Клиент-Проект-Вертикаль"
                      placeholder="Выберите проект из списка"
                      fieldState={fieldState}
                      onSelect={(e) => setValue("projectVerticalId", e)}
                    />
                  )}
                />
              </div>

              {/* Если юзер руководитель отдела(-ов)*/}
              {(headOfDepartment || groupHead) && (
                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <Controller
                    control={control}
                    name="userId"
                    rules={{ required: "Нужно заполнить сотрудника" }}
                    render={({ field, fieldState }) => (
                      <ReactSelectInput
                        options={[
                          {
                            label: formatFullName(userData.me.user),
                            value: userData.me.user.id,
                          },
                        ].concat(
                          groupHead
                            ? userData?.me?.user?.subordinates?.map((s) => ({
                                label: formatFullName(s),
                                value: s.id,
                              }))
                            : employeesData?.findAllUsers?.users?.map((u) => ({
                                label: formatFullName(u),
                                value: u.id,
                              }))
                        )}
                        defaultValue={field.value}
                        name="userId"
                        value={field.value}
                        labelText="Сотрудник"
                        onSelect={(e) => {
                          setValue("userId", e);
                        }}
                        placeholder="Выберите сотрудника из списка"
                        fieldState={fieldState}
                      />
                    )}
                  />
                </div>
              )}

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="vendorId"
                  rules={{ required: "Нужно заполнить площадку" }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={
                        vendorsData &&
                        vendorsData.listVendors.vendors.map((v) => {
                          return {
                            label: v.title,
                            value: v.id,
                          };
                        })
                      }
                      name="vendorId"
                      defaultValue={field.value}
                      value={field.value}
                      labelText="Площадка"
                      placeholder="Выберите площадку из списка"
                      fieldState={fieldState}
                      onSelect={(e) => setValue("vendorId", e)}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="reportDay"
                  rules={{
                    required:
                      "Нужно заполнить количество дней от начала месяца",
                  }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Дней от начала месяца"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseInt(e.target.value))
                          ? parseInt(e.target.value)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      min="0"
                      step="1"
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="buyingPeriod"
                  rules={{
                    required: "Нужно заполнить количество дней в месяце",
                  }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Дней в месяце"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseInt(e.target.value))
                          ? parseInt(e.target.value)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      min="0"
                      step="1"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Бюджет
            </h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="budgetPlanVat"
                  rules={{ required: "Нужно заполнить поле" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Бюджет: МП, vat"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      step="0.01"
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="budgetFactNet"
                  rules={{ required: "Нужно заполнить поле" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Бюджет: Сумма из интерфейса, net"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      step="0.01"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">KPI</h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="kpiTypeId"
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={
                        kpiTypesData &&
                        kpiTypesData.listKpiTypes.kpiTypes.map((k) => {
                          return {
                            label: k.title,
                            value: k.id,
                          };
                        })
                      }
                      isClearable={true}
                      name="kpiTypeId"
                      defaultValue={field.value}
                      value={field.value}
                      labelText="Тип KPI"
                      placeholder="Выберите тип KPI из списка"
                      fieldState={fieldState}
                      onSelect={(e) => setValue("kpiTypeId", e)}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="kpiPlan"
                  rules={{ required: "Нужно заполнить поле" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="KPI: МП"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseInt(e.target.value))
                          ? parseInt(e.target.value)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      step="1"
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="kpiFact"
                  rules={{ required: "Нужно заполнить поле" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="KPI: Факт"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseInt(e.target.value))
                          ? parseInt(e.target.value)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      step="1"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Условия для клиента
            </h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="clientAgencyFeePerc"
                  rules={{ required: "Нужно заполнить поле" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInputWithTrailingAddon
                      labelText="АК"
                      addonText="%"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      step="0.01"
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="clientDiscountPerc"
                  rules={{ required: "Нужно заполнить поле" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInputWithTrailingAddon
                      labelText="Скидка клиенту"
                      addonText="%"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      step="0.01"
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="clientAutomationSystemType"
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={Object.values(AutomationSystemType).map((e) => {
                        return {
                          label: e,
                          value: e,
                        };
                      })}
                      isClearable={true}
                      name="clientAutomationSystemType"
                      defaultValue={field.value}
                      value={field.value}
                      labelText="Система автоматизации"
                      placeholder="Выберите систему автоматизации"
                      fieldState={fieldState}
                      onSelect={(e) =>
                        setValue("clientAutomationSystemType", e)
                      }
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="clientAutomationSystemPerc"
                  rules={{ required: "Нужно заполнить поле" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInputWithTrailingAddon
                      labelText="Система автоматизации"
                      addonText="%"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      step="0.01"
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="clientHiddenFeePerc"
                  rules={{ required: "Нужно заполнить поле" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInputWithTrailingAddon
                      labelText="Вшитая наценка, %"
                      addonText="%"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      step="0.01"
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="clientVatPerc"
                  rules={{ required: "Нужно заполнить поле" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInputWithTrailingAddon
                      labelText="НДС Клиента"
                      addonText="%"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      step="0.01"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Условия с площадкой
            </h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="vendorMoneybackPerc"
                  rules={{ required: "Нужно заполнить поле" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInputWithTrailingAddon
                      labelText="Возвратка"
                      addonText="%"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      step="0.01"
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="vendorDiscountPerc"
                  rules={{ required: "Нужно заполнить поле" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInputWithTrailingAddon
                      labelText="Скидка"
                      addonText="%"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      step="0.01"
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="vendorVatPerc"
                  rules={{ required: "Нужно заполнить поле" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInputWithTrailingAddon
                      labelText="НДС"
                      addonText="%"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      step="0.01"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-x-3">
        <Button type="button" onClick={() => onClose()}>
          Отмена
        </Button>
        <PrimaryButton type="submit">Сохранить</PrimaryButton>
      </div>
    </form>
  ) : (
    <DataLoader />
  );
};
