import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  findAllCases,
  findAllCasesVariables,
} from "../../../graphql/__generated-types__/findAllCases";
import { FIND_ALL_CASES_QUERY } from "../../../graphql/queries";
import {
  DataLoader,
  PrimaryButton,
  Modal,
  SelectColumnFilter,
  StackedAvatarsCell,
  StatusPill,
  Table,
  SingleRowCell,
} from "../../../components";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import CaseCreate from "./create";
import CaseEdit from "./edit";
import { formatFloatNumber } from "../../../utils";
import CaseDelete from "./delete";
import { CaseParts } from "graphql/__generated-types__/CaseParts";
import { LayoutButtonsContext } from "../ServiceLayout";
import { CellProps } from "react-table";
import { Helmet } from "react-helmet-async";

const CaseAdmin = () => {
  const { setLayoutButtons } = useContext(LayoutButtonsContext);

  const clickAddButton = useCallback(() => {
    setIsAdding(true);
  }, []);

  useEffect(() => {
    setLayoutButtons(
      <div className="flex gap-x-2">
        <PrimaryButton
          icon={PlusIcon({
            className: "h-4 w-4",
            "aria-hidden": true,
          })}
          onClick={() => clickAddButton()}
        >
          Добавить
        </PrimaryButton>
      </div>
    );
    return () => {
      setLayoutButtons(null);
    };
  }, [clickAddButton, setLayoutButtons]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentCase, setCurrentCase] = useState<CaseParts | null>(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const clickEditButton = useCallback((battleCase: CaseParts) => {
    setIsEditing(true);
    setCurrentCase(battleCase);
  }, []);

  const clickDeleteButton = useCallback(
    (battleCase: CaseParts) => {
      setShowDeleteModal(!showDeleteModal);
      setCurrentCase(battleCase);
    },
    [showDeleteModal]
  );

  const hideAddingForm = () => {
    setIsAdding(false);
  };

  const hideEditingForm = () => {
    setIsEditing(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Название кейса",
        accessor: (d: CaseParts) => d.title || "Название не указано",
        Cell: (props: CellProps<CaseParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Участники",
        accessor: "participants",
        Cell: (props: CellProps<CaseParts>) =>
          props.row.original.participants?.length > 0 ? (
            <StackedAvatarsCell value={props.row.original.participants} />
          ) : null,
      },
      {
        Header: "Результаты голосования",
        accessor: (d: CaseParts) =>
          (d.startDateTime && formatFloatNumber(d.totalScore)) ||
          "Голосования еще не было",
        Cell: (props: CellProps<CaseParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },

      {
        Header: "Статус",
        accessor: (d: CaseParts) => {
          return d.isArchive ? "Архив" : "Текущий";
        },
        Cell: (props: CellProps<CaseParts, "Архив" | "Текущий">) => (
          <StatusPill
            isPositive={!props.row.original.isArchive}
            value={props.cell.value}
          />
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Действие",
        Cell: (props: CellProps<CaseParts>) => (
          <div className="flex gap-x-2">
            <PrimaryButton
              icon={PencilIcon({
                className: "h-4 w-4",
                "aria-hidden": true,
              })}
              disabled={isAdding}
              onClick={() => clickEditButton(props.row.original)}
            ></PrimaryButton>

            <PrimaryButton
              icon={TrashIcon({
                className: "h-4 w-4",
                "aria-hidden": true,
              })}
              disabled={isAdding || isEditing}
              onClick={() => clickDeleteButton(props.row.original)}
            ></PrimaryButton>
          </div>
        ),
      },
    ],
    [clickDeleteButton, clickEditButton, isAdding, isEditing]
  );

  const { loading, data: casesData } = useQuery<
    findAllCases,
    findAllCasesVariables
  >(FIND_ALL_CASES_QUERY, {
    variables: {
      input: {},
    },
  });

  const cases = useMemo(
    () => casesData?.findAllCases?.battleCases,
    [casesData?.findAllCases?.battleCases]
  );

  return !loading ? (
    <>
      <Helmet>
        <title> Битва кейсов | Артикс Портал</title>
      </Helmet>
      {(isAdding || isEditing) && (
        <div className="mb-2">
          {isAdding && <CaseCreate onClose={hideAddingForm} />}
          {isEditing && (
            <CaseEdit case={currentCase} onClose={hideEditingForm} />
          )}
        </div>
      )}

      {showDeleteModal && (
        <Modal show={showDeleteModal} showFn={setShowDeleteModal}>
          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <CaseDelete
              battleCase={currentCase}
              closeModal={setShowDeleteModal}
            />
          </div>
        </Modal>
      )}
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {cases && (
                <Table
                  columns={columns}
                  data={cases || []}
                  initialState={{
                    pageSize: 20,
                    pageIndex: 0,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};

export default CaseAdmin;
