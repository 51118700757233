import {gql} from "@apollo/client";

export const DELETE_DEPARTMENT_PLAN_MUTATION = gql`
  mutation deleteDepartmentPlan($input: DeleteDepartmentPlanInput!) {
    deleteDepartmentPlan(input: $input) {
      ok
      error
    }
  }
`;
