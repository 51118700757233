import { useNavigate } from "react-router";
import { useMutation } from "@apollo/client";
import {
  deletePmReport,
  deletePmReportVariables,
} from "../../../../graphql/__generated-types__/deletePmReport";
import { DELETE_PM_REPORT_MUTATION } from "../../../../graphql/mutations";
import { serviceMenuRoutes } from "../../../../navigation/routes";
import { toast } from "react-toastify";
import { PmReportDeleteForm } from "../components";

const PmReportDelete = ({ pmReport, closeModal }) => {
  const navigate = useNavigate();
  const [deletePmReport] = useMutation<deletePmReport, deletePmReportVariables>(
    DELETE_PM_REPORT_MUTATION,
    {
      onCompleted: (deletePmRepordData) => {
        if (deletePmRepordData?.deletePmReport?.ok) {
          navigate(serviceMenuRoutes.pmReports.path);
        } else {
          toast.error(deletePmRepordData?.deletePmReport?.error);
        }
      },
      update(cache, { data: { deletePmReport } }) {
        if (deletePmReport?.ok) {
          cache.evict({ id: cache.identify(pmReport) });
          cache.gc();
        }
      },
    }
  );

  const onSubmit = async () => {
    await deletePmReport({
      variables: {
        input: {
          id: pmReport.id,
        },
      },
    });
    closeModal();
  };

  const onClose = () => {
    closeModal();
  };

  return <PmReportDeleteForm onSubmit={onSubmit} onClose={onClose} />;
};
export default PmReportDelete;
