import { useMutation } from "@apollo/client";
import { CREATE_EMPLOYEE_DEPARTMENT_MUTATION } from "graphql/mutations";
import {
  createEmployeeDepartment,
  createEmployeeDepartmentVariables,
} from "graphql/__generated-types__/createEmployeeDepartment";
import { adminMenuRoutes } from "navigation/routes";
import {
  EmployeeDepartmentsForm,
  IEmployeeDepartmentsForm,
} from "pages/Admin/EmployeeDepartments/components";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AdminDepartmentCreate: React.FC = () => {
  const navigate = useNavigate();

  const [createEmployeeDepartment] = useMutation<
    createEmployeeDepartment,
    createEmployeeDepartmentVariables
  >(CREATE_EMPLOYEE_DEPARTMENT_MUTATION, {
    onCompleted: (data) => {
      if (data && data.createEmployeeDepartment.ok)
        navigate(adminMenuRoutes.departments.path);
      else toast.error(data.createEmployeeDepartment.error);
    },
  });

  const onSubmit = (data: IEmployeeDepartmentsForm) => {
    createEmployeeDepartment({
      variables: {
        input: {
          ...data,
        },
      },
    });
  };
  return <EmployeeDepartmentsForm departmentData={null} onSubmit={onSubmit} />;
};

export default AdminDepartmentCreate;
