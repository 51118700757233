import { gql } from "@apollo/client";
import { VENDOR_FRAGMENT } from "graphql/fragments";

export const UPDATE_VENDOR_MUTATION = gql`
  mutation updateVendor($input: UpdateVendorInput!) {
    updateVendor(input: $input) {
      ok
      error
      vendor {
        ...VendorParts
      }
    }
  }
  ${VENDOR_FRAGMENT}
`;
