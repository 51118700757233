import { Helmet } from "react-helmet-async";

const DigitalCalendar = () => {
  return (
    <>
      <Helmet>
        <title> События digital-рынка | Артикс Портал</title>
      </Helmet>
      <iframe
        title="DigitalCalendar"
        src="https://calendar.yandex.ru/embed/week?layer_ids=24589525&layer_names=TrueDigital&show_date=2024-03-18&tz_id=Europe%2FMoscow&uid=1130000059219530"
        className="w-full h-80vh"
      ></iframe>
    </>
  );
};
//https://calendar.yandex.ru/embed/month?&layer_ids=19545058&tz_id=Europe/Moscow&layer_names=График активностей
//<iframe src="https://calendar.yandex.ru/embed/month?&layer_ids=19873841&tz_id=Europe/Moscow&layer_names=Обучение" width="800" height="450" frameborder="0" style="border: 1px solid #eee"></iframe>
export default DigitalCalendar;
