import { gql } from "@apollo/client";
import {CASE_FRAGMENT} from "../fragments";

export const CREATE_VOTE_MUTATION = gql`
  mutation vote($input: CreateVoteInput!) {
    vote(input: $input) {
      ok
      error
      battleCase {
        ...CaseParts
      }
    }
  }
  ${CASE_FRAGMENT}
`;
