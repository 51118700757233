import AvatarTemplate from "assets/user-template.png";
import {useLazyQuery} from "@apollo/client";
import {findOneUser, findOneUserVariables} from "../graphql/__generated-types__/findOneUser";
import {Slideover} from "./Slideover";
import {PersonDetails} from "../pages/Team/components";
import {useState} from "react";
import {FIND_ONE_USER_QUERY} from "../graphql/queries/findOneUser";

interface UserInlineProps {
  id: number
  size: number;
  avatar: string | null;
  name: string;
}

export const UserInline = ({ id, size, avatar, name }: UserInlineProps) => {
    const [open, setOpen] = useState(false);
    const [userData, {data}] = useLazyQuery<
        findOneUser,
        findOneUserVariables>
    (FIND_ONE_USER_QUERY,{
        fetchPolicy: "network-only"
    });

    const clickHandler = () => {
        userData({variables: {input: {id: id}}}).then(() =>
            setOpen(true)
        );
    };

    return (
        <>
            <button className="flex items-center" onClick={clickHandler}>
                <img
                    className={`inline-block h-${size} w-${size} rounded-full object-cover object-center mr-2`}
                    src={avatar || AvatarTemplate}
                    alt=""
                />
                <span>{name}</span>
            </button>
            <Slideover title={`Карточка сотрудника`} open={open} setOpen={setOpen}>
                <PersonDetails person={data?.findOneUser?.user}/>
            </Slideover>
        </>
    );
}
