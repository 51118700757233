import { gql } from "@apollo/client";
import {
  CERTIFICATE_FRAGMENT,
  POSITION_FRAGMENT,
  USER_FRAGMENT,
} from "graphql/fragments";

export const FIND_ALL_USERS_QUERY = gql`
  query findAllUsers($input: FindAllUsersInput!) {
    findAllUsers(input: $input) {
      ok
      error
      users {
        ...UserParts
        employeeHead {
          position {
            ...PositionParts
          }
        }
        certificates {
          ...CertificateParts
        }
      }
      totalPages
      totalResults
    }
  }
  ${USER_FRAGMENT}
  ${POSITION_FRAGMENT}
  ${CERTIFICATE_FRAGMENT}
`;
