import { useMutation } from "@apollo/client";
import { UPDATE_PBT_REPORT_MUTATION } from "../../../../graphql/mutations";
import { toast } from "react-toastify";
import { DataLoader } from "../../../../components";
import { IPbtReportForm, PbtReportForm } from "../components";
import { PBT_REPORT_FRAGMENT } from "../../../../graphql/fragments";
import { useMe } from "../../../../hooks";
import {
  updatePbtReport,
  updatePbtReportVariables,
} from "../../../../graphql/__generated-types__/updatePbtReport";
import { PbtReportParts } from "graphql/__generated-types__/PbtReportParts";

interface IPbtReportEditProps {
  pbtReport: PbtReportParts;
  onClose: () => void;
}

const PbtReportEdit: React.FC<IPbtReportEditProps> = ({
  pbtReport,
  onClose,
}) => {
  const { data: userData } = useMe();
  const [updatePbtReport] = useMutation<
    updatePbtReport,
    updatePbtReportVariables
  >(UPDATE_PBT_REPORT_MUTATION, {
    onCompleted: (updatePbtReportData) => {
      if (updatePbtReportData?.updatePbtReport?.ok) {
        window.location.reload();
      } else {
        toast.error(updatePbtReportData.updatePbtReport.error);
      }
    },
    update(cache, { data: { updatePbtReport } }) {
      if (updatePbtReport.pbtReport && updatePbtReport.pbtReport.id) {
        cache.writeFragment({
          // @ts-ignore
          id: cache.identify(updatePbtReport?.pbtReport),
          data: updatePbtReport.pbtReport,
          fragment: PBT_REPORT_FRAGMENT,
          fragmentName: "PbtReportParts",
        });
      }
    },
  });

  const onSubmit = async (data: IPbtReportForm) => {
    const responseData = await updatePbtReport({
      variables: {
        input: {
          monthYear: data.monthYear,
          workPercent: data.workPercent,
          projectId: +data.projectVerticalId.split("_")[0],
          verticalId: +data.projectVerticalId.split("_")[1],
          id: pbtReport.id,
          userId: +data.userId ?? +userData?.me?.user?.id,
        },
      },
    });
    if (responseData.data.updatePbtReport.ok) {
      onClose();
    }
  };

  return pbtReport ? (
    <PbtReportForm
      pbtReport={pbtReport}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  ) : (
    <DataLoader />
  );
};

export default PbtReportEdit;
