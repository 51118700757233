import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import {
  AvatarCell,
  DataLoader,
  DoubleRowCell,
  PrimaryButton,
  SelectColumnFilter,
  SingleRowCell,
  Table,
} from "components";
import { vendorIconMap } from "config/mappings";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "moment/locale/ru";
import { CellProps } from "react-table";
import { EyeIcon } from "@heroicons/react/outline";
import { Helmet } from "react-helmet-async";
import { CertificateParts } from "graphql/__generated-types__/CertificateParts";
import { formatDate } from "utils";
import {
  listEmployeeCertificates,
  listEmployeeCertificatesVariables,
} from "graphql/__generated-types__/listEmployeeCertificates";
import { LIST_EMPLOYEE_CERTIFICATES_QUERY } from "graphql/queries/listEmployeeCertificates";
moment.locale("ru");

const Certificates = () => {
  const { data: allCertificatesData, loading: allCertificatesLoading } =
    useQuery<listEmployeeCertificates, listEmployeeCertificatesVariables>(
      LIST_EMPLOYEE_CERTIFICATES_QUERY,
      { variables: { input: {} } }
    );

  const columns = useMemo(
    () => [
      {
        Header: "Площадка",
        accessor: (d: CertificateParts) =>
          d?.vendor?.title ? d?.vendor?.title : "Отсуствует",
        Cell: (props: CellProps<CertificateParts, string>) => (
          <DoubleRowCell
            value={props?.cell?.value}
            firstRow={
              vendorIconMap[props?.row?.original?.certificateCategory]?.title
            }
          />
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Сотрудник",
        accessor: (d: CertificateParts) => d?.employee?.surname ?? "Отсуствует",
        Cell: (props: CellProps<CertificateParts, string>) => (
          <AvatarCell
            value={props?.row?.original?.employee?.surname}
            name={props?.row?.original?.employee?.name}
            avatar={props?.row?.original?.employee?.avatar}
            subtitle={props?.row?.original?.employee?.position?.title}
          />
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Действителен до",
        accessor: (d: CertificateParts) =>
          d?.validUntil
            ? formatDate(d?.validUntil)
            : d?.fileLink
            ? "Бессрочный"
            : "Отсуствует",
        Cell: (props: CellProps<CertificateParts, string>) => (
          <SingleRowCell>{props?.cell?.value}</SingleRowCell>
        ),
      },
      {
        Header: "Статус",
        accessor: (d: CertificateParts) =>
          d.isExpired ? "Просрочен" : "Активен",
        Cell: (props: CellProps<CertificateParts, string>) =>
          props.row.original.isExpired ? (
            <FontAwesomeIcon icon={faTimesCircle} className="text-red-600" />
          ) : (
            <FontAwesomeIcon icon={faCheckCircle} className="text-green-600" />
          ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Просмотр",
        accessor: (d: CertificateParts) => (d?.fileLink ? d?.fileLink : null),
        Cell: (props: CellProps<CertificateParts, string>) =>
          props?.cell?.value ? (
            <a href={props.cell.value} target="_blank" rel="noreferrer">
              <PrimaryButton
                icon={<EyeIcon className="h-4 w-4" />}
              ></PrimaryButton>
            </a>
          ) : (
            <p></p>
          ),
      },
    ],
    []
  );

  const certificates = useMemo(
    () => allCertificatesData?.listEmployeeCertificates?.certificates,
    [allCertificatesData?.listEmployeeCertificates?.certificates]
  );

  return !allCertificatesLoading ? (
    <>
      <Helmet>
        <title> Сертификаты площадок | Артикс Портал</title>
      </Helmet>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <Table
                columns={columns}
                data={certificates || []}
                initialState={{
                  pageSize: 20,
                  pageIndex: 0,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};

export default Certificates;
