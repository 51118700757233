import { gql } from "@apollo/client";
import { CERTIFICATE_FRAGMENT, USER_FRAGMENT } from "graphql/fragments";

export const LIST_EMPLOYEE_CERTIFICATES_QUERY = gql`
  query listEmployeeCertificates($input: ListEmployeeCertificatesInput!) {
    listEmployeeCertificates(input: $input) {
      ok
      error
      certificates {
        ...CertificateParts
        employee {
          ...UserParts
        }
      }
    }
  }
  ${CERTIFICATE_FRAGMENT}
  ${USER_FRAGMENT}
`;
