import { gql } from "@apollo/client";

export const DELETE_POST_MUTATION = gql`
  mutation deletePost($input: DeletePostInput!) {
    deletePost(input: $input) {
      ok
      error
    }
  }
`;
