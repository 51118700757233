import AvatarTemplate from "assets/user-template.png";
import { listEmployeeDepartments_listEmployeeDepartments_departments } from "graphql/__generated-types__/listEmployeeDepartments";
import { PeopleListContainer } from "./PeopleListContainer";

interface IDepartmentContainerProps {
  departmentData: listEmployeeDepartments_listEmployeeDepartments_departments;
}

export const DepartmentContainer: React.FC<IDepartmentContainerProps> = ({
  departmentData,
}) => {
  return (
    <>
      <div id={departmentData.id.toString()}>
        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl mb-6">
          {(departmentData.secondaryTitle === null || departmentData.secondaryTitle === undefined || departmentData.secondaryTitle === "") ? departmentData.title : departmentData.secondaryTitle}
        </h2>
        <p className=" block text-xl text-gray-500 mb-12">
          {departmentData.description}
        </p>
        {departmentData.departmentHead && (
          <div className="flex items-center">
            <div
              className="w-20 h-20 rounded-full bg-cover bg-center bg-no-repeat flex-shrink-0 mr-4"
              style={{
                backgroundImage: `url(${
                  departmentData.departmentHead.avatar || AvatarTemplate
                })`,
              }}
            ></div>
            <div className="flex flex-col">
              <span className="mb-2 font-normal text-xs text-gray-500">
                Руководитель отдела:
              </span>
              <div className="space-y-2 w-full flex items-center">
                <div className="text-lg leading-6 font-medium space-y-1">
                  <h3>
                    {departmentData.departmentHead.name}{" "}
                    {departmentData.departmentHead.surname}
                  </h3>
                  <p className="text-gray-500">
                    {departmentData.departmentHead.position?.title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <PeopleListContainer
        key={departmentData.id}
        people={departmentData.employees}
      />
    </>
  );
};
