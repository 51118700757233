import {gql} from "@apollo/client";

export const DELETE_PM_REPORT_MUTATION = gql`
  mutation deletePmReport($input: DeletePmReportInput!) {
    deletePmReport(input: $input) {
      ok
      error
    }
  }
`;
