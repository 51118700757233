import { gql } from "@apollo/client";
import { CONTRACT_FRAGMENT } from "graphql/fragments";

export const FIND_ALL_CONTRACTS_QUERY = gql`
  query findAllContracts($input: FindAllContractsInput!) {
    findAllContracts(input: $input) {
      ok
      error
      contracts {
        ...ContractParts
      }
      totalPages
      totalResults
    }
  }
  ${CONTRACT_FRAGMENT}
`;