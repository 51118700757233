import { useMutation } from "@apollo/client";
import {
  pinPost,
  pinPostVariables,
} from "../../../../../graphql/__generated-types__/pinPost";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { PostPinForm } from "../components";
import { PIN_POST_MUTATION } from "graphql/mutations/pinPost";

const PostPin = ({ post, closeModal, path }) => {
  const navigate = useNavigate();
  const [pinPost] = useMutation<
    pinPost,
    pinPostVariables
  >(PIN_POST_MUTATION, {
    onCompleted: (pinPostData) => {
      if (
        pinPostData &&
        pinPostData?.pinPost?.ok
      ) {
        navigate(path);
      } else {
        toast.error(pinPostData.pinPost.error);
      }
    },
    update(cache, { data: { pinPost } }) {
      if (pinPost?.ok) {
        cache.evict({ id: cache.identify(post) });
        cache.gc();
      }
    },
  });

  const onSubmit = async () => {
    await pinPost({
      variables: {
        input: {
          id: post.id,
        },
      },
    });
    closeModal();
  };
  const onClose = () => {
    closeModal();
  };

  return <PostPinForm onSubmit={onSubmit} onClose={onClose} />;
};
export default PostPin;
