import { useMutation, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { DataLoader, PrimaryButton } from "../../components";
import { canVote, classNames, formatFullName } from "../../utils";
import { Link, useNavigate } from "react-router-dom";
import { FIND_ALL_CASES_QUERY } from "../../graphql/queries";
import { useMe } from "../../hooks";
import { PlayIcon, ThumbUpIcon } from "@heroicons/react/outline";
import { UPDATE_START_VOTING_FOR_CASE_MUTATION } from "../../graphql/mutations/startVoting";
import { activityRoutes } from "../../navigation/routes";
import { toast } from "react-toastify";
import { CASE_FRAGMENT } from "../../graphql/fragments";
import {
  findAllCases,
  findAllCasesVariables,
} from "../../graphql/__generated-types__/findAllCases";
import {
  startVoting,
  startVotingVariables,
} from "../../graphql/__generated-types__/startVoting";
import { UserRole } from "../../graphql/__generated-types__/globalTypes";
import { Helmet } from "react-helmet-async";

const Battle = () => {
  const navigate = useNavigate();
  const { data: userData } = useMe();

  const { loading: battleCasesLoading, data: battleCasesData } = useQuery<
    findAllCases,
    findAllCasesVariables
  >(FIND_ALL_CASES_QUERY, {
    variables: {
      input: {
        includeArchive: false,
      },
    },
  });

  const [startVoting] = useMutation<startVoting, startVotingVariables>(
    UPDATE_START_VOTING_FOR_CASE_MUTATION,
    {
      onCompleted: (startVotingData) => {
        if (startVotingData?.startVoting?.ok) {
          navigate(activityRoutes.battleOfCases.path);
        } else {
          toast.error(startVotingData?.startVoting?.error);
        }
      },
      update(cache, { data: { startVoting } }) {
        if (startVoting?.ok) {
          cache.writeFragment({
            // @ts-ignore
            id: cache.identify(startVoting?.battleCase),
            data: startVoting?.battleCase,
            fragment: CASE_FRAGMENT,
            fragmentName: "CaseParts",
          });
        }
      },
    }
  );
  const startVotingClick = async (caseId: number) => {
    const startDateTime = new Date();
    await startVoting({
      variables: {
        input: {
          id: caseId,
          startDateTime: startDateTime.toISOString(),
        },
      },
    });
  };

  const battleCases = useMemo(
    () => battleCasesData?.findAllCases?.battleCases,
    [battleCasesData?.findAllCases?.battleCases]
  );

  return !battleCasesLoading ? (
    <>
      <Helmet>
        <title> Битва кейсов | Артикс Портал</title>
      </Helmet>
      <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="relative max-w-7xl mx-auto">
          {!!battleCases.length ? (
            <div
              className={classNames(
                `mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-2 lg:max-w-none`
              )}
            >
              {battleCases.map((battleCase) => (
                <div
                  key={battleCase.title}
                  className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                >
                  <div className="flex-shrink-0">
                    <img
                      className="h-48 w-full object-cover"
                      src={battleCase.picture}
                      alt=""
                    />
                  </div>

                  <div className="p-6 flex flex-col">
                    <div className="flex-1">
                      <div className="block mt-2">
                        <Link
                          to={`/activity/cases/${battleCase?.id}`}
                          className="text-base uppercase leading-4 mb-2 p-2 mx-auto"
                        >
                          <p className="text-xl font-semibold text-gray-900">
                            {battleCase.title}
                          </p>
                        </Link>

                        <p className="mt-3 text-base text-gray-500">
                          {battleCase.description}
                        </p>
                      </div>
                    </div>
                    {battleCase.participants.map((user) => (
                      <div key={user.id} className="mt-6 flex items-center">
                        <div className="flex-shrink-0">
                          <div>
                            <img
                              className="h-10 w-10 rounded-full object-cover"
                              src={user?.avatar}
                              alt=""
                            />
                          </div>
                        </div>

                        <div className="ml-3">
                          <p className="text-sm font-medium text-gray-900">
                            {formatFullName(user)}
                          </p>
                          <div className="flex space-x-1 text-sm text-gray-500">
                            <span>{user?.position?.title}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="flex flex-col justify-end h-full">
                    <div className="mb-2 p-2 mx-auto uppercase">
                      {userData?.me?.user?.roles.includes(
                        UserRole.BATTLE_CASE_ADMIN
                      ) &&
                        !battleCase?.startDateTime && (
                          <PrimaryButton
                            icon={PlayIcon({
                              className: "h-4 w-4",
                              "aria-hidden": true,
                            })}
                            className="text-base uppercase leading-4"
                            onClick={(e) => startVotingClick(battleCase.id)}
                          >
                            <span className="px-1">Запустить голосование</span>
                          </PrimaryButton>
                        )}

                      {canVote(battleCase?.startDateTime) &&
                        !battleCase?.isParticipant &&
                        !battleCase.hasVoted && (
                          <Link to={`/activity/cases/${battleCase?.id}/vote`}>
                            <PrimaryButton
                              icon={ThumbUpIcon({
                                className: "h-4 w-4",
                                "aria-hidden": true,
                              })}
                              className="text-base uppercase leading-4"
                            >
                              <span className="px-1">Голосовать за кейс</span>
                            </PrimaryButton>
                          </Link>
                        )}
                    </div>
                  </div>

                  {battleCase?.startDateTime &&
                    !battleCase.isParticipant &&
                    battleCase.hasVoted && (
                      <div className="bg-red-600 text-center p-2">
                        <p className="font-medium text-white">
                          <span>Вы уже проголосовали</span>
                        </p>
                      </div>
                    )}

                  {battleCase?.startDateTime &&
                    !battleCase.hasVoted &&
                    battleCase.isParticipant && (
                      <div className="bg-red-600 text-center p-2">
                        <p className="font-medium text-white">
                          <span>Вы участник кейса. Вы не можете госовать</span>
                        </p>
                      </div>
                    )}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center">
              <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                Пока нет кейсов
              </h2>
            </div>
          )}
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};

export default Battle;
