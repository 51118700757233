import { useMe } from "../../../../hooks";
import { useNavigate } from "react-router";
import { useMutation } from "@apollo/client";
import {
  updatePmReport,
  updatePmReportVariables,
} from "../../../../graphql/__generated-types__/updatePmReport";
import { UPDATE_PM_REPORT_MUTATION } from "../../../../graphql/mutations";
import { serviceMenuRoutes } from "../../../../navigation/routes";
import { toast } from "react-toastify";
import { PM_REPORT_FRAGMENT } from "../../../../graphql/fragments";
import { IPmReportForm, PmReportForm } from "../components";
import { DataLoader } from "../../../../components";
import { PmReportParts } from "graphql/__generated-types__/PmReportParts";

interface IPmReportEditProps {
  pmReport: PmReportParts;
  onClose: () => void;
}

const PmReportEdit: React.FC<IPmReportEditProps> = ({ pmReport, onClose }) => {
  const { data: userData } = useMe();
  const navigate = useNavigate();

  const [updatePmReport] = useMutation<updatePmReport, updatePmReportVariables>(
    UPDATE_PM_REPORT_MUTATION,
    {
      onCompleted: (updatePmReportData) => {
        if (updatePmReportData?.updatePmReport?.ok) {
          navigate(serviceMenuRoutes.pmReports.path);
        } else {
          toast.error(updatePmReportData?.updatePmReport?.error);
        }
      },
      update(cache, { data: { updatePmReport } }) {
        if (updatePmReport?.pmReport?.id) {
          cache.writeFragment({
            // @ts-ignore
            id: cache.identify(updatePmReport?.pmReport),
            data: updatePmReport?.pmReport,
            fragment: PM_REPORT_FRAGMENT,
            fragmentName: "PmReportParts",
          });
        }
      },
    }
  );

  const onSubmit = async (data: IPmReportForm) => {
    if (!!userData?.me?.user?.headOfDepartment?.length) {
      // Руководитель отдел(ов)
    } else if (!userData?.me?.user?.subordinates.length) {
      data.userId = userData?.me?.user?.id;
    }
    const responseData = await updatePmReport({
      variables: {
        input: {
          ...data,
          id: +pmReport.id,
        },
      },
    });
    if (responseData?.data?.updatePmReport?.ok) {
      onClose();
    }
  };

  return pmReport ? (
    <PmReportForm pmReport={pmReport} onSubmit={onSubmit} onClose={onClose} />
  ) : (
    <DataLoader />
  );
};
export default PmReportEdit;
