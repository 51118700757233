import {gql} from "@apollo/client";
import {LEGALNAME_FRAGMENT} from "../fragments";

export const CREATE_LEGALNAME_MUTATION = gql`
  mutation createLegalname($input: CreateLegalnameInput!) {
    createLegalname(input: $input) {
      ok
      error
      legalname {
        ...LegalnameParts
      }
    }
  }
  ${LEGALNAME_FRAGMENT}
`;
