import {gql} from "@apollo/client";
import { POST_FRAGMENT } from "graphql/fragments";

export const FIND_ONE_POST_QUERY = gql`
  query findOnePost($input: FindOnePostInput!) {
    findOnePost(input: $input) {
      ok
      error
      post {
        ...PostParts
      }
    }
  }
  ${POST_FRAGMENT}
`;
