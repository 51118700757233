import { StoreObject, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { adminMenuRoutes } from "../../../../navigation/routes";
import {
  createBuyingType,
  createBuyingTypeVariables,
} from "graphql/__generated-types__/createBuyingType";
import { BuyingTypesForm, IBuyingTypesForm } from "../components";
import { CREATE_BUYING_TYPE_MUTATION } from "graphql/mutations/createBuyingType";
import { BUYING_TYPE_FRAGMENT } from "graphql/fragments";

const BuyingTypeCreate = ({ onClose }) => {
  const navigate = useNavigate();
  const [createBuyingType] = useMutation<
    createBuyingType,
    createBuyingTypeVariables
  >(CREATE_BUYING_TYPE_MUTATION, {
    onCompleted: (createBuyingTypeData) => {
      if (createBuyingTypeData && createBuyingTypeData.createBuyingType.ok) {
        navigate(adminMenuRoutes.buyingTypes.path);
      } else {
        toast.error(createBuyingTypeData.createBuyingType.error);
      }
    },
    update(cache, { data: { createBuyingType } }) {
      if (createBuyingType?.buyingType?.id) {
        const newBuyingTypeRef = cache.writeFragment({
          id: cache.identify(
            createBuyingType?.buyingType as unknown as StoreObject
          ),
          data: createBuyingType?.buyingType,
          fragment: BUYING_TYPE_FRAGMENT,
          fragmentName: "BuyingTypeParts",
        });
        cache.modify({
          id: "ROOT_QUERY",
          fields: {
            findAllBuyingTypes(prev) {
              return {
                ...prev,
                buyingTypes: [newBuyingTypeRef, ...prev.buyingTypes],
              };
            },
          },
        });
      }
    },
  });

  const onSubmit = async (data: IBuyingTypesForm) => {
    const responseData = await createBuyingType({
      variables: {
        input: {
          ...data,
        },
      },
    });
    if (responseData.data.createBuyingType.ok) {
      onClose(true);
    }
  };
  return <BuyingTypesForm onSubmit={onSubmit} onClose={onClose} />;
};

export default BuyingTypeCreate;
