import { useNavigate } from "react-router-dom";
import { StoreObject, useMutation } from "@apollo/client";
import {
  createVendor,
  createVendorVariables,
} from "../../../../graphql/__generated-types__/createVendor";
import { adminMenuRoutes } from "../../../../navigation/routes";
import { toast } from "react-toastify";
import { VENDOR_FRAGMENT } from "../../../../graphql/fragments";
import { AdminVendorForm, IVendorForm } from "../components";
import { CREATE_VENDOR_MUTATION } from "graphql/mutations/createVendor";

const AdminVendorCreate = ({ onClose }) => {
  const navigate = useNavigate();

  const [createVendor] = useMutation<createVendor, createVendorVariables>(
    CREATE_VENDOR_MUTATION,
    {
      onCompleted: (createVendorData) => {
        if (createVendorData?.createVendor?.ok) {
          navigate(adminMenuRoutes.vendors.path);
        } else {
          toast.error(createVendorData?.createVendor?.error);
        }
      },
      update(cache, { data: { createVendor } }) {
        if (createVendor?.vendor?.id) {
          const newVendorRef = cache.writeFragment({
            id: cache.identify(createVendor?.vendor as unknown as StoreObject),
            data: createVendor?.vendor,
            fragment: VENDOR_FRAGMENT,
            fragmentName: "VendorParts",
          });

          cache.modify({
            id: "ROOT_QUERY",
            fields: {
              listVendors(prev) {
                return {
                  ...prev,
                  vendors: [newVendorRef, ...prev.vendors],
                };
              },
            },
          });
        }
      },
    }
  );

  const onSubmit = async (data: IVendorForm) => {
    const responseData = await createVendor({
      variables: {
        input: {
          ...data,
          isOrdNeeded: !!data?.isOrdNeeded,
        },
      },
    });
    if (responseData?.data?.createVendor?.ok) {
      onClose(true);
    }
  };

  return <AdminVendorForm onSubmit={onSubmit} onClose={onClose} />;
};
export default AdminVendorCreate;
