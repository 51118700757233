import {gql} from "@apollo/client";
import { PBT_CALCULATOR_FRAGMENT } from "graphql/fragments";

export const UPDATE_PBT_CALCULATOR_MUTATION = gql`
  mutation updatePbtCalculator($input: UpdatePbtCalculatorInput!) {
    updatePbtCalculator(input: $input) {
      ok
      error
      pbtCalculator {
        ...PbtCalculatorParts
      }
    }
  }
  ${PBT_CALCULATOR_FRAGMENT}
`;
