import { useNavigate } from "react-router-dom";
import { StoreObject, useMutation } from "@apollo/client";
import {
  createClient,
  createClientVariables,
} from "../../../../graphql/__generated-types__/createClient";
import { CREATE_CLIENT_MUTATION } from "../../../../graphql/mutations";
import { adminMenuRoutes } from "../../../../navigation/routes";
import { toast } from "react-toastify";
import { CLIENT_FRAGMENT } from "../../../../graphql/fragments";
import { AdminClientForm, IClientForm } from "../components";

const AdminClientCreate = ({ onClose }) => {
  const navigate = useNavigate();

  const [createClient] = useMutation<createClient, createClientVariables>(
    CREATE_CLIENT_MUTATION,
    {
      onCompleted: (createClientData) => {
        if (createClientData?.createClient?.ok) {
          navigate(adminMenuRoutes.clients.path);
        } else {
          toast.error(createClientData?.createClient?.error);
        }
      },
      update(cache, { data: { createClient } }) {
        if (createClient?.client?.id) {
          const newClientRef = cache.writeFragment({
            id: cache.identify(createClient?.client as unknown as StoreObject),
            data: createClient?.client,
            fragment: CLIENT_FRAGMENT,
            fragmentName: "ClientParts",
          });
          cache.modify({
            id: "ROOT_QUERY",
            fields: {
              listClients(prev) {
                return {
                  ...prev,
                  clients: [newClientRef, ...prev.clients],
                };
              },
            },
          });
        }
      },
    }
  );
  const onSubmit = async (data: IClientForm) => {
    const responseData = await createClient({
      variables: {
        input: data,
      },
    });
    if (responseData?.data?.createClient?.ok) {
      onClose(true);
    }
  };

  return <AdminClientForm onSubmit={onSubmit} onClose={onClose} />;
};
export default AdminClientCreate;
