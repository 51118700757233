import {gql} from "@apollo/client";

export const DELETE_CLIENT_MUTATION = gql`
  mutation deleteClient($input: DeleteClientInput!) {
    deleteClient(input: $input) {
      ok
      error
    }
  }
`;
