
// import { ExcelModal } from "components";

const InitialContractExcel = ({ show, showFn, closeModal, type, link, post_url }) => {

  const onSubmit = async () => {
    closeModal();
    window.location.reload();
  };

  return (
      <></>
    // <ExcelModal
    //   show={show}
    //   showFn={showFn}
    //   headerText="Экспорт договоров"
    //   link={link}
    //   onClose={closeModal}
    //   onSubmit={onSubmit}
    //   type={type}
    //   post_url={post_url}
    // />
  );
};
export default InitialContractExcel;