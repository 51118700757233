import React from "react";
import "./rouletteDesign.css";

import type { IDesignPluginProps, IDesignPlugin } from "react-roulette-pro";

export interface IRegularDesignProps {
  hideCenterDelimiter?: boolean;
  prizesWithText?: boolean;
}

interface ITopChildrenProps {
  hideCenterDelimiter: IRegularDesignProps["hideCenterDelimiter"];
  type: IDesignPluginProps["type"];
}

const TopChildren = ({ type, hideCenterDelimiter }: ITopChildrenProps) =>
  hideCenterDelimiter !== true ? (
    <div
      data-testid="design-top"
      className={`roulette-pro-regular-design-top ${type}`}
    />
  ) : null;

export const RouletteDesign = ({ type }: IDesignPluginProps): IDesignPlugin => {
  const prizeItemWidth: number = 300;
  const prizeItemHeight: number = 300;
  const hideCenterDelimiter = false;
  const prizeItemClassName = `roulette-pro-regular-design-prize-item-${type}`;

  return {
    topChildren: (
      <TopChildren type={type} hideCenterDelimiter={hideCenterDelimiter} />
    ),
    bottomChildren: null,
    prizeItemWidth,
    prizeItemHeight,
    prizeItemRenderFunction: ({ image, text }) => {
      return (
        <div
          className="roulette-pro-regular-prize-item"
          style={{ width: prizeItemWidth, height: prizeItemHeight }}
        >
          <div
            className="aspect-w-3 aspect-h-3 bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
          {/* <div className={"roulette-pro-regular-prize-item-wrapper center"}>
            <div className="overflow-hidden h-full w-full">
              <img
                className="object-cover w-full h-full"
                src={image}
                alt={"prize item"}
              />
            </div>
          </div> */}
        </div>
      );
    },
    classes: {
      prizeItem: prizeItemClassName,
    },
  };
};
