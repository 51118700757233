import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { meQuery } from "graphql/__generated-types__/meQuery";

export const ME_QUERY = gql`
  query meQuery {
    me {
      ok
      error
      user {
        id
        name
        surname
        middlename
        avatar
        roles
        office
        position {
          id
          title
        }
        headOfDepartment {
          id
          title
        }
        headOfVertical {
          id
          title
        }
        department {
          id
          title
        }
        email
        phone
        telegram
        birthdate
        employeeHead {
          id
          name
          surname
          avatar
        }
        subordinates {
          id
          name
          surname
          avatar
          isActive
        }
        isActive
        isRemote
      }
    }
  }
`;

export const useMe = (options?: QueryHookOptions) => {
  return useQuery<meQuery>(ME_QUERY, options);
};
