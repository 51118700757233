import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { adminMenuRoutes } from "../../../../navigation/routes";
import {
  deleteVendor,
  deleteVendorVariables,
} from "../../../../graphql/__generated-types__/deleteVendor";
import { DeleteModal } from "../../../../components";
import { DELETE_VENDOR_MUTATION } from "graphql/mutations/deleteVendor";

const AdminVendorDelete = ({ show, showFn, vendor, closeModal }) => {
  const navigate = useNavigate();

  const [deleteVendor] = useMutation<deleteVendor, deleteVendorVariables>(
    DELETE_VENDOR_MUTATION,
    {
      onCompleted: (deleteVendorData) => {
        if (deleteVendorData?.deleteVendor?.ok) {
          navigate(adminMenuRoutes.vendors.path);
        } else {
          toast.error(deleteVendorData?.deleteVendor?.error);
        }
      },
      update(cache, { data: { deleteVendor } }) {
        if (deleteVendor?.ok) {
          cache.evict({ id: cache.identify(vendor) });
          cache.gc();
        }
      },
    }
  );

  const onSubmit = async () => {
    await deleteVendor({
      variables: {
        input: {
          id: vendor.id,
        },
      },
    });
    closeModal();
  };

  return (
    <DeleteModal
      show={show}
      showFn={showFn}
      headerText="Удаление площадки"
      text="Вы уверены, что хотите удалить данную площадку?"
      onClose={closeModal}
      onSubmit={onSubmit}
    />
  );
};
export default AdminVendorDelete;
