import {gql} from "@apollo/client";
import { BUYING_TYPE_FRAGMENT } from "graphql/fragments";

export const CREATE_BUYING_TYPE_MUTATION = gql`
  mutation createBuyingType($input: CreateBuyingTypeInput!) {
    createBuyingType(input: $input) {
      ok
      error
      buyingType {
        ...BuyingTypeParts
      }
    }
  }
  ${BUYING_TYPE_FRAGMENT}
`;
