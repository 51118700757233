import { useQuery } from "@apollo/client";
import {
  FIND_ALL_USERS_QUERY,
  LIST_CLIENT_PROJECTS_QUERY,
} from "../../../../graphql/queries";
import {
  Button,
  DataLoader,
  LabeledMonthInput,
  LabeledTextInputWithTrailingAddon,
  PrimaryButton,
  ReactSelectInput,
} from "../../../../components";
import { Controller, useForm } from "react-hook-form";
import {
  listClientProjects,
  listClientProjectsVariables,
} from "../../../../graphql/__generated-types__/listClientProjects";
import { formatFullName } from "../../../../utils";
import { useMe } from "../../../../hooks";
import {
  findAllUsers,
  findAllUsersVariables,
} from "../../../../graphql/__generated-types__/findAllUsers";
import { PbtReportParts } from "graphql/__generated-types__/PbtReportParts";

export interface IPbtReportForm {
  monthYear: string;
  projectVerticalId: string;
  userId: number;
  workPercent: number;
}

interface IPbtReportFormProps {
  pbtReport?: PbtReportParts;
  onSubmit: (data: any) => void;
  onClose: () => void;
}

export const PbtReportForm: React.FC<IPbtReportFormProps> = ({
  pbtReport,
  onSubmit,
  onClose,
}) => {
  const { data: userData, loading: userLoading } = useMe();

  const label = pbtReport
    ? "Редактирование данных о загрузке"
    : "Добавление данных о загрузке";

  const { data: projectsData, loading: projectsDataLoading } = useQuery<
    listClientProjects,
    listClientProjectsVariables
  >(LIST_CLIENT_PROJECTS_QUERY, {
    variables: {
      input: {
        includeClients: true,
        includeVerticals: true,
      },
    },
  });

  const projectsOptions = !projectsDataLoading && 
    projectsData?.listClientProjects?.projects?.map((p) => {
      return p?.verticals?.filter(i => ![3, 7, 9].includes(i?.id)).map((v) => {
        return {
          label: `${p?.client?.title} : ${p?.title} : ${v?.title}`,
          value: `${p?.id}_${v?.id}`,
        };
      });
    }).flat();

  const { data: employeesData, loading: employeesLoading } = useQuery<
    findAllUsers,
    findAllUsersVariables
  >(FIND_ALL_USERS_QUERY, {
    variables: {
      input: {
        departmentIds: userData?.me?.user?.headOfDepartment?.map((e) => e.id),
        exceptEmployeeIds: [userData?.me?.user?.id],
      },
    },
  });

  const currentDate = new Date();

  const { handleSubmit, control, setValue } = useForm<IPbtReportForm>({
    mode: "onSubmit",
    shouldUnregister: true,
    defaultValues: pbtReport
      ? {
          ...pbtReport,
          userId: pbtReport.user?.id,
          monthYear: new Date(pbtReport.monthYear).toISOString(),
          projectVerticalId: `${pbtReport.project.id}_${pbtReport.vertical.id}`,
        }
      : {
          monthYear: new Date(
            Date.UTC(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              1,
              0,
              0,
              0
            )
          ).toISOString(),
          projectVerticalId: null,
          userId: null,
          workPercent: null,
        },
  });

  const subordinatesList = () => {
    const headOfDepartment = Boolean(
      userData?.me?.user?.headOfDepartment?.length
    );
    const groupHead = Boolean(userData?.me?.user?.subordinates?.length);

    const fullArray = [
      {
        label: formatFullName(userData?.me?.user),
        value: userData?.me?.user?.id,
      },
      ...(groupHead && Boolean(userData?.me?.user?.subordinates?.length)
        ? userData?.me?.user?.subordinates?.filter((s) => {
          if (s.isActive) return true 
          return false 
        }).map((s) => ({
            label: formatFullName(s),
            value: s.id,
          }))
        : []),
      ...(headOfDepartment &&
      Boolean(employeesData?.findAllUsers?.users?.length)
        ? employeesData?.findAllUsers?.users?.filter((u) => {
          if (u.isActive) return true 
          return false 
        }).map((u) => ({
            label: formatFullName(u),
            value: u.id,
          }))
        : []),
    ];

    return fullArray.filter(
      (item, index, self) =>
        self.findIndex((e) => item.value === e.value) === index
    );
  };

  return !projectsDataLoading && !userLoading && !employeesLoading ? (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {label}
            </h3>
          </div>

          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  rules={{ required: "Нужно заполнить дату" }}
                  name="monthYear"
                  render={({ field, fieldState }) => (
                    <LabeledMonthInput
                      labelText="Месяц"
                      field={field}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-4">
                <Controller
                  control={control}
                  name="projectVerticalId"
                  rules={{
                    required: "Нужно заполнить Клиент-Проект-Вертикаль",
                  }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={projectsOptions}
                      name="projectVerticalId"
                      defaultValue={field.value}
                      value={field.value}
                      labelText="Клиент-Проект-Вертикаль"
                      placeholder="Выберите проект из списка"
                      fieldState={fieldState}
                      onSelect={(e) => setValue("projectVerticalId", e)}
                    />
                  )}
                />
              </div>

              {Boolean(userData?.me?.user?.subordinates?.length) && (
                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <Controller
                    control={control}
                    name="userId"
                    rules={{ required: "Нужно заполнить сотрудника" }}
                    render={({ field, fieldState }) => (
                      <ReactSelectInput
                        options={subordinatesList}
                        defaultValue={field.value}
                        name="userId"
                        value={field.value}
                        labelText="Сотрудник"
                        onSelect={(e) => setValue("userId", e)}
                        placeholder="Выберите сотрудника из списка"
                        fieldState={fieldState}
                      />
                    )}
                  />
                </div>
              )}

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="workPercent"
                  rules={{ required: "Нужно заполнить загрузку на проекте" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInputWithTrailingAddon
                      labelText="Загрузка на проекте"
                      addonText="%"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      step="0.01"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-x-3">
        <Button type="button" onClick={() => onClose()}>
          Отмена
        </Button>
        <PrimaryButton type="submit">Сохранить</PrimaryButton>
      </div>
    </form>
  ) : (
    <DataLoader />
  );
};
