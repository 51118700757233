import { StoreObject, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataLoader } from "../../../../components";
import { adminMenuRoutes } from "../../../../navigation/routes";
import {
  updateClient,
  updateClientVariables,
} from "../../../../graphql/__generated-types__/updateClient";
import { UPDATE_CLIENT_MUTATION } from "../../../../graphql/mutations";
import { CLIENT_FRAGMENT } from "../../../../graphql/fragments";
import { AdminClientForm, IClientForm } from "../components";
import { ClientParts } from "graphql/__generated-types__/ClientParts";

interface IAdminClientEditProps {
  client: ClientParts;
  onClose: () => void;
}

const AdminClientEdit: React.FC<IAdminClientEditProps> = ({
  client,
  onClose,
}) => {
  const navigate = useNavigate();

  const [updateClient] = useMutation<updateClient, updateClientVariables>(
    UPDATE_CLIENT_MUTATION,
    {
      onCompleted: (updateClientData) => {
        if (updateClientData?.updateClient?.ok) {
          navigate(adminMenuRoutes.clients.path);
        } else {
          toast.error(updateClientData?.updateClient?.error);
        }
      },
      update(cache, { data: { updateClient } }) {
        if (updateClient?.client?.id) {
          cache.writeFragment({
            id: cache.identify(updateClient?.client as unknown as StoreObject),
            data: updateClient?.client,
            fragment: CLIENT_FRAGMENT,
            fragmentName: "ClientParts",
          });
        }
      },
    }
  );

  const onSubmit = async (data: IClientForm) => {
    const responseData = await updateClient({
      variables: {
        input: {
          ...data,
          id: +client.id,
        },
      },
    });
    if (responseData?.data?.updateClient?.ok) {
      onClose();
    }
  };

  return client ? (
    <AdminClientForm client={client} onSubmit={onSubmit} onClose={onClose} />
  ) : (
    <DataLoader />
  );
};
export default AdminClientEdit;
