import { gql } from "@apollo/client";
import { FOT_RATIO_FRAGMENT } from "graphql/fragments";

export const FIND_ALL_FOT_RATIOS_QUERY = gql`
  query findAllFOTRatio {
    findAllFOTRatio {
      ok
      error
      FOTRatios {
          ...FotratioParts
      }
    }
  }
  ${FOT_RATIO_FRAGMENT}
`;
