import { useQuery } from "@apollo/client";
import { DataLoader, Modal, PrimaryButton } from "components";
import { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon, PencilIcon, PlusIcon, TableIcon, TrashIcon, XCircleIcon } from "@heroicons/react/outline";
import { findOnePost, findOnePostVariables } from "graphql/__generated-types__/findOnePost";
import { FIND_ONE_POST_QUERY } from "graphql/queries/findOnePost";
import { mainMenuRoutes, newsMenuServicesRoutes } from "navigation/routes";
import { PostType, UserRole } from "graphql/__generated-types__/globalTypes";
import { useMe } from "hooks";
import { PostParts } from "graphql/__generated-types__/PostParts";
import PostPin from "pages/Home/components/PostService/pin";
import PostDelete from "pages/Home/components/PostService/delete";
import PostEdit from "pages/Home/components/PostService/edit";
import PostCreate from "pages/Home/components/PostService/create";
import { Link } from "react-router-dom";
import { findClosestPosts, findClosestPostsVariables } from "graphql/__generated-types__/findClosestPosts";
import { FIND_CLOSEST_POSTS_QUERY } from "graphql/queries/findClosestPosts,";
import Sidebar from "../components/sidebar";
import ReactMarkdown from "react-markdown";
import "pages/Home/components/HomepageStyles.css"
import ReactMde from "react-mde";
import { formatDate } from "utils";

interface IShowNewsParams {
    id: string;
  }

  const ShowNews = () => {

    const hideEditingForm = useCallback(() => {
      setIsEditing(false);
      resetCurrentPost();
    }, []);
  
    const clickAddButton = useCallback(() => {
      hideEditingForm();
      setIsAdding(true);
    }, [hideEditingForm]);
  
  
    const [showDeletePostModal, setShowDeletePostModal] =
      useState(false);
    const [showPinPostModal, setShowPinPostModal] =
      useState(false);
    const [currentPost, setCurrentPost] =
      useState<PostParts>(null);
    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
  
    const resetCurrentPost = () => setCurrentPost(null);

    const hideAddingForm = useCallback(
      (reOpen = false) => {
        setIsAdding(false);
        resetCurrentPost();
        if (reOpen) {
          clickAddButton();
        }
      },
      [clickAddButton]
    );
  
    const clickEditButton = useCallback(
      (post: PostParts) => {
        hideAddingForm();
        hideEditingForm();
        setIsEditing(true);
        setCurrentPost(post);
        const postAddButton = document.getElementById(
          "postAddButton"
        );
        if (postAddButton) {
          postAddButton.scrollIntoView();
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      },
      [hideAddingForm, hideEditingForm]
    );
  
    const clickDeleteButton = useCallback(
      (post: PostParts) => {
        setShowDeletePostModal(!showDeletePostModal);
        setCurrentPost(post);
      },
      [showDeletePostModal]
    );
  
    const clickPinButton = useCallback(
      (post: PostParts) => {
        setShowPinPostModal(!showPinPostModal);
        setCurrentPost(post);
      },
      [showPinPostModal]
    );


  const navigate = useNavigate();
  const user = useMe();
  const params = useParams<keyof IShowNewsParams>();

  const { data: onePostData, loading: onePostLoading } = useQuery<
  findOnePost,
  findOnePostVariables
>(FIND_ONE_POST_QUERY, {
  variables: {
    input: { id: parseInt(params.id) },
  },
});

  const { data: closestPostsData, loading: closestPostsLoading } = useQuery<
  findClosestPosts,
  findClosestPostsVariables
>(FIND_CLOSEST_POSTS_QUERY, {
  variables: {
    input: { id: parseInt(params.id)},
  },
});

//const save: SaveImageHandler = async function*(data: ArrayBuffer) {


        return onePostLoading &&
        closestPostsLoading
        ? (
            <DataLoader />
          ) : (
          <>
          <Helmet>
            <title>Новости | Артикс Портал</title>
          </Helmet>
          <div className="relative bg-gray-50 lg:py-0 lg:px-0">
          {(isAdding || isEditing) && (
            <div className="mb-2">
              {isAdding && <PostCreate onClose={hideAddingForm} path={mainMenuRoutes?.news?.path} />}
              {isEditing && (
                <PostEdit
                  onClose={hideEditingForm}
                  post={currentPost}
                  path={mainMenuRoutes?.news?.path}
                />)}
            </div>
          )}
          {showDeletePostModal && (
            <Modal
              show={showDeletePostModal}
              showFn={setShowDeletePostModal}
            >
              <div className="inline-block align-bottom bg-gray-50 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                {<PostDelete
                  post={currentPost}
                  path={mainMenuRoutes?.news?.path}
                  closeModal={setShowDeletePostModal}
                />}
              </div>
            </Modal>
            )}

            {showPinPostModal && (
              <Modal
                show={showPinPostModal}
                showFn={setShowPinPostModal}
              >
                <div className="inline-block align-bottom bg-gray-50 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  {<PostPin
                    post={currentPost}
                    path={mainMenuRoutes?.news?.path}
                    closeModal={setShowPinPostModal}
                  />}
                </div>
              </Modal>
            )}

            <div className="bg-gray-50 w-full maxhsm:inline-block lg:flex lg:grid-cols-2 max-w-none">
              {/* 1й столбец */}
              <div className="relative lg:w-8/12 maxhsm:w-11/12 lg:m-5 lg:mx-auto hsm:m-5 maxhsm:mx-auto overflow-hidden">
                {(user?.data?.me?.user?.roles.includes(UserRole.PR_ADMIN) || user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
                <>
                  <div className="grid-flow-col gap-2 mb-0 invisible sm:ml-2 sm:mb-2 lg:visible lg:ml-5 lg:mb-5">
                    <PrimaryButton
                      icon={PlusIcon({
                        "className": "h-4 w-4",
                        "aria-hidden": true,
                      })}
                      id="postAddButton"
                      onClick={() => clickAddButton()}
                      className={"mx-1 h-10 w-auto"}
                    >
                      Добавить
                    </PrimaryButton>
                    <PrimaryButton
                      icon={TableIcon({
                        "className": "h-4 w-4",
                        "aria-hidden": true,
                      })}
                      onClick={() => navigate(newsMenuServicesRoutes?.postService?.path)}
                      className={"mx-1 h-10"}
                    >
                      Таблица
                    </PrimaryButton>
                    <PrimaryButton
                      icon={PencilIcon({ 
                        "className": "h-4 w-4",
                        "aria-hidden": true,
                      })}
                      disabled={isAdding}
                      onClick={() => clickEditButton(onePostData?.findOnePost?.post)}
                      className={"mx-1 h-10 w-12"}
                    ></PrimaryButton>
                    <PrimaryButton
                      icon={TrashIcon({
                        "className": "h-4 w-4",
                        "aria-hidden": true,
                      })}
                      disabled={isAdding || isEditing}
                      onClick={() => clickDeleteButton(onePostData?.findOnePost?.post)}
                      className={"mx-1 h-10 w-12"}
                    ></PrimaryButton>
                    <PrimaryButton
                      icon={XCircleIcon({
                        "className": "h-4 w-4",
                        "aria-hidden": true,
                      })}
                      disabled={isAdding || isEditing}
                      onClick={() => clickPinButton(onePostData?.findOnePost?.post)}
                      className={onePostData?.findOnePost?.post?.postType === PostType.PINNED ? 
                        ("mx-1 h-10 w-12 bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500") :
                        "mx-1 h-10 w-12"}
                    ></PrimaryButton>
                  </div>
                </>
                )}
                <div className="relative px-4 sm:px-6 lg:px-1">
                  <div className="mx-auto max-w-full">
                    <h1>
                      <span className="mt-2 block text-left mx-28 text-4xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                        {onePostData?.findOnePost?.post?.header}
                      </span>
                    </h1>
                      <div className="relative mt-5 bg-gray-50 mx-28 ">
                        <img
                          className="p-2 mx-auto w-full object-contain rounded-4xl border-transparent"
                          src={`${onePostData?.findOnePost?.post?.imageURL}`}
                          alt=""
                        />
                      </div>
                    <p className="mt-5 pb-10 text-xs leading-4 mx-28">
                      <div>
                        <div className="absolute right-0 mb-5 self-center">
                          {((closestPostsData?.findClosestPosts?.postsType === 'all') ? (
                            <>
                              <Link to={`/news/${closestPostsData?.findClosestPosts?.posts?.slice().sort((a, b) => b.createdAt - a.createdAt)[0]?.id}`} className="absolute right-0">
                                <ChevronRightIcon className="h-12"/>
                              </Link>
                            </>
                          ) : ((closestPostsData?.findClosestPosts?.postsType === 'next') ?
                            (
                              <>
                                <Link to={`/news/${closestPostsData?.findClosestPosts?.posts[0].id}`} className="absolute right-0">
                                  <ChevronRightIcon className="h-12"/>
                                </Link>
                              </>
                            ) : (
                              <>
                                <div className="absolute right-0">
                                  <ChevronRightIcon className="h-12 text-gray-400"/>
                                </div>
                              </>
                            )
                          )
                          )}
                        </div>
                        <div className="absolute left-0 mb-5 self-center">
                          {((closestPostsData?.findClosestPosts?.postsType === 'all') ? (
                            <>
                              <Link to={`/news/${closestPostsData?.findClosestPosts?.posts?.slice().sort((a, b) => b.createdAt - a.createdAt)[1]?.id}`} className="absolute left-0">
                                <ChevronLeftIcon className="h-12"/>
                              </Link>
                            </>
                          ) : ((closestPostsData?.findClosestPosts?.postsType === 'prev') ?
                            (
                              <>
                                <Link to={`/news/${closestPostsData?.findClosestPosts?.posts[0].id}`} className="absolute left-0">
                                  <ChevronLeftIcon className="h-12"/>
                                </Link>
                              </>
                            ) : (
                              <>
                                <div className="absolute left-0">
                                  <ChevronLeftIcon className="h-12 text-gray-400"/>
                                </div>
                              </>
                            )
                          )
                          )}
                        </div>
                        <p className="break-words leading-5 text-base" id="markdown">
                          <ReactMde
                            value={onePostData?.findOnePost?.post?.text}
                            selectedTab={"preview"} 
                            generateMarkdownPreview={(markdown) =>
                              Promise.resolve(<ReactMarkdown children={markdown} />)}
                            childProps={{
                              writeButton: {
                                disabled: true,
                              },
                              previewButton: {
                                disabled: true,
                              },
                            }}
                          />
                        </p>
                      </div>
                    </p>
                    <cite className="mt-12 relative flex items-center rounded-b-lg bg-red-600 py-5 px-6 not-italic sm:mt-10 sm:items-start sm:py-5 sm:pl-12 sm:pr-10">
                          <span className="relative rounded-full border-2 border-white sm:absolute sm:top-0 sm:-translate-y-1/2 sm:transform">
                            <img
                              className="h-20 w-20 object-cover rounded-full bg-indigo-300"
                              src={onePostData?.findOnePost?.post?.addedBy?.avatar}
                              alt=""
                            />
                          </span>
                          <span className="relative ml-4 font-semibold leading-6 text-red-300 sm:ml-24 sm:pl-1">
                            <span className="font-semibold text-white sm:inline">{onePostData?.findOnePost?.post?.addedBy?.name} {onePostData?.findOnePost?.post?.addedBy?.surname}</span>{' '}
                            <span className="sm:inline">{formatDate(onePostData?.findOnePost?.post?.createdAt)}</span>
                          </span>
                        </cite>
                  </div>
                </div>
              </div>
              {/* 1й столбец */}
              {/* 2й столбец */}
              <Sidebar/>
              {/* 2й столбец */}
            {/*Новостная лента*/}
            </div>
          </div>
          </>
        )
}

export default ShowNews;