/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Роль стороны акта
 */
export enum ActSidesRole {
  ORS = "ORS",
  RA = "RA",
  RD = "RD",
  RR = "RR",
}

/**
 * Типы систем автоматизации
 */
export enum AutomationSystemType {
  ALYTICS = "ALYTICS",
  K50 = "K50",
  MARYLIN = "MARYLIN",
}

/**
 * Тип транзакции
 */
export enum BuyingCashbackTransactionType {
  INCOME = "INCOME",
  OUTCOME = "OUTCOME",
}

/**
 * Признак Пред/Пост оплаты
 */
export enum BuyingConditionType {
  POSTPAID = "POSTPAID",
  PREPAID = "PREPAID",
}

/**
 * Типы взаимодействия сторон посреднического договора
 */
export enum ContractActionType {
  COMMERCIAL_REPRESENTATION = "COMMERCIAL_REPRESENTATION",
  CONTRACTING = "CONTRACTING",
  DISTRIBUTION = "DISTRIBUTION",
  OTHER = "OTHER",
}

/**
 * Разновидность договора
 */
export enum ContractDocType {
  FINAL_CONTRACT = "FINAL_CONTRACT",
  INITIAL_CONTRACT = "INITIAL_CONTRACT",
}

/**
 * Статус договора
 */
export enum ContractStatus {
  IN_PROGRESS = "IN_PROGRESS",
  NOT_SIGNED = "NOT_SIGNED",
  OTHER = "OTHER",
  SIGNED = "SIGNED",
  SIGNED_BY_MGCOM = "SIGNED_BY_MGCOM",
}

/**
 * Предмет договора
 */
export enum ContractSubjectType {
  DISTRIBUTION = "DISTRIBUTION",
  MEDIATION = "MEDIATION",
  ORG_DISTRIBUTION = "ORG_DISTRIBUTION",
  OTHER = "OTHER",
}

/**
 * Тип договора
 */
export enum ContractType {
  MEDIATION_CONTRACT = "MEDIATION_CONTRACT",
  SERVICE_AGREEMENT = "SERVICE_AGREEMENT",
}

/**
 * Валюта
 */
export enum Currency {
  EUR = "EUR",
  RUB = "RUB",
  USD = "USD",
}

/**
 * Грейд сотрудника
 */
export enum EmployeeGrade {
  BASE = "BASE",
  MEDIA = "MEDIA",
  PROGRAMMATIC = "PROGRAMMATIC",
  TARGET = "TARGET",
  UPDATE = "UPDATE",
}

/**
 * Тип ЮЛ
 */
export enum LegalnameType {
  INDIVIDUAL_ENTREPRENEUR = "INDIVIDUAL_ENTREPRENEUR",
  INTERNATIONAL_JURIDICAL_PERSON = "INTERNATIONAL_JURIDICAL_PERSON",
  INTERNATIONAL_PHYSICAL_PERSON = "INTERNATIONAL_PHYSICAL_PERSON",
  JURIDICAL_PERSON = "JURIDICAL_PERSON",
  PHYSICAL_PERSON = "PHYSICAL_PERSON",
}

/**
 * Прикрепленный офис
 */
export enum Office {
  MSK = "MSK",
  SPB = "SPB",
}

/**
 * Тег новости
 */
export enum PostType {
  MAIN = "MAIN",
  MAIN_PAGE = "MAIN_PAGE",
  PINNED = "PINNED",
}

export enum RandomCoffeeWhoAmI {
  INVITEE = "INVITEE",
  INVITER = "INVITER",
}

/**
 * Пол сотрудника
 */
export enum UserGender {
  F = "F",
  M = "M",
}

/**
 * Роль пользователя
 */
export enum UserRole {
  BATTLE_CASE_ADMIN = "BATTLE_CASE_ADMIN",
  BUYING_ADMIN = "BUYING_ADMIN",
  BUYING_USER = "BUYING_USER",
  DOC_ADMIN = "DOC_ADMIN",
  GLOBAL_ADMIN = "GLOBAL_ADMIN",
  HR_ADMIN = "HR_ADMIN",
  PR_ADMIN = "PR_ADMIN",
  RANDOM_COFFEE_ADMIN = "RANDOM_COFFEE_ADMIN",
  USER = "USER",
}

export interface BuyingCashbackRuleAbstract {
  client: ClientAbstract;
  vendor: VendorAbstract;
  cashbackIntervals: BuyingCashbackRuleIntervalAbstract[];
  fallbackCashbackPerc?: number | null;
  buyingConditionType?: BuyingConditionType | null;
}

export interface BuyingCashbackRuleIntervalAbstract {
  buyingCashbackRule?: BuyingCashbackRuleAbstract | null;
  minTurnover?: number | null;
  maxTurnover?: number | null;
  validFrom: any;
  validTo: any;
  cashbackPerc: number;
}

export interface BuyingFormatAbstract {
  title: string;
}

export interface BuyingTypeAbstract {
  title: string;
}

export interface CalculateBuyingCashbackInput {
  cashbackPeriod?: any | null;
  turnover?: number | null;
  clientId: number;
  vendorId: number;
  buyingConditionType?: BuyingConditionType | null;
}

export interface ClientAbstract {
  title: string;
  industry?: ClientIndustryAbstract | null;
  projects?: ClientProjectAbstract[] | null;
}

export interface ClientIndustryAbstract {
  title: string;
  clients?: ClientAbstract[] | null;
}

export interface ClientProjectAbstract {
  title: string;
  client?: ClientAbstract | null;
  legalnames?: LegalnameAbstract[] | null;
  verticals?: VerticalAbstract[] | null;
}

export interface ContractAbstract {
  addedBy?: UserAbstract | null;
  contractNumber: string;
  contractDate: any;
  customerLegalname?: LegalnameAbstract | null;
  contractorLegalname?: LegalnameAbstract | null;
  isVatIncluded: boolean;
  contractType?: ContractType | null;
  contractSubjectType?: ContractSubjectType | null;
  contractActionType?: ContractActionType | null;
  isORD?: boolean | null;
  metaInfo?: string | null;
  contractSumAmount?: number | null;
  contractDocType?: ContractDocType | null;
  finalContract?: ContractAbstract | null;
  finalContracts?: ContractAbstract[] | null;
  clientProjects?: ClientProjectAbstract[] | null;
}

export interface CreateBuyingCashbackRuleInput {
  fallbackCashbackPerc?: number | null;
  buyingConditionType?: BuyingConditionType | null;
  clientId: number;
  vendorId: number;
  cashbackIntervals?: BuyingCashbackRuleIntervalAbstract[] | null;
}

export interface CreateBuyingCashbackTransactionInput {
  transactionType: BuyingCashbackTransactionType;
  cashbackSum: number;
  clientId: number;
  vendorId: number;
  cashbackPeriod?: any | null;
  turnover?: number | null;
  buyingConditionType?: BuyingConditionType | null;
  outcomeAfterIncome?: boolean | null;
}

/**
 * Набор полей для создания условия закупки
 */
export interface CreateBuyingConditionInput {
  title?: string | null;
  category?: VendorCategoryAbstract | null;
  vendor?: VendorAbstract | null;
  seller?: SellerAbstract | null;
  client?: ClientAbstract | null;
  buyingFormat?: BuyingFormatAbstract | null;
  buyingType?: BuyingTypeAbstract | null;
  addedBy?: UserAbstract | null;
  articsLegalname?: LegalnameAbstract | null;
  contractStatus?: ContractStatus | null;
  taskLink?: string | null;
  svnLink?: string | null;
  CPM?: number | null;
  CPMComment?: string | null;
  clientDiscount?: number | null;
  clientDiscountComment?: string | null;
  agencyDiscount?: number | null;
  agencyDiscountComment?: string | null;
  moneyback?: number | null;
  moneybackComment?: string | null;
  bonus?: number | null;
  bonusComment?: string | null;
  discountBefore?: number | null;
  discountBeforeComment?: string | null;
  discountAfter?: number | null;
  discountAfterComment?: string | null;
  paymentDelay?: string | null;
  paymentDelayComment?: string | null;
  comments?: string | null;
  totalMargin?: number | null;
  categoryId?: number | null;
  sellerId?: number | null;
  vendorId?: number | null;
  buyingFormatId?: number | null;
  buyingTypeId?: number | null;
  clientId?: number | null;
  articsLegalnameId?: number | null;
}

/**
 * Набор полей для создания формата закупки
 */
export interface CreateBuyingFormatInput {
  title: string;
}

/**
 * Набор полей для создания типа закупки
 */
export interface CreateBuyingTypeInput {
  title: string;
}

/**
 * Набор полей для создания кейса
 */
export interface CreateCaseInput {
  title?: string | null;
  description?: string | null;
  picture?: string | null;
  presentation?: string | null;
  startDateTime?: any | null;
  isWinner?: boolean | null;
  isArchive?: boolean | null;
  participantsIds?: number[] | null;
}

/**
 * Набор полей для создания клиента
 */
export interface CreateClientInput {
  title: string;
  industryId: number;
  projectIds: number[];
}

/**
 * Набор полей для создания договора
 */
export interface CreateContractInput {
  addedBy?: UserAbstract | null;
  contractNumber: string;
  contractDate: any;
  customerLegalname?: LegalnameAbstract | null;
  contractorLegalname?: LegalnameAbstract | null;
  isVatIncluded: boolean;
  contractType?: ContractType | null;
  contractSubjectType?: ContractSubjectType | null;
  contractActionType?: ContractActionType | null;
  isORD?: boolean | null;
  metaInfo?: string | null;
  contractSumAmount?: number | null;
  contractDocType?: ContractDocType | null;
  clientProjectsIds?: number[] | null;
  finalContractInvoicesIds?: number[] | null;
  initialContractInvoicesIds?: number[] | null;
  customerLegalnameId?: number | null;
  contractorLegalnameId?: number | null;
  finalContractId?: number | null;
  additionalContractId?: number | null;
}

/**
 * Набор полей для создания плана по отделу
 */
export interface CreateDepartmentPlanInput {
  monthYear: any;
  budgetProbability: number;
  turnover: number;
  margin: number;
  departmentId: number;
  projectVerticalId: number;
  legalnameId: number;
  userId: number;
  vendorId: number;
}

/**
 * Набор полей для создания подразделения
 */
export interface CreateEmployeeDepartmentInput {
  title: string;
  description?: string | null;
  order?: number | null;
  departmentHeadId?: number | null;
  employeesIds?: number[] | null;
}

/**
 * Набор полей для создания должности
 */
export interface CreateEmployeePositionInput {
  title: string;
  description?: string | null;
  userIds?: number[] | null;
}

/**
 * Набор полей для создания акта
 */
export interface CreateInvoiceInput {
  invoiceNumber: string;
  invoiceDate: any;
  serviceStartDate?: any | null;
  serviceEndDate?: any | null;
  customerRole?: ActSidesRole | null;
  contractorRole?: ActSidesRole | null;
  invoiceSumAmount?: number | null;
  isVat?: boolean | null;
  initialContract?: ContractAbstract | null;
  initialContractInvoiceId?: number | null;
  finalContract?: ContractAbstract | null;
  finalContractInvoiceId?: number | null;
  initialInvoices?: InvoiceAbstract[] | null;
  breakdown?: boolean | null;
}

/**
 * Набор полей для создания юр лиц
 */
export interface CreateLegalnameInput {
  title: string;
  isVat: boolean;
  isArtics: boolean;
  legalnameType?: LegalnameType | null;
  ITN?: string | null;
  PSRN?: string | null;
  phone?: string | null;
  epayNumber?: string | null;
  oksmNumber?: string | null;
  currency: Currency;
  projectIds?: number[] | null;
  sellerId?: number | null;
}

/**
 * Набор полей для записи авторизации
 */
export interface CreateLoginIpInput {
  email: string;
  ip: string;
}

export interface CreatePbtCalculatorBuyingConditionInput {
  budget: number;
  akDiscount: number;
  overallDiscount: number;
  pbtCalculatorId?: number | null;
  pbtBuyingConditionId?: number | null;
}

export interface CreatePbtCalculatorInput {
  title: string;
  periodFrom?: any | null;
  periodTo?: any | null;
  pbtCalculatorPositionIds?: number[] | null;
  pbtCalculatorPositionSalaries?: number[] | null;
  pbtCalculatorPositionWorkloads?: number[] | null;
  pbtCalculatorBuyingConditionsIds?: number[] | null;
  pbtCalculatorBuyingConditionsBudgets?: number[] | null;
  pbtCalculatorBuyingConditionsAkDiscounts?: number[] | null;
  pbtCalculatorBuyingConditionsOverallDiscounts?: number[] | null;
}

export interface CreatePbtCalculatorPositionInput {
  salary: number;
  workload: number;
  pbtCalculatorId?: number | null;
  pbtPositionId?: number | null;
}

/**
 * Набор полей для создания загрузки сотрудника в PBT отчете
 */
export interface CreatePbtReportInput {
  monthYear: any;
  workPercent: number;
  projectId: number;
  verticalId: number;
  userId?: number | null;
}

/**
 * Набор полей для создания отчета
 */
export interface CreatePmReportInput {
  monthYear: any;
  reportDay: number;
  buyingPeriod: number;
  budgetPlanVat: number;
  budgetFactNet: number;
  kpiPlan: number;
  kpiFact: number;
  clientAgencyFeePerc: number;
  clientDiscountPerc: number;
  clientAutomationSystemType?: AutomationSystemType | null;
  clientAutomationSystemPerc: number;
  clientHiddenFeePerc: number;
  clientVatPerc: number;
  vendorMoneybackPerc: number;
  vendorDiscountPerc: number;
  vendorVatPerc: number;
  projectVerticalId?: number | null;
  userId: number;
  vendorId: number;
  kpiTypeId?: number | null;
}

/**
 * Набор полей для создания новости
 */
export interface CreatePostInput {
  header: string;
  underHeader?: string | null;
  text?: string | null;
  postType?: PostType | null;
  imageURL?: string | null;
  addedBy?: UserAbstract | null;
}

/**
 * Набор полей для создания селлера
 */
export interface CreateSellerInput {
  title: string;
  legalnameIds?: number[] | null;
}

/**
 * Набор полей для создания пользователя
 */
export interface CreateUserInput {
  middlename?: string | null;
  roles?: UserRole[] | null;
  grades?: EmployeeGrade[] | null;
  priorityInsideDepartment?: number | null;
  telegram?: string | null;
  isActive?: boolean | null;
  isRemote?: boolean | null;
  googleEmail?: string | null;
  name: string;
  surname: string;
  avatar?: string | null;
  gender?: UserGender | null;
  office?: Office | null;
  email?: string | null;
  phone?: string | null;
  birthdate?: any | null;
  employmentDate?: any | null;
  departmentId: number;
  positionId: number;
  employeeHeadId?: number | null;
  subordinatesIds?: number[] | null;
}

/**
 * Набор полей для создания площадки
 */
export interface CreateVendorInput {
  title: string;
  isOrdNeeded?: boolean | null;
  category1Id: number;
  category2Id: number;
  category3Id: number;
  buyingFormatIds?: number[] | null;
  buyingTypeIds?: number[] | null;
  sellerIds?: number[] | null;
}

/**
 * Набор полей для голосования
 */
export interface CreateVoteInput {
  caseId: number;
  PROBLEM_DEPTH: number;
  UNORDINARY_IDEAS: number;
  CAMPAIGNS_IMPLEMENTATION_QUALITY: number;
  ACHIEVED_RESULTS_GRADE: number;
  PRESENTATION_LEVEL: number;
}

export interface DeleteBuyingCashbackRuleInput {
  id: number;
}

export interface DeleteBuyingCashbackTransactionInput {
  id: number;
}

/**
 * Набор полей для удаления условия закупки
 */
export interface DeleteBuyingConditionInput {
  id: number;
}

/**
 * Набор полей для удаления формата закупки
 */
export interface DeleteBuyingFormatInput {
  id: number;
}

/**
 * Набор полей для удаления типа закупки
 */
export interface DeleteBuyingTypeInput {
  id: number;
}

/**
 * Набор полей для удаления кейса
 */
export interface DeleteCaseInput {
  id: number;
}

/**
 * Набор полей для удаления клиента
 */
export interface DeleteClientInput {
  id: number;
}

/**
 * Набор полей для удаления новости
 */
export interface DeleteContractInput {
  id: number;
}

/**
 * Набор полей для удаления плана
 */
export interface DeleteDepartmentPlanInput {
  id?: number | null;
  monthYear?: any | null;
  budgetProbability?: number | null;
  turnover?: number | null;
  margin?: number | null;
  departmentId?: number | null;
  projectVerticalId?: number | null;
  legalnameId?: number | null;
  userId?: number | null;
  vendorId?: number | null;
}

/**
 * Набор полей для удаления акта
 */
export interface DeleteInvoiceInput {
  id: number;
}

/**
 * Набор полей для удаления юр лица
 */
export interface DeleteLegalnameInput {
  id: number;
}

/**
 * Набор полей для удаления записи баингового условия для расчета ПБТ
 */
export interface DeletePbtCalculatorBuyingConditionInput {
  id: number;
}

/**
 * Набор полей для удаления расчета ПБТ
 */
export interface DeletePbtCalculatorInput {
  id: number;
}

/**
 * Набор полей для удаления записи должности для расчета ПБТ
 */
export interface DeletePbtCalculatorPositionInput {
  id: number;
}

/**
 * Набор полей для удаления загрузки сотрудника
 */
export interface DeletePbtReportInput {
  id?: number | null;
  monthYear?: any | null;
  workPercent?: number | null;
  projectId?: number | null;
  verticalId?: number | null;
  userId?: number | null;
}

/**
 * Набор полей для удаления ПМ отчета
 */
export interface DeletePmReportInput {
  id?: number | null;
  monthYear?: any | null;
  reportDay?: number | null;
  buyingPeriod?: number | null;
  budgetPlanVat?: number | null;
  budgetFactNet?: number | null;
  kpiPlan?: number | null;
  kpiFact?: number | null;
  clientAgencyFeePerc?: number | null;
  clientDiscountPerc?: number | null;
  clientAutomationSystemType?: AutomationSystemType | null;
  clientAutomationSystemPerc?: number | null;
  clientHiddenFeePerc?: number | null;
  clientVatPerc?: number | null;
  vendorMoneybackPerc?: number | null;
  vendorDiscountPerc?: number | null;
  vendorVatPerc?: number | null;
  projectVerticalId?: number | null;
  userId?: number | null;
  vendorId?: number | null;
  kpiTypeId?: number | null;
}

/**
 * Набор полей для удаления новости
 */
export interface DeletePostInput {
  id: number;
}

export interface DeleteRandomCoffeeInput {
  id: number;
}

/**
 * Набор полей для удаления селлера
 */
export interface DeleteSellerInput {
  id: number;
}

/**
 * Набор полей для удаления площадки
 */
export interface DeleteVendorInput {
  id: number;
}

export interface DepartmentPlanAbstract {
  monthYear: any;
  department?: EmployeeDepartmentAbstract | null;
  projectVerticalId?: number | null;
  legalname?: LegalnameAbstract | null;
  user?: UserAbstract | null;
  vendor?: VendorAbstract | null;
  budgetProbability: number;
  turnover: number;
  margin: number;
}

export interface EmployeeCertificateAbstract {
  employee?: UserAbstract | null;
  vendor?: VendorAbstract | null;
  certificateCategory: string;
  fileLink: string;
  validUntil?: any | null;
  isExpired: boolean;
  passed?: boolean | null;
}

export interface EmployeeDepartmentAbstract {
  title: string;
  secondaryTitle?: string | null;
  description?: string | null;
  order?: number | null;
  departmentHead?: UserAbstract | null;
  employees?: UserAbstract[] | null;
}

export interface EmployeePositionAbstract {
  title: string;
  description?: string | null;
  employees?: UserAbstract[] | null;
  processes?: ProcessesAbstract[] | null;
}

/**
 * Набор полей для вывода информации о всех условиях закупки
 */
export interface FindAllBuyingConditionInput {
  page?: number | null;
  take?: number | null;
}

/**
 * Набор полей для вывода списка форматов закупки
 */
export interface FindAllBuyingFormatsInput {
  keyword?: string | null;
}

/**
 * Набор полей для вывода списка типов закупки
 */
export interface FindAllBuyingTypesInput {
  page?: number | null;
  take?: number | null;
  keyword?: string | null;
}

/**
 * Набор полей для фильтрации кейсов
 */
export interface FindAllCasesInput {
  page?: number | null;
  take?: number | null;
  title?: string | null;
  includeArchive?: boolean | null;
}

/**
 * Набор полей для вывода информации о всех договорах
 */
export interface FindAllContractsInput {
  page?: number | null;
  take?: number | null;
  contractDocType?: ContractDocType | null;
}

/**
 * Набор полей для фильтрации планов отделов
 */
export interface FindAllDepartmentPlansInput {
  page?: number | null;
  take?: number | null;
  budgetProbability?: number | null;
  turnover?: number | null;
  margin?: number | null;
  userIds?: number[] | null;
  departmentsIds?: number[] | null;
}

/**
 * Набор полей для вывода информации о всех актах
 */
export interface FindAllInvoicesInput {
  page?: number | null;
  take?: number | null;
}

/**
 * Набор полей для поиска баинговых условий, на основе которых ведется расчет ПБТ калькулятора
 */
export interface FindAllPbtCalculatorBuyingConditionInput {
  pbtCalculatorId?: number | null;
}

/**
 * Набор полей для поиска расчетов ПБТ калькулятора
 */
export interface FindAllPbtCalculatorInput {
  keyword?: string | null;
  pbtCalculatorBuyingConditionIds?: number[] | null;
  pbtCalculatorPositionIds?: number[] | null;
}

/**
 * Набор полей для поиска должностей, на основе которых ведется расчет ПБТ калькулятора
 */
export interface FindAllPbtCalculatorPositionInput {
  pbtCalculatorId?: number | null;
}

/**
 * Набор полей для фильтрации нагрузки сотрудника
 */
export interface FindAllPbtReportsInput {
  page?: number | null;
  take?: number | null;
  monthYear?: any | null;
  workPercent?: number | null;
  userIds?: number[] | null;
  departmentsIds?: number[] | null;
  distinct?: boolean | null;
}

/**
 * Набор полей для фильтрации ПМ отчетов
 */
export interface FindAllPmReportsInput {
  page?: number | null;
  take?: number | null;
  userIds?: number[] | null;
  departmentsIds?: number[] | null;
}

/**
 * Набор полей для фильтрации пользователей с пагинацией
 */
export interface FindAllUsersInput {
  page?: number | null;
  take?: number | null;
  office?: Office | null;
  isActive?: boolean | null;
  isRemote?: boolean | null;
  keyword?: string | null;
  isHead?: boolean | null;
  isPM?: boolean | null;
  departmentId?: number | null;
  positionId?: number | null;
  departmentIds: number[];
  includeCertificates?: boolean | null;
  exceptEmployeeIds?: number[] | null;
  legalCompany?: string | null;
}

/**
 * Набор полей для поиска новости
 */
export interface FindClosestPostsInput {
  id: number;
}

/**
 * Набор полей для вывода информации о подразделении
 */
export interface FindEmployeeDepartmentInput {
  id: number;
  includeUserData?: boolean | null;
}

/**
 * Набор полей для вывода информации о должности
 */
export interface FindEmployeePositionInput {
  id: number;
  includeUserData?: boolean | null;
}

/**
 * Набор полей для фильтрации новостей с пагинацией
 */
export interface FindManyPostsInput {
  take?: number | null;
  skip?: number | null;
  postType?: PostType | null;
  addedById?: number | null;
}

/**
 * Набор полей для вывода информации об обязательных сертификатах пользователей
 */
export interface FindMissingCertificatesForUserInput {
  id: number;
}

/**
 * Набор полей для вывода информации об условии закупки
 */
export interface FindOneBuyingConditionInput {
  id: number;
}

/**
 * Набор полей для поиска формата закупки
 */
export interface FindOneBuyingFormatInput {
  id: number;
}

/**
 * Набор полей для поиска типа закупки
 */
export interface FindOneBuyingTypeInput {
  id: number;
}

/**
 * Набор полей для поиска определенного кейса
 */
export interface FindOneCaseInput {
  id: number;
}

/**
 * Набор полей для поиска клиента
 */
export interface FindOneClientInput {
  id: number;
  includeProjects?: boolean | null;
  includeIndustries?: boolean | null;
}

/**
 * Набор полей для поиска договора
 */
export interface FindOneContractInput {
  id: number;
}

/**
 * Набор полей для поиска плана отдела
 */
export interface FindOneDepartmentPlanInput {
  id: number;
}

/**
 * Набор полей для поиска акта
 */
export interface FindOneInvoiceInput {
  id: number;
}

/**
 * Набор полей для поиска юр лица
 */
export interface FindOneLegalnameInput {
  id: number;
  includeProjects?: boolean | null;
  includeSellers?: boolean | null;
}

/**
 * Набор полей для поиска баингового условия, на основе которого ведется расчет ПБТ калькулятора
 */
export interface FindOnePbtCalculatorBuyingConditionInput {
  id: number;
}

/**
 * Набор полей для поиска расчета ПБТ
 */
export interface FindOnePbtCalculatorInput {
  id: number;
}

/**
 * Набор полей для поиска должности, на основе которой ведется расчет ПБТ калькулятора
 */
export interface FindOnePbtCalculatorPositionInput {
  id: number;
}

/**
 * Набор полей для поиска загрузки сотрудника
 */
export interface FindOnePbtReportInput {
  id: number;
}

/**
 * Набор полей для поиска ПМ отчета
 */
export interface FindOnePmReportInput {
  id: number;
}

/**
 * Набор полей для поиска новости
 */
export interface FindOnePostInput {
  id: number;
}

/**
 * Набор полей для поиска селлера
 */
export interface FindOneSellerInput {
  id: number;
}

/**
 * Набор полей для поиска сотрудника/пользователя
 */
export interface FindOneUserInput {
  id: number;
}

/**
 * Набор полей для поиска площадки
 */
export interface FindOneVendorInput {
  id: number;
}

export interface InvoiceAbstract {
  invoiceNumber: string;
  invoiceDate: any;
  serviceStartDate?: any | null;
  serviceEndDate?: any | null;
  customerRole?: ActSidesRole | null;
  contractorRole?: ActSidesRole | null;
  invoiceSumAmount?: number | null;
  isVat?: boolean | null;
  initialContract?: ContractAbstract | null;
  initialContractInvoiceId?: number | null;
  finalContract?: ContractAbstract | null;
  finalContractInvoiceId?: number | null;
  initialInvoices?: InvoiceAbstract[] | null;
}

export interface LegalnameAbstract {
  title: string;
  currency: Currency;
  isVat: boolean;
  isArtics: boolean;
  projects?: ClientProjectAbstract[] | null;
  sellers?: SellerAbstract[] | null;
  legalnameType?: LegalnameType | null;
  ITN?: string | null;
  PSRN?: string | null;
  phone?: string | null;
  epayNumber?: string | null;
  oksmNumber?: string | null;
}

export interface ListBuyingCashbackRulesInput {
  page?: number | null;
  take?: number | null;
}

export interface ListBuyingCashbackTransactionsInput {
  page?: number | null;
  take?: number | null;
}

/**
 * Набор полей для вывода списка категорий
 */
export interface ListCategoriesInput {
  keyword?: string | null;
}

/**
 * Набор полей для вывода списка направлении
 */
export interface ListClientIndustriesInput {
  keyword?: string | null;
}

/**
 * Набор полей для вывода списка проектов
 */
export interface ListClientProjectsInput {
  keyword?: string | null;
  includeClients?: boolean | null;
  includeVerticals?: boolean | null;
  includeLegalnames?: boolean | null;
  excludeVerticalIds?: number[] | null;
}

/**
 * Набор полей для вывода списка клиента
 */
export interface ListClientsInput {
  keyword?: string | null;
  includeProjects?: boolean | null;
  includeIndustries?: boolean | null;
}

/**
 * Набор полей для фильтрации сертификатов сотрудников
 */
export interface ListEmployeeCertificatesInput {
  includeInactive?: boolean | null;
}

/**
 * Набор полей для фильтрации подразделений
 */
export interface ListEmployeeDepartmentsInput {
  keyword?: string | null;
  includeUserData?: boolean | null;
  includeEmpty?: boolean | null;
}

/**
 * Набор полей для вывода списка должностей
 */
export interface ListEmployeePositionsInput {
  keyword?: string | null;
  includeUserData?: boolean | null;
}

/**
 * Набор полей для фильтрации KPI типов
 */
export interface ListKpiTypesInput {
  keyword?: string | null;
}

/**
 * Набор полей для вывода списка юридических лиц
 */
export interface ListLegalnamesInput {
  currency?: Currency | null;
  isVat?: boolean | null;
  isArtics?: boolean | null;
  keyword?: string | null;
  includeProjects?: boolean | null;
  includeSellers?: boolean | null;
}

/**
 * Набор полей для вывода списка селлеров
 */
export interface ListSellersInput {
  keyword?: string | null;
}

/**
 * Набор полей для вывода списка площадок
 */
export interface ListVendorsInput {
  keyword?: string | null;
}

/**
 * Набор полей для авторизации пользователя
 */
export interface LoginInput {
  accessToken: string;
}

export interface PbtReportAbstract {
  monthYear: any;
  user?: UserAbstract | null;
  workPercent: number;
  project?: ClientProjectAbstract | null;
  vertical?: VerticalAbstract | null;
}

/**
 * Набор полей для закрепления новости
 */
export interface PinPostInput {
  id: number;
}

export interface ProcessesAbstract {
  title: string;
  schemaLink: string;
  reglamentLink: string;
  isArchive: boolean;
  positions?: EmployeePositionAbstract[] | null;
  positionId?: number | null;
  process?: ProcessesAbstract | null;
}

export interface SellerAbstract {
  title: string;
  legalnames?: LegalnameAbstract[] | null;
}

/**
 * Набор полей для запуска голосования
 */
export interface StartVotingInput {
  id: number;
  startDateTime?: any | null;
}

export interface UpdateBuyingCashbackRuleInput {
  fallbackCashbackPerc?: number | null;
  buyingConditionType?: BuyingConditionType | null;
  clientId?: number | null;
  vendorId?: number | null;
  cashbackIntervals?: BuyingCashbackRuleIntervalAbstract[] | null;
  id: number;
}

export interface UpdateBuyingCashbackTransactionInput {
  transactionType?: BuyingCashbackTransactionType | null;
  cashbackSum?: number | null;
  clientId?: number | null;
  vendorId?: number | null;
  cashbackPeriod?: any | null;
  turnover?: number | null;
  buyingConditionType?: BuyingConditionType | null;
  outcomeAfterIncome?: boolean | null;
  id: number;
}

/**
 * Набор полей для редактирования условия закупки
 */
export interface UpdateBuyingConditionInput {
  id: number;
  createdAt?: any | null;
  updatedAt?: any | null;
  title?: string | null;
  category?: VendorCategoryAbstract | null;
  vendor?: VendorAbstract | null;
  seller?: SellerAbstract | null;
  client?: ClientAbstract | null;
  buyingFormat?: BuyingFormatAbstract | null;
  buyingType?: BuyingTypeAbstract | null;
  addedBy?: UserAbstract | null;
  articsLegalname?: LegalnameAbstract | null;
  contractStatus?: ContractStatus | null;
  taskLink?: string | null;
  svnLink?: string | null;
  CPM?: number | null;
  CPMComment?: string | null;
  clientDiscount?: number | null;
  clientDiscountComment?: string | null;
  agencyDiscount?: number | null;
  agencyDiscountComment?: string | null;
  moneyback?: number | null;
  moneybackComment?: string | null;
  bonus?: number | null;
  bonusComment?: string | null;
  discountBefore?: number | null;
  discountBeforeComment?: string | null;
  discountAfter?: number | null;
  discountAfterComment?: string | null;
  paymentDelay?: string | null;
  paymentDelayComment?: string | null;
  comments?: string | null;
  totalMargin?: number | null;
  categoryId?: number | null;
  sellerId?: number | null;
  vendorId?: number | null;
  buyingFormatId?: number | null;
  buyingTypeId?: number | null;
  clientId?: number | null;
  articsLegalnameId?: number | null;
}

/**
 * Набор полей для изменения формата закупки
 */
export interface UpdateBuyingFormatInput {
  id: number;
  title?: string | null;
}

/**
 * Набор полей для изменения типа закупки
 */
export interface UpdateBuyingTypeInput {
  id: number;
  title?: string | null;
}

/**
 * Набор полей для редактирования кейса
 */
export interface UpdateCaseInput {
  id: number;
  title?: string | null;
  description?: string | null;
  picture?: string | null;
  presentation?: string | null;
  startDateTime?: any | null;
  isWinner?: boolean | null;
  isArchive?: boolean | null;
  participantsIds?: number[] | null;
}

/**
 * Набор полей для редактирования клиента
 */
export interface UpdateClientInput {
  id: number;
  title?: string | null;
  industryId?: number | null;
  projectIds?: number[] | null;
}

export interface UpdateContractInput {
  addedBy?: UserAbstract | null;
  contractNumber?: string | null;
  contractDate?: any | null;
  customerLegalname?: LegalnameAbstract | null;
  contractorLegalname?: LegalnameAbstract | null;
  isVatIncluded?: boolean | null;
  contractType?: ContractType | null;
  contractSubjectType?: ContractSubjectType | null;
  contractActionType?: ContractActionType | null;
  isORD?: boolean | null;
  metaInfo?: string | null;
  contractSumAmount?: number | null;
  contractDocType?: ContractDocType | null;
  clientProjectsIds?: number[] | null;
  finalContractInvoicesIds?: number[] | null;
  initialContractInvoicesIds?: number[] | null;
  customerLegalnameId?: number | null;
  contractorLegalnameId?: number | null;
  finalContractId?: number | null;
  additionalContractId?: number | null;
  id: number;
}

/**
 * Набор полей для редактирования плана
 */
export interface UpdateDepartmentPlanInput {
  id: number;
  monthYear?: any | null;
  budgetProbability?: number | null;
  turnover?: number | null;
  margin?: number | null;
  departmentId?: number | null;
  projectVerticalId?: number | null;
  legalnameId?: number | null;
  userId?: number | null;
  vendorId?: number | null;
}

/**
 * Набор полей для редактирования подразделения
 */
export interface UpdateEmployeeDepartmentInput {
  id: number;
  title?: string | null;
  description?: string | null;
  order?: number | null;
  departmentHeadId?: number | null;
  employeesIds?: number[] | null;
}

/**
 * Набор полей для редактирования должности
 */
export interface UpdateEmployeePositionInput {
  id: number;
  title?: string | null;
  description?: string | null;
  userIds?: number[] | null;
}

export interface UpdateInvoiceInput {
  invoiceNumber?: string | null;
  invoiceDate?: any | null;
  serviceStartDate?: any | null;
  serviceEndDate?: any | null;
  customerRole?: ActSidesRole | null;
  contractorRole?: ActSidesRole | null;
  invoiceSumAmount?: number | null;
  isVat?: boolean | null;
  initialContract?: ContractAbstract | null;
  initialContractInvoiceId?: number | null;
  finalContract?: ContractAbstract | null;
  finalContractInvoiceId?: number | null;
  initialInvoices?: InvoiceAbstract[] | null;
  breakdown?: boolean | null;
  id: number;
}

/**
 * Набор полей для редактирования юр лица
 */
export interface UpdateLegalnameInput {
  id: number;
  title?: string | null;
  isVat?: boolean | null;
  isArtics?: boolean | null;
  legalnameType?: LegalnameType | null;
  ITN?: string | null;
  PSRN?: string | null;
  phone?: string | null;
  epayNumber?: string | null;
  oksmNumber?: string | null;
  currency?: Currency | null;
  projectIds?: number[] | null;
  sellerId?: number | null;
}

/**
 * Набор полей для редактирования записи баингового условия для расчета ПБТ
 */
export interface UpdatePbtCalculatorBuyingConditionInput {
  id: number;
  budget?: number | null;
  akDiscount?: number | null;
  overallDiscount?: number | null;
  pbtCalculatorId?: number | null;
  pbtBuyingConditionId?: number | null;
}

/**
 * Набор полей для редактирования расчета ПБТ
 */
export interface UpdatePbtCalculatorInput {
  id: number;
  title?: string | null;
  periodFrom?: any | null;
  periodTo?: any | null;
  pbtCalculatorPositionIds?: number[] | null;
  pbtCalculatorPositionSalaries?: number[] | null;
  pbtCalculatorPositionWorkloads?: number[] | null;
  pbtCalculatorBuyingConditionsIds?: number[] | null;
  pbtCalculatorBuyingConditionsBudgets?: number[] | null;
  pbtCalculatorBuyingConditionsAkDiscounts?: number[] | null;
  pbtCalculatorBuyingConditionsOverallDiscounts?: number[] | null;
}

/**
 * Набор полей для редактирования записи должности для расчета ПБТ
 */
export interface UpdatePbtCalculatorPositionInput {
  id: number;
  salary?: number | null;
  workload?: number | null;
  pbtCalculatorId?: number | null;
  pbtPositionId?: number | null;
}

/**
 * Набор полей для редактирования загрузки сотрудника
 */
export interface UpdatePbtReportInput {
  id: number;
  monthYear?: any | null;
  workPercent?: number | null;
  projectId?: number | null;
  verticalId?: number | null;
  userId?: number | null;
}

/**
 * Набор полей для редактирования ПМ отчета
 */
export interface UpdatePmReportInput {
  id: number;
  monthYear?: any | null;
  reportDay?: number | null;
  buyingPeriod?: number | null;
  budgetPlanVat?: number | null;
  budgetFactNet?: number | null;
  kpiPlan?: number | null;
  kpiFact?: number | null;
  clientAgencyFeePerc?: number | null;
  clientDiscountPerc?: number | null;
  clientAutomationSystemType?: AutomationSystemType | null;
  clientAutomationSystemPerc?: number | null;
  clientHiddenFeePerc?: number | null;
  clientVatPerc?: number | null;
  vendorMoneybackPerc?: number | null;
  vendorDiscountPerc?: number | null;
  vendorVatPerc?: number | null;
  projectVerticalId?: number | null;
  userId?: number | null;
  vendorId?: number | null;
  kpiTypeId?: number | null;
}

/**
 * Набор полей для редактирования новости
 */
export interface UpdatePostInput {
  id: number;
  header?: string | null;
  underHeader?: string | null;
  text?: string | null;
  postType?: PostType | null;
  imageURL?: string | null;
  addedBy?: UserAbstract | null;
}

export interface UpdateRandomCoffeeInput {
  isInviterCompleted?: boolean | null;
  isInviteeCompleted?: boolean | null;
  inviterScore?: number | null;
  inviteeScore?: number | null;
  inviterFeedback?: string | null;
  inviteeFeedback?: string | null;
  deadline?: any | null;
  id: number;
  inviterId?: number | null;
  inviteeId?: number | null;
}

/**
 * Набор полей для редактирования селлера
 */
export interface UpdateSellerInput {
  id: number;
  title?: string | null;
  legalnameIds?: number[] | null;
}

/**
 * Набор полей для редактирования сотрудника/пользователя
 */
export interface UpdateUserInput {
  id: number;
  middlename?: string | null;
  roles?: UserRole[] | null;
  grades?: EmployeeGrade[] | null;
  priorityInsideDepartment?: number | null;
  telegram?: string | null;
  isActive?: boolean | null;
  isRemote?: boolean | null;
  googleEmail?: string | null;
  name?: string | null;
  surname?: string | null;
  avatar?: string | null;
  gender?: UserGender | null;
  office?: Office | null;
  email?: string | null;
  phone?: string | null;
  birthdate?: any | null;
  employmentDate?: any | null;
  departmentId?: number | null;
  positionId?: number | null;
  employeeHeadId?: number | null;
  subordinatesIds?: number[] | null;
}

/**
 * Набор полей для редактирования площадки
 */
export interface UpdateVendorInput {
  id: number;
  title?: string | null;
  isOrdNeeded?: boolean | null;
  category1Id?: number | null;
  category2Id?: number | null;
  category3Id?: number | null;
  buyingFormatIds?: number[] | null;
  buyingTypeIds?: number[] | null;
  sellerIds?: number[] | null;
}

export interface UserAbstract {
  name: string;
  surname: string;
  surnameAccented?: string | null;
  middlename?: string | null;
  avatar?: string | null;
  gender?: UserGender | null;
  roles: UserRole[];
  grades?: EmployeeGrade[] | null;
  office?: Office | null;
  position?: EmployeePositionAbstract | null;
  headOfDepartment?: EmployeeDepartmentAbstract[] | null;
  userLogs?: UserLogAbstract[] | null;
  headOfVertical?: VerticalAbstract | null;
  department?: EmployeeDepartmentAbstract | null;
  priorityInsideDepartment?: number | null;
  email?: string | null;
  phone?: string | null;
  telegram?: string | null;
  birthdate?: any | null;
  employmentDate?: any | null;
  employeeHead?: UserAbstract | null;
  subordinates?: UserAbstract[] | null;
  certificates?: EmployeeCertificateAbstract[] | null;
  isActive: boolean;
  isRemote: boolean;
  isPM: boolean;
  isAM: boolean;
  googleEmail?: string | null;
  presentationLink?: string | null;
  responsibility?: string | null;
  legalCompany?: string | null;
  isViewedPortal: boolean;
  holidayFrom?: any | null;
  holidayTo?: any | null;
  isAmbassador: boolean;
  pbtReports?: PbtReportAbstract[] | null;
}

export interface UserLogAbstract {
  position?: EmployeePositionAbstract | null;
  department?: EmployeeDepartmentAbstract | null;
  user?: UserAbstract | null;
  surname?: string | null;
}

export interface VendorAbstract {
  title: string;
  departmentPlans?: DepartmentPlanAbstract[] | null;
  category1?: VendorCategoryAbstract | null;
  category2?: VendorCategoryAbstract | null;
  category3?: VendorCategoryAbstract | null;
  isOrdNeeded?: boolean | null;
  sellers?: SellerAbstract[] | null;
  buyingFormats?: BuyingFormatAbstract[] | null;
  buyingTypes?: BuyingTypeAbstract[] | null;
}

export interface VendorCategoryAbstract {
  title: string;
  isCat1: boolean;
  isCat2: boolean;
  isCat3: boolean;
}

export interface VerticalAbstract {
  title: string;
  verticalHead?: UserAbstract | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
