import { gql } from "@apollo/client";
import { SELLER_FRAGMENT } from "../fragments";

export const LIST_SELLERS_QUERY = gql`
  query listSellers($input: ListSellersInput!) {
    listSellers(input: $input) {
      ok
      error
      sellers {
        ...SellerParts
      }
    }
  }
  ${SELLER_FRAGMENT}
`;
