import { useQuery } from "@apollo/client";
import {
  CogIcon,
  PencilIcon,
  PlusIcon,
  TableIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import {
  DataLoader,
  PrimaryButton,
  SecondaryButton,
  SelectColumnFilter,
  SingleRowCell,
  StatusPill,
  Table,
} from "components";
import {
  contractActionTypeStatusMap,
  contractSubjectTypeStatusMap,
  contractTypeStatusMap,
} from "config/mappings";
import { FIND_ALL_CONTRACTS_QUERY } from "graphql/queries/findAllContracts";
import { ContractParts } from "graphql/__generated-types__/ContractParts";
import {
  findAllContracts,
  findAllContractsVariables,
} from "graphql/__generated-types__/findAllContracts";
import {
  ContractDocType,
  UserRole,
} from "graphql/__generated-types__/globalTypes";
import { useMe } from "hooks";
import { adminMenuRoutes } from "navigation/routes";
import { LayoutButtonsContext } from "pages/Admin/AdminLayout";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import { classNames, formatDate } from "utils";
import InitialContractCreate from "./create";
import InitialContractDelete from "./delete";
import InitialContractEdit from "./edit";
import InitialContractExcel from "./excel";

const Checkbox = ({ id, isChecked, onClick }) => (
  <input
    type="checkbox"
    id={id}
    checked={isChecked}
    onChange={() => onClick()}
    className={classNames(
      "h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
    )}
  />
);

const InitialContracts = () => {
  const { setLayoutButtons } = useContext(LayoutButtonsContext);

  const user = useMe();
  const navigate = useNavigate();

  const hideEditingForm = useCallback(() => {
    setIsEditing(false);
    setCurrentContract(null);
  }, []);

  const clickAddButton = useCallback(() => {
    hideEditingForm();
    setIsAdding(true);
  }, [hideEditingForm]);

  useEffect(() => {
    setLayoutButtons(
      <div className="flex gap-x-2">
        <SecondaryButton
          onClick={() => {
            clickExcelButton();
          }}
          icon={TableIcon({
            className: "h-4 w-4",
            "aria-hidden": true,
          })}
        >
          Эскпорт
        </SecondaryButton>
        <SecondaryButton
          icon={CogIcon({
            className: "h-4 w-4",
            "aria-hidden": true,
          })}
          onClick={() => navigate(adminMenuRoutes?.final_contracts?.path)}
        >
          Конечные договоры
        </SecondaryButton>
        {(user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
          user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
          <PrimaryButton
            icon={PlusIcon({
              className: "h-4 w-4",
              "aria-hidden": true,
            })}
            id="contractAddButton"
            onClick={() => clickAddButton()}
          >
            Добавить
          </PrimaryButton>
        )}
      </div>
    );
    return () => {
      setLayoutButtons(null);
    };
  }, [clickAddButton, navigate, setLayoutButtons, user?.data?.me?.user?.roles]);

  const [showExcelContractModal, setShowExcelContractModal] = useState(false);
  const [showDeleteContractModal, setShowDeleteContractModal] = useState(false);
  const [currentContract, setCurrentContract] = useState<ContractParts>(null);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const hideAddingForm = useCallback(
    (reOpen = false) => {
      setIsAdding(false);
      setCurrentContract(null);
      reOpen && clickAddButton();
    },
    [setIsAdding, setCurrentContract, clickAddButton]
  );

  const clickDeleteButton = useCallback(
    (contract: ContractParts) => {
      setShowDeleteContractModal(!showDeleteContractModal);
      setCurrentContract(contract);
    },
    [setShowDeleteContractModal, setCurrentContract, showDeleteContractModal]
  );

  const clickExcelButton = () => {
    setShowExcelContractModal(true);
  };

  const clickEditButton = useCallback(
    (contract: ContractParts) => {
      hideAddingForm();
      hideEditingForm();
      setIsEditing(true);
      setCurrentContract(contract);
      const contractAddButton = document.getElementById("contractAddButton");
      if (contractAddButton) {
        contractAddButton.scrollIntoView();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    [hideAddingForm, hideEditingForm, setIsEditing, setCurrentContract]
  );

  const { loading, data: contractData } = useQuery<
    findAllContracts,
    findAllContractsVariables
  >(FIND_ALL_CONTRACTS_QUERY, {
    variables: {
      input: {
        contractDocType: ContractDocType.INITIAL_CONTRACT,
      },
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: " ",
        accessor: (d: ContractParts) => d?.id,
        Cell: (props: CellProps<ContractParts, string>) => (
          <Checkbox
            key={props.cell.value}
            id={props.cell.value}
            isChecked={document
              ?.getElementById(props.cell.value)
              ?.getAttribute("isChecked")}
            onClick={() => {
              document
                .getElementById(props.cell.value)
                .setAttribute(
                  "isChecked",
                  document
                    .getElementById(props.cell.value)
                    .getAttribute("isChecked") === "true"
                    ? "false"
                    : "true"
                );
            }}
          ></Checkbox>
        ),
        disableGlobalFilter: true,
      },
      {
        Header: "ID изначального договора",
        accessor: (d: ContractParts) =>
          d?.contractNumber && d?.customerLegalname?.title
            ? `${d?.contractNumber} | ${d?.customerLegalname?.title}`
            : "Данные отсутсвуют в БД",
        Cell: (props: CellProps<ContractParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Номер конечного договора/ДС",
        accessor: (d: ContractParts) => d?.finalContract?.contractNumber,
        Cell: (props: CellProps<ContractParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Контрагент по конечному договору",
        accessor: (d: ContractParts) =>
          d?.finalContract?.customerLegalname?.title,
        Cell: (props: CellProps<ContractParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Дата конечного договора",
        accessor: (d: ContractParts) => d?.finalContract?.contractDate ?? null,
        Cell: (props: CellProps<ContractParts, string>) => (
          <SingleRowCell>{formatDate(props.cell.value)}</SingleRowCell>
        ),
      },
      {
        Header: "Заказчик по изначальному договору",
        accessor: (d: ContractParts) => d?.customerLegalname?.title,
        Cell: (props: CellProps<ContractParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Исполнитель по изначальному договору",
        accessor: (d: ContractParts) => d?.contractorLegalname?.title,
        Cell: (props: CellProps<ContractParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Заказчик ИНН",
        accessor: (d: ContractParts) => d?.customerLegalname?.ITN,
        Cell: (props: CellProps<ContractParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Исполнитель ИНН",
        accessor: (d: ContractParts) => d?.contractorLegalname?.ITN,
        Cell: (props: CellProps<ContractParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Номер изначального договора",
        accessor: (d: ContractParts) => d?.contractNumber,
        Cell: (props: CellProps<ContractParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Дата изначального договора",
        accessor: (d: ContractParts) => d?.contractDate,
        Cell: (props: CellProps<ContractParts, string>) => (
          <SingleRowCell>{formatDate(props.cell.value)}</SingleRowCell>
        ),
      },
      {
        Header: "Стоимость услуг по договору",
        accessor: (d: ContractParts) => d?.contractSumAmount,
        Cell: (props: CellProps<ContractParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Включено ли НДС в стоимость",
        accessor: (d: ContractParts) => {
          return d.isVatIncluded ? "Да" : "Нет";
        },
        Cell: (props: CellProps<ContractParts, "Да" | "Нет">) => (
          <StatusPill
            isPositive={props.row.original.isVatIncluded}
            value={props.row.original.isVatIncluded ? "Да" : "Нет"}
          />
        ),
        id: "isVatIncluded",
      },
      {
        Header: "Тип договора",
        accessor: (d: ContractParts) =>
          d?.contractType
            ? contractTypeStatusMap[d?.contractType]
            : "Тип договора не указан",
        Cell: (props: CellProps<ContractParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
        disableGlobalFilter: true,
      },
      {
        Header: "Предмет договора",
        accessor: (d: ContractParts) =>
          d?.contractSubjectType
            ? contractSubjectTypeStatusMap[d?.contractSubjectType]
            : "Предмет договора не указан",
        Cell: (props: CellProps<ContractParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
        disableGlobalFilter: true,
      },
      {
        Header: "Тип взаимодействия сторон посреднического договора",
        accessor: (d: ContractParts) =>
          d?.contractActionType
            ? contractActionTypeStatusMap[d?.contractActionType]
            : "Тип взаимодействия не указан",
        Cell: (props: CellProps<ContractParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
        disableGlobalFilter: true,
      },
      {
        Header: "Передано в ОРД",
        accessor: (d: ContractParts) => {
          return d?.isORD ? "Да" : "Нет";
        },
        Cell: (props: CellProps<ContractParts, "Да" | "Нет">) => (
          <StatusPill
            isPositive={props.row.original.isORD}
            value={props.row.original.isORD ? "Да" : "Нет"}
          />
        ),
        id: "isORD",
      },
      {
        Header: "ID Mediascout изначальный договор",
        accessor: (d: ContractParts) =>
          d?.metaInfo ? d?.metaInfo : "Нет информации",
        Cell: (props: CellProps<ContractParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "ID конечного договора",
        accessor: (d: ContractParts) =>
          d?.finalContract?.contractNumber &&
          d?.finalContract?.customerLegalname?.title
            ? `${d?.finalContract?.contractNumber} | ${d?.finalContract?.customerLegalname?.title}`
            : "Данные отсутсвуют в БД",
        Cell: (props: CellProps<ContractParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "ID Mediascout конечный договор",
        accessor: (d: ContractParts) =>
          d?.finalContract?.metaInfo
            ? d?.finalContract?.metaInfo
            : "Нет информации",
        Cell: (props: CellProps<ContractParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Действие",
        Cell: (props: CellProps<ContractParts>) => (
          <div className="flex gap-x-2">
            {user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
            user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN) ? (
              <>
                <PrimaryButton
                  icon={PencilIcon({
                    className: "h-4 w-4",
                    "aria-hidden": true,
                  })}
                  disabled={isAdding}
                  onClick={() => clickEditButton(props.row.original)}
                ></PrimaryButton>

                <PrimaryButton
                  icon={TrashIcon({
                    className: "h-4 w-4",
                    "aria-hidden": true,
                  })}
                  disabled={isAdding || isEditing}
                  onClick={() => clickDeleteButton(props.row.original)}
                ></PrimaryButton>
              </>
            ) : (
              <SingleRowCell>Н/Д</SingleRowCell>
            )}
          </div>
        ),
      },
    ],
    [
      clickDeleteButton,
      clickEditButton,
      isAdding,
      isEditing,
      user?.data?.me?.user?.roles,
    ]
  );

  const contracts = useMemo(
    () => contractData?.findAllContracts?.contracts,
    [contractData?.findAllContracts?.contracts]
  );

  return !loading ? (
    <>
      <Helmet>
        <title> Изначальные договоры | Артикс Портал</title>
      </Helmet>
      {(isAdding || isEditing) && (
        <div className="mb-2">
          {isAdding &&
            (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
              user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
              <InitialContractCreate onClose={hideAddingForm} />
            )}
          {isEditing &&
            (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
              user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
              <InitialContractEdit
                onClose={hideEditingForm}
                contract={currentContract}
              />
            )}
        </div>
      )}

      {showDeleteContractModal &&
        (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
          user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
          <InitialContractDelete
            show={showDeleteContractModal}
            showFn={setShowDeleteContractModal}
            contract={currentContract}
            closeModal={setShowDeleteContractModal}
          />
        )}

      {showExcelContractModal &&
        (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
          user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
          <InitialContractExcel
            show={showExcelContractModal}
            showFn={setShowExcelContractModal}
            closeModal={setShowExcelContractModal}
            type={"INITIAL_CONTRACT"}
            post_url={"initial_contract"}
            link="https://docs.google.com/spreadsheets/d/1cRIOM9x6YGshhVhycXnK3Lc7LgdbG95F1LcJ4K_oyeg/edit?usp=sharing"
          />
        )}

      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <Table
                columns={columns}
                skipColumns={[]}
                data={contracts}
                initialState={{
                  pageSize: 20,
                  pageIndex: 0,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};

export default InitialContracts;
