
export interface IClientInlineProps {
    title: string
}

export const ClientInline = ({title}: IClientInlineProps) => {

    return (
        <div className={"mb-0"}>
            <p>{title}</p>
        </div>
    )
}