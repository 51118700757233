import {gql} from "@apollo/client";
import {CLIENT_INDUSTRY_FRAGMENT} from "../fragments";

export const LIST_CLIENT_INDUSTRIES_QUERY = gql`
  query listClientIndustries($input: ListClientIndustriesInput!) {
    listClientIndustries(input: $input) {
      ok
      error
      clientIndustries {
        ...ClientIndustryParts
      }
    }
  }
  ${CLIENT_INDUSTRY_FRAGMENT}
`;
