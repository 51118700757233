import { gql } from "@apollo/client";
import { BUYING_CASHBACK_TRANSACTION_FRAGMENT } from "graphql/fragments";

export const UPDATE_BUYING_CASHBACK_TRANSACTION_MUTATION = gql`
  mutation updateBuyingCashbackTransaction(
    $input: UpdateBuyingCashbackTransactionInput!
  ) {
    updateBuyingCashbackTransaction(input: $input) {
      ok
      error
      buyingCashbackTransaction {
        ...BuyingCashbackTransactionParts
      }
    }
  }
  ${BUYING_CASHBACK_TRANSACTION_FRAGMENT}
`;
