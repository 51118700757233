import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import {
  DataLoader,
  SelectColumnFilter,
  SingleRowCell,
  Table,
} from "../../../components";
import { CellProps } from "react-table";
import { Helmet } from "react-helmet-async";
import { listClientProjects, listClientProjectsVariables } from "graphql/__generated-types__/listClientProjects";
import { LIST_CLIENT_PROJECTS_QUERY } from "graphql/queries";
import { ClientProjectParts } from "graphql/__generated-types__/ClientProjectParts";

const AdminProjects = () => {

  const { loading, data: projectData } = useQuery<
    listClientProjects,
    listClientProjectsVariables
  >(LIST_CLIENT_PROJECTS_QUERY, {
    variables: {
      input: {
        keyword:"",
        includeClients: true,
        includeVerticals: true,
        includeLegalnames: true,
      },
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: "Проект",
        accessor: (d: ClientProjectParts) => d.title,
        Cell: (props: CellProps<ClientProjectParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Вертикали проекта",
        Cell: (props: CellProps<ClientProjectParts, string>) => {
          if (props.row.original?.verticals) {
            return (
              <SingleRowCell>
                {props.row.original?.verticals?.map(
                  (vertical) => <p>{vertical?.title}</p>
                )}
              </SingleRowCell>
            );
          } else return null;
        },
        disableGlobalFilter: true,
      },
      {
        Header: "Клиент",
        accessor: (d: ClientProjectParts) => d?.client?.title,
        Cell: (props: CellProps<ClientProjectParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Направление клиента",
        accessor: (d: ClientProjectParts) => d?.client?.industry?.title || "Не указано",
        Cell: (props: CellProps<ClientProjectParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "ЮЛ проекта",
        Cell: (props: CellProps<ClientProjectParts, string>) => {
          if (props.row.original?.legalnames) {
            return (
              <SingleRowCell>
                {props.row.original?.legalnames?.map(
                  (legalname) => <p>{legalname?.title}</p>
                )}
              </SingleRowCell>
            );
          } else return null;
        },
        disableGlobalFilter: true,
      },
    ],
    []
  );

  const projects = useMemo(
    () => projectData?.listClientProjects?.projects,
    [projectData?.listClientProjects?.projects]
  );

  return !loading ? (
    <>
      <Helmet>
        <title> Проекты | Артикс Портал</title>
      </Helmet>

      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <Table
                columns={columns}
                skipColumns={[]}
                data={projects}
                initialState={{
                  pageSize: 20,
                  pageIndex: 0,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};

export default AdminProjects;
