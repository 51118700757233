import { RandomCoffeeParts } from "graphql/__generated-types__/RandomCoffeeParts";
import { RandomCoffeeWhoAmI } from "graphql/__generated-types__/globalTypes";
import { UPDATE_RANDOM_COFFEE_MUTATION } from "graphql/mutations/updateRandomCoffee";
import {
  updateRandomCoffee,
  updateRandomCoffeeVariables,
} from "graphql/__generated-types__/updateRandomCoffee";
import AvatarTemplate from "assets/user-template.png";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/ru";
import { StoreObject, useMutation } from "@apollo/client";
import Ratings from "react-ratings-declarative";
import { classNames } from "utils";
import { RANDOM_COFFEE_FRAGMENT } from "graphql/fragments";
import { useState } from "react";
dayjs.locale("ru");
dayjs.extend(localizedFormat);

interface IActiveRandomCoffeeProps {
  randomCoffee: RandomCoffeeParts;
}

export const ActiveRandomCoffee: React.FC<IActiveRandomCoffeeProps> = ({
  randomCoffee,
}) => {
  const [showFeedbackForm, setShowFeedbackForm] = useState(
    randomCoffee?.whoAmI === RandomCoffeeWhoAmI["INVITER"]
      ? randomCoffee?.isInviterCompleted
      : randomCoffee?.isInviteeCompleted
  );

  const [score, setScore] = useState(
    randomCoffee?.whoAmI === RandomCoffeeWhoAmI["INVITER"]
      ? randomCoffee?.inviterScore
      : randomCoffee?.inviteeScore
  );

  const [feedback, setFeedback] = useState(
    randomCoffee?.whoAmI === RandomCoffeeWhoAmI["INVITER"]
      ? randomCoffee?.inviterFeedback
      : randomCoffee?.inviteeFeedback
  );

  const [completeRandomCoffee] = useMutation<
    updateRandomCoffee,
    updateRandomCoffeeVariables
  >(UPDATE_RANDOM_COFFEE_MUTATION, {
    variables: {
      input: {
        id: randomCoffee?.id,
        ...(randomCoffee?.whoAmI === RandomCoffeeWhoAmI["INVITER"]
          ? {
              isInviterCompleted: true,
              inviterScore: score,
              inviterFeedback: feedback,
            }
          : {
              isInviteeCompleted: true,
              inviteeScore: score,
              inviteeFeedback: feedback,
            }),
      },
    },
    update(cache, { data: { updateRandomCoffee } }) {
      if (updateRandomCoffee?.randomCoffee?.id) {
        cache.writeFragment({
          id: cache.identify(
            updateRandomCoffee.randomCoffee as unknown as StoreObject
          ),
          data: updateRandomCoffee.randomCoffee,
          fragment: RANDOM_COFFEE_FRAGMENT,
          fragmentName: "RandomCoffeeParts",
        });
      }
    },
  });

  const userPair =
    randomCoffee.whoAmI === RandomCoffeeWhoAmI["INVITER"]
      ? randomCoffee.invitee
      : randomCoffee.inviter;

  const getButton = () => {
    if (
      (randomCoffee.whoAmI === RandomCoffeeWhoAmI["INVITER"] &&
        randomCoffee.isInviterCompleted) ||
      (randomCoffee.whoAmI === RandomCoffeeWhoAmI["INVITEE"] &&
        randomCoffee.isInviteeCompleted)
    ) {
      return {
        disabled: true,
        text: "Ждем подтверждения от второго участника",
      };
    }

    return { disabled: false, text: "Завершить встречу" };
  };

  return (
    <div className="w-full flex flex-col justify-center items-center -mt-16 pt-24 pb-8">
      {/* Заголовок */}
      <h2 className="w-full text-center text-4xl font-bold mb-8 text-gray-800">
        Твой собеседник до {dayjs(randomCoffee.deadline).format("D MMMM")}
      </h2>
      {/* Аватарка и ФИО*/}
      <div className="w-1/4 flex flex-col justify-center">
        <div
          className="aspect-w-3 aspect-h-3 rounded-xl bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${userPair.avatar ?? AvatarTemplate})`,
          }}
        ></div>
        <div className="space-y-2 mt-4 flex flex-col items-center">
          <div
            className="text-xl text-center leading-6 font-medium space-y-1"
            style={{ minHeight: "5rem" }}
          >
            <h3>
              {userPair.name} {userPair.surname}
            </h3>
            <div className="relative flex flex-col">
              <span className="text-gray-500">{userPair?.position?.title}</span>
            </div>
          </div>
        </div>
      </div>
      {/* Кнопки */}
      <div className="mt-2 w-1/2 lg:w-1/3 flex flex-col space-y-3">
        {showFeedbackForm ? (
          <>
            <div className="flex flex-col items-center gap-x-3 gap-y-3">
              <Ratings
                rating={score ?? 0}
                widgetRatedColors="red"
                changeRating={getButton().disabled ? null : setScore}
              >
                <Ratings.Widget />
                <Ratings.Widget />
                <Ratings.Widget />
                <Ratings.Widget />
                <Ratings.Widget />
              </Ratings>
              <div className="w-full">
                <label
                  htmlFor="feedback"
                  className="block text-sm font-medium text-gray-700"
                >
                  Поделитесь фидбеком о встрече:
                </label>
                <div className="mt-1">
                  <textarea
                    rows={4}
                    name="feedback"
                    id="feedback"
                    className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    value={feedback}
                    disabled={getButton().disabled}
                    onChange={(e) => setFeedback(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <button
              className={classNames(
                getButton().disabled
                  ? "cursor-not-allowed opacity-75"
                  : "hover:bg-red-700 focus:ring-2 focus:ring-offset-2 focus:ring-red-500",
                "flex-1 w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-red-600 focus:outline-none"
              )}
              onClick={() => completeRandomCoffee()}
              disabled={getButton().disabled}
            >
              {getButton().text}
            </button>
          </>
        ) : (
          <>
            <div className="flex flex-wrap gap-x-3 gap-y-3">
              {userPair?.telegram && (
                <a
                  href={`tg://resolve?domain=${userPair.telegram}`}
                  className="flex-1 w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Написать
                </a>
              )}
              {userPair?.phone && (
                <a
                  href={`tel://${userPair.phone.replace(/[^0-9.]/g, "")}`}
                  className="flex-1 w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Позвонить
                </a>
              )}
            </div>
            <button
              className={classNames(
                "hover:bg-red-700 focus:ring-2 focus:ring-offset-2 focus:ring-red-500",
                "flex-1 w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-red-600 focus:outline-none"
              )}
              onClick={() => setShowFeedbackForm(true)}
            >
              Завершить встречу
            </button>
          </>
        )}
      </div>
    </div>
  );
};
