import { gql } from "@apollo/client";

export const DELETE_BUYING_CONDITION_MUTATION = gql`
  mutation deleteBuyingCondition($input: DeleteBuyingConditionInput!) {
    deleteBuyingCondition(input: $input) {
      ok
      error
    }
  }
`;
