import {gql} from "@apollo/client";
import {SELLER_FRAGMENT} from "../fragments";

export const CREATE_SELLER_MUTATION = gql`
  mutation createSeller($input: CreateSellerInput!) {
    createSeller(input: $input) {
      ok
      error
      seller {
        ...SellerParts
      }
    }
  }
  ${SELLER_FRAGMENT}
`;
