import { StoreObject, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { serviceMenuRoutes } from "../../../../navigation/routes";
import { toast } from "react-toastify";
import { DataLoader } from "../../../../components";
import { IBuyingConditionForm, BuyingConditionForm } from "../components";
import { BUYING_CONDITION_FRAGMENT } from "../../../../graphql/fragments";
import {
  updateBuyingCondition,
  updateBuyingConditionVariables,
} from "../../../../graphql/__generated-types__/updateBuyingCondition";
import { UPDATE_BUYING_CONDITION_MUTATION } from "graphql/mutations/updateBuyingCondition";
import { BuyingConditionParts } from "graphql/__generated-types__/BuyingConditionParts";

interface IBuyingConditionEditProps {
  buyingCondition: BuyingConditionParts;
  onClose: () => void;
}

const BuyingConditionEdit: React.FC<IBuyingConditionEditProps> = ({
  buyingCondition,
  onClose,
}) => {
  const navigate = useNavigate();
  const [updateBuyingCondition] = useMutation<
    updateBuyingCondition,
    updateBuyingConditionVariables
  >(UPDATE_BUYING_CONDITION_MUTATION, {
    onCompleted: (updateBuyingConditionData) => {
      if (updateBuyingConditionData?.updateBuyingCondition?.ok) {
        navigate(serviceMenuRoutes.buyingConditions.path);
      } else {
        toast.error(updateBuyingConditionData?.updateBuyingCondition?.error);
      }
    },
    update(cache, { data: { updateBuyingCondition } }) {
      if (updateBuyingCondition?.buyingCondition?.id) {
        cache.writeFragment({
          // @ts-ignore
          id: cache.identify(
            updateBuyingCondition?.buyingCondition as unknown as StoreObject
          ),
          data: updateBuyingCondition?.buyingCondition,
          fragment: BUYING_CONDITION_FRAGMENT,
          fragmentName: "BuyingConditionParts",
        });
      }
    },
  });

  const onSubmit = async (data: IBuyingConditionForm) => {
    const responseData = await updateBuyingCondition({
      variables: {
        input: {
          ...data,
          id: +buyingCondition.id,
          clientDiscount: data.clientDiscount / 100,
          agencyDiscount: data.agencyDiscount / 100,
          moneyback: data.moneyback / 100,
          bonus: data.bonus / 100,
          discountAfter: data.discountAfter / 100,
          discountBefore: data.discountBefore / 100,
        },
      },
    });
    if (responseData?.data?.updateBuyingCondition?.ok) {  
    onClose();
    }
  };

  return buyingCondition ? (
    <BuyingConditionForm
      buyingCondition={buyingCondition}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  ) : (
    <DataLoader />
  );
};

export default BuyingConditionEdit;
