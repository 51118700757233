import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  deleteSeller,
  deleteSellerVariables,
} from "../../../../graphql/__generated-types__/deleteSeller";
import { DELETE_SELLER_MUTATION } from "../../../../graphql/mutations";
import { adminMenuRoutes } from "../../../../navigation/routes";
import { DeleteModal } from "../../../../components";

const AdminSellerDelete = ({ show, showFn, seller, closeModal }) => {
  const navigate = useNavigate();

  const [deleteSeller] = useMutation<deleteSeller, deleteSellerVariables>(
    DELETE_SELLER_MUTATION,
    {
      onCompleted: (deleteSellerData) => {
        if (deleteSellerData?.deleteSeller?.ok) {
          navigate(adminMenuRoutes.sellers.path);
        } else {
          toast.error(deleteSellerData?.deleteSeller?.error);
        }
      },
      update(cache, { data: { deleteSeller } }) {
        if (deleteSeller?.ok) {
          cache.evict({ id: cache.identify(seller) });
          cache.gc();
        }
      },
    }
  );

  const onSubmit = async () => {
    await deleteSeller({
      variables: {
        input: {
          id: seller.id,
        },
      },
    });
    closeModal();
  };

  return (
    <DeleteModal
      show={show}
      showFn={showFn}
      headerText="Удаление селлера"
      text="Вы уверены, что хотите удалить данного селлера?"
      onClose={closeModal}
      onSubmit={onSubmit}
    />
  );
};

export default AdminSellerDelete;
