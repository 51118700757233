import {gql} from "@apollo/client";
import {DEPARTMENT_PLAN_FRAGMENT} from "../fragments";

export const CREATE_DEPARTMENT_PLAN_MUTATION = gql`
  mutation createDepartmentPlan($input: CreateDepartmentPlanInput!) {
    createDepartmentPlan(input: $input) {
      ok
      error
      departmentPlan {
        ...DepartmentPlanParts
      }
    }
  }
  ${DEPARTMENT_PLAN_FRAGMENT}
`;
