import { useNavigate } from "react-router";
import { useMutation } from "@apollo/client";
import { serviceMenuRoutes } from "../../../../navigation/routes";
import { toast } from "react-toastify";
import {
  deleteDepartmentPlan,
  deleteDepartmentPlanVariables,
} from "../../../../graphql/__generated-types__/deleteDepartmentPlan";
import { DELETE_DEPARTMENT_PLAN_MUTATION } from "../../../../graphql/mutations";
import { DepartmentPlanDeleteForm } from "../components";

const DepartmentPlanDelete = ({ departmentPlan, closeModal }) => {
  const navigate = useNavigate();
  const [deleteDepartmentPlan] = useMutation<
    deleteDepartmentPlan,
    deleteDepartmentPlanVariables
  >(DELETE_DEPARTMENT_PLAN_MUTATION, {
    onCompleted: (deleteDepartmentPlanData) => {
      if (
        deleteDepartmentPlanData &&
        deleteDepartmentPlanData.deleteDepartmentPlan.ok
      ) {
        navigate(serviceMenuRoutes.departmentPlans.path);
      } else {
        toast.error(deleteDepartmentPlanData.deleteDepartmentPlan.error);
      }
    },
    update(cache, { data: { deleteDepartmentPlan } }) {
      if (deleteDepartmentPlan.ok) {
        cache.evict({ id: cache.identify(departmentPlan) });
        cache.gc();
      }
    },
  });

  const onSubmit = async () => {
    await deleteDepartmentPlan({
      variables: {
        input: {
          id: departmentPlan.id,
        },
      },
    });
    closeModal();
  };

  const onClose = () => {
    closeModal();
  };
  return <DepartmentPlanDeleteForm onSubmit={onSubmit} onClose={onClose} />;
};

export default DepartmentPlanDelete;
