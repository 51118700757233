
import { StoreObject, useMutation } from "@apollo/client";
import { DataLoader } from "components";
import { CONTRACT_FRAGMENT } from "graphql/fragments";
import { UPDATE_CONTRACT_MUTATION } from "graphql/mutations/updateContract";
import { ContractParts } from "graphql/__generated-types__/ContractParts";
import { ContractDocType } from "graphql/__generated-types__/globalTypes";
import { updateContract, updateContractVariables } from "graphql/__generated-types__/updateContract";
import { adminMenuRoutes } from "navigation/routes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IInitialContractForm, InitialContractForm } from "../components/InitialContractForm";

interface IInitialContractEditProps {
  contract: ContractParts;
  onClose: () => void;
}

const InitialContractEdit: React.FC<IInitialContractEditProps> = ({
  contract,
  onClose,
}) => {
  const navigate = useNavigate();
  const [updateContract] = useMutation<
    updateContract,
    updateContractVariables
  >(UPDATE_CONTRACT_MUTATION, {
    onCompleted: (updateContractData) => {
      if (updateContractData?.updateContract?.ok) {
        navigate(adminMenuRoutes?.initial_contracts?.path);
      } else {
        toast.error(updateContractData?.updateContract?.error);
      }
    },
    update(cache, { data: { updateContract } }) {
      if (updateContract?.contract?.id) {
        cache.writeFragment({
          // @ts-ignore
          id: cache.identify(
            updateContract?.contract as unknown as StoreObject
          ),
          data: updateContract?.contract,
          fragment: CONTRACT_FRAGMENT,
          fragmentName: "ContractParts",
        });
      }
    },
  });

  const onSubmit = async (data: IInitialContractForm) => {
    const responseData = await updateContract({
      variables: {
        input: {
          ...data,
          id: +contract.id,
          contractDocType: ContractDocType.INITIAL_CONTRACT,
        },
      },
    });
    if (responseData?.data?.updateContract?.ok) {  
    onClose();
    }
  };

  return contract ? (
    <InitialContractForm
      onSubmit={onSubmit}
      onClose={onClose}
      contract={contract}
    />
  ) : (
    <DataLoader />
  );
};

export default InitialContractEdit;