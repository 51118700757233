import {gql} from "@apollo/client";
import {LOGINIP_FRAGMENT} from "../fragments";

export const CREATE_LOGINIP_MUTATION = gql`
    mutation createLoginIp($input: CreateLoginIpInput!) {
        createLoginIp(input: $input) {
            ok
            error
            loginIp {
                ...LoginIpParts
            }
        }
    }
    ${LOGINIP_FRAGMENT}
`;
