import { useQuery } from "@apollo/client";
import {
  ExternalLinkIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import Tooltip from "components/Tooltip";
import { buyingConditionContractStatusMap } from "config/mappings";
import { FIND_ALL_BUYING_CONDITIONS_QUERY } from "graphql/queries/findAllBuyingConditions";
import { BuyingConditionParts } from "graphql/__generated-types__/BuyingConditionParts";
import {
  findAllBuyingConditions,
  findAllBuyingConditionsVariables,
} from "graphql/__generated-types__/findAllBuyingConditions";
import { UserRole } from "graphql/__generated-types__/globalTypes";
import { useMe } from "hooks";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { CellProps } from "react-table";
import { formatFullName, formatSums } from "utils";
import {
  AvatarCell,
  DataLoader,
  PrimaryButton,
  Modal,
  SelectColumnFilter,
  Table,
  SingleRowCell,
} from "../../../components";
import { LayoutButtonsContext } from "../ServiceLayout";
import BuyingConditionCreate from "./create";
import BuyingConditionDelete from "./delete";
import BuyingConditionEdit from "./edit";

const BuyingCondition = () => {
  const { setLayoutButtons } = useContext(LayoutButtonsContext);

  const user = useMe();

  const hideEditingForm = useCallback(() => {
    setIsEditing(false);
    resetCurrentBuyingCondition();
  }, []);

  const clickAddButton = useCallback(() => {
    hideEditingForm();
    setIsAdding(true);
  }, [hideEditingForm]);

  useEffect(() => {
    setLayoutButtons(
      <div className="flex gap-x-2">
        {(user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
          user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
          <PrimaryButton
            icon={PlusIcon({
              className: "h-4 w-4",
              "aria-hidden": true,
            })}
            id="buyingConditionAddButton"
            onClick={() => clickAddButton()}
          >
            Добавить
          </PrimaryButton>
        )}
      </div>
    );
    return () => {
      setLayoutButtons(null);
    };
  }, [clickAddButton, setLayoutButtons, user?.data?.me?.user?.roles]);

  const { loading, data: buyingConditionsData } = useQuery<
    findAllBuyingConditions,
    findAllBuyingConditionsVariables
  >(FIND_ALL_BUYING_CONDITIONS_QUERY, {
    variables: {
      input: {},
    },
  });

  const [showDeleteBuyingConditionModal, setShowDeleteBuyingConditionModal] =
    useState(false);
  const [currentBuyingCondition, setCurrentBuyingCondition] =
    useState<BuyingConditionParts>(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const resetCurrentBuyingCondition = () => setCurrentBuyingCondition(null);

  const hideAddingForm = useCallback(
    (reOpen = false) => {
      setIsAdding(false);
      resetCurrentBuyingCondition();
      if (reOpen) {
        clickAddButton();
      }
    },
    [clickAddButton]
  );

  const clickEditButton = useCallback(
    (buyingCondition: BuyingConditionParts) => {
      hideAddingForm();
      hideEditingForm();
      setIsEditing(true);
      setCurrentBuyingCondition(buyingCondition);
      const buyingConditionAddButton = document.getElementById(
        "buyingConditionAddButton"
      );
      if (buyingConditionAddButton) {
        buyingConditionAddButton.scrollIntoView();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    [hideAddingForm, hideEditingForm]
  );

  const clickDeleteButton = useCallback(
    (buyingCondition: BuyingConditionParts) => {
      setShowDeleteBuyingConditionModal(!showDeleteBuyingConditionModal);
      setCurrentBuyingCondition(buyingCondition);
    },
    [showDeleteBuyingConditionModal]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Наименование для закрытия",
        accessor: (d: BuyingConditionParts) =>
          d.title ? d.title : "Наименование не внесено в БД",
        Cell: (props: CellProps<BuyingConditionParts, string>) => (
          <SingleRowCell onClick={() => clickEditButton(props.row.original)}>
            <span className="cursor-pointer">{props.cell.value}</span>
          </SingleRowCell>
        ),
      },
      {
        Header: "CPM",
        cellClassName: "w-5",
        accessor: (d: BuyingConditionParts) => d?.CPM,
        Cell: (props: CellProps<BuyingConditionParts, number>) =>
          props.cell.value ? (
            <SingleRowCell>
              <Tooltip text={props.row.original?.CPMComment}>
                {props.cell.value} руб.
              </Tooltip>
            </SingleRowCell>
          ) : (
            ""
          ),
        disableGlobalFilter: true,
      },
      {
        Header: "Баинговые условия",
        Cell: (props: CellProps<BuyingConditionParts>) => (
          <SingleRowCell>
            {
              <div className="flex justify-between gap-x-4">
                <span>Скидка клиентская:</span>
                <div className="inline-flex w-14">
                  <Tooltip text={props.row.original?.clientDiscountComment}>
                    <span className="font-bold">
                      {formatSums(
                        props.row.original.clientDiscount
                          ? props.row.original.clientDiscount * 100
                          : 0
                      ) + "%"}
                    </span>
                  </Tooltip>
                </div>
              </div>
            }
            {
              <div className="flex justify-between gap-x-4">
                <span>Скидка агентская:</span>
                <div className="inline-flex w-14">
                  <Tooltip text={props.row.original?.agencyDiscountComment}>
                    <span className="font-bold">
                      {formatSums(
                        props.row.original.agencyDiscount
                          ? props.row.original.agencyDiscount * 100
                          : 0
                      ) + "%"}
                    </span>
                  </Tooltip>
                </div>
              </div>
            }
            {
              <div className="flex justify-between gap-x-4">
                <span>Возвратка:</span>
                <div className="inline-flex w-14">
                  <Tooltip text={props.row.original?.moneybackComment}>
                    <span className="font-bold">
                      {formatSums(
                        props.row.original.moneyback
                          ? props.row.original.moneyback * 100
                          : 0
                      ) + "%"}
                    </span>
                  </Tooltip>
                </div>
              </div>
            }
            {
              <div className="flex justify-between gap-x-4">
                <span>Бонус:</span>
                <div className="inline-flex w-14">
                  <Tooltip text={props.row.original?.bonusComment}>
                    <span className="font-bold">
                      {formatSums(
                        props.row.original.bonus
                          ? props.row.original.bonus * 100
                          : 0
                      ) + "%"}
                    </span>
                  </Tooltip>
                </div>
              </div>
            }
            {
              <div className="flex justify-between gap-x-4">
                <span>СК (до скидки):</span>
                <div className="inline-flex w-14">
                  <Tooltip text={props.row.original?.discountBeforeComment}>
                    <span className="font-bold">
                      {formatSums(
                        props.row.original.discountBefore
                          ? props.row.original.discountBefore * 100
                          : 0
                      ) + "%"}
                    </span>
                  </Tooltip>
                </div>
              </div>
            }
            {
              <div className="flex justify-between gap-x-4">
                <span>СК (после скидки):</span>
                <div className="inline-flex w-14">
                  <Tooltip text={props.row.original?.discountAfterComment}>
                    <span className="font-bold">
                      {formatSums(
                        props.row.original.discountAfter
                          ? props.row.original.discountAfter * 100
                          : 0
                      ) + "%"}
                    </span>
                  </Tooltip>
                </div>
              </div>
            }
          </SingleRowCell>
        ),
        disableGlobalFilter: true,
      },
      {
        Header: "Маржинальность площадки",
        accessor: (d: BuyingConditionParts) =>
          (d?.totalMargin ? formatSums(d.totalMargin * 100) : 0) + "%",
        Cell: (props: CellProps<BuyingConditionParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        disableGlobalFilter: true,
      },
      {
        Header: "Селлер",
        accessor: (d: BuyingConditionParts) =>
          d?.seller?.title || "Селлер не указан",
        Cell: (props: CellProps<BuyingConditionParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Юр. лица",
        Cell: (props: CellProps<BuyingConditionParts, string>) => (
          <SingleRowCell>
            <div className="flex flex-col">
              {props.row.original.seller?.legalnames?.map((legalname) => (
                <span>{legalname.title}</span>
              ))}
            </div>
          </SingleRowCell>
        ),
      },
      {
        Header: "Категория",
        accessor: (d: BuyingConditionParts) =>
          d.category?.title || "Категория не указана",
        Cell: (props: CellProps<BuyingConditionParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
        disableGlobalFilter: true,
      },
      {
        Header: "К1",
        accessor: (d: BuyingConditionParts) => d?.vendor?.category1?.title,
        Cell: (props: CellProps<BuyingConditionParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
        disableGlobalFilter: true,
      },
      {
        Header: "К2",
        accessor: (d: BuyingConditionParts) => d?.vendor?.category2?.title,
        Cell: (props: CellProps<BuyingConditionParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
        disableGlobalFilter: true,
      },
      {
        Header: "К3",
        accessor: (d: BuyingConditionParts) => d?.vendor?.category3?.title,
        Cell: (props: CellProps<BuyingConditionParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
        disableGlobalFilter: true,
      },
      {
        Header: "Клиент",
        accessor: (d: BuyingConditionParts) =>
          d?.client?.title || "Все клиенты",
        Cell: (props: CellProps<BuyingConditionParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
        disableGlobalFilter: true,
      },
      {
        Header: "Тип закупки",
        accessor: (d: BuyingConditionParts) =>
          d?.buyingType?.title || "Все типы закупки",
        Cell: (props: CellProps<BuyingConditionParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
        disableGlobalFilter: true,
      },
      {
        Header: "Формат закупки",
        accessor: (d: BuyingConditionParts) =>
          d?.buyingFormat?.title || "Все форматы закупки",
        Cell: (props: CellProps<BuyingConditionParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
        disableGlobalFilter: true,
      },
      {
        Header: "Статус договора",
        accessor: (d: BuyingConditionParts) =>
          d?.contractStatus
            ? buyingConditionContractStatusMap[d?.contractStatus] ===
              buyingConditionContractStatusMap.SIGNED
              ? d.articsLegalname?.id !== 1337
                ? `Договор не подписан`
                : buyingConditionContractStatusMap[d?.contractStatus]
              : buyingConditionContractStatusMap[d?.contractStatus]
            : "Статус договора не указан",
        Cell: (props: CellProps<BuyingConditionParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
        disableGlobalFilter: true,
      },
      {
        Header: "Контрагент с нашей стороны",
        accessor: (d: BuyingConditionParts) =>
          d.articsLegalname?.title || "ЮЛ не указано",
        Cell: (props: CellProps<BuyingConditionParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
        disableGlobalFilter: true,
      },
      {
        Header: "Отсрочка",
        accessor: (d: BuyingConditionParts) => d?.paymentDelay ?? "",
        cellClassName: "w-12",
        Cell: (props: CellProps<BuyingConditionParts, string>) => (
          <SingleRowCell>
            <Tooltip text={props.row.original?.paymentDelayComment}>
              {props.cell.value}
            </Tooltip>
          </SingleRowCell>
        ),
        disableGlobalFilter: true,
      },

      {
        Header: "Комментарии",
        accessor: (d: BuyingConditionParts) => d?.comments ?? "",
        cellClassName: "w-25",
        Cell: (props: CellProps<BuyingConditionParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        disableGlobalFilter: true,
      },
      {
        Header: "Ответственный",
        accessor: (d: BuyingConditionParts) => formatFullName(d.addedBy),
        Cell: (props: CellProps<BuyingConditionParts, string>) => (
          <AvatarCell
            value={
              props.row.original.addedBy?.surname || "Пользователь не указан"
            }
            name={props.row.original.addedBy?.name || null}
            avatar={props.row.original.addedBy?.avatar || null}
            subtitle={props.row.original.addedBy?.position?.title || null}
          />
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
        disableGlobalFilter: true,
      },
      {
        Header: "Ссылка на задачу",
        accessor: (d: BuyingConditionParts) => d?.taskLink,
        Cell: (props: CellProps<BuyingConditionParts, string>) =>
          props.cell.value && (
            <a href={props.cell.value} target="_blank" rel="noreferrer">
              <PrimaryButton
                icon={<ExternalLinkIcon className="h-4 w-4" />}
              ></PrimaryButton>
            </a>
          ),
        disableGlobalFilter: true,
      },
      {
        Header: "Ссылка на SVN",
        accessor: (d: BuyingConditionParts) => d?.svnLink,
        Cell: (props: CellProps<BuyingConditionParts, string>) =>
          props.cell.value && (
            <a href={props.cell.value} target="_blank" rel="noreferrer">
              <PrimaryButton
                icon={<ExternalLinkIcon className="h-4 w-4" />}
              ></PrimaryButton>
            </a>
          ),
        disableGlobalFilter: true,
      },
      {
        Header: "Действие",
        Cell: (props: CellProps<BuyingConditionParts>) => (
          <div className="flex gap-x-2">
            {user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
            user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN) ? (
              <>
                <PrimaryButton
                  icon={PencilIcon({
                    className: "h-4 w-4",
                    "aria-hidden": true,
                  })}
                  disabled={isAdding}
                  onClick={() => clickEditButton(props.row.original)}
                ></PrimaryButton>

                <PrimaryButton
                  icon={TrashIcon({
                    className: "h-4 w-4",
                    "aria-hidden": true,
                  })}
                  disabled={isAdding || isEditing}
                  onClick={() => clickDeleteButton(props.row.original)}
                ></PrimaryButton>
              </>
            ) : (
              <SingleRowCell>Н/Д</SingleRowCell>
            )}
          </div>
        ),
        disableGlobalFilter: true,
      },
    ],
    [
      clickDeleteButton,
      clickEditButton,
      isAdding,
      isEditing,
      user?.data?.me?.user?.roles,
    ]
  );

  const buyingConditions = useMemo(
    () => buyingConditionsData?.findAllBuyingConditions?.buyingConditions,
    [buyingConditionsData?.findAllBuyingConditions?.buyingConditions]
  );

  return !loading && buyingConditions ? (
    <>
      <Helmet>
        <title> Баинговая таблица | Артикс Портал</title>
      </Helmet>
      {(isAdding || isEditing) && (
        <div className="mb-2">
          {isAdding && <BuyingConditionCreate onClose={hideAddingForm} />}
          {isEditing && (
            <BuyingConditionEdit
              onClose={hideEditingForm}
              buyingCondition={currentBuyingCondition}
            />
          )}
        </div>
      )}

      {showDeleteBuyingConditionModal && (
        <Modal
          show={showDeleteBuyingConditionModal}
          showFn={setShowDeleteBuyingConditionModal}
        >
          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <BuyingConditionDelete
              buyingCondition={currentBuyingCondition}
              closeModal={setShowDeleteBuyingConditionModal}
            />
          </div>
        </Modal>
      )}

      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-x-scroll border-b border-gray-200 sm:rounded-lg">
              {buyingConditions && (
                <Table
                  columns={columns}
                  data={buyingConditions || []}
                  initialState={{
                    pageSize: 50,
                    pageIndex: 0,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};

export default BuyingCondition;
