import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { adminMenuRoutes } from "../../../../navigation/routes";
import { DELETE_LEGALNAME_MUTATION } from "../../../../graphql/mutations";
import {
  deleteLegalname,
  deleteLegalnameVariables,
} from "../../../../graphql/__generated-types__/deleteLegalname";
import { DeleteModal } from "../../../../components";

const AdminLegalnameDelete = ({ show, showFn, legalname, closeModal }) => {
  const navigate = useNavigate();

  const [deleteLegalname] = useMutation<
    deleteLegalname,
    deleteLegalnameVariables
  >(DELETE_LEGALNAME_MUTATION, {
    onCompleted: (deleteLegalnameData) => {
      if (deleteLegalnameData?.deleteLegalname?.ok) {
        navigate(adminMenuRoutes.legalnames.path);
      } else {
        toast.error(deleteLegalnameData?.deleteLegalname?.error);
      }
    },
    update(cache, { data: { deleteLegalname } }) {
      if (deleteLegalname?.ok) {
        cache.evict({ id: cache.identify(legalname) });
        cache.gc();
      }
    },
  });

  const onSubmit = async () => {
    await deleteLegalname({
      variables: {
        input: {
          id: legalname.id,
        },
      },
    });
    closeModal();
  };

  return (
    <DeleteModal
      show={show}
      showFn={showFn}
      headerText="Удаление юридического лица"
      text="Вы уверены, что хотите удалить данное юридическое лицо?"
      onClose={closeModal}
      onSubmit={onSubmit}
    />
  );
};
export default AdminLegalnameDelete;
