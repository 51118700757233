import {gql} from "@apollo/client";
import { POST_FRAGMENT } from "graphql/fragments";

export const PIN_POST_MUTATION = gql`
  mutation pinPost($input: PinPostInput!) {
    pinPost(input: $input) {
      ok
      error
      post {
        ...PostParts
      }
    }
  }
  ${POST_FRAGMENT}
`;
