import { gql } from "@apollo/client";
import {DEPARTMENT_PLAN_FRAGMENT} from "../fragments";

export const FIND_ALL_DEPARTMENT_PLANS_QUERY = gql`
  query findAllDepartmentPlans($input: FindAllDepartmentPlansInput!) {
    findAllDepartmentPlans(input: $input) {
      ok
      error
      departmentPlans {
        ...DepartmentPlanParts
      }
      totalPages
      totalResults
    }
  }
  ${DEPARTMENT_PLAN_FRAGMENT}
`;
