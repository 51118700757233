import { gql } from "@apollo/client";
import { POSITION_FRAGMENT } from "graphql/fragments";

export const CREATE_EMPLOYEE_POSITION_MUTATION = gql`
  mutation createEmployeePosition($input: CreateEmployeePositionInput!) {
    createEmployeePosition(input: $input) {
      ok
      error
      position {
        ...PositionParts
      }
    }
  }
  ${POSITION_FRAGMENT}
`;
