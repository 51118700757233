import {gql} from "@apollo/client";

export const DELETE_BUYING_TYPE_MUTATION = gql`
  mutation deleteBuyingType($input: DeleteBuyingTypeInput!) {
    deleteBuyingType(input: $input) {
      ok
      error
    }
  }
`;
