import { useMutation } from "@apollo/client";
import { DeleteModal } from "components";
import { DELETE_BUYING_TYPE_MUTATION } from "graphql/mutations/deleteBuyingType";
import {
  deleteBuyingType,
  deleteBuyingTypeVariables,
} from "graphql/__generated-types__/deleteBuyingType";
import { adminMenuRoutes } from "navigation/routes";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const BuyingTypeDelete = ({ show, showFn, buyingType, closeModal }) => {
  const navigate = useNavigate();
  const [deleteBuyingType] = useMutation<
    deleteBuyingType,
    deleteBuyingTypeVariables
  >(DELETE_BUYING_TYPE_MUTATION, {
    onCompleted: (deleteBuyingTypeData) => {
      if (deleteBuyingTypeData && deleteBuyingTypeData.deleteBuyingType.ok) {
        navigate(adminMenuRoutes.buyingTypes.path);
      } else {
        toast.error(deleteBuyingTypeData.deleteBuyingType.error);
      }
    },
    update(cache, { data: { deleteBuyingType } }) {
      if (deleteBuyingType?.ok) {
        cache.evict({ id: cache.identify(buyingType) });
        cache.gc();
      }
    },
  });

  const onSubmit = async () => {
    await deleteBuyingType({
      variables: {
        input: {
          id: buyingType.id,
        },
      },
    });
    closeModal();
  };
  const onClose = () => {
    closeModal();
  };

  return (
    <DeleteModal
      show={show}
      showFn={showFn}
      headerText={"Удаление типа закупки"}
      text={"Вы уверены, что хотите удалить тип закупки?"}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};
export default BuyingTypeDelete;
