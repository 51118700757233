import { gql } from "@apollo/client";
import { VENDOR_FRAGMENT } from "graphql/fragments";

export const MISSING_CERTIFICATES = gql`
  query findMissingCertificatesForUser($input: FindMissingCertificatesForUserInput!) {
    findMissingCertificatesForUser(input: $input) {
      ok
      error
      missingCertificates {
        ...VendorParts
      }
    }
  }
  ${VENDOR_FRAGMENT}
`;
