import { Helmet } from "react-helmet-async";

const ActivitiesCalendar = () => {
  return (
    <>
      <Helmet>
        <title> Календарь активностей | Артикс Портал</title>
      </Helmet>
      <iframe title="ActivitiesCalendar" src="https://calendar.yandex.ru/embed/month?private_token=bdd2a61165af01402eca460a9fba0597b6d7dbab&tz_id=Europe/Moscow" className="w-full h-80vh"></iframe>
    </>
  );
};
//https://calendar.yandex.ru/embed/month?&layer_ids=19545058&tz_id=Europe/Moscow&layer_names=График активностей
//<iframe src="https://calendar.yandex.ru/embed/month?&layer_ids=19873841&tz_id=Europe/Moscow&layer_names=Обучение" width="800" height="450" frameborder="0" style="border: 1px solid #eee"></iframe>
export default ActivitiesCalendar;
