import { useNavigate } from "react-router";
import { useMutation } from "@apollo/client";
import {
  updateCase,
  updateCaseVariables,
} from "../../../../graphql/__generated-types__/updateCase";
import { UPDATE_CASE_MUTATION } from "../../../../graphql/mutations";
import { serviceMenuRoutes } from "../../../../navigation/routes";
import { toast } from "react-toastify";
import { CASE_FRAGMENT } from "../../../../graphql/fragments";
import { CaseForm, ICaseForm } from "../components";
import { CaseParts } from "graphql/__generated-types__/CaseParts";

interface ICaseEditProps {
  case: CaseParts;
  onClose: () => void;
}

const CaseEdit: React.FC<ICaseEditProps> = ({ case: battleCase, onClose }) => {
  const navigate = useNavigate();

  const [updateCase] = useMutation<updateCase, updateCaseVariables>(
    UPDATE_CASE_MUTATION,
    {
      onCompleted: (updateCaseData) => {
        if (updateCaseData?.updateCase?.ok) {
          navigate(serviceMenuRoutes.cases.path);
        } else {
          toast.error(updateCaseData?.updateCase?.error);
        }
      },
      update(cache, { data: { updateCase } }) {
        if (updateCase?.battleCase?.id) {
          cache.writeFragment({
            // @ts-ignore
            id: cache.identify(updateCase?.battleCase),
            data: updateCase?.battleCase,
            fragment: CASE_FRAGMENT,
            fragmentName: "CaseParts",
          });
        }
      },
    }
  );

  const onSubmit = async (data: ICaseForm) => {
    delete data["externalLink"];
    const responseData = await updateCase({
      variables: {
        input: {
          ...data,
          id: +battleCase.id,
        },
      },
    });
    if (responseData.data.updateCase.ok) {
      onClose();
    }
  };
  return (
    <CaseForm caseData={battleCase} onSubmit={onSubmit} onClose={onClose} />
  );
};

export default CaseEdit;
