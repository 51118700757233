import {gql} from "@apollo/client";
import {PM_REPORT_FRAGMENT} from "../fragments";

export const CREATE_PM_REPORT_MUTATION = gql`
  mutation createPmReport($input: CreatePmReportInput!) {
    createPmReport(input: $input) {
      ok
      error
      pmReport {
        ...PmReportParts
      }
    }
  }
  ${PM_REPORT_FRAGMENT}
`;
