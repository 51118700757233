import { Controller, useForm } from "react-hook-form";
import {
  Button,
  DataLoader,
  LabeledTextInput,
  PrimaryButton,
  ReactSelectInput,
  LabeledTextInputWithTrailingAddon,
} from "../../../../components";
import {
  findAllCategories,
  findAllCategoriesVariables,
} from "graphql/__generated-types__/findAllCategories";
import {
  LIST_CLIENTS_QUERY,
  LIST_LEGALNAMES_QUERY,
  LIST_SELLERS_QUERY,
  LIST_VENDORS_QUERY,
} from "graphql/queries";
import { FIND_ALL_CATEGORIES_QUERY } from "graphql/queries/findAllCategories";
import {
  listClients,
  listClientsVariables,
} from "graphql/__generated-types__/listClients";
import { ContractStatus } from "graphql/__generated-types__/globalTypes";
import {
  listLegalnames,
  listLegalnamesVariables,
} from "graphql/__generated-types__/listLegalnames";
import { useQuery } from "@apollo/client";

import {
  listVendors,
  listVendorsVariables,
} from "graphql/__generated-types__/listVendors";
import {
  listSellers,
  listSellersVariables,
} from "graphql/__generated-types__/listSellers";
import {
  findAllBuyingTypes,
  findAllBuyingTypesVariables,
} from "graphql/__generated-types__/findAllBuyingTypes";
import { FIND_ALL_BUYING_TYPE_QUERY } from "graphql/queries/findAllBuyingTypes";
import {
  findAllBuyingFormats,
  findAllBuyingFormatsVariables,
} from "graphql/__generated-types__/findAllBuyingFormats";
import { FIND_ALL_BUYING_FORMAT_QUERY } from "graphql/queries/findAllBuyingFormats";
import { BuyingConditionParts } from "graphql/__generated-types__/BuyingConditionParts";
import { buyingConditionContractStatusMap } from "config/mappings";

export interface IBuyingConditionForm {
  title: string;
  categoryId?: number;
  buyingTypeId?: number;
  buyingFormatId?: number;
  vendorId: number;
  sellerId: number;
  clientId?: number;
  articsLegalnameId?: number;
  addedById: number;
  contractStatus: ContractStatus;
  taskLink?: string;
  svnLink?: string;
  CPM?: number;
  CPMComment?: string;
  clientDiscount: number;
  clientDiscountComment?: string;
  agencyDiscount: number;
  agencyDiscountComment?: string;
  moneyback: number;
  moneybackComment?: string;
  bonus: number;
  bonusComment?: string;
  discountBefore: number;
  discountBeforeComment?: string;
  discountAfter: number;
  discountAfterComment?: string;
  paymentDelay?: string;
  paymentDelayComment?: string;
  comments?: string;
}

interface IBuyingConditionFormProps {
  buyingCondition?: BuyingConditionParts;
  onSubmit: (data: any) => void;
  onClose: () => void;
}

export const BuyingConditionForm: React.FC<IBuyingConditionFormProps> = ({
  buyingCondition,
  onSubmit,
  onClose,
}) => {
  const label = buyingCondition
    ? "Редактирование данных об условиях закупки"
    : "Добавление данных об условиях закупки";

  const { handleSubmit, control, setValue } = useForm<IBuyingConditionForm>({
    mode: "onSubmit",
    shouldUnregister: true,
    defaultValues: buyingCondition
      ? {
          ...buyingCondition,
          clientDiscount: buyingCondition?.clientDiscount * 100,
          agencyDiscount: buyingCondition?.agencyDiscount * 100,
          moneyback: buyingCondition?.moneyback * 100,
          bonus: buyingCondition?.bonus * 100,
          discountBefore: buyingCondition?.discountBefore * 100,
          discountAfter: buyingCondition?.discountAfter * 100,
          addedById: buyingCondition?.addedBy?.id,
          categoryId: buyingCondition?.category?.id,
          vendorId: buyingCondition?.vendor?.id,
          sellerId: buyingCondition?.seller?.id,
          buyingFormatId: buyingCondition?.buyingFormat?.id,
          buyingTypeId: buyingCondition?.buyingType?.id,
          clientId: buyingCondition?.client?.id,
          articsLegalnameId: buyingCondition?.articsLegalname?.id,
        }
      : {},
  });

  const { data: categoriesData, loading: categoriesLoading } = useQuery<
    findAllCategories,
    findAllCategoriesVariables
  >(FIND_ALL_CATEGORIES_QUERY, {
    variables: {
      input: {
        keyword: "",
      },
    },
  });

  const { data: clientsData, loading: clientsLoading } = useQuery<
    listClients,
    listClientsVariables
  >(LIST_CLIENTS_QUERY, {
    variables: {
      input: {
        keyword: "",
      },
    },
  });

  const { data: articsLegalnamesData, loading: articsLegalnamesLoading } =
    useQuery<listLegalnames, listLegalnamesVariables>(LIST_LEGALNAMES_QUERY, {
      variables: {
        input: {
          isArtics: true,
        },
      },
    });

  const { data: vendorsData, loading: vendorsLoading } = useQuery<
    listVendors,
    listVendorsVariables
  >(LIST_VENDORS_QUERY, {
    variables: {
      input: { keyword: "" },
    },
  });

  const { data: sellersData, loading: sellersLoading } = useQuery<
    listSellers,
    listSellersVariables
  >(LIST_SELLERS_QUERY, {
    variables: {
      input: { keyword: "" },
    },
  });

  const { data: buyingTypesData, loading: buyingTypesLoading } = useQuery<
    findAllBuyingTypes,
    findAllBuyingTypesVariables
  >(FIND_ALL_BUYING_TYPE_QUERY, {
    variables: {
      input: { keyword: "" },
    },
  });

  const { data: buyingFormatsData, loading: buyingFormatsLoading } = useQuery<
    findAllBuyingFormats,
    findAllBuyingFormatsVariables
  >(FIND_ALL_BUYING_FORMAT_QUERY, {
    variables: {
      input: { keyword: "" },
    },
  });

  return !vendorsLoading &&
    !sellersLoading &&
    !categoriesLoading &&
    !clientsLoading &&
    !buyingTypesLoading &&
    !buyingFormatsLoading &&
    !articsLegalnamesLoading ? (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {label}
            </h3>
          </div>

          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                <Controller
                  control={control}
                  name="title"
                  rules={{
                    required:
                      "Нужно заполнить наименование условия для закрытия",
                  }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Наименование условия для закрытия"
                      value={field.value === null ? "" : field.value}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="vendorId"
                  rules={{ required: "Нужно заполнить площадку" }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={
                        vendorsData &&
                        vendorsData?.listVendors?.vendors?.map((v) => {
                          return {
                            label: v?.title,
                            value: v?.id,
                          };
                        })
                      }
                      defaultValue={field.value}
                      name="vendorId"
                      value={field.value}
                      labelText="Площадка"
                      onSelect={(e) => {
                        setValue("vendorId", e);
                      }}
                      placeholder="Выберите площадку из списка"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="sellerId"
                  rules={{ required: "Нужно заполнить селлера" }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={
                        sellersData &&
                        sellersData?.listSellers?.sellers?.map((s) => {
                          return {
                            label: s?.title,
                            value: s?.id,
                          };
                        })
                      }
                      defaultValue={field.value}
                      name="sellerId"
                      value={field.value}
                      labelText="Селлер"
                      onSelect={(e) => {
                        setValue("sellerId", e);
                      }}
                      placeholder="Выберите селлера из списка"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="categoryId"
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={
                        categoriesData &&
                        categoriesData?.findAllCategories?.categories?.map(
                          (c) => {
                            return {
                              label: `${c?.title}`,
                              value: c?.id,
                            };
                          }
                        )
                      }
                      defaultValue={field.value}
                      name="categoryId"
                      value={field.value}
                      labelText="Категория условия"
                      onSelect={(e) => setValue("categoryId", e)}
                      placeholder="Выберите категорию из списка"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="clientId"
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={
                        clientsData &&
                        clientsData?.listClients?.clients?.map((cl) => {
                          return {
                            label: `${cl?.title}`,
                            value: cl?.id,
                          };
                        })
                      }
                      defaultValue={field.value}
                      name="clientId"
                      value={field.value}
                      labelText="Клиент"
                      onSelect={(e) => setValue("clientId", e)}
                      placeholder="Выберите клиента из списка"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="buyingTypeId"
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={
                        buyingTypesData &&
                        buyingTypesData?.findAllBuyingTypes?.buyingTypes?.map(
                          (t) => {
                            return {
                              label: t?.title,
                              value: t?.id,
                            };
                          }
                        )
                      }
                      defaultValue={field.value}
                      name="buyingTypeId"
                      value={field.value}
                      labelText="Тип размещения"
                      onSelect={(e) => setValue("buyingTypeId", e)}
                      placeholder="Выберите тип размещения из списка"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="buyingFormatId"
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={
                        buyingFormatsData &&
                        buyingFormatsData?.findAllBuyingFormats?.buyingFormats?.map(
                          (f) => {
                            return {
                              label: f?.title,
                              value: f?.id,
                            };
                          }
                        )
                      }
                      defaultValue={field.value}
                      name="buyingFormatId"
                      value={field.value}
                      labelText="Формат размещения"
                      onSelect={(e) => setValue("buyingFormatId", e)}
                      placeholder="Выберите формат размещения из списка"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="contractStatus"
                  rules={{
                    required: "Нужно заполнить статус договора условия",
                  }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={Object.values(ContractStatus).map((cs) => {
                        return {
                          label: buyingConditionContractStatusMap[cs],
                          value: cs,
                        };
                      })}
                      defaultValue={field.value}
                      name="contractStatus"
                      value={field.value}
                      labelText="Статус договора"
                      onSelect={(e) => setValue("contractStatus", e)}
                      placeholder="Статус договора"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="taskLink"
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Ссылка на задачу в ПФ"
                      value={field.value === null ? "" : field.value}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="articsLegalnameId"
                  rules={{ required: "Нужно заполнить наше ЮЛ" }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={
                        articsLegalnamesData &&
                        articsLegalnamesData?.listLegalnames?.legalnames?.map(
                          (l) => {
                            return {
                              label: `${l?.title}`,
                              value: l?.id,
                            };
                          }
                        )
                      }
                      defaultValue={field.value}
                      name="articsLegalnameId"
                      value={field.value}
                      labelText="Контрагент с нашей стороны"
                      onSelect={(e) => setValue("articsLegalnameId", e)}
                      placeholder="Контрагент с нашей стороны"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="CPM"
                  render={({ field, fieldState }) => (
                    <LabeledTextInputWithTrailingAddon
                      addonText="руб."
                      labelText="CPM"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseInt(e.target.value))
                          ? parseInt(e.target.value)
                          : parseInt('0');
                        field.onChange(parsedValue);
                      }}
                      defaultValue={field.value}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      min="0"
                      step="1"
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-4">
                <Controller
                  control={control}
                  name="CPMComment"
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Комментарий к CPM"
                      value={field.value === null ? "" : field.value}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="paymentDelay"
                  render={({ field, fieldState }) => (
                    <LabeledTextInputWithTrailingAddon
                      addonText="дней"
                      labelText="Отсрочка"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      defaultValue={field.value}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      min="0"
                      step="1"
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-4">
                <Controller
                  control={control}
                  name="paymentDelayComment"
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Комментарий к отсрочке"
                      value={field.value === null ? "" : field.value}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="clientDiscount"
                  render={({ field, fieldState }) => (
                    <LabeledTextInputWithTrailingAddon
                      labelText="Скидка (Клиентская)"
                      addonText="%"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : parseFloat('0');
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      defaultValue={field.value}
                      type="number"
                      min="0"
                      step="0.1"
                      max="100"
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-4">
                <Controller
                  control={control}
                  name="clientDiscountComment"
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Комментарий к клиентской скидке"
                      value={field.value === null ? "" : field.value}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="agencyDiscount"
                  render={({ field, fieldState }) => (
                    <LabeledTextInputWithTrailingAddon
                      labelText="Скидка (Агентская)"
                      addonText="%"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : parseFloat('0');
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      defaultValue={field.value}
                      type="number"
                      min="0"
                      step="0.1"
                      max="100"
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-4">
                <Controller
                  control={control}
                  name="agencyDiscountComment"
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Комментарий к агентской скидке"
                      value={field.value === null ? "" : field.value}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="moneyback"
                  render={({ field, fieldState }) => (
                    <LabeledTextInputWithTrailingAddon
                      labelText="Возвратка"
                      addonText="%"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : parseFloat('0');
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      defaultValue={field.value}
                      type="number"
                      min="0"
                      step="0.1"
                      max="100"
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-4">
                <Controller
                  control={control}
                  name="moneybackComment"
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Комментарий к возвратке"
                      value={field.value === null ? "" : field.value}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="bonus"
                  render={({ field, fieldState }) => (
                    <LabeledTextInputWithTrailingAddon
                      labelText="Бонус"
                      addonText="%"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : parseFloat('0');
                        field.onChange(parsedValue);
                      }}
                      defaultValue={field.value}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      min="0"
                      step="0.1"
                      max="100"
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-4">
                <Controller
                  control={control}
                  name="bonusComment"
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Комментарий к бонусу"
                      value={field.value === null ? "" : field.value}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="discountAfter"
                  render={({ field, fieldState }) => (
                    <LabeledTextInputWithTrailingAddon
                      labelText="СК (От суммы после скидки)"
                      addonText="%"
                      value={field.value === null ? "" : field.value} 
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : parseFloat('0');
                        field.onChange(parsedValue);
                      }}
                      defaultValue={field.value}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      min="0"
                      step="0.1"
                      max="100"
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-4">
                <Controller
                  control={control}
                  name="discountAfterComment"
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Комментарий к СК (от суммы после скидки)"
                      value={field.value === null ? "" : field.value}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  name="discountBefore"
                  render={({ field, fieldState }) => (
                    <LabeledTextInputWithTrailingAddon
                      addonText="%"
                      labelText="СК (От суммы до скидки), %"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : parseFloat('0');
                        field.onChange(parsedValue);
                      }}
                      defaultValue={field.value}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      min="0"
                      step="0.1"
                      max="100"
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-4">
                <Controller
                  control={control}
                  name="discountBeforeComment"
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Комментарий к СК (от суммы до скидки)"
                      value={field.value === null ? "" : field.value}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                <Controller
                  control={control}
                  name="comments"
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Комментарии к условию закупки"
                      value={field.value === null ? "" : field.value}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                <Controller
                  control={control}
                  name="svnLink"
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Ссылка на SVN"
                      value={field.value === null ? "" : field.value}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-x-3">
        <Button type="button" onClick={() => onClose()}>
          Отмена
        </Button>
        <PrimaryButton type="submit">Сохранить</PrimaryButton>
      </div>
    </form>
  ) : (
    <DataLoader />
  );
};
