import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { PrimaryButton } from "./Button";
import { Controller, useForm } from "react-hook-form";
import { LabeledTextInput } from "./Input";
import { classNames } from "utils";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";

interface IModalProps {
  show: boolean;
  showFn: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Modal: React.FC<IModalProps> = ({ children, show, showFn }) => {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={showFn}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {children}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

interface IDeleteModalProps extends IModalProps {
  headerText: string;
  text: string;
  onSubmit: () => void;
  onClose: () => void;
}

export const DeleteModal: React.FC<IDeleteModalProps> = ({
  show,
  showFn,
  onClose,
  onSubmit,
  headerText,
  text,
}) => {
  const { handleSubmit } = useForm({
    mode: "onSubmit",
    shouldUnregister: true,
  });

  const cancelButtonRef = useRef(null);

  return (
    <Modal show={show} showFn={showFn}>
      <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationIcon
                  className="h-6 w-6 text-red-600"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <Dialog.Title
                  as="h3"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  {headerText}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {text} Данное действие не может быть отменено.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <PrimaryButton
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Удалить
            </PrimaryButton>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => onClose()}
              ref={cancelButtonRef}
            >
              Нет
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

async function fetchContract(gSheetsId, arrayIds, type, post_url) {
  const response = await (
    await fetch(
      `${process.env.REACT_APP_BACKEND_HOST}/google-sheets-adapter/${post_url}`,
      {
        method: "POST",
        body: JSON.stringify({
          gSheetsId:
            gSheetsId ?? "1-5uPARgpyzTeSMfoFvMV6BAHh1SmJdxB0WHBbIPXMKg",
          arrayIds: arrayIds ?? [],
          contractDocType: type,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
  ).json();
  return response;
}

interface IExcelModalProps extends IModalProps {
  headerText: string;
  onSubmit: () => void;
  onClose: () => void;
  GoogleURL?: string;
  type: string;
  post_url: string;
  link: string;
}

export const ExcelModal: React.FC<IExcelModalProps> = ({
  show,
  showFn,
  onClose,
  onSubmit,
  headerText,
  link,
  type,
  post_url,
}) => {
  const { handleSubmit, control } = useForm({
    mode: "onSubmit",
    shouldUnregister: true,
  });
  const cancelButtonRef = useRef(null);

  const rowscount = [];
  rowscount.push(
    ...document.getElementsByTagName("table").item(0).childNodes.item(1)
      .childNodes
  );

  const rows = [];
  for (let i = 0; i < rowscount.length; i++) {
    rows.push(
      document
        .getElementsByTagName("table")
        .item(0)
        .childNodes.item(1)
        .childNodes.item(i)
        .childNodes.item(0)
        .childNodes.item(0)
    );
  }

  let arrayIds = [];
  for (let i = 0; i < rowscount.length; i++) {
    if (rows[i]?.checked) arrayIds.push(rows[i]?.id);
  }

  for (let i = 0; i < arrayIds.length; i++) arrayIds[i] = parseInt(arrayIds[i]);

  const returnArrayIds = arrayIds;
  return (
    <Modal show={show} showFn={showFn}>
      <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4  ">
            <div className="grid grid-rows-2">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <Dialog.Title
                  as="h3"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  {headerText}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    <div>
                      <p>
                        Укажите ссылку на Google таблицу с правом
                        редактирования:
                      </p>
                      <img
                        src="https://storage.yandexcloud.net/artics-inner-static/SheetScreen.jpg"
                        alt=""
                        className="w-full"
                      ></img>
                      <p>Выгрузка пройдет на новый лист.</p>
                      <p>
                        Если не выбрать галочкой, что выгружать - экспортируется
                        вся таблица.
                      </p>
                      <p>
                        Если ссылка некорректная, выгрузится{" "}
                        <a href={link} className="text-blue-500">
                          сюда
                        </a>
                      </p>
                    </div>
                  </p>
                </div>
              </div>
              <div className="text-center p-4 self-center">
                <Controller
                  control={control}
                  name="GoogleURL"
                  rules={{ pattern: /\/([\w-_]{15,})\/(.*?gid=(\d+))?/ }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Googlesheets URL"
                      id="GoogleURL"
                      value={field.value ?? null}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <PrimaryButton
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => {
                fetchContract(
                  document?.getElementById("GoogleURL")?.getAttribute("value")
                    ? document
                        ?.getElementById("GoogleURL")
                        ?.getAttribute("value")
                        ?.split("spreadsheets/d/")[1]
                      ? document
                          ?.getElementById("GoogleURL")
                          ?.getAttribute("value")
                          ?.split("spreadsheets/d/")[1]
                          ?.split("/")[0]
                      : document
                          ?.getElementById("GoogleURL")
                          ?.getAttribute("value")
                          ?.split("spreadsheets/d/")[1]
                    : headerText === "Экспорт актов"
                    ? "1_B-VGedcTZl9w5ntpO3WIdXEIwgvO51pvuxrh4Bc-TI"
                    : headerText === "Экспорт договоров" &&
                      "1cRIOM9x6YGshhVhycXnK3Lc7LgdbG95F1LcJ4K_oyeg",
                  returnArrayIds ?? [],
                  type,
                  post_url
                );

                window.open(
                  document
                    ?.getElementById("GoogleURL")
                    ?.getAttribute("value")
                    ?.split("docs.google.com/spreadsheets/d/")[1]
                    ? document
                        ?.getElementById("GoogleURL")
                        ?.getAttribute("value")
                    : headerText === "Экспорт актов"
                    ? "https://docs.google.com/spreadsheets/d/1_B-VGedcTZl9w5ntpO3WIdXEIwgvO51pvuxrh4Bc-TI/edit#gid=0"
                    : headerText === "Экспорт договоров" &&
                      "https://docs.google.com/spreadsheets/d/1cRIOM9x6YGshhVhycXnK3Lc7LgdbG95F1LcJ4K_oyeg/edit#gid=0",
                  //'https://docs.google.com/spreadsheets/d/1-5uPARgpyzTeSMfoFvMV6BAHh1SmJdxB0WHBbIPXMKg/',
                  "_blank",
                  "noopener,noreferrer"
                );
                onClose();
              }}
            >
              Отправить
            </PrimaryButton>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => onClose()}
              ref={cancelButtonRef}
            >
              Отмена
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

interface IVerificationProps extends IModalProps {
  headerText: string;
  onSubmit: () => void;
  onClose: () => void;
  GoogleURL?: string;
  post_url: string;
}

export const VerificationModal: React.FC<IVerificationProps> = ({
  show,
  showFn,
  onClose,
  onSubmit,
  headerText,
  post_url,
}) => {
  const { handleSubmit, control } = useForm({
    mode: "onSubmit",
    shouldUnregister: true,
  });
  const cancelButtonRef = useRef(null);

  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  async function fetchVerification(gSheetsId, post_url) {
    setLoading(true);
    const response = await (
      await fetch(
        `${process.env.REACT_APP_BACKEND_HOST}/google-sheets-adapter/${post_url}`,
        {
          method: "POST",
          body: JSON.stringify({
            gSheetsId:
              gSheetsId ?? "1-5uPARgpyzTeSMfoFvMV6BAHh1SmJdxB0WHBbIPXMKg",
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    if (response?.ok) {
      setErrors(response);
    } else {
      toast.error(
        "Произошла ошибка. Проверьте корректность ссылки на документ и права на его чтение"
      );
    }
    setLoading(false);
    return response;
  }

  useEffect(() => {
    console.log(errors);
  }, [errors, setLoading]);

  return (
    <Modal show={show} showFn={showFn}>
      <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
        <form
          className="space-y-6"
          onSubmit={handleSubmit(onSubmit)}
          id="verificationForm"
        >
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4  ">
            <div className="">
              <div className="mt-2 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <Dialog.Title
                  as="h3"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  {headerText}
                </Dialog.Title>
                <div className="mt-1">
                  <p className="text-sm text-gray-500">
                    <div>
                      <p>
                        Укажите ссылку на Google таблицу с правом просмотра:
                      </p>
                      {/*<img src='https://storage.yandexcloud.net/artics-inner-static/SheetScreen.jpg' alt="" className="w-full"></img>*/}
                      <p>После нажатия кнопки "Принять" начнется верификация</p>
                      <p>
                        По результатам верификации вы получите отчет об ошибках
                        или их отсуствии
                      </p>
                      {/* ПБТ - проверяяем и отправляем и заменим блаблабла + доп. окно на подтверждение */}
                      {/* ПМ - проверяяем и отправляем и заменим блаблабла + доп. окно на подтверждение */}
                      {/* Баинг - Только проверяем и добавляем? Точно хотим заменять? */}
                    </div>
                    <div className="overflow-y-auto bg-white shadow sm:rounded-md max-h-96">
                      {errors?.errors?.length && (
                        <ul className="divide-y divide-gray-200">
                          {errors?.errors?.flat()?.map((er, i) => (
                            <li key={i} id={i}>
                              <div className="flex items-center px-1 py-1 sm:px-2">
                                <div className="flex min-w-0 flex-1 items-center">
                                  <div className="min-w-0 flex-1 px-1 md:gap-1">
                                    <div>
                                      <p className="mt-1 flex items-center text-sm text-gray-500">
                                        {er?.cell}: {er?.error}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </p>
                </div>
              </div>
              <div className="text-center p-1 mt-1 self-center">
                <Controller
                  control={control}
                  name="GoogleURL"
                  rules={{ pattern: /\/([\w-_]{15,})\/(.*?gid=(\d+))?/ }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Googlesheets URL"
                      id="GoogleURL"
                      value={field.value ?? null}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <PrimaryButton
              type="button"
              id="verificationButton"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => {
                if (headerText === "Верификация файла закрытия") {
                  const googleURL = document
                    ?.getElementById("GoogleURL")
                    ?.getAttribute("value");
                  if (googleURL.length) {
                    document
                      ?.getElementById("GoogleURL")
                      ?.getAttribute("value")
                      ?.split("spreadsheets/d/")[1]
                      ? fetchVerification(
                          document
                            ?.getElementById("GoogleURL")
                            ?.getAttribute("value")
                            ?.split("spreadsheets/d/")[1]
                            ?.split("/")[0],
                          post_url
                        )
                      : fetchVerification(
                          document
                            ?.getElementById("GoogleURL")
                            ?.getAttribute("value")
                            ?.split("spreadsheets/d/")[1],
                          post_url
                        );
                  }
                }
              }}
            >
              Верифицировать
            </PrimaryButton>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => onClose()}
              ref={cancelButtonRef}
            >
              Закрыть
            </button>
          </div>
          {loading && (
            <div
              className={classNames(`left-1/2 bottom-1/2`, "absolute z-1000")}
            >
              <Loader color="#f04444" type="Puff" height={50} width={50} />
            </div>
          )}
        </form>
      </div>
    </Modal>
  );
};
