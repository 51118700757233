import { Button, PrimaryButton, LabeledTextInput } from "components";
import { findEmployeePosition } from "graphql/__generated-types__/findEmployeePosition";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export interface IEmployeePositionsForm {
  title: string;
  description?: string;
}

interface IEmployeePositionsFormProps {
  positionData: findEmployeePosition | null;
  onSubmit: (data: any) => void;
}

export const EmployeePositionsForm: React.FC<IEmployeePositionsFormProps> = ({
  positionData,
  onSubmit,
}) => {
  const navigate = useNavigate();

  const { handleSubmit, control } = useForm<IEmployeePositionsForm>({
    mode: "onSubmit",
    shouldUnregister: true,
    defaultValues: { ...positionData?.findEmployeePosition?.position },
  });

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Информация о должности
            </h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                <Controller
                  control={control}
                  name="title"
                  rules={{ required: "Нужно заполнить название должности" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Название должности"
                      value={field.value || ""}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                <Controller
                  control={control}
                  name="description"
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Описание должности"
                      value={field.value || ""}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-x-3">
        <Button type="button" onClick={() => navigate(-1)}>
          Отмена
        </Button>
        <PrimaryButton type="submit">Сохранить</PrimaryButton>
      </div>
    </form>
  );
};
