import { useMutation } from "@apollo/client";
import { CREATE_USER_MUTATION } from "graphql/mutations";
import {
  createUser,
  createUserVariables,
} from "graphql/__generated-types__/createUser";
import { adminMenuRoutes } from "navigation/routes";
import { useNavigate } from "react-router-dom";
import { IUserForm, UserForm } from "../components";
import { toast } from "react-toastify";

const AdminUsersCreate: React.FC = () => {
  const navigate = useNavigate();

  const [createUser] = useMutation<createUser, createUserVariables>(
    CREATE_USER_MUTATION,
    {
      onCompleted: (createUserData) => {
        if (createUserData?.createUser?.ok)
          navigate(adminMenuRoutes?.users?.path);
        else toast.error(createUserData.createUser.error);
      },
    }
  );

  const onSubmit = (data: IUserForm) => {
    createUser({
      variables: {
        input: {
          ...data,
          birthdate: new Date(data?.birthdate).setUTCHours(0, 0, 0, 0),
        },
      },
    });
  };
  return <UserForm userData={null} onSubmit={onSubmit} />;
};

export default AdminUsersCreate;
