import { gql } from "@apollo/client";
import { POSITION_FRAGMENT } from "graphql/fragments";

export const FIND_EMPLOYEE_POSITION_QUERY = gql`
  query findEmployeePosition($input: FindEmployeePositionInput!) {
    findEmployeePosition(input: $input) {
      ok
      error
      position {
        ...PositionParts
      }
    }
  }
  ${POSITION_FRAGMENT}
`;
