import { gql } from "@apollo/client";
import { BUYING_CONDITION_FRAGMENT } from "graphql/fragments";

export const FIND_ALL_BUYING_CONDITIONS_QUERY = gql`
  query findAllBuyingConditions($input: FindAllBuyingConditionInput!) {
    findAllBuyingConditions(input: $input) {
      ok
      error
      buyingConditions {
          ...BuyingConditionParts
      }
      totalPages
      totalResults
    }
  }
  ${BUYING_CONDITION_FRAGMENT}
`;
