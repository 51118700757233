import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import Login from "./Auth/Login";

export const LoggedOutRouter = () => (
    <Router>
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route path="*" element={<Navigate to="/" />}/>
      </Routes>
    </Router>
)
