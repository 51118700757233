import { gql } from "@apollo/client";
import { USER_FRAGMENT } from "graphql/fragments";

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ok
      error
      user {
        ...UserParts
      }
    }
  }
  ${USER_FRAGMENT}
`;
