import {gql} from "@apollo/client";
import { BUYING_FORMAT_FRAGMENT } from "graphql/fragments";

export const UPDATE_BUYING_FORMAT_MUTATION = gql`
  mutation updateBuyingFormat($input: UpdateBuyingFormatInput!) {
    updateBuyingFormat(input: $input) {
      ok
      error
      buyingFormat {
        ...BuyingFormatParts
      }
    }
  }
  ${BUYING_FORMAT_FRAGMENT}
`;
