import {gql} from "@apollo/client";
import { PBT_REPORT_FRAGMENT } from "graphql/fragments";

export const UPDATE_PBT_REPORT_MUTATION = gql`
  mutation updatePbtReport($input: UpdatePbtReportInput!) {
    updatePbtReport(input: $input) {
      ok
      error
      pbtReport {
        ...PbtReportParts
      }
    }
  }
  ${PBT_REPORT_FRAGMENT}
`;
