import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useLazyQuery} from "@apollo/client";
import {findOneUser, findOneUserVariables} from "../../../graphql/__generated-types__/findOneUser";
import {FIND_ONE_USER_QUERY} from "../../../graphql/queries/findOneUser";
import {Slideover} from "../../../components";
import {PersonDetails} from "./PersonDetails";

export const SearchedPerson = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const userIdParam = searchParams.get("userid");
	const userId = userIdParam ? parseInt(decodeURI(userIdParam)) : undefined;
	const [open, setOpen] = useState(!!userId);

	const [getUser, {loading: userLoading, data: userData}] = useLazyQuery<
		findOneUser,
		findOneUserVariables
	>(FIND_ONE_USER_QUERY, {
		variables: {
			input: {
				id: userId,
			},
		},

		fetchPolicy: "cache-first",
	});


	useEffect(() => {
		const getUserAsync = async () => {
			await getUser();
		}

		if (userId && !userData) {
			getUserAsync();
		}
	}, [userId])
	const handleClose = (value: boolean) => {
		setOpen(value);
		searchParams.delete('userid');
		setSearchParams(searchParams);
	}

	return (
		<Slideover title={`Карточка сотрудника`} open={open && !userLoading && !!userData?.findOneUser?.user}
							 setOpen={handleClose}>
			{ open &&
			<PersonDetails
				key={'searchedUser'}
				person={userData?.findOneUser?.user}/>
			}
		</Slideover>
	)
}