import { gql } from "@apollo/client";
import { DEPARTMENT_FRAGMENT } from "graphql/fragments";

export const CREATE_EMPLOYEE_DEPARTMENT_MUTATION = gql`
  mutation createEmployeeDepartment($input: CreateEmployeeDepartmentInput!) {
    createEmployeeDepartment(input: $input) {
      ok
      error
      department {
        ...DepartmentParts
      }
    }
  }
  ${DEPARTMENT_FRAGMENT}
`;
