import { InformationCircleIcon } from "@heroicons/react/outline";

interface ITooltipProps {
  text: string;
}

const Tooltip: React.FC<ITooltipProps> = ({ text, children }) => (
  <div className="flex items-center">
    {children}
    {text && (
      <p className="hasTooltip relative ml-2">
        <InformationCircleIcon className="h-4 w-4 text-red-600" />
        <span className="tooltip w-60 max-h-80 max-w-80 overflow-auto whitespace-pre-wrap rounded shadow-lg p-2 bg-gray-800 text-gray-100 text-sm -mt-16">
          {
          text //Есть проблема, при которой автовычет ширины текста сокращает некоторые длинные записи до очень коротких с ползунком. #TODO
          } 
        </span>
      </p>
    )}
  </div>
);

export default Tooltip;
