import {gql} from "@apollo/client";
import {CASE_FRAGMENT} from "../fragments";

export const CREATE_CASE_MUTATION = gql`
  mutation createCase($input: CreateCaseInput!) {
    createCase(input: $input) {
      ok
      error
      battleCase {
        ...CaseParts
      }
    }
  }
  ${CASE_FRAGMENT}
`;
