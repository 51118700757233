import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";
import { useState, useMemo } from "react";
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { classNames } from "utils";
import { Button, PageButton } from "./Button";
import { SortDownIcon, SortIcon, SortUpIcon } from "./SortIcons";
import AvatarTemplate from "assets/user-template.png";

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="flex gap-x-2 items-center">
      <label>
        <span className="text-sm text-gray-700">Поиск: </span>
      </label>
      <input
        type="text"
        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} записей...`}
      />
    </div>
  );
};

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) => {
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <div className="flex gap-x-2 items-center" key={id}>
      <label>
        <span className="text-gray-700 text-sm">{render("Header")}: </span>
      </label>
      <select
        className="max-w-xs block w-full rounded-md border-gray-300 shadow-sm focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">Все</option>
        {options.map((option: string, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export function StatusPill({ isPositive, value }) {
  return (
    <span
      className={classNames(
        "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
        isPositive ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"
      )}
    >
      {value}
    </span>
  );
}

export function AvatarCell({ value, name, avatar, subtitle }) {
  return (
    <div className="flex items-center">
      <div className="flex-shrink-0 h-10 w-10">
        {avatar && (
          <img
            className="h-10 w-10 rounded-full object-cover"
            src={avatar}
            alt=""
          />
        )}
      </div>
      <div className="ml-4">
        <div className="text-sm font-medium text-gray-900">
          {name} {value}
        </div>
        <div className="text-sm text-gray-500">{subtitle}</div>
      </div>
    </div>
  );
}

export const DoubleRowCell: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & { value: any; firstRow: any }
> = ({ value, firstRow, ...rest }) => {
  return (
    <div {...rest}>
      <div className="text-sm text-gray-900">{firstRow}</div>
      <div className="text-sm text-gray-500">{value}</div>
    </div>
  );
};

export const SingleRowCell: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ children, ...rest }) => (
  <div {...rest} className="text-sm text-gray-900">
    {children}
  </div>
);

export function StackedAvatarsCell({ value }) {
  return (
    <div className="flex items-center w-40">
      {value.length <= 3 ? (
        <div className="flex items-center -space-x-2 relative z-0">
          {value.map((user) => (
            <img
              key={user.id}
              className={classNames(
                user.avatar ? "ring-white ring-2" : "ring-black ring-1",
                "relative z-30 inline-block h-10 w-10 rounded-full object-cover object-center"
              )}
              src={user.avatar || AvatarTemplate}
              alt={`${user.name} ${user.surname}`}
            />
          ))}
        </div>
      ) : (
        <>
          <div className="flex items-center -space-x-2 relative z-0">
            {value.slice(0, 3).map((user) => (
              <img
                key={user.id}
                className={classNames(
                  user.avatar ? "ring-white ring-2" : "ring-black ring-1",
                  "relative z-30 inline-block h-10 w-10 rounded-full object-cover object-center"
                )}
                src={user.avatar || AvatarTemplate}
                alt={`${user.name} ${user.surname}`}
              />
            ))}
          </div>
          <span className="ml-2 text-lg ">+{value.length - 3}</span>
        </>
      )}
    </div>
  );
}

export const Table = ({
  columns,
  data,
  initialState = null,
  skipColumns = [],
  autoResetFilters = false,
  autoResetSortBy = false,
  autoResetGlobalFilter = false,
}) => {
  const defaultPropGetter = () => ({});
  const {
    getTableProps,
    getTableBodyProps,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
    headerGroups,
    footerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState,
      autoResetFilters,
      autoResetSortBy,
      autoResetGlobalFilter,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg ">
            <div className="flex gap-x-2">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
              {headerGroups.map((headerGroup) =>
                headerGroup.headers.map((column) =>
                  column.Filter
                    ? column.render("Filter", { key: column.id })
                    : null
                )
              )}
            </div>

            <table
              {...getTableProps()}
              className="min-w-full divide-y divide-gray-200 mt-2"
            >
              <thead className="bg-gray-50">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) =>
                      // @ts-ignore
                      !skipColumns.includes(column.Header.toString()) ? (
                        <th
                          scope="col"
                          className={classNames(
                            "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider",
                            // @ts-ignore
                            column?.columnClassName ?? ""
                          )}
                          {...column.getHeaderProps([
                            column.getSortByToggleProps(),
                            getColumnProps(column),
                            getHeaderProps(column),
                          ])}
                        >
                          <div className="flex items-center justify-between">
                            {column.render("Header")}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortDownIcon className="w-4 h-4 text-gray-400" />
                                ) : (
                                  <SortUpIcon className="w-4 h-4 text-gray-400" />
                                )
                              ) : (
                                <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                              )}
                            </span>
                          </div>
                        </th>
                      ) : null
                    )}
                  </tr>
                ))}
              </thead>
              <tbody
                className="bg-white divide-y divide-gray-200"
                {...getTableBodyProps()}
              >
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return !skipColumns.includes(
                          cell.column.Header.toString()
                        ) ? (
                          <td
                            className="px-6 py-4 whitespace-nowrap"
                            {...cell.getCellProps([
                              {
                                // @ts-ignore
                                className: cell.column.cellClassName,
                              },
                              getColumnProps(cell.column),
                              getCellProps(cell),
                            ])}
                          >
                            {cell.render("Cell")}
                          </td>
                        ) : null;
                      })}
                    </tr>
                  );
                })}
              </tbody>
              <tfoot className="bg-gray-50">
                {footerGroups.map((group) => (
                  //@ts-ignore
                  <tr {...group.getFooterGroupProps()}>
                    {group.headers.map((column) =>
                      !skipColumns.includes(column.Header.toString()) ? (
                        <td
                          {...column.getFooterProps()}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {column.render("Footer")}
                        </td>
                      ) : null
                    )}
                  </tr>
                ))}
              </tfoot>
            </table>
            <div className="py-3 flex items-center justify-between">
              <div className="flex-1 flex justify-between sm:hidden">
                <Button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  Назад
                </Button>
                <Button onClick={() => nextPage()} disabled={!canNextPage}>
                  Вперед
                </Button>
              </div>
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div className="flex gap-x-2">
                  <div className="text-sm text-gray-700 flex items-center">
                    <span>
                      Страница{" "}
                      <span className="font-medium">{state.pageIndex + 1}</span>{" "}
                      из{" "}
                      <span className="font-medium">{pageOptions.length}</span>
                    </span>
                  </div>
                  <label>
                    <span className="sr-only">Items Per Page</span>
                    <select
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
                      value={state.pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value));
                      }}
                    >
                      {[5, 10, 20, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize} записей
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div>
                  <nav
                    className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                    aria-label="Pagination"
                  >
                    <PageButton
                      className="rounded-l-md"
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      <span className="sr-only">В начало</span>
                      <ChevronDoubleLeftIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </PageButton>
                    <PageButton
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      <span className="sr-only">Назад</span>
                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </PageButton>
                    <PageButton
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      <span className="sr-only">Вперед</span>
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </PageButton>
                    <PageButton
                      className="rounded-r-md"
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      <span className="sr-only">В конец</span>
                      <ChevronDoubleRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </PageButton>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
