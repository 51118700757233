import { useQuery } from "@apollo/client";
import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import {
  AvatarCell,
  DataLoader,
  Modal,
  PrimaryButton,
  SelectColumnFilter,
  SingleRowCell,
  Table,
} from "components";
import { FIND_ALL_FOT_RATIOS_QUERY } from "graphql/queries/findAllFOTRatios";
import { FIND_ALL_PBT_CALCULATORS_QUERY } from "graphql/queries/findAllPbtCalculator";
import { findAllFOTRatio } from "graphql/__generated-types__/findAllFOTRatio";
import {
  findAllPbtCalculators,
  findAllPbtCalculatorsVariables,
} from "graphql/__generated-types__/findAllPbtCalculators";
import { PbtCalculatorParts } from "graphql/__generated-types__/PbtCalculatorParts";
import { pbtCalculatorPagesRoutes } from "navigation/routes";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";
import { formatDate, formatFloatNumber, formatFullName } from "utils";
import { LayoutButtonsContext } from "../ServiceLayout";
import PbtCalculatorDelete from "./delete";

const PbtCalculator = () => {
  const { setLayoutButtons } = useContext(LayoutButtonsContext);

  const hideEditingForm = useCallback(() => {
    setIsEditing(false);
    resetCurrentPbtCalculator();
  }, []);

  const clickAddButton = useCallback(() => {
    hideEditingForm();
    setIsAdding(true);
  }, [hideEditingForm]);

  const { data: fotRatiosData, loading: fotRatiosLoading } =
    useQuery<findAllFOTRatio>(FIND_ALL_FOT_RATIOS_QUERY, {});

  useEffect(() => {
    setLayoutButtons(
      <div className="flex gap-x-2">
        <Link to={pbtCalculatorPagesRoutes?.pbtCalculatorCreate?.path}>
          <PrimaryButton
            icon={PlusIcon({
              className: "h-4 w-4",
              "aria-hidden": true,
            })}
            id="pbtCalculatorAddButton"
            onClick={() => clickAddButton()}
          >
            Добавить
          </PrimaryButton>
        </Link>
      </div>
    );
    return () => {
      setLayoutButtons(null);
    };
  }, [clickAddButton, setLayoutButtons]);

  const [showDeletePbtCalculatorModal, setShowDeletePbtCalculatorModal] =
    useState(false);
  const [currentPbtCalculator, setCurrentPbtCalculator] =
    useState<PbtCalculatorParts>(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const resetCurrentPbtCalculator = () => setCurrentPbtCalculator(null);

  const clickDeleteButton = useCallback(
    (pbtCalculator: PbtCalculatorParts) => {
      setShowDeletePbtCalculatorModal(!showDeletePbtCalculatorModal);
      setCurrentPbtCalculator(pbtCalculator);
    },
    [showDeletePbtCalculatorModal]
  );

  const calculateOverallArray = (array, multiplier) => {
    let arraySums = 0;
    array?.map((ar) => (arraySums += ar));
    return Math.round((arraySums * multiplier + Number.EPSILON) * 100) / 100;
  };

  const replaceFormat = (value: number) => {
    const result = value.toString();
    return result
      .replace(/[a-zA-Z]/g, "")
      .replace(/\s/g, "")
      .replace(/[,]/g, ".");
  };

  const summArray = (arr: number[]) => {
    let summ = 0;
    arr.map((e) => (summ += e));
    return summ;
  };

  const calculatePeriodMonths = useCallback(
    (salary, workload, periodFrom, periodTo) => {
      const start = new Date(periodFrom).toISOString().split("-");
      const end = new Date(periodTo).toISOString().split("-");
      const startYear = parseInt(start[0]);
      const endYear = parseInt(end[0]);
      const dates = [];

      for (let i = startYear; i <= endYear; i++) {
        const endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
        const startMonth = i === startYear ? parseInt(start[1]) - 1 : 0;
        for (
          let j = startMonth;
          j <= endMonth;
          j = j > 12 ? j % 12 || 11 : j + 1
        ) {
          const month = j + 1;
          const displayMonth = month < 10 ? "0" + month : month;
          dates.push([i, displayMonth, "01"].join("-"));
        }
      }
      let arraySums = parseFloat("0");
      for (let i = 0; i < dates?.length; i++) {
        const index = fotRatiosData?.findAllFOTRatio?.FOTRatios?.findIndex(
          (p) => p.monthYear.split("T")[0] === dates[i]
        );
        if (index < 0) {
          arraySums += salary * workload;
        } else {
          arraySums +=
            salary *
            workload *
            fotRatiosData?.findAllFOTRatio?.FOTRatios[index].value;
        }
      }
      return arraySums;
    },
    [fotRatiosData?.findAllFOTRatio?.FOTRatios]
  );

  const calculateOverallArrayDivide = (array, divider) => {
    let arraySums = parseFloat("0");
    array?.map((ar) => (arraySums += parseFloat(ar)));
    return Math.round((arraySums / divider + Number.EPSILON) * 100) / 100;
  };

  const calculatePeriodNumber = (periodFrom: Date, periodTo: Date) => {
    return (
      (periodTo.getFullYear() - periodFrom.getFullYear()) * 12 +
      (periodTo.getMonth() + 1 - periodFrom.getMonth())
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Наименование расчета",
        accessor: (d: PbtCalculatorParts) => d?.title,
        Cell: (props: CellProps<PbtCalculatorParts, string>) => (
          <Link to={`/service/calculator-pbt/${props.row.original.id}/edit`}>
            <SingleRowCell>{props.cell.value}</SingleRowCell>
          </Link>
        ),
      },
      {
        Header: "Кем создан",
        accessor: (d: PbtCalculatorParts) => formatFullName(d?.addedBy),
        Cell: (props: CellProps<PbtCalculatorParts>) => (
          <AvatarCell
            value={props.row.original.addedBy?.surname}
            name={props.row.original.addedBy?.name}
            avatar={props.row.original.addedBy?.avatar}
            subtitle={props.row.original.addedBy?.position?.title}
          />
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Дата создания",
        accessor: (d: PbtCalculatorParts) => formatDate(d?.createdAt),
        Cell: (props: CellProps<PbtCalculatorParts>) => (
          <SingleRowCell>
            {formatDate(props.row.original.createdAt)}
          </SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Общий бюджет",
        Cell: (props: CellProps<PbtCalculatorParts, string>) => (
          <SingleRowCell>
            {formatFloatNumber(
              calculateOverallArray(
                props.row.original.pbtCalculatorBuyingConditions?.map(
                  (bc) => bc?.budget
                ),
                1
              ) ?? parseFloat("0")
            ) ?? parseFloat("0")}
          </SingleRowCell>
        ),
      },
      {
        Header: "Общая маржа",
        Cell: (props: CellProps<PbtCalculatorParts, string>) => (
          <SingleRowCell>
            {formatFloatNumber(
              calculateOverallArray(
                props.row.original.pbtCalculatorBuyingConditions?.map(
                  (bc) =>
                    (parseFloat(
                      (1 -
                        ((1 -
                          bc?.pbtBuyingCondition?.agencyDiscount -
                          bc?.pbtBuyingCondition?.discountBefore) *
                          (1 -
                            bc?.pbtBuyingCondition?.moneyback -
                            bc?.pbtBuyingCondition?.discountAfter)) /
                          (1 + bc?.pbtBuyingCondition?.bonus)) as any
                    ) ?? parseFloat("0")) *
                      bc?.budget +
                    (bc?.budget * bc?.akDiscount) / 100 -
                    (bc?.overallDiscount / 100) * bc?.budget
                ),
                1 ?? parseFloat("0")
              )
            )}
          </SingleRowCell>
        ),
      },
      {
        Header: "Маржа в мес. (среднее)",
        Cell: (props: CellProps<PbtCalculatorParts, string>) => (
          <SingleRowCell>
            {formatFloatNumber(
              calculateOverallArrayDivide(
                props.row.original.pbtCalculatorBuyingConditions?.map(
                  (bc) =>
                    (parseFloat(
                      (1 -
                        ((1 -
                          bc?.pbtBuyingCondition?.agencyDiscount -
                          bc?.pbtBuyingCondition?.discountBefore) *
                          (1 -
                            bc?.pbtBuyingCondition?.moneyback -
                            bc?.pbtBuyingCondition?.discountAfter)) /
                          (1 + bc?.pbtBuyingCondition?.bonus)) as any
                    ) ?? parseFloat("0")) *
                      parseFloat(replaceFormat(bc?.budget)) +
                    (parseFloat(replaceFormat(bc?.budget)) * bc?.akDiscount) /
                      100 -
                    (bc?.overallDiscount / 100) *
                      parseFloat(replaceFormat(bc?.budget))
                ),
                calculatePeriodNumber(
                  new Date(props?.row?.original?.periodFrom),
                  new Date(props?.row?.original?.periodTo)
                )
              ) ?? parseFloat("0")
            ) ?? parseFloat("0")}
          </SingleRowCell>
        ),
      },
      {
        Header: "% маржинальности",
        Cell: (props: CellProps<PbtCalculatorParts, string>) => (
          <SingleRowCell>
            {formatFloatNumber(
              Math.round(
                (calculateOverallArray(
                  props.row.original.pbtCalculatorBuyingConditions?.map(
                    (bc) =>
                      100 *
                      ((parseFloat(
                        (1 -
                          ((1 -
                            bc?.pbtBuyingCondition?.agencyDiscount -
                            bc?.pbtBuyingCondition?.discountBefore) *
                            (1 -
                              bc?.pbtBuyingCondition?.moneyback -
                              bc?.pbtBuyingCondition?.discountAfter)) /
                            (1 + bc?.pbtBuyingCondition?.bonus)) as any
                      ) ?? parseFloat("0")) *
                        bc?.budget +
                        (bc?.budget * bc?.akDiscount) / 100 -
                        (bc?.overallDiscount / 100) * bc?.budget)
                  ),
                  1 ?? parseFloat("0")
                ) /
                  calculateOverallArray(
                    props.row.original.pbtCalculatorBuyingConditions?.map(
                      (budgets) => budgets?.budget
                    ),
                    1
                  ) +
                  Number.EPSILON) *
                  100
              ) / 100 ??
                parseFloat("0") ??
                parseFloat("0")
            )}
          </SingleRowCell>
        ),
      },
      {
        Header: "ФОТ с коэф.",
        Cell: (props: CellProps<PbtCalculatorParts, string>) => (
          <SingleRowCell>
            {formatFloatNumber(
              summArray(
                props.row.original.pbtCalculatorPositions?.map((period) =>
                  calculatePeriodMonths(
                    replaceFormat(period?.salary),
                    replaceFormat(period?.workload / 100),
                    props?.row?.original?.periodFrom,
                    props?.row?.original?.periodTo
                  )
                )
              )
            )}
          </SingleRowCell>
        ),
      },
      {
        Header: "Общий PBT",
        Cell: (props: CellProps<PbtCalculatorParts, string>) => (
          <SingleRowCell>
            {formatFloatNumber(
              (calculateOverallArray(
                props.row.original.pbtCalculatorBuyingConditions?.map(
                  (bc) =>
                    (parseFloat(
                      (1 -
                        ((1 -
                          bc?.pbtBuyingCondition?.agencyDiscount -
                          bc?.pbtBuyingCondition?.discountBefore) *
                          (1 -
                            bc?.pbtBuyingCondition?.moneyback -
                            bc?.pbtBuyingCondition?.discountAfter)) /
                          (1 + bc?.pbtBuyingCondition?.bonus)) as any
                    ) ?? parseFloat("0")) *
                      bc?.budget +
                    (bc?.budget * bc?.akDiscount) / 100 -
                    (bc?.overallDiscount / 100) * bc?.budget
                ),
                1
              ) ?? parseFloat("0")) -
                (props.row.original.pbtCalculatorPositions?.length
                  ? summArray(
                      props.row.original.pbtCalculatorPositions?.map((period) =>
                        calculatePeriodMonths(
                          period?.salary,
                          period?.workload / 100,
                          props?.row?.original?.periodFrom,
                          props?.row?.original?.periodTo
                        )
                      )
                    )
                  : parseFloat("1"))
            ) ?? parseFloat("0")}
          </SingleRowCell>
        ),
      },
      {
        Header: "PBT в мес. (среднее)",
        Cell: (props: CellProps<PbtCalculatorParts, string>) => (
          <SingleRowCell>
            {formatFloatNumber(
              ((calculateOverallArray(
                props.row.original.pbtCalculatorBuyingConditions?.map(
                  (bc) =>
                    (parseFloat(
                      (1 -
                        ((1 -
                          bc?.pbtBuyingCondition?.agencyDiscount -
                          bc?.pbtBuyingCondition?.discountBefore) *
                          (1 -
                            bc?.pbtBuyingCondition?.moneyback -
                            bc?.pbtBuyingCondition?.discountAfter)) /
                          (1 + bc?.pbtBuyingCondition?.bonus)) as any
                    ) ?? parseFloat("0")) *
                      parseFloat(replaceFormat(bc?.budget)) +
                    (parseFloat(replaceFormat(bc?.budget)) * bc?.akDiscount) /
                      100 -
                    (bc?.overallDiscount / 100) *
                      parseFloat(replaceFormat(bc?.budget))
                ),
                1
              ) ?? parseFloat("0")) -
                (props.row.original.pbtCalculatorPositions?.length
                  ? summArray(
                      props.row.original.pbtCalculatorPositions?.map((period) =>
                        calculatePeriodMonths(
                          replaceFormat(period?.salary),
                          replaceFormat(period?.workload / 100),
                          props?.row?.original?.periodFrom,
                          props?.row?.original?.periodTo
                        )
                      )
                    )
                  : parseFloat("1"))) /
                calculatePeriodNumber(
                  new Date(props?.row?.original?.periodFrom),
                  new Date(props?.row?.original?.periodTo)
                )
            ) ?? parseFloat("0")}
          </SingleRowCell>
        ),
      },
      {
        Header: "% PBT",
        Cell: (props: CellProps<PbtCalculatorParts, string>) => (
          <SingleRowCell>
            {formatFloatNumber(
              Math.round(
                (((calculateOverallArray(
                  props.row.original.pbtCalculatorBuyingConditions?.map(
                    (bc) =>
                      (parseFloat(
                        (1 -
                          ((1 -
                            bc?.pbtBuyingCondition?.agencyDiscount -
                            bc?.pbtBuyingCondition?.discountBefore) *
                            (1 -
                              bc?.pbtBuyingCondition?.moneyback -
                              bc?.pbtBuyingCondition?.discountAfter)) /
                            (1 + bc?.pbtBuyingCondition?.bonus)) as any
                      ) ?? parseFloat("0")) *
                        bc?.budget +
                      (bc?.budget * bc?.akDiscount) / 100 -
                      (bc?.overallDiscount / 100) * bc?.budget
                  ),
                  1
                ) ?? parseFloat("0")) -
                  (props.row.original.pbtCalculatorPositions?.length
                    ? summArray(
                        props.row.original.pbtCalculatorPositions?.map(
                          (period) =>
                            calculatePeriodMonths(
                              period?.salary,
                              period?.workload / 100,
                              props?.row?.original?.periodFrom,
                              props?.row?.original?.periodTo
                            )
                        )
                      )
                    : parseFloat("1"))) /
                  calculateOverallArray(
                    props.row.original.pbtCalculatorBuyingConditions?.map(
                      (bc) => bc?.budget
                    ),
                    1
                  ) +
                  Number.EPSILON) *
                  10000
              ) / 100
            ) ?? parseFloat("0")}
          </SingleRowCell>
        ),
      },
      {
        Header: "Действие",
        Cell: (props: any) => (
          <div className="flex gap-x-2">
            <PrimaryButton
              icon={TrashIcon({
                className: "h-4 w-4",
                "aria-hidden": true,
              })}
              disabled={isAdding || isEditing}
              onClick={() => clickDeleteButton(props.row.original)}
            ></PrimaryButton>
          </div>
        ),
      },
    ],
    [calculatePeriodMonths, clickDeleteButton, isAdding, isEditing]
  );

  const { loading, data: pbtCalculatorsData } = useQuery<
    findAllPbtCalculators,
    findAllPbtCalculatorsVariables
  >(FIND_ALL_PBT_CALCULATORS_QUERY, {
    variables: {
      input: {},
    },
  });

  const pbtCalculators = useMemo(
    () => pbtCalculatorsData?.findAllPbtCalculators?.pbtCalculators,
    [pbtCalculatorsData?.findAllPbtCalculators?.pbtCalculators]
  );

  return !loading && !fotRatiosLoading ? (
    <>
      <Helmet>
        <title> Калькулятор маржи | Артикс Портал</title>
      </Helmet>
      {/*(isAdding || isEditing) && (
        <div className="mb-2">
          {
          isAdding && <PbtCalculatorCreate />
          }
          {isEditing && (
            <PbtCalculatorEdit
              onClose={hideEditingForm}
              pbtCalculator={currentPbtCalculator}
            />
          )}
        </div>
          )*/}

      {showDeletePbtCalculatorModal && (
        <Modal
          show={showDeletePbtCalculatorModal}
          showFn={setShowDeletePbtCalculatorModal}
        >
          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <PbtCalculatorDelete
              pbtCalculator={currentPbtCalculator}
              closeModal={setShowDeletePbtCalculatorModal}
            />
          </div>
        </Modal>
      )}

      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {pbtCalculators && (
                <Table
                  columns={columns}
                  data={pbtCalculators || []}
                  initialState={{
                    pageSize: 20,
                    pageIndex: 0,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};

export default PbtCalculator;
