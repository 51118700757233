import { StoreObject, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { cashbackRulesRoutes } from "../../../../navigation/routes";
import { BUYING_CASHBACK_RULE_FRAGMENT } from "graphql/fragments";
import { CREATE_BUYING_CASHBACK_RULE_MUTATION } from "graphql/mutations/createBuyingCashbackRule";
import {
  createBuyingCashbackRule,
  createBuyingCashbackRuleVariables,
} from "graphql/__generated-types__/createBuyingCashbackRule";
import { BuyingCashbackRuleForm, IBuyingCashbackRuleForm } from "../components";

interface IBuyingCashbackRuleCreateProps {
  onClose: () => void;
}

const BuyingCashbackRuleCreate: React.FC<IBuyingCashbackRuleCreateProps> = ({
  onClose,
}) => {
  const navigate = useNavigate();

  const [createBuyingCashbackRule] = useMutation<
    createBuyingCashbackRule,
    createBuyingCashbackRuleVariables
  >(CREATE_BUYING_CASHBACK_RULE_MUTATION, {
    onCompleted: (data) => {
      if (data?.createBuyingCashbackRule?.ok) {
        navigate(cashbackRulesRoutes.buyingCashbackRules.path);
      } else {
        toast.error(data?.createBuyingCashbackRule?.error);
      }
    },
    update(cache, { data: { createBuyingCashbackRule } }) {
      if (createBuyingCashbackRule?.buyingCashbackRule?.id) {
        const newBuyingCashbackRef = cache.writeFragment({
          id: cache.identify(
            createBuyingCashbackRule?.buyingCashbackRule as unknown as StoreObject
          ),
          data: createBuyingCashbackRule?.buyingCashbackRule,
          fragment: BUYING_CASHBACK_RULE_FRAGMENT,
          fragmentName: "BuyingCashbackRuleParts",
        });
        cache.modify({
          id: "ROOT_QUERY",
          fields: {
            listBuyingCashbackRules(prev) {
              return {
                ...prev,
                buyingCashbackRules: [
                  newBuyingCashbackRef,
                  ...prev.buyingCashbackRules,
                ],
              };
            },
          },
        });
      }
    },
  });

  const onSubmit = async (data: IBuyingCashbackRuleForm) => {
    const response = await createBuyingCashbackRule({
      variables: {
        input: {
          ...data,
          fallbackCashbackPerc: data?.fallbackCashbackPerc / 100,
          cashbackIntervals: data?.cashbackIntervals?.map((interval) => ({
            validFrom: interval?.validFrom,
            validTo: interval?.validTo,
            minTurnover: +interval?.minTurnover,
            maxTurnover: +interval?.maxTurnover,
            cashbackPerc: +interval?.cashbackPerc / 100,
          })),
        },
      },
    });
    if (response?.data?.createBuyingCashbackRule?.ok) {
      onClose();
    }
  };
  return <BuyingCashbackRuleForm onSubmit={onSubmit} onClose={onClose} />;
};

export default BuyingCashbackRuleCreate;
