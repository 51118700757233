import { useQuery } from "@apollo/client";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import {
  DataLoader,
  PrimaryButton,
  SelectColumnFilter,
  SingleRowCell,
  Table,
} from "components";
import { LIST_BUYING_CASHBACK_RULES_QUERY } from "graphql/queries";
import { BuyingCashbackRuleParts } from "graphql/__generated-types__/BuyingCashbackRuleParts";
import {
  listBuyingCashbackRules,
  listBuyingCashbackRulesVariables,
  listBuyingCashbackRules_listBuyingCashbackRules_buyingCashbackRules,
} from "graphql/__generated-types__/listBuyingCashbackRules";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CellProps } from "react-table";
import { LayoutButtonsContext } from "../ServiceLayout";
import BuyingCashbackRuleCreate from "./create";
import BuyingCashbackRuleDelete from "./delete";
import BuyingCashbackRuleEdit from "./edit";
import dayjs from "dayjs";
import { Helmet } from "react-helmet-async";
import { useMe } from "hooks";
import { UserRole } from "graphql/__generated-types__/globalTypes";

const BuyingCashbackRules = () => {
  const { setLayoutButtons } = useContext(LayoutButtonsContext);

  const user = useMe();

  const clickAddButton = useCallback(() => {
    hideEditingForm();
    setIsAdding(true);
  }, []);

  useEffect(() => {
    setLayoutButtons(
      <div className="flex gap-x-2">
        {(user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
          user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
          <PrimaryButton
            icon={PlusIcon({
              className: "h-4 w-4",
              "aria-hidden": true,
            })}
            id="buyingCashbackRuleAddButton"
            onClick={() => clickAddButton()}
          >
            Добавить
          </PrimaryButton>
        )}
      </div>
    );

    return () => {
      setLayoutButtons(null);
    };
  }, [clickAddButton, setLayoutButtons, user?.data?.me?.user?.roles]);

  const { loading, data: cashBackData } = useQuery<
    listBuyingCashbackRules,
    listBuyingCashbackRulesVariables
  >(LIST_BUYING_CASHBACK_RULES_QUERY, {
    variables: {
      input: {},
    },
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentItem, setCurrentItem] =
    useState<listBuyingCashbackRules_listBuyingCashbackRules_buyingCashbackRules>(
      null
    );
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const hideAddingForm = useCallback(
    (reOpen = false) => {
      setIsAdding(false);
      setCurrentItem(null);
      if (reOpen) {
        clickAddButton();
      }
    },
    [clickAddButton]
  );

  const clickEditButton = useCallback(
    (
      buyingCashbackRule: listBuyingCashbackRules_listBuyingCashbackRules_buyingCashbackRules
    ) => {
      hideAddingForm();
      hideEditingForm();
      setIsEditing(true);
      setCurrentItem(buyingCashbackRule);
      const buyingCashbackRuleAddButton = document.getElementById(
        "buyingCashbackRuleAddButton"
      );
      if (buyingCashbackRuleAddButton) {
        buyingCashbackRuleAddButton.scrollIntoView();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    [hideAddingForm]
  );

  const clickDeleteButton = useCallback(
    (
      buyingCashbackRule: listBuyingCashbackRules_listBuyingCashbackRules_buyingCashbackRules
    ) => {
      setShowDeleteModal(!showDeleteModal);
      setCurrentItem(buyingCashbackRule);
    },
    [showDeleteModal]
  );

  const hideEditingForm = () => {
    setIsEditing(false);
    setCurrentItem(null);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Клиент",
        accessor: (d: BuyingCashbackRuleParts) => d.client?.title,
        Cell: (props: CellProps<BuyingCashbackRuleParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Площадка",
        accessor: (d: BuyingCashbackRuleParts) => d.vendor?.title,
        Cell: (props: CellProps<BuyingCashbackRuleParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Кэшбек от оборота",
        Cell: (props: CellProps<BuyingCashbackRuleParts>) =>
          props.row.original.cashbackIntervals.length ? (
            <ul className="divide-y divide-gray-200">
              {props.row.original.cashbackIntervals.map((interval) => (
                <li className="py-2 text-sm">
                  <div className="flex justify-between items-center">
                    <span className="">
                      {dayjs(interval.validFrom).format("MM.YYYY")} -{" "}
                      {dayjs(interval.validTo).format("MM.YYYY")}
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-gray-600">
                      {interval?.maxTurnover ? (
                        <>
                          {interval?.minTurnover?.toLocaleString()} руб. -{" "}
                          {interval?.maxTurnover?.toLocaleString()} руб.
                        </>
                      ) : (
                        <>От {interval?.minTurnover?.toLocaleString()} руб.</>
                      )}
                    </span>
                    <span className="text-gray-900 ml-5">
                      {" "}
                      {Math.round(
                        (interval?.cashbackPerc + Number.EPSILON) * 10000
                      ) / 100}{" "}
                      %
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          ) : null,
      },
      {
        Header: "Кэшбек в остальных случаях",
        accessor: "fallbackCashbackPerc",
        Cell: (
          props: CellProps<
            BuyingCashbackRuleParts,
            BuyingCashbackRuleParts["fallbackCashbackPerc"]
          >
        ) => (
          <div className="flex justify-center align-center text-sm text-gray-900">
            {Math.round(
              (props.row.original?.fallbackCashbackPerc + Number.EPSILON) *
                10000
            ) / 100}{" "}
            %
          </div>
        ),
      },
      {
        Header: "Тип оплаты",
        accessor: (d: BuyingCashbackRuleParts) => {
          if (d?.buyingConditionType === "PREPAID") return "Предоплата";
          if (d?.buyingConditionType === "POSTPAID") return "Постоплата";
          return "Тип не указан";
        },
        Cell: (props: CellProps<BuyingCashbackRuleParts>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Действие",
        Cell: (props: CellProps<BuyingCashbackRuleParts>) => (
          <div className="flex gap-x-2">
            {user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
            user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN) ? (
              <>
                <PrimaryButton
                  icon={PencilIcon({
                    className: "h-4 w-4",
                    "aria-hidden": true,
                  })}
                  disabled={isAdding}
                  onClick={() => clickEditButton(props.row.original)}
                ></PrimaryButton>

                <PrimaryButton
                  icon={TrashIcon({
                    className: "h-4 w-4",
                    "aria-hidden": true,
                  })}
                  disabled={isAdding || isEditing}
                  onClick={() => clickDeleteButton(props.row.original)}
                ></PrimaryButton>
              </>
            ) : (
              <SingleRowCell>Н/Д</SingleRowCell>
            )}
          </div>
        ),
      },
    ],
    [
      clickDeleteButton,
      clickEditButton,
      isAdding,
      isEditing,
      user?.data?.me?.user?.roles,
    ]
  );

  const cashbackRules = useMemo(
    () => cashBackData?.listBuyingCashbackRules?.buyingCashbackRules,
    [cashBackData?.listBuyingCashbackRules?.buyingCashbackRules]
  );

  return !loading ? (
    <>
      <Helmet>
        <title> Правила начисления кэшбека | Артикс Портал</title>
      </Helmet>
      {(isAdding || isEditing) && (
        <div className="mb-2">
          {isAdding && <BuyingCashbackRuleCreate onClose={hideAddingForm} />}
          {isEditing && (
            <BuyingCashbackRuleEdit
              onClose={hideEditingForm}
              buyingCashbackRule={currentItem}
            />
          )}
        </div>
      )}

      {showDeleteModal && (
        <BuyingCashbackRuleDelete
          show={showDeleteModal}
          showFn={setShowDeleteModal}
          buyingCashbackRule={currentItem}
        />
      )}

      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <Table
                columns={columns}
                data={cashbackRules || []}
                initialState={{
                  pageSize: 20,
                  pageIndex: 0,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};

export default BuyingCashbackRules;
