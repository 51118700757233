import {gql} from "graphql.macro";
import {LEGALNAME_FRAGMENT} from "../fragments";

export const LIST_LEGALNAMES_QUERY = gql`
  query listLegalnames($input: ListLegalnamesInput!) {
    listLegalnames(input: $input) {
      ok
      error
      legalnames {
        ...LegalnameParts
      }
    }
  }
  ${LEGALNAME_FRAGMENT}
`;
