import {gql} from "@apollo/client";
import {SELLER_FRAGMENT} from "../fragments";

export const UPDATE_SELLER_MUTATION = gql`
  mutation updateSeller($input: UpdateSellerInput!) {
    updateSeller(input: $input) {
      ok
      error
      seller {
        ...SellerParts
      }
    }
  }
  ${SELLER_FRAGMENT}
`;
