import { gql } from "@apollo/client";
import { PBT_CALCULATOR_FRAGMENT } from "graphql/fragments";

export const FIND_ALL_PBT_CALCULATORS_QUERY = gql`
  query findAllPbtCalculators($input: FindAllPbtCalculatorInput!) {
    findAllPbtCalculators(input: $input) {
      ok
      error
      pbtCalculators {
        ...PbtCalculatorParts
      }
    }
  }
  ${PBT_CALCULATOR_FRAGMENT}
`;
