import { gql } from "@apollo/client";
import {CERTIFICATE_FRAGMENT, USER_FRAGMENT} from "graphql/fragments";

export const FIND_ONE_USER_QUERY = gql`
  query findOneUser($input: FindOneUserInput!) {
    findOneUser(input: $input) {
      ok
      error
      user {
        ...UserParts
        certificates {
          ...CertificateParts
        }
      }
    }
  }
  ${USER_FRAGMENT}
	${CERTIFICATE_FRAGMENT}
`;
