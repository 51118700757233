import { useQuery } from "@apollo/client";
import { XIcon } from "@heroicons/react/outline";
import {
  PrimaryButton,
  DataLoader,
  ReactSelectInput,
  LabeledTextInput,
  LabeledMonthInput,
  LabeledTextInputWithTrailingAddon,
} from "components";
import { LIST_EMPLOYEE_POSITIONS_QUERY } from "graphql/queries";
import { FIND_ALL_BUYING_CONDITIONS_QUERY } from "graphql/queries/findAllBuyingConditions";
import { FIND_ALL_FOT_RATIOS_QUERY } from "graphql/queries/findAllFOTRatios";
import {
  findAllBuyingConditions,
  findAllBuyingConditionsVariables,
} from "graphql/__generated-types__/findAllBuyingConditions";
import { findAllFOTRatio } from "graphql/__generated-types__/findAllFOTRatio";
import { findOnePbtCalculator } from "graphql/__generated-types__/findOnePbtCalculator";
import {
  listEmployeePositions,
  listEmployeePositionsVariables,
} from "graphql/__generated-types__/listEmployeePositions";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { formatDate, formatFloatNumber } from "utils";

export interface IPbtCalculatorForm {
  title: string;
  periodFrom: string;
  periodTo: string;
  addedById: number;
  pbtCalculatorPositions: [
    {
      salary: number;
      workload: number;
      pbtPositionId: number;
    }
  ];
  pbtCalculatorBuyingConditions: [
    {
      budget: number;
      akDiscount: number;
      overallDiscount: number;
      marginality: number;
      pbtBuyingConditionId: string;
    }
  ];
}

interface IPbtCalculatorFormProps {
  pbtCalculator: findOnePbtCalculator | null;
  onSubmit: (data: any) => void;
}

export const PbtCalculatorForm: React.FC<IPbtCalculatorFormProps> = ({
  pbtCalculator,
  onSubmit,
}) => {
  const { handleSubmit, control, setValue, getValues, register } =
    useForm<IPbtCalculatorForm>({
      mode: "onSubmit",
      shouldUnregister: true,
      defaultValues: pbtCalculator?.findOnePbtCalculator?.pbtCalculator
        ? {
            ...pbtCalculator?.findOnePbtCalculator?.pbtCalculator,
            title: pbtCalculator?.findOnePbtCalculator?.pbtCalculator?.title,
            periodFrom:
              pbtCalculator?.findOnePbtCalculator?.pbtCalculator?.periodFrom,
            periodTo:
              pbtCalculator?.findOnePbtCalculator?.pbtCalculator?.periodTo,
            addedById:
              pbtCalculator?.findOnePbtCalculator?.pbtCalculator?.addedBy?.id,
            pbtCalculatorBuyingConditions: pbtCalculator
              ? pbtCalculator?.findOnePbtCalculator?.pbtCalculator?.pbtCalculatorBuyingConditions?.map(
                  (buyingCondition) => ({
                    ...buyingCondition,
                    pbtBuyingConditionId: `${
                      buyingCondition?.pbtBuyingCondition?.id
                    } | ${
                      1 -
                      ((1 -
                        buyingCondition?.pbtBuyingCondition?.agencyDiscount -
                        buyingCondition?.pbtBuyingCondition?.discountBefore) *
                        (1 -
                          buyingCondition?.pbtBuyingCondition?.moneyback -
                          buyingCondition?.pbtBuyingCondition?.discountAfter)) /
                        (1 + buyingCondition?.pbtBuyingCondition?.bonus)
                    }`,
                    akDiscount: buyingCondition?.akDiscount,
                    budget: buyingCondition?.budget,
                    overallDiscount: buyingCondition?.overallDiscount,
                  })
                )
              : [
                  {
                    akDiscount: parseFloat("0"),
                    budget: parseFloat("0"),
                    overallDiscount: parseFloat("0"),
                    marginality: parseFloat("0"),
                  },
                ],
            pbtCalculatorPositions: pbtCalculator
              ? pbtCalculator?.findOnePbtCalculator?.pbtCalculator?.pbtCalculatorPositions?.map(
                  (position) => ({
                    salary: position?.salary,
                    workload: position?.workload,
                    pbtPositionId: position?.employeePosition?.id,
                  })
                )
              : [
                  {
                    salary: parseFloat("0"),
                    workload: parseFloat("0"),
                  },
                ],
          }
        : {
            ...pbtCalculator?.findOnePbtCalculator?.pbtCalculator,
            title: `Расчет - ${formatDate(
              new Date().toISOString()
            )} - ${new Date().toLocaleTimeString()}`,
            periodFrom: new Date().toISOString(),
            periodTo: new Date().toISOString(),
            addedById:
              pbtCalculator?.findOnePbtCalculator?.pbtCalculator?.addedBy?.id,
            pbtCalculatorBuyingConditions: pbtCalculator
              ? pbtCalculator?.findOnePbtCalculator?.pbtCalculator?.pbtCalculatorBuyingConditions?.map(
                  (buyingCondition) => ({
                    ...buyingCondition,
                    pbtBuyingConditionId: `${
                      buyingCondition?.pbtBuyingCondition?.id
                    } | ${
                      1 -
                      ((1 -
                        buyingCondition?.pbtBuyingCondition?.agencyDiscount -
                        buyingCondition?.pbtBuyingCondition?.discountBefore) *
                        (1 -
                          buyingCondition?.pbtBuyingCondition?.moneyback -
                          buyingCondition?.pbtBuyingCondition?.discountAfter)) /
                        (1 + buyingCondition?.pbtBuyingCondition?.bonus)
                    }`,
                    akDiscount: buyingCondition?.akDiscount,
                    budget: buyingCondition?.budget,
                    overallDiscount: buyingCondition?.overallDiscount,
                  })
                )
              : [
                  {
                    akDiscount: parseFloat("0"),
                    budget: parseFloat("0"),
                    overallDiscount: parseFloat("0"),
                    marginality: parseFloat("0"),
                  },
                ],
            pbtCalculatorPositions: pbtCalculator
              ? pbtCalculator?.findOnePbtCalculator?.pbtCalculator?.pbtCalculatorPositions?.map(
                  (position) => ({
                    salary: position?.salary,
                    workload: position?.workload,
                    pbtPositionId: position?.employeePosition?.id,
                  })
                )
              : [
                  {
                    salary: parseFloat("0"),
                    workload: parseFloat("0"),
                    pbtPositionId: null,
                  },
                ],
          },
    });

  const { data: positionsData, loading: positionsLoading } = useQuery<
    listEmployeePositions,
    listEmployeePositionsVariables
  >(LIST_EMPLOYEE_POSITIONS_QUERY, {
    variables: { input: { includeUserData: false } },
  });

  const { data: buyingConditionsData, loading: buyingConditionsLoading } =
    useQuery<findAllBuyingConditions, findAllBuyingConditionsVariables>(
      FIND_ALL_BUYING_CONDITIONS_QUERY,
      {
        variables: { input: {} },
      }
    );

  const { data: fotRatiosData, loading: fotRatiosLoading } =
    useQuery<findAllFOTRatio>(FIND_ALL_FOT_RATIOS_QUERY, {});

  const {
    fields: BCfields,
    append: BCappend,
    remove: BCremove,
  } = useFieldArray({
    control,
    name: "pbtCalculatorBuyingConditions",
  });

  const {
    fields: Pfields,
    append: Pappend,
    remove: Premove,
  } = useFieldArray({
    control,
    name: "pbtCalculatorPositions",
  });

  const label = pbtCalculator
    ? "Редактирование расчета ПБТ"
    : "Добавление расчета ПБТ";

  const replaceFormat = (value: number) => {
    const result = value.toString();
    return result
      .replace(/[a-zA-Z]/g, "")
      .replace(/\s/g, "")
      .replace(/[,]/g, ".");
  };

  const CalculateMarginality = (idx: number) => {
    const buyingConditionMarginality = getValues()
      .pbtCalculatorBuyingConditions[idx]?.pbtBuyingConditionId
      ? Math.round(
          (parseFloat(
            getValues()
              .pbtCalculatorBuyingConditions[
                idx
              ]?.pbtBuyingConditionId?.toString()
              .substring(
                getValues()
                  .pbtCalculatorBuyingConditions[
                    idx
                  ]?.pbtBuyingConditionId?.toString()
                  .indexOf("|") + 2
              )
          ) +
            Number.EPSILON) *
            100
        ) / 100
      : parseFloat("0");

    const budget =
      Math.round(
        (parseFloat(
          replaceFormat(getValues().pbtCalculatorBuyingConditions[idx]?.budget)
        ) +
          Number.EPSILON) *
          100
      ) / 100;

    const akDiscount =
      getValues().pbtCalculatorBuyingConditions[idx]?.akDiscount / 100;

    const overallDiscount =
      getValues().pbtCalculatorBuyingConditions[idx]?.overallDiscount / 100;

    const result =
      Math.round(
        (buyingConditionMarginality * budget +
          akDiscount * budget -
          overallDiscount * budget +
          Number.EPSILON) *
          100
      ) / 100;

    setValue(`pbtCalculatorBuyingConditions.${idx}.marginality` as any, result);
  };

  const CalculateMarginalityForLabel = (idx: number) => {
    const buyingConditionMarginality = getValues()
      .pbtCalculatorBuyingConditions[idx]?.pbtBuyingConditionId
      ? Math.round(
          (parseFloat(
            getValues()
              .pbtCalculatorBuyingConditions[
                idx
              ]?.pbtBuyingConditionId?.toString()
              .substring(
                getValues()
                  .pbtCalculatorBuyingConditions[
                    idx
                  ]?.pbtBuyingConditionId?.toString()
                  .indexOf("|") + 2
              )
          ) +
            Number.EPSILON) *
            10000
        ) / 10000
      : parseFloat("0");

    const budget =
      Math.round(
        (parseFloat(
          replaceFormat(getValues().pbtCalculatorBuyingConditions[idx]?.budget)
        ) +
          Number.EPSILON) *
          100
      ) / 100;

    const akDiscount =
      getValues().pbtCalculatorBuyingConditions[idx]?.akDiscount / 100;

    const overallDiscount =
      getValues().pbtCalculatorBuyingConditions[idx]?.overallDiscount / 100;

    const result =
      Math.round(
        (buyingConditionMarginality * budget +
          akDiscount * budget -
          overallDiscount * budget +
          Number.EPSILON) *
          100
      ) / 100;

    return result;
  };

  const CalculateFormulas = () => {
    //Общий бюджет проекта
    setValue(
      `pbtCalculator.overAllBudget` as any,
      formatFloatNumber(
        calculateOverallArray(
          getValues().pbtCalculatorBuyingConditions?.map((bc) =>
            replaceFormat(bc?.budget)
          ),
          1
        ) ?? parseFloat("0")
      ) ?? parseFloat("0")
    );

    //Общая маржа
    setValue(
      `pbtCalculator.overAllMarginality` as any,
      formatFloatNumber(
        calculateOverallArray(
          getValues().pbtCalculatorBuyingConditions?.map(
            (bc) =>
              (parseFloat(
                bc?.pbtBuyingConditionId
                  ?.toString()
                  .substring(
                    bc?.pbtBuyingConditionId?.toString().indexOf("|") + 2
                  )
              ) ?? parseFloat("0")) *
                parseFloat(replaceFormat(bc?.budget)) +
              (parseFloat(replaceFormat(bc?.budget)) * bc?.akDiscount) / 100 -
              (bc?.overallDiscount / 100) *
                parseFloat(replaceFormat(bc?.budget))
          ),
          1
        ) ?? parseFloat("0")
      ) ?? parseFloat("0")
    );

    //% Маржинальности по проекту
    setValue(
      `pbtCalculator.marginalityPercent` as any,
      formatFloatNumber(
        Math.round(
          ((calculateOverallArray(
            getValues().pbtCalculatorBuyingConditions?.map(
              (bc) =>
                (parseFloat(
                  bc?.pbtBuyingConditionId
                    ?.toString()
                    .substring(
                      bc?.pbtBuyingConditionId?.toString().indexOf("|") + 2
                    )
                ) ?? parseFloat("0")) *
                  parseFloat(replaceFormat(bc?.budget)) +
                (parseFloat(replaceFormat(bc?.budget)) * bc?.akDiscount) / 100 -
                (bc?.overallDiscount / 100) *
                  parseFloat(replaceFormat(bc?.budget))
            ),
            1
          ) ?? parseFloat("0")) /
            (calculateOverallArray(
              getValues().pbtCalculatorBuyingConditions?.map((bc) =>
                replaceFormat(bc?.budget)
              ),
              1
            ) ?? parseFloat("1")) +
            Number.EPSILON) *
            10000
        ) / 100
      )
    );

    //Маржа в мес. (среднее)
    setValue(
      `pbtCalculator.marginalityPerMonth` as any,
      formatFloatNumber(
        calculateOverallArrayDivide(
          getValues().pbtCalculatorBuyingConditions?.map(
            (bc) =>
              (parseFloat(
                bc?.pbtBuyingConditionId
                  ?.toString()
                  .substring(
                    bc?.pbtBuyingConditionId?.toString().indexOf("|") + 2
                  )
              ) ?? parseFloat("0")) *
                parseFloat(replaceFormat(bc?.budget)) +
              (parseFloat(replaceFormat(bc?.budget)) * bc?.akDiscount) / 100 -
              (bc?.overallDiscount / 100) *
                parseFloat(replaceFormat(bc?.budget))
          ),
          calculatePeriodNumber(
            new Date(getValues().periodFrom),
            new Date(getValues().periodTo)
          )
        ) ?? parseFloat("0")
      ) ?? parseFloat("0")
    );

    //ФОТ с коэф.
    if (getValues().pbtCalculatorPositions?.length) {
      let FOT = 0;
      getValues().pbtCalculatorPositions?.map(
        (period) =>
          (FOT += calculatePeriodMonths(
            replaceFormat(period?.salary),
            replaceFormat(period?.workload / 100)
          ))
      );
      setValue(
        `pbtCalculator.FOT` as any,
        formatFloatNumber(FOT) ?? parseFloat("0")
      );
    } else {
      setValue(`pbtCalculator.FOT` as any, 0);
    }

    //Общий PBT
    const overallMarginality =
      calculateOverallArray(
        getValues().pbtCalculatorBuyingConditions?.map(
          (bc) =>
            (parseFloat(
              bc?.pbtBuyingConditionId
                ?.toString()
                .substring(
                  bc?.pbtBuyingConditionId?.toString().indexOf("|") + 2
                )
            ) ?? parseFloat("0")) *
              parseFloat(replaceFormat(bc?.budget)) +
            (parseFloat(replaceFormat(bc?.budget)) * bc?.akDiscount) / 100 -
            (bc?.overallDiscount / 100) * parseFloat(replaceFormat(bc?.budget))
        ),
        1
      ) ?? parseFloat("0");

    let FOT = 0;
    if (getValues().pbtCalculatorPositions?.length) {
      getValues().pbtCalculatorPositions?.map(
        (period) =>
          (FOT += calculatePeriodMonths(
            replaceFormat(period?.salary),
            replaceFormat(period?.workload / 100)
          ))
      );
    }

    const overallPBT = overallMarginality - FOT;
    setValue(
      `pbtCalculator.overallPBT` as any,
      formatFloatNumber(overallPBT) ?? parseFloat("0")
    );

    //% PBT
    const pbtPercent =
      overallPBT /
      calculateOverallArray(
        getValues().pbtCalculatorBuyingConditions?.map((bc) =>
          replaceFormat(bc?.budget)
        ),
        1
      );
    setValue(
      `pbtCalculator.PBTpercent` as any,
      formatFloatNumber(pbtPercent) ?? parseFloat("0")
    );

    //PBT в мес. (среднее)
    const pbtDividedByMonths =
      overallPBT /
      calculatePeriodNumber(
        new Date(getValues().periodFrom),
        new Date(getValues().periodTo)
      );
    setValue(
      `pbtCalculator.PBTDividedByMonths` as any,
      formatFloatNumber(pbtDividedByMonths) ?? parseFloat("0")
    );
  };

  const calculateOverallArray = (array, multiplier) => {
    let arraySums = parseFloat("0");
    array?.map((ar) => (arraySums += parseFloat(ar)));
    return Math.round((arraySums * multiplier + Number.EPSILON) * 100) / 100;
  };

  const calculateOverallArrayDivide = (array, divider) => {
    let arraySums = parseFloat("0");
    array?.map((ar) => (arraySums += parseFloat(ar)));
    return Math.round((arraySums / divider + Number.EPSILON) * 100) / 100;
  };

  const calculatePeriodNumber = (periodFrom: Date, periodTo: Date) => {
    return (
      (periodTo.getFullYear() - periodFrom.getFullYear()) * 12 +
      (periodTo.getMonth() + 1 - periodFrom.getMonth())
    );
  };

  const summArray = (arr) => {
    let summ = 0;
    arr.map((e) => (summ += e));
    return summ;
  };

  const calculatePeriodMonths = (salary, workload) => {
    const start = new Date(getValues().periodFrom).toISOString().split("-");
    const end = new Date(getValues().periodTo).toISOString().split("-");
    const startYear = parseInt(start[0]);
    const endYear = parseInt(end[0]);
    const dates = [];

    for (let i = startYear; i <= endYear; i++) {
      const endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
      const startMonth = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (
        let j = startMonth;
        j <= endMonth;
        j = j > 12 ? j % 12 || 11 : j + 1
      ) {
        const month = j + 1;
        const displayMonth = month < 10 ? "0" + month : month;
        dates.push([i, displayMonth, "01"].join("-"));
      }
    }
    let arraySums = parseFloat("0");
    for (let i = 0; i < dates?.length; i++) {
      const index = fotRatiosData?.findAllFOTRatio?.FOTRatios?.findIndex(
        (p) => p.monthYear.split("T")[0] === dates[i]
      );
      console.log(
        fotRatiosData?.findAllFOTRatio?.FOTRatios[0]?.monthYear.split("T")[0]
      );
      console.log(dates[i]);
      console.log(index);
      if (index < 0) {
        arraySums += salary * workload;
      } else {
        arraySums +=
          salary *
          workload *
          fotRatiosData?.findAllFOTRatio?.FOTRatios[index].value;
      }
    }
    return arraySums;
  };

  return !positionsLoading && !buyingConditionsLoading && !fotRatiosLoading ? (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-1 md:gap-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {label}
          </h3>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <hr className="my-8 h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12 grid grid-cols-12 gap-6">
                {/* Наименование расчета */}
                <div className="col-span-3">
                  <Controller
                    control={control}
                    name="title"
                    rules={{
                      required: "Нужно заполнить наименование расчета",
                    }}
                    render={({ field, fieldState }) => (
                      <LabeledTextInput
                        labelText="Наименование расчета"
                        value={field.value ?? ""}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        name={field.name}
                        fieldState={fieldState}
                        type="text"
                      />
                    )}
                  />
                </div>

                {/* дата начала периода РК (Месяцев) */}
                <div className="col-span-2">
                  <Controller
                    control={control}
                    rules={{
                      required:
                        "Необходимо заполнить дату начала периода РК (Месяцев)",
                    }}
                    name="periodFrom"
                    render={({ field, fieldState }) => (
                      <LabeledMonthInput
                        id="periodFrom"
                        labelText="Период РК:"
                        field={field}
                        name={field.name}
                        fieldState={fieldState}
                        onSelect={() => CalculateFormulas()}
                      />
                    )}
                  />
                </div>

                {/* дата окончания периода РК (Месяцев) */}
                <div className="col-span-2">
                  <Controller
                    control={control}
                    rules={{
                      required:
                        "Необходимо заполнить дату окончания периода РК (Месяцев)",
                    }}
                    name="periodTo"
                    render={({ field, fieldState }) => (
                      <LabeledMonthInput
                        id="periodTo"
                        labelText="⠀"
                        field={field}
                        name={field.name}
                        fieldState={fieldState}
                        onSelect={() => CalculateFormulas()}
                      />
                    )}
                  />
                </div>
              </div>
              <hr className="my-8 h-px bg-gray-200 border-0 dark:bg-gray-700 col-span-12"></hr>
              <div className="mr-2 col-span-12">
                {/* Заполнение ФОТ */}
                <div className="col-span-full">
                  <span className="block text-sm text-gray-700 font-bold mb-5">
                    Заполнить ФОТ (месячный):
                  </span>

                  {Pfields.map((item, idx) => (
                    <div
                      className="grid grid-cols-12 gap-x-4 gap-y-1 mb-2"
                      key={item.id}
                    >
                      <>
                        {/* Должность сотрудника */}
                        <div className="col-span-3">
                          <Controller
                            control={control}
                            rules={{
                              required: "Нужно заполнить должность сотрудника",
                            }}
                            name={
                              `pbtCalculatorPositions.${idx}.pbtPositionId` as any
                            }
                            render={({ field, fieldState }) => (
                              <ReactSelectInput
                                options={
                                  positionsData &&
                                  positionsData?.listEmployeePositions?.positions?.map(
                                    (p) => {
                                      return {
                                        label: p?.title,
                                        value: p?.id,
                                      };
                                    }
                                  )
                                }
                                defaultValue={field.value}
                                name={`pbtCalculatorPositions.${idx}.pbtPositionId`}
                                id={`pbtCalculatorPositions.${idx}.pbtPositionId`}
                                value={
                                  !isNaN(parseFloat(field.value))
                                    ? parseFloat(field.value)
                                    : parseFloat("0")
                                }
                                labelText="Сотрудник"
                                onSelect={(e) => {
                                  setValue(
                                    `pbtCalculatorPositions.${idx}.pbtPositionId` as any,
                                    e
                                  );
                                  CalculateFormulas();
                                }}
                                placeholder="Выберите должность из списка"
                                fieldState={fieldState}
                              />
                            )}
                          />
                        </div>

                        {/* Зарплата */}
                        <div className="col-span-4">
                          <div className="flex rounded-md">
                            <Controller
                              control={control}
                              name={
                                `pbtCalculatorPositions.${idx}.salary` as any
                              }
                              rules={{
                                required:
                                  "Нужно указать зарплату сотрудника в мес.",
                                min: 0,
                              }}
                              render={({ field, fieldState }) => (
                                <>
                                  <div className="col-span-full w-full">
                                    <LabeledTextInputWithTrailingAddon
                                      labelText="Зарплата сотрудника в мес."
                                      id={`pbtCalculatorPositions[${idx}]salary`}
                                      addonText="руб."
                                      name={field.name}
                                      fieldState={fieldState}
                                      type="text"
                                      {...register(
                                        `pbtCalculatorPositions.${idx}.salary` as any
                                      )}
                                      className={
                                        "flex-1 min-w-0 block w-full px-3 py-2 rounded-none focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300"
                                      }
                                      value={field.value.toLocaleString() ?? 0}
                                      min="0"
                                      onBlur={(e) => {
                                        CalculateFormulas();
                                        field.onChange(
                                          (
                                            Math.round(
                                              (parseFloat(
                                                e.target.value
                                                  .replace(/[a-zA-Z]/g, "")
                                                  .replace(/\s/g, "")
                                              ) +
                                                Number.EPSILON) *
                                                100
                                            ) / 100
                                          ).toLocaleString()
                                        );
                                      }}
                                      onFocus={(e) => {
                                        field.onChange(
                                          e.target.value
                                            .replace(/[a-zA-Z]/g, "")
                                            .replace(/\s/g, "")
                                            .replace(/[,]/g, ".")
                                        );
                                        CalculateFormulas();
                                      }}
                                      onChange={(e) => {
                                        field.onChange(
                                          e.target.value
                                            .replace(/[a-zA-Z]/g, "")
                                            .replace(/\s/g, "")
                                            .replace(/[,]/g, ".")
                                        );
                                        CalculateFormulas();
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            />
                          </div>
                        </div>

                        {/* Загрузка сотрудника */}
                        <div className="col-span-4">
                          <div className="flex rounded-md ">
                            <Controller
                              control={control}
                              name={
                                `pbtCalculatorPositions.${idx}.workload` as any
                              }
                              rules={{
                                required:
                                  "Нужно указать загрузку сотрудника в мес.",
                                min: 0,
                              }}
                              render={({ field, fieldState }) => (
                                <>
                                  <div className="col-span-full w-full">
                                    <LabeledTextInputWithTrailingAddon
                                      labelText="Загрузка сотрудника в мес."
                                      id={`pbtCalculatorPositions[${idx}]workload`}
                                      addonText="%"
                                      name={field.name}
                                      fieldState={fieldState}
                                      type="text"
                                      {...register(
                                        `pbtCalculatorPositions.${idx}.workload` as any
                                      )}
                                      className={
                                        "flex-1 min-w-0 block w-full px-3 py-2 rounded-none focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300"
                                      }
                                      value={field.value ?? 0}
                                      min="0"
                                      max="100"
                                      onBlur={(e) => {
                                        if (
                                          Math.round(
                                            (parseFloat(
                                              e.target.value
                                                .replace(/[a-zA-Z]/g, "")
                                                .replace(/\s/g, "")
                                            ) +
                                              Number.EPSILON) *
                                              100
                                          ) /
                                            100 >
                                          100
                                        ) {
                                          field.onChange(parseFloat("100"));
                                          CalculateFormulas();
                                        } else {
                                          if (
                                            Math.round(
                                              (parseFloat(
                                                e.target.value
                                                  .replace(/[a-zA-Z]/g, "")
                                                  .replace(/\s/g, "")
                                              ) +
                                                Number.EPSILON) *
                                                100
                                            ) /
                                              100 <
                                            0
                                          ) {
                                            field.onChange(parseFloat("0"));
                                            CalculateFormulas();
                                          } else {
                                            field.onChange(
                                              Math.round(
                                                (parseFloat(
                                                  e.target.value
                                                    .replace(/[a-zA-Z]/g, "")
                                                    .replace(/\s/g, "")
                                                ) +
                                                  Number.EPSILON) *
                                                  100
                                              ) / 100
                                            );
                                            CalculateFormulas();
                                            field.onChange(
                                              (
                                                Math.round(
                                                  (parseFloat(
                                                    e.target.value
                                                      .replace(/[a-zA-Z]/g, "")
                                                      .replace(/\s/g, "")
                                                  ) +
                                                    Number.EPSILON) *
                                                    100
                                                ) / 100
                                              ).toLocaleString()
                                            );
                                          }
                                        }
                                      }}
                                      onFocus={(e) => {
                                        field.onChange(
                                          e.target.value
                                            .replace(/[a-zA-Z]/g, "")
                                            .replace(/\s/g, "")
                                            .replace(/[,]/g, ".")
                                        );
                                        CalculateFormulas();
                                      }}
                                      onChange={(e) => {
                                        field.onChange(
                                          e.target.value
                                            .replace(/[a-zA-Z]/g, "")
                                            .replace(/\s/g, "")
                                            .replace(/[,]/g, ".")
                                        );
                                        CalculateFormulas();
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            />
                          </div>
                        </div>

                        <div className="col-span-1 mt-6">
                          <PrimaryButton
                            icon={XIcon({
                              className: "h-4 w-4",
                            })}
                            onClick={() => Premove(idx)}
                          ></PrimaryButton>
                        </div>
                      </>
                    </div>
                  ))}

                  <span
                    className="mt-2 mb-5 cursor-pointer text-sm text-red-800"
                    onClick={() =>
                      Pappend({
                        salary: parseFloat("0"),
                        workload: parseFloat("0"),
                        pbtPositionId: null,
                      })
                    }
                  >
                    Добавить сотрудника
                  </span>
                </div>
              </div>
              <hr className="my-8 h-px bg-gray-200 border-0 dark:bg-gray-700 col-span-12"></hr>
              <div className="mr-2 col-span-12 mb-5">
                {/* Заполнить примерные бюджеты по площадкам (За период) */}
                <div className="col-span-full">
                  <span className="block text-sm text-gray-700 font-bold mb-5">
                    Заполнить примерные бюджет по площадкам за весь период:
                  </span>

                  {BCfields.map((item, idx) => (
                    <div
                      className="grid grid-cols-12 gap-x-4 gap-y-1 mb-2"
                      key={item.id}
                    >
                      <>
                        {/* Баинговое условие, по которому происходит расчет */}
                        <div className="col-span-3">
                          <Controller
                            control={control}
                            rules={{
                              required:
                                "Нужно заполнить баинговое условие, по которому происходит расчет",
                            }}
                            name={
                              `pbtCalculatorBuyingConditions.${idx}.pbtBuyingConditionId` as any
                            }
                            render={({ field, fieldState }) => (
                              <ReactSelectInput
                                options={
                                  buyingConditionsData &&
                                  buyingConditionsData?.findAllBuyingConditions?.buyingConditions?.map(
                                    (bc) => {
                                      return {
                                        label: `${bc?.title}`,
                                        value: `${bc?.id} | ${
                                          1 -
                                          ((1 -
                                            bc?.agencyDiscount -
                                            bc?.discountBefore) *
                                            (1 -
                                              bc?.moneyback -
                                              bc?.discountAfter)) /
                                            (1 + bc?.bonus)
                                        }`,
                                      };
                                    }
                                  )
                                }
                                defaultValue={field.value}
                                name={`pbtCalculatorBuyingConditions.${idx}.pbtBuyingConditionId`}
                                id={`pbtCalculatorBuyingConditions.${idx}.pbtBuyingConditionId`}
                                value={field.value}
                                labelText="Условие закупки:"
                                onSelect={(e) => {
                                  setValue(
                                    `pbtCalculatorBuyingConditions.${idx}.pbtBuyingConditionId` as any,
                                    e
                                  );

                                  setValue(
                                    `pbtCalculatorBuyingConditions.${idx}.BCmarginality` as any,
                                    getValues().pbtCalculatorBuyingConditions[
                                      idx
                                    ]?.pbtBuyingConditionId
                                      ? !isNaN(
                                          Math.round(
                                            ((parseFloat(
                                              getValues()
                                                .pbtCalculatorBuyingConditions[
                                                  idx
                                                ]?.pbtBuyingConditionId?.toString()
                                                .substring(
                                                  getValues()
                                                    .pbtCalculatorBuyingConditions[
                                                      idx
                                                    ]?.pbtBuyingConditionId?.toString()
                                                    .indexOf("|") + 2
                                                )
                                            ) ?? parseFloat("0")) +
                                              Number.EPSILON) *
                                              10000
                                          ) / 100 ?? parseFloat("0")
                                        )
                                        ? Math.round(
                                            ((parseFloat(
                                              getValues()
                                                .pbtCalculatorBuyingConditions[
                                                  idx
                                                ]?.pbtBuyingConditionId?.toString()
                                                .substring(
                                                  getValues()
                                                    .pbtCalculatorBuyingConditions[
                                                      idx
                                                    ]?.pbtBuyingConditionId?.toString()
                                                    .indexOf("|") + 2
                                                )
                                            ) ?? parseFloat("0")) +
                                              Number.EPSILON) *
                                              10000
                                          ) / 100 ?? parseFloat("0")
                                        : parseFloat("0")
                                      : parseFloat("0")
                                  );

                                  setValue(
                                    `pbtCalculatorBuyingConditions.${idx}.marginality` as any,
                                    Math.round(
                                      ((getValues()
                                        .pbtCalculatorBuyingConditions[idx]
                                        ?.pbtBuyingConditionId
                                        ? !isNaN(
                                            Math.round(
                                              ((parseFloat(
                                                getValues()
                                                  .pbtCalculatorBuyingConditions[
                                                    idx
                                                  ]?.pbtBuyingConditionId?.toString()
                                                  .substring(
                                                    getValues()
                                                      .pbtCalculatorBuyingConditions[
                                                        idx
                                                      ]?.pbtBuyingConditionId?.toString()
                                                      .indexOf("|") + 2
                                                  )
                                              ) *
                                                Math.round(
                                                  (parseFloat(
                                                    replaceFormat(
                                                      getValues()
                                                        .pbtCalculatorBuyingConditions[
                                                        idx
                                                      ]?.budget
                                                    )
                                                  ) +
                                                    Number.EPSILON) *
                                                    100
                                                )) /
                                                100 +
                                                Number.EPSILON) *
                                                100
                                            ) /
                                              100 +
                                              Math.round(
                                                (((getValues()
                                                  .pbtCalculatorBuyingConditions[
                                                  idx
                                                ]?.akDiscount /
                                                  100) *
                                                  Math.round(
                                                    (parseFloat(
                                                      replaceFormat(
                                                        getValues()
                                                          .pbtCalculatorBuyingConditions[
                                                          idx
                                                        ]?.budget
                                                      )
                                                    ) +
                                                      Number.EPSILON) *
                                                      100
                                                  )) /
                                                  100 +
                                                  Number.EPSILON) *
                                                  100
                                              ) /
                                                100 -
                                              Math.round(
                                                (((getValues()
                                                  .pbtCalculatorBuyingConditions[
                                                  idx
                                                ]?.overallDiscount /
                                                  100) *
                                                  Math.round(
                                                    (parseFloat(
                                                      replaceFormat(
                                                        getValues()
                                                          .pbtCalculatorBuyingConditions[
                                                          idx
                                                        ]?.budget
                                                      )
                                                    ) +
                                                      Number.EPSILON) *
                                                      100
                                                  )) /
                                                  100 +
                                                  Number.EPSILON) *
                                                  100
                                              ) /
                                                100 ?? parseFloat("0")
                                          )
                                          ? Math.round(
                                              ((parseFloat(
                                                getValues()
                                                  .pbtCalculatorBuyingConditions[
                                                    idx
                                                  ]?.pbtBuyingConditionId?.toString()
                                                  .substring(
                                                    getValues()
                                                      .pbtCalculatorBuyingConditions[
                                                        idx
                                                      ]?.pbtBuyingConditionId?.toString()
                                                      .indexOf("|") + 2
                                                  )
                                              ) *
                                                Math.round(
                                                  (parseFloat(
                                                    replaceFormat(
                                                      getValues()
                                                        .pbtCalculatorBuyingConditions[
                                                        idx
                                                      ]?.budget
                                                    )
                                                  ) +
                                                    Number.EPSILON) *
                                                    100
                                                )) /
                                                100 +
                                                Number.EPSILON) *
                                                100
                                            ) /
                                              100 +
                                            Math.round(
                                              (((getValues()
                                                .pbtCalculatorBuyingConditions[
                                                idx
                                              ]?.akDiscount /
                                                100) *
                                                Math.round(
                                                  (parseFloat(
                                                    replaceFormat(
                                                      getValues()
                                                        .pbtCalculatorBuyingConditions[
                                                        idx
                                                      ]?.budget
                                                    )
                                                  ) +
                                                    Number.EPSILON) *
                                                    100
                                                )) /
                                                100 +
                                                Number.EPSILON) *
                                                100
                                            ) /
                                              100 -
                                            Math.round(
                                              (((getValues()
                                                .pbtCalculatorBuyingConditions[
                                                idx
                                              ]?.overallDiscount /
                                                100) *
                                                Math.round(
                                                  (parseFloat(
                                                    replaceFormat(
                                                      getValues()
                                                        .pbtCalculatorBuyingConditions[
                                                        idx
                                                      ]?.budget
                                                    )
                                                  ) +
                                                    Number.EPSILON) *
                                                    100
                                                )) /
                                                100 +
                                                Number.EPSILON) *
                                                100
                                            ) /
                                              100
                                          : parseFloat("0")
                                        : parseFloat("0")) +
                                        Number.EPSILON) *
                                        100
                                    ) / 100
                                  );

                                  CalculateFormulas();
                                }}
                                placeholder="Выберите условие из списка"
                                fieldState={fieldState}
                              />
                            )}
                          />
                        </div>

                        {/* Бюджет без НДС, без АК и Скидки */}
                        <div className="col-span-4">
                          <div className="flex rounded-md">
                            <Controller
                              control={control}
                              name={
                                `pbtCalculatorBuyingConditions.${idx}.budget` as any
                              }
                              rules={{
                                required:
                                  "Нужно указать бюджет без НДС, без АК и Скидки",
                                min: 0,
                              }}
                              render={({ field, fieldState }) => (
                                <>
                                  <div className="col-span-full w-full">
                                    <LabeledTextInputWithTrailingAddon
                                      labelText="Бюджет Без НДС / АК / Скидки"
                                      id={`pbtCalculatorBuyingConditions[${idx}]budget`}
                                      addonText="руб."
                                      name={field.name}
                                      fieldState={fieldState}
                                      type="text"
                                      {...register(
                                        `pbtCalculatorBuyingConditions.${idx}.budget` as any
                                      )}
                                      className={
                                        "flex-1 min-w-0 block w-full px-3 py-2 rounded-none focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300"
                                      }
                                      value={field.value.toLocaleString() ?? 0}
                                      min="0"
                                      onBlur={(e) => {
                                        CalculateFormulas();
                                        field.onChange(
                                          (
                                            Math.round(
                                              (parseFloat(
                                                e.target.value
                                                  .replace(/[a-zA-Z]/g, "")
                                                  .replace(/\s/g, "")
                                              ) +
                                                Number.EPSILON) *
                                                100
                                            ) / 100
                                          ).toLocaleString()
                                        );
                                        CalculateFormulas();
                                      }}
                                      onFocus={(e) => {
                                        CalculateFormulas();
                                        field.onChange(
                                          e.target.value
                                            .replace(/[a-zA-Z]/g, "")
                                            .replace(/\s/g, "")
                                            .replace(/[,]/g, ".")
                                        );
                                        CalculateFormulas();
                                      }}
                                      onChange={(e) => {
                                        field.onChange(
                                          e.target.value
                                            .replace(/[a-zA-Z]/g, "")
                                            .replace(/\s/g, "")
                                            .replace(/[,]/g, ".")
                                        );
                                        CalculateMarginality(idx);
                                        CalculateFormulas();
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            />
                          </div>
                        </div>

                        {/* АК, % */}
                        <div className="col-span-2">
                          <Controller
                            control={control}
                            name={
                              `pbtCalculatorBuyingConditions.${idx}.akDiscount` as any
                            }
                            rules={{
                              required: "Нужно указать АК",
                              min: 0,
                            }}
                            render={({ field }) => (
                              <>
                                <div className="col-span-full w-full">
                                  <label className="block text-sm font-medium text-gray-700">
                                    АК
                                  </label>
                                  <div className="flex rounded-md my-1">
                                    <input
                                      id={`pbtCalculatorBuyingConditions[${idx}]akDiscount`}
                                      type="number"
                                      min="0"
                                      step="0.01"
                                      {...register(
                                        `pbtCalculatorBuyingConditions.${idx}.akDiscount` as any
                                      )}
                                      className={
                                        "flex-1 min-w-0 block w-full px-3 py-2 rounded-none focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300"
                                      }
                                      name={field.name}
                                      value={
                                        !isNaN(parseFloat(field.value))
                                          ? parseFloat(field.value)
                                          : parseFloat("0")
                                      }
                                      onBlur={(e) => {
                                        const parsedValue = !isNaN(
                                          parseFloat(e.target.value)
                                        )
                                          ? parseFloat(e.target.value)
                                          : parseFloat("0");
                                        field.onChange(parsedValue);
                                      }}
                                      onFocus={(e) => {
                                        const parsedValue = !isNaN(
                                          parseFloat(e.target.value)
                                        )
                                          ? parseFloat(e.target.value)
                                          : parseFloat("0");
                                        field.onChange(parsedValue);
                                      }}
                                      onChange={(e) => {
                                        const parsedValue = !isNaN(
                                          parseFloat(e.target.value)
                                        )
                                          ? parseFloat(e.target.value)
                                          : parseFloat("0");
                                        field.onChange(parsedValue);
                                        CalculateMarginality(idx);
                                        CalculateFormulas();
                                      }}
                                    />
                                    <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                      %
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          />
                        </div>

                        {/* Скидка, % */}
                        <div className="col-span-2">
                          <Controller
                            control={control}
                            name={
                              `pbtCalculatorBuyingConditions.${idx}.overallDiscount` as any
                            }
                            rules={{
                              required: "Нужно указать скидку",
                              min: 0,
                            }}
                            render={({ field }) => (
                              <>
                                <div className="col-span-full w-full">
                                  <label className="block text-sm font-medium text-gray-700">
                                    Скидка
                                  </label>
                                  <div className="flex rounded-md my-1">
                                    <input
                                      id={`pbtCalculatorBuyingConditions[${idx}]overallDiscount`}
                                      type="number"
                                      min="0"
                                      {...register(
                                        `pbtCalculatorBuyingConditions.${idx}.overallDiscount` as any
                                      )}
                                      className={
                                        "flex-1 min-w-0 block w-full px-3 py-2 rounded-none focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300"
                                      }
                                      name={field.name}
                                      step="0.01"
                                      value={
                                        !isNaN(parseFloat(field.value))
                                          ? parseFloat(field.value)
                                          : parseFloat("0")
                                      }
                                      onBlur={(e) => {
                                        const parsedValue = !isNaN(
                                          parseFloat(e.target.value)
                                        )
                                          ? parseFloat(e.target.value)
                                          : parseFloat("0");
                                        field.onChange(parsedValue);
                                      }}
                                      onFocus={(e) => {
                                        const parsedValue = !isNaN(
                                          parseFloat(e.target.value)
                                        )
                                          ? parseFloat(e.target.value)
                                          : parseFloat("0");
                                        field.onChange(parsedValue);
                                      }}
                                      onChange={(e) => {
                                        const parsedValue = !isNaN(
                                          parseFloat(e.target.value)
                                        )
                                          ? parseFloat(e.target.value)
                                          : parseFloat("0");
                                        field.onChange(parsedValue);
                                        CalculateMarginality(idx);
                                        CalculateFormulas();
                                      }}
                                    />
                                    <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                      %
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          />
                        </div>

                        <div className="col-span-1 mt-6">
                          <PrimaryButton
                            icon={XIcon({
                              className: "h-4 w-4",
                            })}
                            onClick={() => BCremove(idx)}
                          ></PrimaryButton>
                        </div>

                        {/* Маржа */}
                        <div className="col-span-3">
                          <Controller
                            control={control}
                            name={
                              `pbtCalculatorBuyingConditions.${idx}.marginality` as any
                            }
                            render={({ field }) => (
                              <>
                                <div className="col-span-full w-full">
                                  <label className="block text-sm font-medium text-gray-700">
                                    Маржа
                                  </label>
                                  <div className="flex rounded-md my-1">
                                    <label
                                      id={`pbtCalculatorBuyingConditions.${idx}.marginality`}
                                      className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300"
                                      {...register(
                                        `pbtCalculatorBuyingConditions.${idx}.marginality` as any
                                      )}
                                    >
                                      {formatFloatNumber(
                                        CalculateMarginalityForLabel(
                                          `${idx}` as any
                                        )
                                      ) ?? parseFloat("0")}{" "}
                                      руб.
                                    </label>
                                  </div>
                                </div>
                              </>
                            )}
                          />
                        </div>

                        {/* Маржинальность площадки */}
                        <div className="col-span-3">
                          <Controller
                            control={control}
                            name={
                              `pbtCalculatorBuyingConditions.${idx}.BCmarginality` as any
                            }
                            render={({ field }) => (
                              <>
                                <div className="col-span-full w-full">
                                  <label className="block text-sm font-medium text-gray-700">
                                    Маржинальность площадки
                                  </label>
                                  <div className="flex rounded-md my-1">
                                    <label
                                      id={`pbtCalculatorBuyingConditions.${idx}.BCmarginality`}
                                      className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300"
                                    >
                                      {getValues()
                                        .pbtCalculatorBuyingConditions[idx]
                                        ?.pbtBuyingConditionId
                                        ? !isNaN(
                                            Math.round(
                                              ((parseFloat(
                                                getValues()
                                                  .pbtCalculatorBuyingConditions[
                                                    idx
                                                  ]?.pbtBuyingConditionId?.toString()
                                                  .substring(
                                                    getValues()
                                                      .pbtCalculatorBuyingConditions[
                                                        idx
                                                      ]?.pbtBuyingConditionId?.toString()
                                                      .indexOf("|") + 2
                                                  )
                                              ) ?? parseFloat("0")) +
                                                Number.EPSILON) *
                                                10000
                                            ) / 100 ?? parseFloat("0")
                                          )
                                          ? Math.round(
                                              ((parseFloat(
                                                getValues()
                                                  .pbtCalculatorBuyingConditions[
                                                    idx
                                                  ]?.pbtBuyingConditionId?.toString()
                                                  .substring(
                                                    getValues()
                                                      .pbtCalculatorBuyingConditions[
                                                        idx
                                                      ]?.pbtBuyingConditionId?.toString()
                                                      .indexOf("|") + 2
                                                  )
                                              ) ?? parseFloat("0")) +
                                                Number.EPSILON) *
                                                10000
                                            ) / 100 ?? parseFloat("0")
                                          : parseFloat("0")
                                        : parseFloat("0")}{" "}
                                      %
                                    </label>
                                  </div>
                                </div>
                              </>
                            )}
                          />
                        </div>
                      </>
                    </div>
                  ))}

                  <span
                    className="mt-2 mb-5 cursor-pointer text-sm text-red-800"
                    onClick={() =>
                      BCappend({
                        akDiscount: 0,
                        budget: 0,
                        overallDiscount: 0,
                        marginality: 0,
                        pbtBuyingConditionId: null,
                      })
                    }
                  >
                    Добавить примерный бюджет
                  </span>
                </div>
              </div>
            </div>

            <hr className="my-8 h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
            <span className="block text-sm text-gray-700 font-bold my-5">
              Расчетные значения:
            </span>
            <div className="mt-5 grid grid-cols-11 gap-6">
              {/* Общий бюджет проекта */}
              <div className="col-span-full sm:col-span-3">
                <div className="flex rounded-md ">
                  <Controller
                    control={control}
                    name={`pbtCalculator.overAllBudget` as any}
                    render={({ field }) => (
                      <>
                        <div className="col-span-full w-full">
                          <label className="block text-sm font-medium text-gray-700">
                            Общий бюджет проекта
                          </label>
                          <div className="flex rounded-md my-1">
                            <label
                              id={`pbtCalculator.overAllBudget`}
                              {...register(
                                `pbtCalculator.overAllBudget` as any
                              )}
                              className={
                                "flex-1 min-w-0 block w-full px-3 py-2 rounded-none focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300"
                              }
                            >
                              {formatFloatNumber(
                                calculateOverallArray(
                                  getValues().pbtCalculatorBuyingConditions?.map(
                                    (bc) => replaceFormat(bc?.budget)
                                  ),
                                  1
                                ) ?? parseFloat("0")
                              ) ?? parseFloat("0")}
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>

              {/* Общая маржа */}
              <div className="col-span-full sm:col-span-3">
                <div className="flex rounded-md">
                  <Controller
                    control={control}
                    name={`pbtCalculator.overAllMarginality` as any}
                    render={({ field }) => (
                      <>
                        <div className="col-span-full w-full">
                          <label className="block text-sm font-medium text-gray-700">
                            Общая маржа
                          </label>
                          <div className="flex rounded-md my-1">
                            <label
                              id={`pbtCalculator.overAllMarginality`}
                              {...register(
                                `pbtCalculator.overAllMarginality` as any
                              )}
                              className={
                                "flex-1 min-w-0 block w-full px-3 py-2 rounded-none focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300"
                              }
                            >
                              {formatFloatNumber(
                                calculateOverallArrayDivide(
                                  getValues().pbtCalculatorBuyingConditions?.map(
                                    (bc) =>
                                      (parseFloat(
                                        bc?.pbtBuyingConditionId
                                          ?.toString()
                                          .substring(
                                            bc?.pbtBuyingConditionId
                                              ?.toString()
                                              .indexOf("|") + 2
                                          )
                                      ) ?? parseFloat("0")) *
                                        parseFloat(replaceFormat(bc?.budget)) +
                                      (parseFloat(replaceFormat(bc?.budget)) *
                                        bc?.akDiscount) /
                                        100 -
                                      (bc?.overallDiscount / 100) *
                                        parseFloat(replaceFormat(bc?.budget))
                                  ),
                                  1
                                ) ?? parseFloat("0")
                              ) ?? parseFloat("0")}
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>

              {/* Маржа в мес (Среднее) */}
              <div className="col-span-full sm:col-span-3">
                <div className="flex rounded-md ">
                  <Controller
                    control={control}
                    name={`pbtCalculator.marginalityPerMonth` as any}
                    render={({ field }) => (
                      <>
                        <div className="col-span-full w-full">
                          <label className="block text-sm font-medium text-gray-700">
                            Маржа в мес (Среднее)
                          </label>
                          <div className="flex rounded-md my-1">
                            <label
                              id={`pbtCalculator.marginalityPerMonth`}
                              {...register(
                                `pbtCalculator.marginalityPerMonth` as any
                              )}
                              className={
                                "flex-1 min-w-0 block w-full px-3 py-2 rounded-none focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300"
                              }
                            >
                              {formatFloatNumber(
                                calculateOverallArrayDivide(
                                  getValues().pbtCalculatorBuyingConditions?.map(
                                    (bc) =>
                                      (parseFloat(
                                        bc?.pbtBuyingConditionId
                                          ?.toString()
                                          .substring(
                                            bc?.pbtBuyingConditionId
                                              ?.toString()
                                              .indexOf("|") + 2
                                          )
                                      ) ?? parseFloat("0")) *
                                        parseFloat(replaceFormat(bc?.budget)) +
                                      (parseFloat(replaceFormat(bc?.budget)) *
                                        bc?.akDiscount) /
                                        100 -
                                      (bc?.overallDiscount / 100) *
                                        parseFloat(replaceFormat(bc?.budget))
                                  ),
                                  calculatePeriodNumber(
                                    new Date(getValues().periodFrom),
                                    new Date(getValues().periodTo)
                                  )
                                ) ?? parseFloat("0")
                              ) ?? parseFloat("0")}
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>

              {/* % Маржинальности */}
              <div className="col-span-full sm:col-span-2">
                <div className="flex rounded-md ">
                  <Controller
                    control={control}
                    name={`pbtCalculator.marginalityPercent` as any}
                    render={({ field }) => (
                      <>
                        <div className="col-span-full w-full">
                          <label className="block text-sm font-medium text-gray-700">
                            % Маржинальности
                          </label>
                          <div className="flex rounded-md my-1">
                            <label
                              id={`pbtCalculator.marginalityPercent`}
                              {...register(
                                `pbtCalculator.marginalityPercent` as any
                              )}
                              className={
                                "flex-1 min-w-0 block w-full px-3 py-2 rounded-none focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300"
                              }
                            >
                              {formatFloatNumber(
                                Math.round(
                                  ((calculateOverallArray(
                                    getValues().pbtCalculatorBuyingConditions?.map(
                                      (bc) =>
                                        (parseFloat(
                                          bc?.pbtBuyingConditionId
                                            ?.toString()
                                            .substring(
                                              bc?.pbtBuyingConditionId
                                                ?.toString()
                                                .indexOf("|") + 2
                                            )
                                        ) ?? parseFloat("0")) *
                                          parseFloat(
                                            replaceFormat(bc?.budget)
                                          ) +
                                        (parseFloat(replaceFormat(bc?.budget)) *
                                          bc?.akDiscount) /
                                          100 -
                                        (bc?.overallDiscount / 100) *
                                          parseFloat(replaceFormat(bc?.budget))
                                    ),
                                    1
                                  ) ?? parseFloat("0")) /
                                    (calculateOverallArray(
                                      getValues().pbtCalculatorBuyingConditions?.map(
                                        (bc) => replaceFormat(bc?.budget)
                                      ),
                                      1
                                    ) ?? parseFloat("1")) +
                                    Number.EPSILON) *
                                    10000
                                ) / 100
                              ) ?? parseFloat("0")}
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>

              {/* ФОТ с коэф. */}
              <div className="col-span-full sm:col-span-3">
                <div className="flex rounded-md ">
                  <Controller
                    control={control}
                    name={`pbtCalculator.FOT` as any}
                    render={({ field }) => (
                      <>
                        <div className="col-span-full w-full">
                          <label className="block text-sm font-medium text-gray-700">
                            ФОТ с коэф.
                          </label>
                          <div className="flex rounded-md my-1">
                            <label
                              id={`pbtCalculator.FOT`}
                              {...register(`pbtCalculator.FOT` as any)}
                              className={
                                "flex-1 min-w-0 block w-full px-3 py-2 rounded-none focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300"
                              }
                            >
                              {getValues().pbtCalculatorPositions?.length
                                ? formatFloatNumber(
                                    summArray(
                                      getValues().pbtCalculatorPositions?.map(
                                        (period) =>
                                          calculatePeriodMonths(
                                            replaceFormat(period?.salary),
                                            replaceFormat(
                                              period?.workload / 100
                                            )
                                          )
                                      )
                                    )
                                  ) ?? parseFloat("0")
                                : parseFloat("0")}
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>

              {/* Общий PBT */}
              <div className="col-span-full sm:col-span-3">
                <div className="flex rounded-md ">
                  <Controller
                    control={control}
                    name={`pbtCalculator.overallPBT` as any}
                    render={({ field }) => (
                      <>
                        <div className="col-span-full w-full">
                          <label className="block text-sm font-medium text-gray-700">
                            Общий PBT
                          </label>
                          <div className="flex rounded-md my-1">
                            <label
                              id={`pbtCalculator.overallPBT`}
                              {...register(`pbtCalculator.overallPBT` as any)}
                              className={
                                "flex-1 min-w-0 block w-full px-3 py-2 rounded-none focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300"
                              }
                            >
                              {" "}
                              {formatFloatNumber(
                                (calculateOverallArray(
                                  getValues().pbtCalculatorBuyingConditions?.map(
                                    (bc) =>
                                      (parseFloat(
                                        bc?.pbtBuyingConditionId
                                          ?.toString()
                                          .substring(
                                            bc?.pbtBuyingConditionId
                                              ?.toString()
                                              .indexOf("|") + 2
                                          )
                                      ) ?? parseFloat("0")) *
                                        parseFloat(replaceFormat(bc?.budget)) +
                                      (parseFloat(replaceFormat(bc?.budget)) *
                                        bc?.akDiscount) /
                                        100 -
                                      (bc?.overallDiscount / 100) *
                                        parseFloat(replaceFormat(bc?.budget))
                                  ),
                                  1
                                ) ?? parseFloat("0")) -
                                  (getValues().pbtCalculatorPositions?.length
                                    ? summArray(
                                        getValues().pbtCalculatorPositions?.map(
                                          (period) =>
                                            calculatePeriodMonths(
                                              replaceFormat(period?.salary),
                                              replaceFormat(
                                                period?.workload / 100
                                              )
                                            )
                                        )
                                      )
                                    : parseFloat("1"))
                              ) ?? parseFloat("0")}
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>

              {/* PBT в мес. (среднее) */}
              <div className="col-span-full sm:col-span-3">
                <div className="flex rounded-md ">
                  <Controller
                    control={control}
                    name={`pbtCalculator.PBTDividedByMonths` as any}
                    render={({ field }) => (
                      <>
                        <div className="col-span-full w-full">
                          <label className="block text-sm font-medium text-gray-700">
                            PBT в мес. (среднее)
                          </label>
                          <div className="flex rounded-md my-1">
                            <label
                              id={`pbtCalculator.PBTDividedByMonths`}
                              {...register(
                                `pbtCalculator.PBTDividedByMonths` as any
                              )}
                              className={
                                "flex-1 min-w-0 block w-full px-3 py-2 rounded-none focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300"
                              }
                            >
                              {formatFloatNumber(
                                ((calculateOverallArray(
                                  getValues().pbtCalculatorBuyingConditions?.map(
                                    (bc) =>
                                      (parseFloat(
                                        bc?.pbtBuyingConditionId
                                          ?.toString()
                                          .substring(
                                            bc?.pbtBuyingConditionId
                                              ?.toString()
                                              .indexOf("|") + 2
                                          )
                                      ) ?? parseFloat("0")) *
                                        parseFloat(replaceFormat(bc?.budget)) +
                                      (parseFloat(replaceFormat(bc?.budget)) *
                                        bc?.akDiscount) /
                                        100 -
                                      (bc?.overallDiscount / 100) *
                                        parseFloat(replaceFormat(bc?.budget))
                                  ),
                                  1
                                ) ?? parseFloat("0")) -
                                  (getValues().pbtCalculatorPositions?.length
                                    ? summArray(
                                        getValues().pbtCalculatorPositions?.map(
                                          (period) =>
                                            calculatePeriodMonths(
                                              replaceFormat(period?.salary),
                                              replaceFormat(
                                                period?.workload / 100
                                              )
                                            )
                                        )
                                      )
                                    : parseFloat("1"))) /
                                  calculatePeriodNumber(
                                    new Date(getValues().periodFrom),
                                    new Date(getValues().periodTo)
                                  )
                              ) ?? parseFloat("0")}
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>

              {/* % PBT */}
              <div className="col-span-full sm:col-span-2">
                <div className="flex rounded-md ">
                  <Controller
                    control={control}
                    name={`pbtCalculator.PBTpercent` as any}
                    render={({ field }) => (
                      <>
                        <div className="col-span-full w-full">
                          <label className="block text-sm font-medium text-gray-700">
                            % PBT
                          </label>
                          <div className="flex rounded-md my-1">
                            <label
                              id={`pbtCalculator.PBTpercent`}
                              {...register(`pbtCalculator.PBTpercent` as any)}
                              className={
                                "flex-1 min-w-0 block w-full px-3 py-2 rounded-none focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300"
                              }
                            >
                              {formatFloatNumber(
                                Math.round(
                                  (((calculateOverallArray(
                                    getValues().pbtCalculatorBuyingConditions?.map(
                                      (bc) =>
                                        (parseFloat(
                                          bc?.pbtBuyingConditionId
                                            ?.toString()
                                            .substring(
                                              bc?.pbtBuyingConditionId
                                                ?.toString()
                                                .indexOf("|") + 2
                                            )
                                        ) ?? parseFloat("0")) *
                                          parseFloat(
                                            replaceFormat(bc?.budget)
                                          ) +
                                        (parseFloat(replaceFormat(bc?.budget)) *
                                          bc?.akDiscount) /
                                          100 -
                                        (bc?.overallDiscount / 100) *
                                          parseFloat(replaceFormat(bc?.budget))
                                    ),
                                    1
                                  ) ?? parseFloat("0")) -
                                    (getValues().pbtCalculatorPositions?.length
                                      ? summArray(
                                          getValues().pbtCalculatorPositions?.map(
                                            (period) =>
                                              calculatePeriodMonths(
                                                replaceFormat(period?.salary),
                                                replaceFormat(
                                                  period?.workload / 100
                                                )
                                              )
                                          )
                                        )
                                      : parseFloat("1"))) /
                                    calculateOverallArray(
                                      getValues().pbtCalculatorBuyingConditions?.map(
                                        (bc) => replaceFormat(bc?.budget)
                                      ),
                                      1
                                    ) +
                                    Number.EPSILON) *
                                    10000
                                ) / 100
                              ) ?? parseFloat("0")}
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-x-3">
        <PrimaryButton type="submit">Сохранить</PrimaryButton>
      </div>
    </form>
  ) : (
    <DataLoader />
  );
};
