import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { adminMenuRoutes } from "../../../../navigation/routes";
import { DeleteModal } from "../../../../components";
import {
  deleteClient,
  deleteClientVariables,
} from "../../../../graphql/__generated-types__/deleteClient";
import { DELETE_CLIENT_MUTATION } from "../../../../graphql/mutations";

const AdminClientDelete = ({ show, showFn, client, closeModal }) => {
  const navigate = useNavigate();

  const [deleteClient] = useMutation<deleteClient, deleteClientVariables>(
    DELETE_CLIENT_MUTATION,
    {
      onCompleted: (deleteClientData) => {
        if (deleteClientData?.deleteClient?.ok) {
          navigate(adminMenuRoutes.clients.path);
        } else {
          toast.error(deleteClientData?.deleteClient?.error);
        }
      },
      update(cache, { data: { deleteClient } }) {
        if (deleteClient?.ok) {
          cache.evict({ id: cache.identify(client) });
          cache.gc();
        }
      },
    }
  );

  const onSubmit = async () => {
    await deleteClient({
      variables: {
        input: {
          id: client.id,
        },
      },
    });
    closeModal();
  };

  return (
    <DeleteModal
      show={show}
      showFn={showFn}
      headerText="Удаление клиента"
      text="Вы уверены, что хотите удалить клиента?"
      onClose={closeModal}
      onSubmit={onSubmit}
    />
  );
};
export default AdminClientDelete;
