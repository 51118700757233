import { gql } from "graphql.macro";
import { RANDOM_COFFEE_FRAGMENT } from "../fragments";

export const GET_CURRENT_RANDOM_COFFEE_QUERY = gql`
  query getCurrentRandomCoffee {
    getCurrentRandomCoffee {
      ok
      error
      randomCoffee {
        ...RandomCoffeeParts
      }
    }
  }
  ${RANDOM_COFFEE_FRAGMENT}
`;
