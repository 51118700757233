import { gql } from "@apollo/client";
import { BUYING_CONDITION_FRAGMENT } from "graphql/fragments";

export const CREATE_BUYING_CONDITION_MUTATION = gql`
  mutation createBuyingCondition($input: CreateBuyingConditionInput!) {
    createBuyingCondition(input: $input) {
      ok
      error
      buyingCondition {
        ...BuyingConditionParts
      }
    }
  }
  ${BUYING_CONDITION_FRAGMENT}
`;
