import { gql } from "@apollo/client";
import {PBT_REPORT_FRAGMENT} from "../fragments";

export const FIND_ALL_PBT_REPORTS_QUERY = gql`
  query findAllPbtReports($input: FindAllPbtReportsInput!) {
    findAllPbtReports(input: $input) {
      ok
      error
      pbtReports {
        ...PbtReportParts
      }
      totalPages
      totalResults
    }
  }
  ${PBT_REPORT_FRAGMENT}
`;
