import { Controller, useForm } from "react-hook-form";
import {
  Button,
  ImageInput,
  LabeledTextInput,
  PrimaryButton,
  ReactSelectInput,
} from "../../../../../components";
import { PostParts } from "graphql/__generated-types__/PostParts";
import { PostType } from "graphql/__generated-types__/globalTypes";
import { postTypeMap } from "config/mappings";
import ReactMde, { SaveImageHandler } from "react-mde";
import ReactMarkdown from "react-markdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import { UPLOAD_ROUTE } from "config/constants";
import { useState } from "react";

export interface IPostForm {
  header: string;
  underHeader?: string;
  text?: string;
  imageURL: string;
  postType: PostType;
  priorityOrder: number;
  addedById: number;
}

interface IPostFormProps {
  post: PostParts;
  onSubmit: (data: any) => void;
  onClose: () => void;
}

export const PostForm: React.FC<IPostFormProps> = ({
  post,
  onSubmit,
  onClose,
}) => {
  const label = post ? "Редактирование новости" : "Добавление новости";

  const { handleSubmit, control, setValue } = useForm<IPostForm>({
    mode: "onSubmit",
    shouldUnregister: true,
    defaultValues: post
      ? {
          ...post,
          header: post?.header,
          underHeader: post?.underHeader,
          text: post?.text,
          imageURL: post?.imageURL,
          addedById: post?.addedBy?.id,
          postType: post?.postType,
        }
      : {},
  });

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  const saveImage: SaveImageHandler = async function* (
    _data: ArrayBuffer,
    file: Blob
  ) {
    try {
      let blobToFile = new File(
        [file],
        `Post-${new Date().toISOString().replace(/(\d)[\s.]+(?=\d)/g, "$1")}.${
          file.type.split("/")[1]
        }`,
        { type: "file" }
      );
      let fileToUpload = null;
      fileToUpload = blobToFile;
      const formBody = new FormData();
      formBody.append("file", fileToUpload);
      await (
        await fetch(`${UPLOAD_ROUTE}/post`, {
          method: "POST",
          body: formBody,
        })
      ).json();
      yield `https://storage.yandexcloud.net/artics-portal-uploads/posts/images/${fileToUpload.name}`;
      return (
        `https://storage.yandexcloud.net/artics-portal-uploads/posts/images/${fileToUpload.name}${fileToUpload.type}` !==
        ""
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <form className="space-y-6 mx-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {label}
              </h3>
            </div>

            <div className="grid grid-cols-12 gap-6"></div>
            <div className="mt-5 md:mt-0 md:col-span-12">
              <div className="grid grid-cols-2 gap-6">
                <div className="row-span-1 flex-grid grid-rows-2">
                  <Controller
                    control={control}
                    name="imageURL"
                    rules={{ required: "Нужно загрузить фоновое изображение" }}
                    render={({ field, fieldState }) => (
                      <ImageInput
                        labelText="Изображение"
                        image={field.value}
                        field={field}
                        name={field.name}
                        alt={`Post-${new Date().toISOString()}`}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </div>
                <div className="flex-grid grid-row-4 grid-cols-12">
                  <div className="col-span-12">
                    <Controller
                      control={control}
                      name="header"
                      rules={{
                        required: "Нужно заполнить заголовок новости",
                      }}
                      render={({ field, fieldState }) => (
                        <LabeledTextInput
                          labelText="Заголовок новости"
                          value={field.value === null ? "" : field.value}
                          onChange={field.onChange}
                          name={field.name}
                          fieldState={fieldState}
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-12">
                    <Controller
                      control={control}
                      name="underHeader"
                      rules={{
                        required: "Нужно заполнить подзаголовок новости",
                      }}
                      render={({ field, fieldState }) => (
                        <LabeledTextInput
                          labelText="Подзаголовок новости"
                          value={field.value === null ? "" : field.value}
                          onChange={field.onChange}
                          name={field.name}
                          fieldState={fieldState}
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-12">
                    <Controller
                      control={control}
                      name="postType"
                      rules={{
                        required: "Нужно заполнить тип новости",
                      }}
                      render={({ field, fieldState }) => (
                        <ReactSelectInput
                          options={Object.values(PostType).map((cs) => {
                            return {
                              label: postTypeMap[cs],
                              value: cs,
                            };
                          })}
                          defaultValue={field.value}
                          name="postType"
                          value={field.value}
                          labelText="Тип новости"
                          onSelect={(e) => setValue("postType", e)}
                          placeholder="Тип новости"
                          fieldState={fieldState}
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-12 mt-5" id="MarkdownMdeForm">
                    <Controller
                      control={control}
                      name="text"
                      rules={{
                        required: "Нужно заполнить текст новости",
                      }}
                      render={({ field, fieldState }) => (
                        <ReactMde
                          classes={{
                            textArea:
                              "focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md",
                          }}
                          l18n={{
                            write: "Редактирование",
                            preview: "Предпросмотр",
                            uploadingImage: "Загрузка",
                            pasteDropSelect:
                              "Прикрепите изображение нажав сюда, или перетащите изображение на поле ввода",
                          }}
                          toolbarCommands={[
                            [
                              "bold",
                              "italic",
                              "header",
                              "quote",
                              "image",
                              "link",
                              "unordered-list",
                              "checked-list",
                            ],
                          ]}
                          value={field.value === null ? "" : field.value}
                          onChange={field.onChange}
                          selectedTab={selectedTab}
                          onTabChange={setSelectedTab}
                          generateMarkdownPreview={(markdown) =>
                            Promise.resolve(
                              <ReactMarkdown children={markdown} />
                            )
                          }
                          childProps={{
                            writeButton: {
                              tabIndex: -1,
                              id: "writeButton",
                            },
                            previewButton: {
                              id: "previewButton",
                            },
                          }}
                          paste={{
                            saveImage: saveImage,
                            accept: "image/jpeg,image/png,image/gif",
                            multiple: false,
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-x-3">
          <Button type="button" onClick={() => onClose()}>
            Отмена
          </Button>
          <PrimaryButton type="submit">Сохранить</PrimaryButton>
        </div>
      </form>
    </>
  );
};
