import { DataLoader, Layout } from "components";
import { UserRole } from "graphql/__generated-types__/globalTypes";
import { useMe } from "hooks";
import AdminLayout from "pages/Admin/AdminLayout";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import NotFound from "./NotFound";
import {
  activityRoutes,
  adminMenuRoutes,
  adminPagesRoutes,
  casePagesRoutes,
  cashbackRulesRoutes,
  commonRoutes,
  mainMenuRoutes,
  newsMenuRoutes,
  newsMenuServicesRoutes,
  pbtCalculatorPagesRoutes,
  serviceMenuRoutes
} from "./routes";
import ServiceLayout from "../pages/Service/ServiceLayout";
import { NovuProvider } from "@novu/notification-center";
import {useEffect} from "react";
import {logoutUser} from "../services/apollo";

export const LoggedInRouter = () => {
  const { data, loading, error } = useMe();

  useEffect(() => {
    if (data && data.me.ok && !data.me.user.isActive) {
      logoutUser();
    }
  }, [data]);

  if (!data || loading || error) {
    return <DataLoader />;
  }
  return (
    <NovuProvider
      backendUrl={process.env.REACT_APP_NOVU_API_BACKEND}
      socketUrl={process.env.REACT_APP_NOVU_WS_BACKEND}
      subscriberId={data.me.user.id.toString()}
      applicationIdentifier={process.env.REACT_APP_NOVU_APP_ID}
      i18n={{
        translations: {
          notifications: "Уведомления",
          markAllAsRead: "Отметить все сообщения прочитанными",
          poweredBy: "работает на",
        },
        lang: "ru",
      }}
    >
      <Router>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={mainMenuRoutes.news.path} />}
          />
          {Object.entries(casePagesRoutes).map(
            ([_, { path, title, component }]) => (
              <Route
                key={path}
                path={path}
                element={<Layout>{component}</Layout>}
              />
            )
          )}
          {Object.entries(commonRoutes).map(([_, { path, component }]) => (
            <Route
              key={path}
              path={path}
              element={<Layout>{component}</Layout>}
            />
          ))}
          {Object.entries(mainMenuRoutes).map(
            ([_, { path, component }]) => (
              <Route
                key={path}
                path={path}
                element={<Layout>{component}</Layout>}
              />
            )
          )}
          {Object.entries(activityRoutes).map(
            ([_, { path, component }]) => (
              <Route
                key={path}
                path={path}
                element={<Layout>{component}</Layout>}
              />
            )
          )}
          {Object.entries(serviceMenuRoutes).map(
            ([_, { path, title, component }]) => (
              <Route
                key={path}
                path={path}
                element={
                  <Layout>
                    <ServiceLayout title={title}>{component}</ServiceLayout>
                  </Layout>
                }
              />
            )
          )}
          {Object.entries(newsMenuRoutes).map(
            ([_, { path, title, component }]) => (
              <Route
                key={path}
                path={path}  
                element={<Layout>{component}</Layout>}
              />
            )
          )}
          {(data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN) || data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) || data?.me?.user?.roles.includes(UserRole.BUYING_USER)) && (
            <>
              {Object.entries(cashbackRulesRoutes).map(
                ([_, { path, title, component }]) => (
                  <Route
                    key={path}
                    path={path}  
                    element={
                      <Layout>
                      <ServiceLayout title={title}>{component}</ServiceLayout>
                    </Layout>
                  }
                  />
                )
              )}
            </> 
          )}
          {Object.entries(adminMenuRoutes).map(
            ([_, { path, title, component }]) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <Layout>
                      <AdminLayout title={title}>{component}</AdminLayout>
                    </Layout>
                  }
                />
                )
              )}
          {(data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN) || data?.me?.user?.roles.includes(UserRole.HR_ADMIN)) && (
            <>
              {Object.entries(adminPagesRoutes).map(
                ([_, { path, title, component }]) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <Layout>
                        <AdminLayout title={title}>{component}</AdminLayout>
                      </Layout>
                    }
                  />
                )
              )}
            </>
          )}
          {Object.entries(serviceMenuRoutes).map(
            ([_, { path, title, component }]) => (
              <Route
                key={path}
                path={path}
                element={
                  <Layout>
                    <ServiceLayout title={title}>{component}</ServiceLayout>
                  </Layout>
                }
              />
            )
          )}
          {Object.entries(newsMenuServicesRoutes).map(
            ([_, { path, component }]) => (
              <Route
                key={path}
                path={path}  
                element={<Layout>{component}</Layout>}
              />
            )
            )}
          {Object.entries(pbtCalculatorPagesRoutes).map(
            ([_, { path, title, component }]) => (
              <Route
                key={path}
                path={path}  
                element={
                  <Layout>
                    <ServiceLayout title={title}>{component}</ServiceLayout>
                  </Layout>
                }
              />
            )
            )}
          <Route
            path="*"
            element={
              <Layout>
                <NotFound />
              </Layout>
            }
          />
        </Routes>
      </Router>
    </NovuProvider>
  );
};
