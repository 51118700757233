import { StoreObject, useMutation } from "@apollo/client";
import { INVOICE_FRAGMENT } from "graphql/fragments";
import { CREATE_INVOICE_MUTATION } from "graphql/mutations/createInvoice";
import { createInvoice, createInvoiceVariables } from "graphql/__generated-types__/createInvoice";
import { adminMenuRoutes } from "navigation/routes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IInvoiceForm, InvoiceForm } from "../components/InvoiceForm";

const InvoiceCreate = ({ onClose }) => {
  const navigate = useNavigate();
  const [createInvoice] = useMutation<
    createInvoice,
    createInvoiceVariables
  >(CREATE_INVOICE_MUTATION, {
    onCompleted: (createInvoiceData) => {
      if (createInvoiceData?.createInvoice?.ok) {
        navigate(adminMenuRoutes?.invoices?.path);
      } else {
        toast.error(createInvoiceData?.createInvoice?.error);
      }
    },
    update(cache, { data: { createInvoice } }) {
      if (createInvoice?.invoice?.id) {
        const newInvoiceRef = cache.writeFragment({
          // @ts-ignore
          id: cache.identify(
            createInvoice?.invoice as unknown as StoreObject
          ),
          data: createInvoice?.invoice,
          fragment: INVOICE_FRAGMENT,
          fragmentName: "InvoiceParts",
        });
        cache.modify({
          id: "ROOT_QUERY",
          fields: {
            findAllInvoices(prev) {
              return {
                ...prev,
                invoices: [
                  newInvoiceRef,
                  ...prev.invoices,
                ],
              };
            },
          },
        });
      }
    },
  });

  const onSubmit = async (data: IInvoiceForm) => {
    let responseData;
    if (data?.initialInvoices?.length) {
      responseData = await createInvoice({
        variables: {
          input: {
            ...data,
            initialInvoices: data?.initialInvoices?.map((intInvoice) => ({
              invoiceNumber: data?.invoiceNumber,
              invoiceDate: data?.invoiceDate,
              invoiceSumAmount: +intInvoice?.invoiceSumAmount,
              initialContractInvoiceId: +intInvoice?.initialContractInvoiceId,
              isVat: intInvoice?.isVat,
              finalContractInvoiceId: +intInvoice?.finalContractInvoiceId,
            })),
          },
        },
      });
    } else {
      responseData = await createInvoice({
        variables: {
          input: {
            ...data,
          },
        },
      });
    }
    if (responseData?.data?.createInvoice?.ok) {
      onClose(true);
    }
  };
  return (
    <InvoiceForm
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default InvoiceCreate;
