import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation loginMutation($loginInput: LoginInput!) {
    login(input: $loginInput) {
      ok
      error
      token
      me {
        id
        name
        surname
        email
        avatar
        roles
        position {
          id
          title
        }
      }
    }
  }
`;
