import { gql } from "@apollo/client";

export const DELETE_BUYING_CASHBACK_TRANSACTION_MUTATION = gql`
  mutation deleteBuyingCashbackTransaction(
    $input: DeleteBuyingCashbackTransactionInput!
  ) {
    deleteBuyingCashbackTransaction(input: $input) {
      ok
      error
    }
  }
`;
