import { useParams } from "react-router";
import { ICaseEditParams } from "../Vote";
import { useQuery } from "@apollo/client";
import {
  findOneCase,
  findOneCaseVariables,
} from "../../../graphql/__generated-types__/findOneCase";
import { FIND_ONE_CASE_QUERY } from "../../../graphql/queries";
import { DataLoader, PrimaryButton } from "../../../components";
import { formatFullName } from "../../../utils";
import { DownloadIcon } from "@heroicons/react/outline";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const CaseView = () => {
  const params = useParams<keyof ICaseEditParams>();

  const { loading: caseLoading, data: caseData } = useQuery<
    findOneCase,
    findOneCaseVariables
  >(FIND_ONE_CASE_QUERY, {
    variables: {
      input: {
        id: +params.id,
      },
    },
  });

  return !caseLoading ? (
    <div className="relative px-4 sm:px-6 lg:px-8 py-5">
      <div className="relative max-w-4xl mx-auto py-10">
        <div>
          <h2 className="text-xl leading-6 font-medium text-gray-900">
            Информация о кейсе
          </h2>
        </div>

        <div className="mt-5 border-t border-gray-200">
          <dl className="divide-y divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-base font-medium text-gray-500">
                Название кейса
              </dt>
              <dd className="mt-1 flex text-base text-gray-900 sm:mt-0 sm:col-span-2">
                <p>{caseData?.findOneCase?.battleCase?.title}</p>
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-base font-medium text-gray-500">
                Описание кейса
              </dt>
              <dd className="mt-1 flex text-base text-gray-900 sm:mt-0 sm:col-span-2">
                <p>{caseData?.findOneCase?.battleCase?.description}</p>
              </dd>
            </div>

            <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt className="text-base font-medium text-gray-500">
                Участники кейса
              </dt>
              <dd className="mt-1 flex text-base text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="flex flex-col justify-between">
                  {caseData?.findOneCase?.battleCase?.participants.map(
                    (user) => (
                      <div key={user.id} className="mt-6 flex items-center">
                        <div className="flex-shrink-0">
                          <div>
                            <img
                              className="h-10 w-10 rounded-full object-cover"
                              src={user?.avatar}
                              alt=""
                            />
                          </div>
                        </div>

                        <div className="ml-3">
                          <p className="text-sm font-medium text-gray-900">
                            {formatFullName(user)}
                          </p>
                          <div className="flex space-x-1 text-sm text-gray-500">
                            <span>{user?.position?.title}</span>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </dd>
            </div>

            <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt className="text-base font-medium text-gray-500">
                Презентация кейса
              </dt>
              <dd className="mt-1 flex text-base text-gray-900 sm:mt-0 sm:col-span-2">
                <a
                  href={caseData?.findOneCase?.battleCase?.presentation}
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  <PrimaryButton
                    icon={DownloadIcon({
                      className: "h-4 w-4",
                      "aria-hidden": true,
                    })}
                  >
                    <span className="px-1">
                      {caseData?.findOneCase?.battleCase?.presentation.includes(
                        "storage.googleapis.com"
                      )
                        ? "Скачать презентацию"
                        : "Перейти на презентацию"}
                    </span>
                  </PrimaryButton>
                </a>
              </dd>
            </div>
          </dl>
        </div>

        {caseData?.findOneCase?.battleCase?.presentation.includes(
          "storage.googleapis.com"
        ) && (
          <>
            <div>
              <dt className="text-base font-medium text-gray-500">
                Просмотр презентации кейса
              </dt>
            </div>

            <div
              style={{
                border: "1px solid rgba(0, 0, 0, 0.3)",
                height: "750px",
              }}
            >
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={caseData?.findOneCase?.battleCase?.presentation}
                />
              </Worker>
            </div>
          </>
        )}
      </div>
    </div>
  ) : (
    <DataLoader />
  );
};

export default CaseView;
