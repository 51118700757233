import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";
import {
  Button,
  DataLoader,
  LabeledDateInput,
  LabeledTextInput,
  LabeledTextInputWithTrailingAddon,
  LabeledToggleInput,
  PrimaryButton,
  ReactSelectInput,
} from "components";
import { ContractActionType, ContractSubjectType, ContractType } from "graphql/__generated-types__/globalTypes";
import { ContractParts } from "graphql/__generated-types__/ContractParts";
import { useQuery } from "@apollo/client";
import { listLegalnames, listLegalnamesVariables } from "graphql/__generated-types__/listLegalnames";
import { listClientProjects, listClientProjectsVariables } from "graphql/__generated-types__/listClientProjects";
import { LIST_CLIENT_PROJECTS_QUERY, LIST_LEGALNAMES_QUERY } from "graphql/queries";
import { contractActionTypeStatusMap, contractSubjectTypeStatusMap, contractTypeStatusMap } from "config/mappings";

export interface IFinalContractForm {
  contractNumber: string;
  contractDate: Date;
  clientProjectsIds?: number[];
  contractorLegalnameId?: number;
  customerLegalnameId?: number;
  isVatIncluded: boolean;
  contractType: ContractType;
  contractSubjectType: ContractSubjectType;
  contractActionType?: ContractActionType;
  contractSumAmount?: number;
  metaInfo?: string;
  isORD: boolean;
}

interface IFinalContractFormProps {
  contract?: ContractParts;
  onSubmit: (data: any) => void;
  onClose: () => void;
}

export const FinalContractForm: React.FC<IFinalContractFormProps> = ({
  contract,
  onSubmit,
  onClose,
}) => {
  const label = contract
    ? "Редактирование конечного договора"
    : "Добавление конечного договора";

  const { data: legalnamesData, loading: legalnamesLoading } = useQuery<
    listLegalnames,
    listLegalnamesVariables
  >(LIST_LEGALNAMES_QUERY, { variables: { input: {} } });
  
  const { data: ourLegalnamesData, loading: ourLegalnamesLoading } = useQuery<
    listLegalnames,
    listLegalnamesVariables
  >(LIST_LEGALNAMES_QUERY, { variables: { input: {isArtics: true} } }); 

  const { data: clientProjectsData, loading: clientProjectsLoading } = useQuery<
    listClientProjects,
    listClientProjectsVariables
  >(LIST_CLIENT_PROJECTS_QUERY, { variables: { input: {includeClients: true} } });

  const { handleSubmit, control, setValue } = useForm<IFinalContractForm>({
    mode: "onSubmit",
    shouldUnregister: true,
    defaultValues: contract
      ? {
          ...contract,
          contractorLegalnameId: contract?.contractorLegalname?.id,
          customerLegalnameId: contract?.customerLegalname?.id,
          isVatIncluded: contract?.isVatIncluded,
          contractDate: contract?.contractDate,
          clientProjectsIds: contract?.clientProjects?.map((cp) => cp?.id), 
          contractType: contract?.contractType,
          contractActionType: contract?.contractActionType,
          contractSubjectType: contract?.contractSubjectType,
          contractNumber: contract?.contractNumber,
          contractSumAmount: contract?.contractSumAmount,
          metaInfo: contract?.metaInfo,
          isORD: contract?.isORD,
        }
      : {
        contractNumber: null,
        contractDate: new Date(new Date().setUTCHours(0, 0, 0, 0)).toISOString(),
        clientProjectsIds: [],
        contractorLegalnameId: null,
        customerLegalnameId: null,
        isVatIncluded: true,
        contractType: null,
        contractSubjectType: null,
        contractActionType: null,
        contractSumAmount: null,
        metaInfo: null,
        isORD: false,
      },
  });

  const legalnamesOptions =
    !legalnamesLoading &&
    legalnamesData?.listLegalnames?.legalnames?.map((legalname) => ({
      label: `${legalname?.title}`,
      value: legalname?.id,
    }));
    
  const ourLegalnamesOptions =
  !ourLegalnamesLoading &&
  ourLegalnamesData?.listLegalnames?.legalnames?.map((legalname) => ({
    label: `${legalname?.title}`,
    value: legalname?.id,
  }));

    const clientProjectsOptions =
    !clientProjectsLoading &&
    clientProjectsData?.listClientProjects?.projects?.map((projects) => ({
      label: `${projects?.client?.title}: ${projects?.title}`,
      value: projects?.id,
    }));

    
  const [isActionTypeDisabled, setIsActionTypeDisabled] = useState(true);

  return  !legalnamesLoading &&
          !ourLegalnamesLoading &&
          !clientProjectsLoading ? ( 
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-3">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {label}
            </h3>
          </div>

          <div className="mt-5 md:mt-0 md:col-span-3">
            <div className="grid grid-cols-6 gap-6">

              <div className="col-span-3">
                <Controller
                  control={control}
                  rules={{ required: "Нужно заполнить клиентов/проекты по конечному договору" }}
                  name="clientProjectsIds"
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      isMulti={true}
                      labelText="Клиенты/Проекты по договору"
                      value={field.value}
                      field={field}
                      fieldState={fieldState}
                      options={clientProjectsOptions}
                      name={field.name}
                      defaultValue={clientProjectsOptions?.filter((option) =>
                        field?.value?.some((id) => id === option?.value)
                      )}
                      onSelect={(e) => {
                        setValue(
                          "clientProjectsIds",
                          e?.map((option) => option?.value)
                        );
                      }}
                      placeholder={"Выберите клиентов/проекты по конечному договору"}
                    />
                  )}
                />
              </div>

              <div className="col-span-3">
                <Controller
                  control={control}
                  name="contractNumber"
                  rules={{
                    required: "Нужно заполнить номер конечного договора",
                  }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Номер конечного договора"
                      value={field.value === null ? "" : field.value}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-3">
                <Controller
                  control={control}
                  name="customerLegalnameId"
                  rules={{ required: "Нужно заполнить заказчика по конечному договору" }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      name="customerLegalnameId"
                      labelText="Заказчик по конечному договору"
                      options={legalnamesOptions}
                      fieldState={fieldState}
                      value={field.value}
                      onSelect={(e) => {
                        setValue("customerLegalnameId", e);
                      }}
                      placeholder={"Выберите заказчика из списка"}
                    />
                  )}
                />
              </div>

              <div className="col-span-3">
                <Controller
                  control={control}
                  name="contractorLegalnameId"
                  rules={{ required: "Нужно заполнить исполнителя по конечному договору" }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      name="contractorLegalnameId"
                      labelText="Исполнитель по конечному договору"
                      options={ourLegalnamesOptions}
                      fieldState={fieldState}
                      value={field.value}
                      onSelect={(e) => {
                        setValue("contractorLegalnameId", e);
                      }}
                      placeholder={"Выберите исполнителя из списка"}
                    />
                  )}
                />
              </div>

              <div className="col-span-3">
                <Controller
                      control={control}
                      name="contractDate"
                      rules={{
                        required: "Нужно заполнить дату конечного договора",
                      }}
                      render={({ field, fieldState }) => (
                        <LabeledDateInput
                          labelText="Дата конечного договора"
                          field={field}
                          name={field.name}
                          fieldState={fieldState}
                        />
                      )}
                  />
              </div>

              <div className="col-span-3">
                <Controller
                  control={control}
                  name="isVatIncluded"
                  render={({ field, fieldState }) => (
                    <LabeledToggleInput
                      labelText="Включено ли НДС в стоимость"
                      checked={field.value}
                      defaultChecked={true}
                      field={field}
                      name={field.name}
                      onChange={(e) => setValue("isVatIncluded", !!e)}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-2">
                <Controller
                  control={control}
                  name="contractType"
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      name="contractType"
                      labelText="Тип договора"
                      options={Object.values(ContractType).map((cs) => {
                        return {
                          label: contractTypeStatusMap[cs],
                          value: cs,
                        };
                      })}
                      fieldState={fieldState}
                      value={field.value}
                      onSelect={(e) => {
                        setValue("contractType", e);
                      }}
                      placeholder={"Выберите тип договора из списка"}
                    />
                  )}
                />
              </div>

              <div className="col-span-2">
                <Controller
                  control={control}
                  name="contractSubjectType"
                  rules={{ required: "Нужно заполнить предмет договора" }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      name="contractSubjectType"
                      labelText="Предмет договора"
                      options={Object.values(ContractSubjectType).map((cs) => {
                        return {
                          label: contractSubjectTypeStatusMap[cs],
                          value: cs,
                        };
                      })}
                      fieldState={fieldState}
                      value={field.value}
                      onSelect={(e) => {
                        setValue("contractSubjectType", e);
                        if(e === 'MEDIATION') {
                          setIsActionTypeDisabled(false);
                        } else {
                          setIsActionTypeDisabled(true);
                        }
                      }}
                      placeholder={"Выберите предмет договора из списка"}
                    />
                  )}
                />
              </div>

              <div className="col-span-2">
                <Controller
                  control={control}
                  name="contractActionType"
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      isDisabled={isActionTypeDisabled}
                      name="contractActionType"
                      labelText="Тип взаимодействия сторон договора"
                      options={Object.values(ContractActionType).map((cs) => {
                        return {
                          label: contractActionTypeStatusMap[cs],
                          value: cs,
                        };
                      })}
                      fieldState={fieldState}
                      value={field.value}
                      onSelect={(e) => {
                        setValue("contractActionType", e);
                      }}
                      placeholder={"Выберите тип взаимодействия сторон договора из списка"}
                    />
                  )}
                />
              </div>

              <div className="col-span-2">
                  <Controller
                    control={control}
                    name="contractSumAmount"
                    render={({ field, fieldState }) => (
                      <LabeledTextInputWithTrailingAddon
                        labelText="Стоимость услуг по договору (опционально)"
                        addonText="Руб."
                        value={field.value ?? null}
                        onChange={(e) => {
                          const parsedValue = !isNaN(parseFloat(e.target.value))
                            ? parseFloat(e.target.value)
                            : "";
                          field.onChange(parsedValue);
                        }}
                        name={field.name}
                        fieldState={fieldState}
                        type="number"
                        min="0"
                        step="0.01"
                      />
                    )}
                  />
                </div>

                <div className="col-span-2">
                  <Controller
                    control={control}
                    name="metaInfo"
                    render={({ field, fieldState }) => (
                      <LabeledTextInput
                        value={field.value === null ? "" : field.value}
                        onChange={field.onChange}
                        name={field.name}
                        fieldState={fieldState}
                        labelText="Мета Информация"
                      />
                    )}
                  />
                </div>


                <div className="col-span-2">
                <Controller
                  control={control}
                  name="isORD"
                  render={({ field, fieldState }) => (
                    <LabeledToggleInput
                      id="isORD"
                      labelText="Добавлено в ОРД"
                      checked={field.value}
                      defaultChecked={false}
                      field={field}
                      name={field.name}
                      onChange={(e) => setValue("isORD", !!e)}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

            </div>  
          </div>

          <div></div>
          <div></div>
          <div className="flex justify-end gap-x-3">
            <Button type="button" onClick={() => onClose()}>
              Отмена
            </Button>
            <PrimaryButton type="submit">Сохранить</PrimaryButton>
          </div>
        </div>
      </div>
    </form>
  ) : (
    <DataLoader />
  );
};