import {
  DataLoader,
  PrimaryButton,
  SingleRowCell,
  Table,
} from "../../../components";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import {
  findAllBuyingFormats,
  findAllBuyingFormatsVariables,
} from "../../../graphql/__generated-types__/findAllBuyingFormats";
import { FIND_ALL_BUYING_FORMAT_QUERY } from "graphql/queries/findAllBuyingFormats";
import BuyingFormatCreate from "./create";
import BuyingFormatEdit from "./edit";
import BuyingFormatDelete from "./delete";
import { LayoutButtonsContext } from "../AdminLayout";
import { BuyingFormatParts } from "graphql/__generated-types__/BuyingFormatParts";
import { CellProps } from "react-table";
import { Helmet } from "react-helmet-async";
import { useMe } from "hooks";
import { UserRole } from "graphql/__generated-types__/globalTypes";

const AdminBuyingFormats = () => {
  const user = useMe();
  const { setLayoutButtons } = useContext(LayoutButtonsContext);

  const hideEditingForm = useCallback(() => {
    setIsEditing(false);
    setCurrentBuyingFormat(null);
  }, []);

  const clickAddButton = useCallback(() => {
    hideEditingForm();
    setIsAdding(true);
  }, [hideEditingForm]);

  useEffect(() => {
    setLayoutButtons(
      <div className="flex gap-x-2">
        {(user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
          user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
          <PrimaryButton
            icon={PlusIcon({
              className: "h-4 w-4",
              "aria-hidden": true,
            })}
            id="buyingFormatAddButton"
            onClick={() => clickAddButton()}
          >
            Добавить
          </PrimaryButton>
        )}
      </div>
    );
    return () => {
      setLayoutButtons(null);
    };
  }, [clickAddButton, setLayoutButtons, user?.data?.me?.user?.roles]);

  const [showDeleteBuyingFormatModal, setShowDeleteBuyingFormatModal] =
    useState(false);
  const [currentBuyingFormat, setCurrentBuyingFormat] =
    useState<BuyingFormatParts>(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const resetCurrentBuyingFormat = () => setCurrentBuyingFormat(null);

  const hideAddingForm = useCallback(
    (reOpen = false) => {
      setIsAdding(false);
      resetCurrentBuyingFormat();
      if (reOpen) {
        clickAddButton();
      }
    },
    [clickAddButton]
  );

  const clickEditButton = useCallback(
    (buyingFormat: BuyingFormatParts) => {
      hideAddingForm();
      hideEditingForm();
      setIsEditing(true);
      setCurrentBuyingFormat(buyingFormat);
      const buyingFormatAddButton = document.getElementById(
        "buyingFormatAddButton"
      );
      if (buyingFormatAddButton) {
        buyingFormatAddButton.scrollIntoView();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    [hideAddingForm, hideEditingForm]
  );

  const clickDeleteButton = useCallback(
    (buyingFormat: BuyingFormatParts) => {
      setShowDeleteBuyingFormatModal(!showDeleteBuyingFormatModal);
      setCurrentBuyingFormat(buyingFormat);
    },
    [showDeleteBuyingFormatModal]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Формат закупки",
        accessor: "title",
        Cell: (props: CellProps<BuyingFormatParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Действие",
        Cell: (props: CellProps<BuyingFormatParts>) => (
          <div className="flex gap-x-2">
            {user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
            user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN) ? (
              <>
                <PrimaryButton
                  icon={PencilIcon({
                    className: "h-4 w-4",
                    "aria-hidden": true,
                  })}
                  disabled={isAdding}
                  onClick={() => clickEditButton(props.row.original)}
                ></PrimaryButton>

                <PrimaryButton
                  icon={TrashIcon({
                    className: "h-4 w-4",
                    "aria-hidden": true,
                  })}
                  disabled={isAdding || isEditing}
                  onClick={() => clickDeleteButton(props.row.original)}
                ></PrimaryButton>
              </>
            ) : (
              <SingleRowCell>Н/Д</SingleRowCell>
            )}
          </div>
        ),
      },
    ],
    [
      clickDeleteButton,
      clickEditButton,
      isAdding,
      isEditing,
      user?.data?.me?.user?.roles,
    ]
  );

  const { loading, data: buyingFormatsData } = useQuery<
    findAllBuyingFormats,
    findAllBuyingFormatsVariables
  >(FIND_ALL_BUYING_FORMAT_QUERY, {
    variables: {
      input: {
        keyword: "",
      },
    },
  });

  const buyingFormats = useMemo(
    () => buyingFormatsData?.findAllBuyingFormats?.buyingFormats,
    [buyingFormatsData?.findAllBuyingFormats?.buyingFormats]
  );

  return !loading ? (
    <>
      <Helmet>
        <title> Форматы закупки | Артикс Портал</title>
      </Helmet>
      {(isAdding || isEditing) && (
        <div className="mb-2">
          {isAdding &&
            (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
              user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
              <BuyingFormatCreate onClose={hideAddingForm} />
            )}
          {isEditing &&
            (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
              user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
              <BuyingFormatEdit
                onClose={hideEditingForm}
                buyingFormat={currentBuyingFormat}
              />
            )}
        </div>
      )}

      {showDeleteBuyingFormatModal &&
        (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
          user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
          <BuyingFormatDelete
            show={showDeleteBuyingFormatModal}
            showFn={setShowDeleteBuyingFormatModal}
            buyingFormat={currentBuyingFormat}
            closeModal={setShowDeleteBuyingFormatModal}
          />
        )}

      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {buyingFormats && (
                <Table
                  columns={columns}
                  data={buyingFormats || []}
                  initialState={{
                    pageSize: 20,
                    pageIndex: 0,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};

export default AdminBuyingFormats;
