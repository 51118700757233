import { useQuery } from "@apollo/client";
import {
  PencilIcon,
  PlusIcon,
  TableIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { DataLoader, Modal, PrimaryButton } from "components";
import { FIND_MANY_POSTS_QUERY } from "graphql/queries/findManyPosts";
import {
  findManyPosts,
  findManyPostsVariables,
} from "graphql/__generated-types__/findManyPosts";
import { UserRole } from "graphql/__generated-types__/globalTypes";
import { PostType } from "graphql/__generated-types__/globalTypes";
import { PostParts } from "graphql/__generated-types__/PostParts";
import { useMe } from "hooks";
import { mainMenuRoutes, newsMenuServicesRoutes } from "navigation/routes";
import PostCreate from "pages/Home/components/PostService/create";
import PostDelete from "pages/Home/components/PostService/delete";
import PostEdit from "pages/Home/components/PostService/edit";
import PostPin from "pages/Home/components/PostService/pin";
import { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import Sidebar from "./components/sidebar";

const News = () => {
  const limit = 10;
  const newsContainerRef = useRef(null);

  const {
    data: postsData,
    loading: postsLoading,
    fetchMore,
  } = useQuery<findManyPosts, findManyPostsVariables>(FIND_MANY_POSTS_QUERY, {
    variables: {
      input: { take: limit, skip: 0, postType: PostType.MAIN_PAGE },
    },
  });

  const { data: mainPostData, loading: mainPostLoading } = useQuery<
    findManyPosts,
    findManyPostsVariables
  >(FIND_MANY_POSTS_QUERY, {
    variables: {
      input: { postType: PostType.MAIN },
    },
  });

  const { data: pinnedPostData, loading: pinnedPostLoading } = useQuery<
    findManyPosts,
    findManyPostsVariables
  >(FIND_MANY_POSTS_QUERY, {
    variables: {
      input: { postType: PostType.PINNED },
    },
  });

  const hideEditingForm = useCallback(() => {
    setIsEditing(false);
    resetCurrentPost();
  }, []);

  const clickAddButton = useCallback(() => {
    hideEditingForm();
    setIsAdding(true);
  }, [hideEditingForm]);

  const [currentPost, setCurrentPost] = useState<PostParts>(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeletePostModal, setShowDeletePostModal] = useState(false);
  const [showPinPostModal, setShowPinPostModal] = useState(false);

  const resetCurrentPost = () => setCurrentPost(null);

  const hideAddingForm = useCallback(
    (reOpen = false) => {
      setIsAdding(false);
      resetCurrentPost();
      if (reOpen) {
        clickAddButton();
      }
    },
    [clickAddButton]
  );

  const clickEditButton = useCallback(
    (post: PostParts) => {
      hideAddingForm();
      hideEditingForm();
      setIsEditing(true);
      setCurrentPost(post);
      const postAddButton = document.getElementById("postAddButton");
      if (postAddButton) {
        postAddButton.scrollIntoView();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    [hideAddingForm, hideEditingForm]
  );

  const clickDeleteButton = useCallback(
    (post: PostParts) => {
      setShowDeletePostModal(!showDeletePostModal);
      setCurrentPost(post);
    },
    [showDeletePostModal]
  );

  const clickPinButton = useCallback(
    (post: PostParts) => {
      setShowPinPostModal(!showPinPostModal);
      setCurrentPost(post);
    },
    [showPinPostModal]
  );

  const navigate = useNavigate();
  const user = useMe();

  useEffect(() => {
    const handleScroll = () => {
      if (
        newsContainerRef?.current?.getBoundingClientRect().bottom <=
        window.innerHeight
      ) {
        fetchMore({
          variables: {
            input: {
              take: limit,
              skip: postsData?.findManyPosts?.posts?.length,
              postType: PostType.MAIN_PAGE,
            },
          },
        });
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [fetchMore, postsData?.findManyPosts?.posts?.length]);

  return postsLoading && mainPostLoading && pinnedPostLoading ? (
    <DataLoader />
  ) : (
    <>
      <Helmet>
        <title>Новости | Артикс Портал</title>
      </Helmet>
      <div className="relative bg-gray-50 lg:py-0 lg:px-0">
        {(isAdding || isEditing) && (
          <div className="mb-2">
            {isAdding && (
              <PostCreate
                onClose={hideAddingForm}
                path={mainMenuRoutes?.news?.path}
              />
            )}
            {isEditing && (
              <PostEdit
                onClose={hideEditingForm}
                post={currentPost}
                path={mainMenuRoutes?.news?.path}
              />
            )}
          </div>
        )}
        {showDeletePostModal && (
          <Modal show={showDeletePostModal} showFn={setShowDeletePostModal}>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              {
                <PostDelete
                  post={currentPost}
                  path={mainMenuRoutes?.news?.path}
                  closeModal={setShowDeletePostModal}
                />
              }
            </div>
          </Modal>
        )}

        {showPinPostModal && (
          <Modal show={showPinPostModal} showFn={setShowPinPostModal}>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              {
                <PostPin
                  post={currentPost}
                  path={mainMenuRoutes?.news?.path}
                  closeModal={setShowPinPostModal}
                />
              }
            </div>
          </Modal>
        )}

        {/*Новостная лента*/}
        <div className="bg-gray-50 w-full maxhsm:inline-block lg:flex lg:grid-cols-2 max-w-none">
          {/* 1й столбец */}
          <div className="relative lg:w-8/12 maxhsm:w-11/12 lg:m-5 lg:mx-auto hsm:m-5 maxhsm:mx-auto">
            {(user?.data?.me?.user?.roles.includes(UserRole.PR_ADMIN) ||
              user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
              <>
                <div className="grid grid-cols-2 gap-2 mb-0 invisible sm:max-w-xs sm:ml-2 sm:mb-2 lg:visible lg:max-w-xs lg:ml-5 lg:mb-5">
                  <div className="w-40">
                    <PrimaryButton
                      icon={PlusIcon({
                        className: "h-3 w-3",
                        "aria-hidden": true,
                      })}
                      id="postAddButton"
                      onClick={() => clickAddButton()}
                    >
                      Добавить
                    </PrimaryButton>
                  </div>
                  <div className="w-40">
                    <PrimaryButton
                      icon={TableIcon({
                        className: "h-3 w-3",
                        "aria-hidden": true,
                      })}
                      onClick={() =>
                        navigate(newsMenuServicesRoutes?.postService?.path)
                      }
                    >
                      Таблица
                    </PrimaryButton>
                  </div>
                </div>
              </>
            )}
            {/* Главная */}
            <div id="pinned" className="mx-auto lg:max-w-none w-full">
              {mainPostData?.findManyPosts?.posts?.map((post) => (
                <div
                  key={post?.id}
                  className="flex flex-col rounded-lg overflow-hidden bg-white"
                >
                  {(user?.data?.me?.user?.roles.includes(UserRole.PR_ADMIN) ||
                    user?.data?.me?.user?.roles.includes(
                      UserRole.GLOBAL_ADMIN
                    )) && (
                    <div className="absolute grid gap-y-0.5 z-10">
                      <PrimaryButton
                        icon={PencilIcon({
                          className: "h-2 w-2 p-0 m-0",
                          "aria-hidden": true,
                        })}
                        disabled={isAdding}
                        onClick={() => clickEditButton(post)}
                        className={"h-6 w-8"}
                        size={"xs"}
                      ></PrimaryButton>

                      <PrimaryButton
                        icon={TrashIcon({
                          className: "h-2 w-2 p-0 m-0",
                          "aria-hidden": true,
                        })}
                        disabled={isAdding || isEditing}
                        onClick={() => clickDeleteButton(post)}
                        className={"h-6 w-8"}
                        size={"xs"}
                      ></PrimaryButton>

                      <PrimaryButton
                        icon={XCircleIcon({
                          className: "h-2 w-2 p-0 m-0",
                          "aria-hidden": true,
                        })}
                        disabled={isAdding || isEditing}
                        onClick={() => clickPinButton(post)}
                        className={"h-6 w-8"}
                        size={"xs"}
                      ></PrimaryButton>
                    </div>
                  )}
                  <a href={`/news/${post?.id}`}>
                    <div className="flex-shrink-0 flex-col rounded-lg shadow-lg overflow-hidden">
                      <img
                        className="filter brightness-75 h-72 w-full object-cover"
                        src={post?.imageURL}
                        alt=""
                      />
                      {user?.data?.me?.user?.roles.includes(
                        UserRole.PR_ADMIN
                      ) ||
                      user?.data?.me?.user?.roles.includes(
                        UserRole.GLOBAL_ADMIN
                      ) ? (
                        <p className="text-4xl font-semibold absolute top-14 left-12 text-white">
                          {post?.header}
                        </p>
                      ) : (
                        <p className="text-4xl font-semibold absolute top-4 left-12 text-white">
                          {post?.header}
                        </p>
                      )}
                    </div>
                    <div className="flex-1 bg-white p-4 flex flex-col justify-between">
                      <div className="flex-1">
                        <p className="mt-1 md:text-sm xl:text-base 2xl:text-lg text-gray-700">
                          {post?.underHeader}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
            {/* Главная */}
            <div
              ref={newsContainerRef}
              className="mt-12 mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none"
            >
              {/* Закреп */}
              {pinnedPostData?.findManyPosts?.posts?.map((post) => (
                <div
                  key={post?.id}
                  className="relative rounded-lg shadow-lg overflow-hidden bg-white"
                >
                  {(user?.data?.me?.user?.roles.includes(UserRole.PR_ADMIN) ||
                    user?.data?.me?.user?.roles.includes(
                      UserRole.GLOBAL_ADMIN
                    )) && (
                    <div className="absolute grid gap-y-0.5 z-10">
                      <>
                        <PrimaryButton
                          icon={PencilIcon({
                            className: "h-2 w-2 p-0 m-0",
                            "aria-hidden": true,
                          })}
                          disabled={isAdding}
                          onClick={() => clickEditButton(post)}
                          className={"h-6 w-8"}
                          size={"xs"}
                        ></PrimaryButton>

                        <PrimaryButton
                          icon={TrashIcon({
                            className: "h-2 w-2 p-0 m-0",
                            "aria-hidden": true,
                          })}
                          disabled={isAdding || isEditing}
                          onClick={() => clickDeleteButton(post)}
                          className={"h-6 w-8"}
                          size={"xs"}
                        ></PrimaryButton>

                        <PrimaryButton
                          icon={XCircleIcon({
                            className: "h-2 w-2 p-0 m-0",
                            "aria-hidden": true,
                          })}
                          disabled={isAdding || isEditing}
                          onClick={() => clickPinButton(post)}
                          className={
                            "h-6 w-8 text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                          }
                          size={"xs"}
                        ></PrimaryButton>
                      </>
                    </div>
                  )}
                  <a href={`/news/${post?.id}`}>
                    <div className="flex-shrink-0 relative">
                      <img
                        className="filter brightness-75 w-full object-contain  rounded-lg shadow-xl"
                        src={post?.imageURL}
                        alt=""
                      />
                    </div>
                    <div className="flex-1 bg-white p-4 flex flex-col justify-between">
                      <div className="flex-1">
                        <p className="md:text-base xl:text-lg 2xl:text-xl font-semibold text-gray-900">
                          {post?.header}
                        </p>
                        <p className="mt-1 md:text-xs xl:text-sm 2xl:text-base text-gray-500">
                          {post?.underHeader}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
              {/* Закреп */}
              {/* Посты по приоритету */}
              {postsData?.findManyPosts?.posts?.map((post) => (
                <div
                  key={post?.id}
                  className="relative flex bg-white flex-col rounded-lg shadow-lg overflow-hidden"
                >
                  {(user?.data?.me?.user?.roles.includes(UserRole.PR_ADMIN) ||
                    user?.data?.me?.user?.roles.includes(
                      UserRole.GLOBAL_ADMIN
                    )) && (
                    <div className="absolute grid gap-y-0.5 z-10">
                      <PrimaryButton
                        icon={PencilIcon({
                          className: "h-2 w-2 p-0 m-0",
                          "aria-hidden": true,
                        })}
                        disabled={isAdding}
                        onClick={() => clickEditButton(post)}
                        className={"h-6 w-8"}
                        size={"xs"}
                      ></PrimaryButton>
                      <PrimaryButton
                        icon={TrashIcon({
                          className: "h-2 w-2 p-0 m-0",
                          "aria-hidden": true,
                        })}
                        disabled={isAdding || isEditing}
                        onClick={() => clickDeleteButton(post)}
                        className={"h-6 w-8"}
                        size={"xs"}
                      ></PrimaryButton>

                      <PrimaryButton
                        icon={XCircleIcon({
                          className: "h-2 w-2 p-0 m-0",
                          "aria-hidden": true,
                        })}
                        disabled={isAdding || isEditing}
                        onClick={() => clickPinButton(post)}
                        className={"h-6 w-8"}
                        size={"xs"}
                      ></PrimaryButton>
                    </div>
                  )}
                  <a href={`/news/${post?.id}`}>
                    <div className="flex-shrink-0">
                      <img
                        className="filter brightness-75 w-full object-contain rounded-lg shadow-xl"
                        src={post?.imageURL}
                        alt=""
                      />
                    </div>
                    <img
                      src="https://artics-portal-uploads.storage.yandexcloud.net/posts/images/Post-2022-10-04T14%3A56%3A44361Z"
                      alt=""
                    ></img>
                    <div className="flex-1 bg-white p-4 flex flex-col justify-between">
                      <div className="flex-1">
                        <p className="md:text-base xl:text-lg 2xl:text-xl font-semibold text-gray-900">
                          {post?.header}
                        </p>
                        <p className="mt-1 md:text-xs xl:text-sm 2xl:text-base text-gray-500">
                          {post?.underHeader}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
            {/* Посты по приоритету */}
          </div>
          {/* 1й столбец */}
          {/* 2й столбец */}
          <Sidebar />
          {/* 2й столбец */}
          {/*Новостная лента*/}
        </div>
      </div>
    </>
  );
};
export default News;
