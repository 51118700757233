import {
  UserParts,
  UserParts_position_processes,
} from "graphql/__generated-types__/UserParts";
import AvatarTemplate from "assets/user-template.png";
import { UserInline } from "components";
import { capitalizeFirstLetter, formatDate, getTimeDiff } from "utils";
import { listEmployeeDepartments_listEmployeeDepartments_departments_employees_certificates } from "graphql/__generated-types__/listEmployeeDepartments";
import CertificateInline from "./CertificateInline";
import { useLazyQuery } from "@apollo/client";
import { findMissingCertificatesForUser, findMissingCertificatesForUserVariables } from "graphql/__generated-types__/findMissingCertificatesForUser";
import { MISSING_CERTIFICATES } from "graphql/queries/FindAllRequiredCertificatesForUser";
import MissingCertificateInline from "./MissingCertificateInline";
import ProcessInline from "./ProcessInline";
import {FIND_ALL_PBT_REPORTS_QUERY} from "../../../graphql/queries";
import {findAllPbtReports, findAllPbtReportsVariables} from "../../../graphql/__generated-types__/findAllPbtReports";
import {ClientInline} from "./ClientInline";
import moment from "moment";
import {usefullIconsMap} from "../../../config/mappings";
import {Tooltip} from "react-tooltip";
import {useEffect, useState} from "react";

interface PersonDetailsProps {
  person: UserParts & {
    certificates?: listEmployeeDepartments_listEmployeeDepartments_departments_employees_certificates[];
  };
}

export const PersonDetails = ({ person }: PersonDetailsProps) => {
  const date = moment();

  const previousMonth = date.utc(false).subtract(1, 'month').startOf('month').format();
  const [processes, setProcesses] = useState<UserParts_position_processes[]>([]);
  const [missingCertificatesData, {data}] = useLazyQuery<
    findMissingCertificatesForUser,
    findMissingCertificatesForUserVariables
  >(MISSING_CERTIFICATES);

  const [pbtReports, pbtReportsData] = useLazyQuery<
    findAllPbtReports,
    findAllPbtReportsVariables
  >(FIND_ALL_PBT_REPORTS_QUERY);

  useEffect(() => {
    if (person.position.processes && person.position.processes.length > 0) {
      setProcesses(person.position?.processes);
    }
  }, [person])
  return (
    <div className={'relative z-50'}>
      <div className="pb-1 sm:pb-6">
        <div>
          <div
            className="aspect-w-3 aspect-h-3 rounded-xl bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${person.avatar || AvatarTemplate})`,
            }}
          ></div>
          <div className="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
            <div className="sm:flex-1">
              <div>
                <div className="flex items-center">
                  <h3 className="font-bold text-xl text-gray-900 sm:text-2xl flex">
                    {person.surname} {person.name} {person.middlename}
                  </h3>
                </div>
                <p className="text-sm text-gray-500">{person.email}</p>
              </div>
              <div className="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                {person.email && (
                  <a
                    href={`mailto:${person.email}`}
                    className="flex-shrink-0 w-full inline-flex items-center justify-center px-2 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:flex-1"
                  >
                    Написать
                  </a>
                )}
                {person.phone && (
                  <a
                    href={`tel://${person.phone.replace(/[^0-9.]/g, "")}`}
                    className="flex-1 w-full inline-flex items-center justify-center px-2 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Позвонить
                  </a>
                )}
                {person.telegram && (
                  <a
                    href={`tg://resolve?domain=${person.telegram}`}
                    className="flex-1 w-full inline-flex items-center justify-center px-2 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Telegram
                  </a>
                )}
                {person.presentationLink && (
                  <a
                    target='_blank'
                    href={person.presentationLink} rel="noreferrer"
                    className="flex-1 w-full inline-flex items-center justify-center px-1 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    О себе
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
        <dl
          onLoad={async () => {
            await missingCertificatesData({variables: {input: {id: person?.id}}});
            await pbtReports({variables: {input: {userIds: [person?.id], monthYear: previousMonth, distinct: true}}});
          }}
          className="space-y-8 px-4 sm:px-6 sm:space-y-6"
        >
          {person.position && (
            <div>
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                Должность
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                <p>{person.position?.title}</p>
              </dd>
            </div>
          )}

          {person.department && (
            <div>
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                Отдел
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {person.department.title}
              </dd>
            </div>
          )}

          {person.office && (
            <div>
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                Офис
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {person.office === "MSK" ? "Москва" : null}
                {person.office === "SPB" ? "Санкт-Петербург" : null}
              </dd>
            </div>
          )}

          {person.birthdate && (
            <div>
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                День рождения
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                <time dateTime={person.birthdate}>
                  {formatDate(person.birthdate)}
                </time>
              </dd>
            </div>
          )}

          {person.employmentDate && (
            <div>
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                Стаж работы
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                <span>
                  {capitalizeFirstLetter(getTimeDiff(person.employmentDate))}
                </span>{" "}
                <time dateTime={person.employmentDate}>
                  (с {formatDate(person.employmentDate)})
                </time>
              </dd>
            </div>
          )}

          {person.googleEmail && (
            <div>
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                Альтернативный Email
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {person.googleEmail}
              </dd>
            </div>
          )}

          {person.employeeHead && (
            <div>
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                Руководитель
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                <UserInline
                  id={person.employeeHead.id}
                  size={8}
                  avatar={person.employeeHead.avatar}
                  name={`${person.employeeHead.name} ${person.employeeHead.surname}`}
                />
              </dd>
            </div>
          )}

          {person.subordinates &&
            person.subordinates?.filter((s) => s.isActive)?.length > 0 && (
              <div>
                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                  Подчиненные
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 space-y-1">
                  {person.subordinates
                    .filter((s) => s.isActive)
                    .map((subordinate) => (
                      <UserInline
                        key={subordinate.id}
                        id={subordinate.id}
                        size={8}
                        avatar={subordinate.avatar}
                        name={`${subordinate.name} ${subordinate.surname}`}
                      />
                    ))}
                </dd>
              </div>
            )}

          {person?.certificates && (person?.certificates?.length || data?.findMissingCertificatesForUser?.missingCertificates?.length) > 0 &&
            (
              <div>
                <dt
                  className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                  {
                    person?.certificates?.length > 0
                      ? `Сертификаты ${person?.certificates?.filter(certificate => certificate.passed).length}`
                      : 'Сертификаты'
                  }
                </dt>
                <dd
                  className="mt-1 text-sm text-gray-900 sm:col-span-2 space-y-1">
                  {person?.certificates?.filter(certificate => certificate.passed).map((certificate) => (
                    <CertificateInline
                      key={`certificate-${certificate?.id}`}
                      certificate={certificate}
                    />
                  ))}
                  {data?.findMissingCertificatesForUser?.missingCertificates?.map((missingCertificate) => (
                    <MissingCertificateInline
                      key={`missing-${missingCertificate.id}`}
                      missingCertificate={missingCertificate}
                    />
                  ))}
                </dd>
              </div>
            )}
          {processes && processes.filter((process) => !process.process.isArchive).length > 0 &&
            (
              <div>
                <div className="flex">
                  <dt
                    className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0"
                    style={{width: "unset"}}
                  >
                    Процессы
                  </dt>
                  <a id="clickable">
                    <img src={`/services/${usefullIconsMap["info"].icon}`} className="pl-1 h-4 rounded-full mx-auto"
                         alt=""/>
                  </a>
                  <Tooltip anchorSelect="#clickable" clickable>
                    <p>Чтобы залогиниться в Lucid,
                      <br/> где все процессы,
                      <br/> используйте доступы от него в
                      <a
                        href={"https://passwork.artics.ru/enter#/login"}
                        target="_blank"
                        style={{textDecoration: "underline", color: "#00A3FF"}}>
                        Passwork
                      </a>.
                      <br/>Если не знаете, как зайти в Passwork,
                      <br/> обратитесь в Владу Бересневу
                    </p>
                  </Tooltip>
                </div>
                <dd
                  className="mt-1 text-sm text-gray-900 sm:col-span-2 space-y-1">
                  {processes.map((process) => (
                    <ProcessInline
                      key={`process-${process.id}`}
                      process={process}
                    />
                  ))}
                </dd>
              </div>
            )}
          {person.responsibility && (
            <div>
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                Зона ответственности
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {person.responsibility}
              </dd>
            </div>
          )}
          {pbtReportsData?.data?.findAllPbtReports?.pbtReports?.length > 0 && (
            <div>
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                Работает с клиентами
              </dt>
              <dd
                className="mt-1 text-sm text-gray-900 sm:col-span-2 space-y-1">
                {[...new Set(pbtReportsData?.data?.findAllPbtReports?.pbtReports?.filter((pbtReport) => {
                      return (moment(pbtReport.monthYear).isSame(previousMonth, 'month') && moment(pbtReport.monthYear).isSame(previousMonth, 'year'))
                    }
                  ).length > 0 ?
                    pbtReportsData?.data?.findAllPbtReports?.pbtReports?.filter((pbtReport) =>
                      moment(pbtReport.monthYear).isSame(previousMonth, 'month') && moment(pbtReport.monthYear).isSame(previousMonth, 'year')
                    ).map(pbtReport =>
                      pbtReport.project.client.title)
                    : pbtReportsData?.data?.findAllPbtReports?.pbtReports?.filter((pbtReport) =>
                      !moment(pbtReport.monthYear).isSame(previousMonth, 'month') && moment(pbtReport.monthYear).isSame(previousMonth, 'year')
                    ).map(pbtReport =>
                      pbtReport.project.client.title)
                )
                ].sort().map(title =>
                  <ClientInline
                    title={title}
                    key={title}
                  />
                )}
              </dd>
            </div>
          )}
        </dl>
      </div>
    </div>
  );
}
