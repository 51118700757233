import { useNavigate } from "react-router";
import { useMutation } from "@apollo/client";
import { serviceMenuRoutes } from "../../../../navigation/routes";
import { toast } from "react-toastify";
import { DEPARTMENT_PLAN_FRAGMENT } from "../../../../graphql/fragments";
import { DataLoader } from "../../../../components";
import {
  updateDepartmentPlan,
  updateDepartmentPlanVariables,
} from "../../../../graphql/__generated-types__/updateDepartmentPlan";
import { UPDATE_DEPARTMENT_PLAN_MUTATION } from "../../../../graphql/mutations";
import { DepartmentPlanForm, IDepartmentPlanForm } from "../components";
import { useMe } from "../../../../hooks";
import { DepartmentPlanParts } from "graphql/__generated-types__/DepartmentPlanParts";

interface IDepartmentPlanEditProps {
  departmentPlan: DepartmentPlanParts;
  onClose: () => void;
}

const DepartmentPlanEdit: React.FC<IDepartmentPlanEditProps> = ({
  departmentPlan,
  onClose,
}) => {
  const { data: userData } = useMe();
  const navigate = useNavigate();

  const [updateDepartmentPlan] = useMutation<
    updateDepartmentPlan,
    updateDepartmentPlanVariables
  >(UPDATE_DEPARTMENT_PLAN_MUTATION, {
    onCompleted: (updateDepartmentPlanData) => {
      if (
        updateDepartmentPlanData &&
        updateDepartmentPlanData.updateDepartmentPlan.ok
      ) {
        navigate(serviceMenuRoutes.departmentPlans.path);
      } else {
        toast.error(updateDepartmentPlanData.updateDepartmentPlan.error);
      }
    },
    update(cache, { data: { updateDepartmentPlan } }) {
      if (
        updateDepartmentPlan.departmentPlan &&
        updateDepartmentPlan.departmentPlan.id
      ) {
        cache.writeFragment({
          // @ts-ignore
          id: cache.identify(updateDepartmentPlan?.departmentPlan),
          data: updateDepartmentPlan?.departmentPlan,
          fragment: DEPARTMENT_PLAN_FRAGMENT,
          fragmentName: "DepartmentPlanParts",
        });
      }
    },
  });
  const onSubmit = async (data: IDepartmentPlanForm) => {
    if (!!userData?.me?.user?.headOfDepartment?.length) {
      // Руководитель отдел(ов)
    } else if (!userData?.me?.user?.subordinates.length) {
      data.userId = userData?.me?.user?.id;
      data.departmentId = userData?.me?.user?.department?.id;
    }
    const responseData = await updateDepartmentPlan({
      variables: {
        input: {
          ...data,
          id: +departmentPlan.id,
        },
      },
    });
    if (responseData.data.updateDepartmentPlan.ok) {
      onClose();
    }
  };

  return departmentPlan ? (
    <DepartmentPlanForm
      departmentPlan={departmentPlan}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  ) : (
    <DataLoader />
  );
};

export default DepartmentPlanEdit;
