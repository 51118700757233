import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";
import {
  Button,
  DataLoader,
  LabeledDateInput,
  LabeledTextInput,
  LabeledTextInputWithTrailingAddon,
  LabeledToggleInput,
  PrimaryButton,
  ReactSelectInput,
} from "components";
import { ContractParts } from "graphql/__generated-types__/ContractParts";
import { useQuery } from "@apollo/client";
import { ContractActionType, ContractDocType, ContractSubjectType, ContractType } from "graphql/__generated-types__/globalTypes";
import { findAllContracts, findAllContractsVariables } from "graphql/__generated-types__/findAllContracts";
import { FIND_ALL_CONTRACTS_QUERY } from "graphql/queries/findAllContracts";
import { listLegalnames, listLegalnamesVariables } from "graphql/__generated-types__/listLegalnames";
import { LIST_LEGALNAMES_QUERY } from "graphql/queries";
import { contractActionTypeStatusMap, contractSubjectTypeStatusMap, contractTypeStatusMap } from "config/mappings";

export interface IInitialContractForm {
  contractNumber: string;
  contractDate: Date;
  contractorLegalnameId?: number;
  customerLegalnameId?: number;
  finalContractId?: number;
  isVatIncluded: boolean;
  contractType: ContractType;
  contractSubjectType: ContractSubjectType;
  contractActionType?: ContractActionType;
  contractSumAmount?: number;
  metaInfo?: string;
  isORD: boolean;
}

interface IInitialContractFormProps {
  contract?: ContractParts;
  onSubmit: (data: any) => void;
  onClose: () => void;
}

export const InitialContractForm: React.FC<IInitialContractFormProps> = ({
  contract,
  onSubmit,
  onClose,
}) => {
  const label = contract
    ? "Редактирование изначального договора"
    : "Добавление изначального договора";

  
  const { data: finalContractsData, loading: finalContractsLoading } = useQuery<
    findAllContracts,
    findAllContractsVariables
  >(FIND_ALL_CONTRACTS_QUERY, { variables: { input: { contractDocType: ContractDocType.FINAL_CONTRACT } } });
  
  const { data: legalnamesData, loading: legalnamesLoading } = useQuery<
    listLegalnames,
    listLegalnamesVariables
  >(LIST_LEGALNAMES_QUERY, { variables: { input: {} } });

  //invoices

  const { handleSubmit, control, setValue } = useForm<IInitialContractForm>({
    mode: "onSubmit",
    shouldUnregister: true,
    defaultValues: contract
      ? {
          ...contract,
          //clientProjectsids: contract?.clientProjects?.map((clientProject) => clientProject.id),
          contractorLegalnameId: contract?.contractorLegalname?.id,
          customerLegalnameId: contract?.customerLegalname?.id,
          //additionalContractId: contract?.additionalContract?.id,
          
          finalContractId: contract?.finalContract?.id,
          metaInfo: contract?.metaInfo,
          isORD: contract?.isORD,
          
          //finalContractInvoicesIds: contract?.finalContractInvoices?.mao((fContractInvoices) => fContractInvoices.id),
          //initialContractInvoicesIds: contract?.initialContractInvoices?.mao((iContractInvoices) => iContractInvoices.id),
          
          //Invoices
        }
      : {
        isVatIncluded: true,
        contractDate: new Date(new Date().setUTCHours(0, 0, 0, 0)).toISOString(),
        finalContractId: null,
        metaInfo: null,
        isORD: false,
      },
  });

  
  const finalContractsOptions =
    !finalContractsLoading &&
    finalContractsData?.findAllContracts?.contracts?.map((fContract) => ({
      label: `${fContract?.contractNumber} | ${fContract?.customerLegalname?.title}`,
      value: fContract?.id,
    }));
  /*
  const additionalContractsOptions =
  !additionalContractsLoading &&
  additionalContractsData?.findAllContracts?.contracts?.map((aContract) => ({
    label: `${aContract?.contractNumber}`,
    value: aContract?.id,
  }));
  */
  const legalnamesOptions =
    !legalnamesLoading &&
    legalnamesData?.listLegalnames?.legalnames?.map((legalname) => ({
      label: `${legalname?.title}`,
      value: legalname?.id,
    }));
    
  const [isActionTypeDisabled, setIsActionTypeDisabled] = useState(true);

  return  !finalContractsLoading &&
          !legalnamesLoading ? ( 
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {label}
            </h3>
          </div>

          <div className="mt-5 md:mt-0 md:col-span-3">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-3">
                <Controller
                  control={control}
                  name="customerLegalnameId"
                  rules={{ required: "Нужно заполнить заказчика по изначальному договору" }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      labelText="Заказчик по изначальному договору"
                      name="customerLegalnameId"
                      options={legalnamesOptions}
                      fieldState={fieldState}
                      value={field.value}
                      onSelect={(e) => {
                        setValue("customerLegalnameId", e);
                      }}
                      placeholder={"Выберите заказчика из списка"}
                    />
                  )}
                />
              </div>

              <div className="col-span-3">
                <Controller
                  control={control}
                  name="contractorLegalnameId"
                  rules={{ required: "Нужно заполнить исполнителя по изначальному договору" }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      labelText="Исполнитель по изначальному договору"
                      name="contractorLegalnameId"
                      options={legalnamesOptions}
                      fieldState={fieldState}
                      value={field.value}
                      onSelect={(e) => {
                        setValue("contractorLegalnameId", e);
                      }}
                      placeholder={"Выберите исполнителя из списка"}
                    />
                  )}
                />
              </div>

              <div className="col-span-3">
                <Controller
                  control={control}
                  name="contractNumber"
                  rules={{
                    required: "Нужно заполнить номер изначального договора",
                  }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Номер изначального договора"
                      value={field.value === null ? "" : field.value}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-3">
                <Controller
                      control={control}
                      name="contractDate"
                      rules={{
                        required: "Нужно заполнить дату изначального договора",
                      }}
                      render={({ field, fieldState }) => (
                        <LabeledDateInput
                          labelText="Дата изначального договора"
                          field={field}
                          name={field.name}
                          fieldState={fieldState}
                        />
                      )}
                  />
              </div>

              <div className="col-span-3">
                  <Controller
                    control={control}
                    name="contractSumAmount"
                    render={({ field, fieldState }) => (
                      <LabeledTextInputWithTrailingAddon
                        labelText="Стоимость услуг по договору (опционально)"
                        addonText="Руб."
                        value={field.value ?? null}
                        onChange={(e) => {
                          const parsedValue = !isNaN(parseFloat(e.target.value))
                            ? parseFloat(e.target.value)
                            : "";
                          field.onChange(parsedValue);
                        }}
                        name={field.name}
                        fieldState={fieldState}
                        type="number"
                        min="0"
                        step="0.01"
                      />
                    )}
                  />
                </div>

                <div className="col-span-2">
                <Controller
                  control={control}
                  name="isVatIncluded"
                  render={({ field, fieldState }) => (
                    <LabeledToggleInput
                      labelText="Включено ли НДС в стоимость"
                      checked={field.value}
                      defaultChecked={true}
                      field={field}
                      name={field.name}
                      onChange={(e) => setValue("isVatIncluded", !!e)}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-2">
                <Controller
                  control={control}
                  name="contractType"
                  rules={{ required: "Нужно заполнить тип договора" }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      name="contractType"
                      labelText="Тип договора"
                      options={Object.values(ContractType).map((cs) => {
                        return {
                          label: contractTypeStatusMap[cs],
                          value: cs,
                        };
                      })}
                      fieldState={fieldState}
                      value={field.value}
                      onSelect={(e) => {
                        setValue("contractType", e);
                      }}
                      placeholder={"Выберите тип договора из списка"}
                    />
                  )}
                />
              </div>

              <div className="col-span-2">
                <Controller
                  control={control}
                  name="contractSubjectType"
                  rules={{ required: "Нужно заполнить предмет договора" }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      name="contractSubjectType"
                      labelText="Предмет договора"
                      options={Object.values(ContractSubjectType).map((cs) => {
                        return {
                          label: contractSubjectTypeStatusMap[cs],
                          value: cs,
                        };
                      })}
                      fieldState={fieldState}
                      value={field.value}
                      onSelect={(e) => {
                        setValue("contractSubjectType", e);
                        if(e === 'MEDIATION') {
                          setIsActionTypeDisabled(false);
                        } else {
                          setIsActionTypeDisabled(true);
                        }
                      }}
                      placeholder={"Выберите предмет договора из списка"}
                    />
                  )}
                />
              </div>

              <div className="col-span-2">
                <Controller
                  control={control}
                  name="contractActionType"
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      isDisabled={isActionTypeDisabled}
                      name="contractActionType"
                      labelText="Тип взаимодействия сторон договора"
                      options={Object.values(ContractActionType).map((cs) => {
                        return {
                          label: contractActionTypeStatusMap[cs],
                          value: cs,
                        };
                      })}
                      fieldState={fieldState}
                      value={field.value}
                      onSelect={(e) => {
                        setValue("contractActionType", e);
                      }}
                      placeholder={"Выберите тип взаимодействия сторон договора из списка"}
                    />
                  )}
                />
              </div>

              <div className="col-span-2">
                <Controller
                  control={control}
                  name="finalContractId"
                  rules={{ required: "Нужно заполнить конечный договор" }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      name="finalContractId"
                      labelText="Конечный договор"
                      options={finalContractsOptions}
                      fieldState={fieldState}
                      value={field.value}
                      onSelect={(e) => setValue("finalContractId", e)}
                      placeholder={"Выберите конечный договор из списка"}
                    />
                  )}
                />
              </div>

              <div className="col-span-2">
                  <Controller
                    control={control}
                    name="metaInfo"
                    render={({ field, fieldState }) => (
                      <LabeledTextInput
                        value={field.value === null ? "" : field.value}
                        onChange={field.onChange}
                        name={field.name}
                        fieldState={fieldState}
                        labelText="Мета Информация"
                      />
                    )}
                  />
                </div>


                <div className="col-span-2">
                <Controller
                  control={control}
                  name="isORD"
                  render={({ field, fieldState }) => (
                    <LabeledToggleInput
                      id="isORD"
                      labelText="Добавлено в ОРД"
                      checked={field.value}
                      defaultChecked={false}
                      field={field}
                      name={field.name}
                      onChange={(e) => setValue("isORD", !!e)}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

            </div>  
          </div>

          <div></div>
          <div></div>
          <div className="flex justify-end gap-x-3">
            <Button type="button" onClick={() => onClose()}>
              Отмена
            </Button>
            <PrimaryButton type="submit">Сохранить</PrimaryButton>
          </div>
        </div>
      </div>
    </form>
  ) : (
    <DataLoader />
  );
};