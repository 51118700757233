
import { StoreObject, useMutation } from "@apollo/client";
import { DataLoader } from "components";
import { INVOICE_FRAGMENT } from "graphql/fragments";
import { UPDATE_INVOICE_MUTATION } from "graphql/mutations/updateInvoice";
import { InvoiceParts } from "graphql/__generated-types__/InvoiceParts";
import { updateInvoice, updateInvoiceVariables } from "graphql/__generated-types__/updateInvoice";
import { adminMenuRoutes } from "navigation/routes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IInvoiceForm, InvoiceForm } from "../components/InvoiceForm";

interface IInvoiceEditProps {
  invoice: InvoiceParts;
  onClose: () => void;
}

const InvoiceEdit: React.FC<IInvoiceEditProps> = ({
  invoice,
  onClose,
}) => {
  const navigate = useNavigate();
  const [updateInvoice] = useMutation<
    updateInvoice,
    updateInvoiceVariables
  >(UPDATE_INVOICE_MUTATION, {
    onCompleted: (updateInvoiceData) => {
      if (updateInvoiceData?.updateInvoice?.ok) {
        navigate(adminMenuRoutes?.invoices?.path);
      } else {
        toast.error(updateInvoiceData?.updateInvoice?.error);
      }
    },
    update(cache, { data: { updateInvoice } }) {
      if (updateInvoice?.invoice?.id) {
        cache.writeFragment({
          // @ts-ignore
          id: cache.identify(
            updateInvoice?.invoice as unknown as StoreObject
          ),
          data: updateInvoice?.invoice,
          fragment: INVOICE_FRAGMENT,
          fragmentName: "InvoiceParts",
        });
      }
    },
  });

  const onSubmit = async (data: IInvoiceForm) => {
    const responseData = await updateInvoice({
      variables: {
        input: {
          ...data,
          id: +invoice.id,
        },
      },
    });
    if (responseData?.data?.updateInvoice?.ok) {  
    onClose();
    }
  };

  return invoice ? (
    <InvoiceForm
      onSubmit={onSubmit}
      onClose={onClose}
      invoice={invoice}
    />
  ) : (
    <DataLoader />
  );
};

export default InvoiceEdit;