import { StoreObject, useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { serviceMenuRoutes } from "../../../../navigation/routes";
import { toast } from "react-toastify";
import { DataLoader } from "../../../../components";
import { IPbtCalculatorForm, PbtCalculatorForm } from "../components";
import {
  updatePbtCalculator,
  updatePbtCalculatorVariables,
} from "../../../../graphql/__generated-types__/updatePbtCalculator";
import { UPDATE_PBT_CALCULATOR_MUTATION } from "graphql/mutations/updatePbtCalculator";
import { PBT_CALCULATOR_FRAGMENT } from "graphql/fragments";
import { findOnePbtCalculator, findOnePbtCalculatorVariables } from "graphql/__generated-types__/findOnePbtCalculator";
import { FIND_ONE_PBT_CALCULATOR_QUERY } from "graphql/queries/findOnePbtCalculator";

interface IPbtCalculatorEditParams {
  id: string;
}

const PbtCalculatorEdit = () => {
  const params = useParams<keyof IPbtCalculatorEditParams>();
  const navigate = useNavigate();
  const { loading: pbtCalculatorLoading, data: pbtCalculatorData } = useQuery<
    findOnePbtCalculator,
    findOnePbtCalculatorVariables
  >(FIND_ONE_PBT_CALCULATOR_QUERY, {
    variables: {
      input:{
        id: +params.id,
      },
    },
  });

  const [updatePbtCalculator, { loading: updatePbtCalculatorLoading }] = useMutation<
    updatePbtCalculator,
    updatePbtCalculatorVariables
  >(UPDATE_PBT_CALCULATOR_MUTATION, {
    onCompleted: (updatePbtCalculatorData) => {
      if (updatePbtCalculatorData?.updatePbtCalculator?.ok) {
        navigate(serviceMenuRoutes?.pbtCalculators?.path);
      } else {
        toast.error(updatePbtCalculatorData?.updatePbtCalculator?.error);
      }
    },
    update(cache, { data: { updatePbtCalculator } }) {
      if (updatePbtCalculator?.pbtCalculator?.id) {
        cache.writeFragment({
          // @ts-ignore
          id: cache.identify(
            updatePbtCalculator?.pbtCalculator as unknown as StoreObject
          ),
          data: updatePbtCalculator?.pbtCalculator,
          fragment: PBT_CALCULATOR_FRAGMENT,
          fragmentName: "PbtCalculatorParts",
        });
      }
    },
  });
                                            
  const onSubmit = async (data: IPbtCalculatorForm) => {
    updatePbtCalculator({
      variables: {
        input: {
          id: +params.id,
          title: data.title,
          periodFrom: data.periodFrom,
          periodTo: data.periodTo,
          pbtCalculatorPositionIds: data?.pbtCalculatorPositions?.map((pbtCalculatorPositionId) => pbtCalculatorPositionId?.pbtPositionId),
          pbtCalculatorPositionSalaries: 
            data?.pbtCalculatorPositions?.map((pbtCalculatorPositionSalary) => 
              parseFloat(pbtCalculatorPositionSalary?.salary.toString().replace(/[a-zA-Z]/g, '').replace(/\s/g, '').replace(/[,]/g, '.'))),
          pbtCalculatorPositionWorkloads: 
            data?.pbtCalculatorPositions?.map((pbtCalculatorPositionWorkload) => 
              parseFloat(pbtCalculatorPositionWorkload?.workload.toString().replace(/[a-zA-Z]/g, '').replace(/\s/g, '').replace(/[,]/g, '.'))),
          pbtCalculatorBuyingConditionsAkDiscounts: 
            data?.pbtCalculatorBuyingConditions?.map((pbtCalculatorBuyingCondition) => 
              parseFloat(pbtCalculatorBuyingCondition?.akDiscount.toString().replace(/[a-zA-Z]/g, '').replace(/\s/g, '').replace(/[,]/g, '.'))),
          pbtCalculatorBuyingConditionsBudgets: 
            data?.pbtCalculatorBuyingConditions?.map((pbtCalculatorBuyingCondition) => 
              parseFloat(pbtCalculatorBuyingCondition?.budget.toString().replace(/[a-zA-Z]/g, '').replace(/\s/g, '').replace(/[,]/g, '.'))),
          pbtCalculatorBuyingConditionsOverallDiscounts: 
            data?.pbtCalculatorBuyingConditions?.map((pbtCalculatorBuyingCondition) => 
              parseFloat(pbtCalculatorBuyingCondition?.overallDiscount.toString().replace(/[a-zA-Z]/g, '').replace(/\s/g, '').replace(/[,]/g, '.'))),
          pbtCalculatorBuyingConditionsIds: data?.pbtCalculatorBuyingConditions?.map((pbtCalculatorBuyingCondition) => 
            pbtCalculatorBuyingCondition?.pbtBuyingConditionId 
            ? (!isNaN(
              Math.round(((
                parseFloat(
                  pbtCalculatorBuyingCondition?.pbtBuyingConditionId?.toString().substring(
                    0 , pbtCalculatorBuyingCondition?.pbtBuyingConditionId?.toString().indexOf('|') 
                  )
                ) ?? parseFloat('0')
              ) + Number.EPSILON) * 100) / 100 ?? parseFloat('0')
            ) ? (
              Math.round(((
                parseFloat(
                  pbtCalculatorBuyingCondition?.pbtBuyingConditionId?.toString().substring(
                    0 , pbtCalculatorBuyingCondition?.pbtBuyingConditionId?.toString().indexOf('|') 
                  )
                ) ?? parseFloat('0')
                ) + Number.EPSILON) * 100) / 100 ?? parseFloat('0')
            ) : parseFloat('0')
          ) : parseFloat('0')
          ),
        },
      },
    });
  };

  return !pbtCalculatorLoading && !updatePbtCalculatorLoading && pbtCalculatorData?.findOnePbtCalculator?.pbtCalculator ? (
    <PbtCalculatorForm
      onSubmit={onSubmit}
      pbtCalculator={pbtCalculatorData}
    />
  ) : (
    <DataLoader />
  );
};

export default PbtCalculatorEdit;
