import { useMutation } from "@apollo/client";
import { CONTRACT_FRAGMENT } from "graphql/fragments";
import { CREATE_CONTRACT_MUTATION } from "graphql/mutations/createContract";
import { createContract, createContractVariables } from "graphql/__generated-types__/createContract";
import { ContractDocType } from "graphql/__generated-types__/globalTypes";
import { adminMenuRoutes } from "navigation/routes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IInitialContractForm, InitialContractForm } from "../components";

const InitialContractCreate = ({ onClose }) => {
  const navigate = useNavigate();
  const [createContract] = useMutation<
    createContract,
    createContractVariables
  >(CREATE_CONTRACT_MUTATION, {
    onCompleted: (createContractData) => {
      if (createContractData?.createContract?.ok) {
        navigate(adminMenuRoutes?.initial_contracts?.path);
      } else {
        toast.error(createContractData?.createContract?.error);
      }
    },
    update(cache, { data: { createContract } }) {
      if (createContract?.contract?.id) {
        const newContractRef = cache.writeFragment({
          // @ts-ignore
          id: cache.identify(createContract?.contract),
          data: createContract?.contract,
          fragment: CONTRACT_FRAGMENT,
          fragmentName: "ContractParts",
        });
        cache.modify({
          id: "ROOT_QUERY",
          fields: {
            findAllContracts(prev) {
              return {
                ...prev,
                contracts: [
                  newContractRef,
                  ...prev.contracts,
                ],
              };
            },
          },
        });
      }
    },
  });

  const onSubmit = async (data: IInitialContractForm) => {
    let responseData;
    if (data.contractSubjectType !== 'MEDIATION' && data.contractActionType) {
      responseData = await createContract({
        variables: {
          input: {
            ...data,
            contractDocType: ContractDocType.INITIAL_CONTRACT,
            contractActionType: null,
          },
        },
      });
    } else {
      responseData = await createContract({
        variables: {
          input: {
            ...data,
            contractDocType: ContractDocType.INITIAL_CONTRACT,
          },
        },
      });
    }
    if (responseData?.data?.createContract?.ok) {
      onClose(true);
    }
  };
  return (
    <InitialContractForm
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default InitialContractCreate;