import { useQuery } from "@apollo/client";
import {
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { postTypeMap } from "config/mappings";
import { FIND_MANY_POSTS_QUERY } from "graphql/queries/findManyPosts";
import { findManyPosts, findManyPostsVariables } from "graphql/__generated-types__/findManyPosts";
import { PostParts } from "graphql/__generated-types__/PostParts";
import { newsMenuServicesRoutes } from "navigation/routes";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import { CellProps } from "react-table";
import {
  DataLoader,
  PrimaryButton,
  Modal,
  SelectColumnFilter,
  Table,
  SingleRowCell,
  AvatarCell,
} from "../../../../components";
import { LayoutButtonsContext } from "../../../Service/ServiceLayout";
import PostCreate from "./create";
import PostDelete from "./delete";
import PostEdit from "./edit";
import PostPin from "./pin";

const PostService = () => {

  
  const { setLayoutButtons } = useContext(LayoutButtonsContext);

  const hideEditingForm = useCallback(() => {
    setIsEditing(false);
    resetCurrentPost();
  }, []);

  const clickAddButton = useCallback(() => {
    hideEditingForm();
    setIsAdding(true);
  }, [hideEditingForm]);

  useEffect(() => {
    setLayoutButtons(
      <div className="absolute gap-x-2">
        <PrimaryButton
          icon={PlusIcon({
            className: "h-4 w-4",
            "aria-hidden": true,
          })}
          id="postAddButton"
          onClick={() => clickAddButton()}
        >
          Добавить
        </PrimaryButton>
      </div>
    );
    return () => {
      setLayoutButtons(null);
    };
  }, [clickAddButton, setLayoutButtons]);

  const [showDeletePostModal, setShowDeletePostModal] =
    useState(false);
  const [showPinPostModal, setShowPinPostModal] =
      useState(false);
  const [currentPost, setCurrentPost] =
    useState<PostParts>(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const resetCurrentPost = () => setCurrentPost(null);

  const hideAddingForm = useCallback(
    (reOpen = false) => {
      setIsAdding(false);
      resetCurrentPost();
      if (reOpen) {
        clickAddButton();
      }
    },
    [clickAddButton]
  );

  const clickEditButton = useCallback(
    (post: PostParts) => {
      hideAddingForm();
      hideEditingForm();
      setIsEditing(true);
      setCurrentPost(post);
      const postAddButton = document.getElementById(
        "postAddButton"
      );
      if (postAddButton) {
        postAddButton.scrollIntoView();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    [hideAddingForm, hideEditingForm]
  );

  const clickDeleteButton = useCallback(
    (post: PostParts) => {
      setShowDeletePostModal(!showDeletePostModal);
      setCurrentPost(post);
    },
    [showDeletePostModal]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Заголовок новости",
        accessor: (d: PostParts) =>
          d.header ? d.header : "Заголовок не занесен в БД",
        Cell: (props: CellProps<PostParts, string>) => (
          <SingleRowCell onClick={() => clickEditButton(props.row.original)}>
            <span className="cursor-pointer">{props.cell.value}</span>
          </SingleRowCell>
        ),
      },
      {
        Header: "Подзаголовок новости",
        accessor: (d: PostParts) =>
          d.underHeader ? d.underHeader : "Подзаголовок не занесен в БД",
        Cell: (props: CellProps<PostParts, string>) => (
          <SingleRowCell onClick={() => clickEditButton(props.row.original)}>
            <span className="cursor-pointer">{props.cell.value}</span>
          </SingleRowCell>
        ),
      },
      {
        Header: "Текст новости",
        cellClassName: "w-120",
        accessor: (d: PostParts) =>
          d.text ? d.text : "Текст не занесен в БД",
        Cell: (props: CellProps<PostParts, string>) => (
          <SingleRowCell onClick={() => clickEditButton(props.row.original)}>
            <ReactMarkdown disallowedElements={[ 'img', 'a' ]} unwrapDisallowed={true}>
              {(props.cell.value.substring(0, props.cell.value.indexOf('. ',45)).length > 10) ? (
                  props.cell.value.substring(0, props.cell.value.indexOf('. ',45)) + `...`
              )
              :
              (
                props.cell.value
              )}
            </ReactMarkdown>
          </SingleRowCell>
        ),
        disableGlobalFilter: true,
      },
      {
        Header: "Тип новости",
        accessor: (d: PostParts) =>
          d?.postType
            ? postTypeMap[d?.postType]
            : "Тип новости",
        Cell: (props: CellProps<PostParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
        disableGlobalFilter: true,
      },
      {
        Header: "Изображение новости",
        accessor: (d: PostParts) => d?.imageURL,
        Cell: (props: CellProps<PostParts, string>) => (
          <AvatarCell
            value={""}
            name={""}
            avatar={props.row.original.imageURL || null}
            subtitle={""}
          />
        ),
        disableGlobalFilter: true,
      },
      {
        Header: "Действие",
        Cell: (props: CellProps<PostParts>) => (
          <div className="flex gap-x-2">
            <PrimaryButton
              icon={PencilIcon({
                className: "h-4 w-4",
                "aria-hidden": true,
              })}
              disabled={isAdding}
              onClick={() => clickEditButton(props.row.original)}
            ></PrimaryButton>

            <PrimaryButton
              icon={TrashIcon({
                className: "h-4 w-4",
                "aria-hidden": true,
              })}
              disabled={isAdding || isEditing}
              onClick={() => clickDeleteButton(props.row.original)}
            ></PrimaryButton>
          </div>
        ),
        disableGlobalFilter: true,
      },
    ],
    [clickDeleteButton, clickEditButton, isAdding, isEditing]
  );

  const { loading, data: postsData } = useQuery<
    findManyPosts,
    findManyPostsVariables
  >(FIND_MANY_POSTS_QUERY, {
    variables:{
      input:{}
    }
  });

  const posts =
    postsData?.findManyPosts?.posts;

  return !loading ? (
    <>
      {(isAdding || isEditing) && (
        <div className="mb-2">
          {isAdding && <PostCreate onClose={hideAddingForm} path={newsMenuServicesRoutes?.postService?.path} />}
          {isEditing && (
            <PostEdit
              onClose={hideEditingForm}
              post={currentPost}
              path={newsMenuServicesRoutes?.postService?.path}
            />)}
        </div>
          )}

      {showDeletePostModal && (
        <Modal
          show={showDeletePostModal}
          showFn={setShowDeletePostModal}
        >
          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            {<PostDelete
              post={currentPost}
              path={newsMenuServicesRoutes?.postService?.path}
              closeModal={setShowDeletePostModal}
            />}
          </div>
        </Modal>
            )}

      {showPinPostModal && (
        <Modal
          show={showPinPostModal}
          showFn={setShowPinPostModal}
        >
          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            {<PostPin
              post={currentPost}
              path={newsMenuServicesRoutes?.postService?.path}
              closeModal={setShowPinPostModal}
            />}
          </div>
        </Modal>
            )}
      
      
      <div className="flex flex-col flex-1 pt-5 pb-4  bg-gray-100">  
        <div className="absolute right-12">
          <PrimaryButton
            icon={PlusIcon({
              className: "h-4 w-4",
              "aria-hidden": true,
            })}
            id="postAddButton"
            onClick={() => clickAddButton()}
          >
            Добавить
          </PrimaryButton>
        </div>
        <h1 className="text-2xl font-semibold text-gray-900 px-4 sm:px-6 md:px-8">
          Таблица новостей
        </h1>
      </div>
      <div className="overflow-x-auto lg:-mx-8 px-4 sm:px-6 bg-gray-100">
        <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-x-scroll border-b border-gray-200 sm:rounded-lg">
            {posts && (
              <Table
                columns={columns}
                data={posts || []}
                initialState={{
                  pageSize: 20,
                  pageIndex: 0,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};

export default PostService;
