import { useNavigate } from "react-router";
import { useMutation } from "@apollo/client";
import {
  createCase,
  createCaseVariables,
} from "../../../../graphql/__generated-types__/createCase";
import { CREATE_CASE_MUTATION } from "../../../../graphql/mutations";
import { serviceMenuRoutes } from "../../../../navigation/routes";
import { toast } from "react-toastify";
import { CaseForm } from "../components";
import { CASE_FRAGMENT } from "../../../../graphql/fragments";

const CaseCreate = ({ onClose }) => {
  const navigate = useNavigate();
  const [createCase] = useMutation<createCase, createCaseVariables>(
    CREATE_CASE_MUTATION,
    {
      onCompleted: (createCaseData) => {
        if (createCaseData?.createCase?.ok) {
          navigate(serviceMenuRoutes.cases.path);
        } else {
          toast.error(createCaseData?.createCase?.error);
        }
      },
      update(cache, { data: { createCase } }) {
        if (createCase?.battleCase?.id) {
          const newCaseRef = cache.writeFragment({
            // @ts-ignore
            id: cache.identify(createCase?.battleCase),
            data: createCase?.battleCase,
            fragment: CASE_FRAGMENT,
            fragmentName: "CaseParts",
          });
          cache.modify({
            id: "ROOT_QUERY",
            fields: {
              findAllCases(prev) {
                return {
                  ...prev,
                  battleCases: [newCaseRef, ...prev.battleCases],
                };
              },
            },
          });
        }
      },
    }
  );

  const onSubmit = async (data: any) => {
    delete data["externalLink"];
    const responseData = await createCase({
      variables: {
        input: {
          ...data,
        },
      },
    });
    if (responseData?.data?.createCase?.ok) {
      onClose();
    }
  };

  return <CaseForm onSubmit={onSubmit} onClose={onClose} />;
};

export default CaseCreate;
