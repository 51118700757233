import { Helmet } from "react-helmet-async";

const VacationCalendar = () => {
  return (
    <>
      <Helmet>
        <title> Календарь отпусков | Артикс Портал</title>
      </Helmet>
      <iframe title="VacationCalendar" src="https://calendar.yandex.ru/embed/month?private_token=884d598603ebfea2e5104a66884734620e310319&tz_id=Europe/Moscow" className="w-full h-80vh"></iframe>
    </>
  );
};

export default VacationCalendar;
