import Loader from "react-loader-spinner";
import { classNames } from "utils";

export const DataLoader = ({ size = undefined }) => {
  return (
    <div
      style={size && { top: size / 2, left: size / 2 }}
      className={classNames(!size && "left-1/2 top-1/2", "absolute z-1000")}
    >
      <Loader color="#f04444" type="Puff" height={size} width={size} />
    </div>
  );
};
