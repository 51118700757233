import { Controller, useForm } from "react-hook-form";
import React from "react";
import {
  Button,
  DataLoader,
  LabeledTextInput,
  PrimaryButton,
  ReactSelectInput,
} from "../../../../components";
import { useQuery } from "@apollo/client";
import {
  listClientIndustries,
  listClientIndustriesVariables,
} from "../../../../graphql/__generated-types__/listClientIndustries";
import {
  LIST_CLIENT_INDUSTRIES_QUERY,
  LIST_CLIENT_PROJECTS_QUERY,
} from "../../../../graphql/queries";
import { ClientParts } from "graphql/__generated-types__/ClientParts";
import {
  listClientProjects,
  listClientProjectsVariables,
} from "graphql/__generated-types__/listClientProjects";

interface IClientFormProps {
  client?: ClientParts;
  onSubmit: (data: any) => void;
  onClose: () => void;
}

export interface IClientForm {
  title: string;
  industryId: number;
  projectIds: number[];
}

export const AdminClientForm: React.FC<IClientFormProps> = ({
  client,
  onSubmit,
  onClose,
}) => {
  const label = client
    ? "Редактирование данных о клиенте"
    : "Добавление данных о клиенте";

  const { handleSubmit, control, setValue } = useForm<IClientForm>({
    mode: "onSubmit",
    shouldUnregister: true,
    defaultValues: client
      ? {
          ...client,
          industryId: client?.industry?.id,
          projectIds: client?.projects?.map((project) => project?.id),
        }
      : {
          title: null,
          industryId: null,
          projectIds: [],
        },
  });

  const { data: clientIndustriesData, loading: clientIndustriesLoading } =
    useQuery<listClientIndustries, listClientIndustriesVariables>(
      LIST_CLIENT_INDUSTRIES_QUERY,
      {
        variables: { input: {} },
      }
    );

  const { data: projectsData, loading: projectsLoading } = useQuery<
    listClientProjects,
    listClientProjectsVariables
  >(LIST_CLIENT_PROJECTS_QUERY, {
    variables: { input: {} },
  });

  const projectOptions =
    !projectsLoading &&
    projectsData?.listClientProjects?.projects?.map((project) => ({
      label: `${project?.title}`,
      value: project?.id,
    }));

  return !clientIndustriesLoading && !projectsLoading ? (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {label}
            </h3>
          </div>

          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                <Controller
                  control={control}
                  name="title"
                  rules={{ required: "Нужно заполнить название клиента" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Название клиента"
                      value={field.value === null ? "" : field.value}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                <Controller
                  control={control}
                  rules={{ required: "Нужно заполнить направление" }}
                  name="industryId"
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={clientIndustriesData?.listClientIndustries?.clientIndustries?.map(
                        (p) => {
                          return {
                            label: p?.title,
                            value: p?.id,
                          };
                        }
                      )}
                      name="industryId"
                      defaultValue={field.value}
                      value={field.value}
                      labelText="Направление"
                      placeholder="Выберите направление из списка"
                      fieldState={fieldState}
                      onSelect={(e) => setValue("industryId", e)}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                <Controller
                  control={control}
                  rules={{ required: "Нужно заполнить проекты клиента" }}
                  name="projectIds"
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      isMulti={true}
                      options={projectOptions}
                      name="projectIds"
                      value={field.value}
                      labelText="Проекты"
                      placeholder="Выберите проекты клиента из списка"
                      field={field}
                      fieldState={fieldState}
                      defaultValue={projectOptions?.filter((option) =>
                        field?.value?.some((id) => id === option.value)
                      )}
                      onSelect={(e) => {
                        setValue(
                          "projectIds",
                          e?.map((option) => option.value)
                        );
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-x-3">
        <Button type="button" onClick={() => onClose()}>
          Отмена
        </Button>
        <PrimaryButton type="submit">Сохранить</PrimaryButton>
      </div>
    </form>
  ) : (
    <DataLoader />
  );
};
