import { gql } from "@apollo/client";
import {PM_REPORT_FRAGMENT} from "../fragments";

export const FIND_ALL_PM_REPORTS_QUERY = gql`
  query findAllPmReports($input: FindAllPmReportsInput!) {
    findAllPmReports(input: $input) {
      ok
      error
      pmReports {
        ...PmReportParts
      }
      totalPages
      totalResults
    }
  }
  ${PM_REPORT_FRAGMENT}
`;
