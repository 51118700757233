import { gql } from "@apollo/client";

export const CALCULATE_BUYING_CASHBACK_MUTATION = gql`
  mutation calculateBuyingCashback($input: CalculateBuyingCashbackInput!) {
    calculateBuyingCashback(input: $input) {
      ok
      error
      cashbackSum
    }
  }
`;
