import { gql } from "@apollo/client";
import { BUYING_CASHBACK_RULE_FRAGMENT } from "graphql/fragments";

export const CREATE_BUYING_CASHBACK_RULE_MUTATION = gql`
  mutation createBuyingCashbackRule($input: CreateBuyingCashbackRuleInput!) {
    createBuyingCashbackRule(input: $input) {
      ok
      error
      buyingCashbackRule {
        ...BuyingCashbackRuleParts
      }
    }
  }
  ${BUYING_CASHBACK_RULE_FRAGMENT}
`;
