import { gql } from "@apollo/client";

export const DELETE_VENDOR_MUTATION = gql`
  mutation deleteVendor($input: DeleteVendorInput!) {
    deleteVendor(input: $input) {
      ok
      error
    }
  }
`;
