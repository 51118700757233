
import { useMutation } from "@apollo/client";
import { DELETE_INVOICE_MUTATION } from "graphql/mutations/deleteInvoice";
import { deleteInvoice, deleteInvoiceVariables } from "graphql/__generated-types__/deleteInvoice";
import { adminMenuRoutes } from "navigation/routes";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { InvoiceDeleteForm } from "../components/InvoiceDeleteFrom";

const InvoiceDelete = ({ invoice, closeModal }) => {
  const navigate = useNavigate();
  const [deleteInvoice] = useMutation<
    deleteInvoice,
    deleteInvoiceVariables
  >(DELETE_INVOICE_MUTATION, {
    onCompleted: (deleteInvoiceData) => {
      if (
        deleteInvoiceData &&
        deleteInvoiceData.deleteInvoice.ok
      ) {
        navigate(adminMenuRoutes?.invoices?.path);
      } else {
        toast.error(deleteInvoiceData.deleteInvoice.error);
      }
    },
    update(cache, { data: { deleteInvoice } }) {
      if (deleteInvoice?.ok) {
        cache.evict({ id: cache.identify(invoice) });
        cache.gc();
      }
    },
  });

  const onSubmit = async () => {
    await deleteInvoice({
      variables: {
        input: {
          id: invoice.id,
        },
      },
    });
    closeModal();
  };
  const onClose = () => {
    closeModal();
  };

  return <InvoiceDeleteForm onSubmit={onSubmit} onClose={onClose} />;
};
export default InvoiceDelete;