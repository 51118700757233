import { gql } from "@apollo/client";
import { VENDOR_FRAGMENT } from "../fragments";

export const CREATE_VENDOR_MUTATION = gql`
  mutation createVendor($input: CreateVendorInput!) {
    createVendor(input: $input) {
      ok
      error
      vendor {
        ...VendorParts
      }
    }
  }
  ${VENDOR_FRAGMENT}
`;
