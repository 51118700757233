import { formatFullName, formatMonth, formatPercent } from "utils";
import {
  AvatarCell,
  DataLoader,
  DoubleRowCell,
  PrimaryButton,
  Modal,
  SelectColumnFilter,
  Table,
  SingleRowCell,
} from "../../../components";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FIND_ALL_PBT_REPORTS_QUERY } from "graphql/queries";
import { useQuery } from "@apollo/client";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import {
  findAllPbtReports,
  findAllPbtReportsVariables,
} from "../../../graphql/__generated-types__/findAllPbtReports";
import PbtReportDelete from "./delete";
import PbtReportCreate from "./create";
import { useMe } from "../../../hooks";
import PbtReportEdit from "./edit";
import { PbtReportParts } from "graphql/__generated-types__/PbtReportParts";
import { LayoutButtonsContext } from "../ServiceLayout";
import { CellProps, FooterProps } from "react-table";
import { Helmet } from "react-helmet-async";

const PbtReport = () => {
  const { setLayoutButtons } = useContext(LayoutButtonsContext);

  const hideEditingForm = useCallback(() => {
    setIsEditing(false);
    resetCurrentPbtReport();
  }, []);

  const clickAddButton = useCallback(() => {
    hideEditingForm();
    setIsAdding(true);
  }, [hideEditingForm]);

  useEffect(() => {
    setLayoutButtons(
      <div className="flex gap-x-2">
        {/*<PrimaryButton
          icon={TableIcon({
            className: "h-4 w-4",
            "aria-hidden": true,
          })}
          id="pbtImportButton"
          onClick={() => clickImportButton()}
        >
          Верификация и импорт
        </PrimaryButton>*/}
        <PrimaryButton
          icon={PlusIcon({
            className: "h-4 w-4",
            "aria-hidden": true,
          })}
          id="pbtAddButton"
          onClick={() => clickAddButton()}
        >
          Добавить
        </PrimaryButton>
      </div>
    );
    return () => {
      setLayoutButtons(null);
    };
  }, [clickAddButton, setLayoutButtons]);

  const [showDeletePbtReportModal, setShowDeletePbtReportModal] =
    useState(false);
  const [currentPbtReport, setCurrentPbtReport] =
    useState<PbtReportParts>(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  //const [showImportPbtReportModal, setShowImportPbtReportModal] = useState(false);

  const resetCurrentPbtReport = () => setCurrentPbtReport(null);

  const hideAddingForm = useCallback(
    (reOpen = false) => {
      setIsAdding(false);
      resetCurrentPbtReport();
      if (reOpen) {
        clickAddButton();
      }
    },
    [clickAddButton]
  );

  const clickEditButton = useCallback(
    (pbtReport: PbtReportParts) => {
      hideAddingForm();
      hideEditingForm();
      setIsEditing(true);
      setCurrentPbtReport(pbtReport);
      const pbtAddButton = document.getElementById("pbtAddButton");
      if (pbtAddButton) {
        pbtAddButton.scrollIntoView();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    [hideAddingForm, hideEditingForm]
  );

  const clickDeleteButton = useCallback(
    (pbtReport: PbtReportParts) => {
      setShowDeletePbtReportModal(!showDeletePbtReportModal);
      setCurrentPbtReport(pbtReport);
    },
    [showDeletePbtReportModal]
  );

  /*
  const clickImportButton = () => {
      setShowImportPbtReportModal(true);
  };
  */

  const columns = useMemo(
    () => [
      {
        Header: "Месяц",
        accessor: (d: PbtReportParts) =>
          d.monthYear ? formatMonth(d.monthYear) : null,
        Cell: (props: CellProps<PbtReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Клиент",
        accessor: (d: PbtReportParts) =>
          d.project?.client?.title || "Клиент не указан",
        Cell: (props: CellProps<PbtReportParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Проект-Вертикаль",
        accessor: (d: PbtReportParts) =>
          d.vertical?.title || "Вертикаль не указана",
        Cell: (props: CellProps<PbtReportParts, string>) => (
          <DoubleRowCell
            value={props.cell.value}
            firstRow={props.row.original.project?.title || "Проект не указан"}
          />
        ),
      },
      {
        Header: "Проект",
        accessor: (d: PbtReportParts) => d.project?.title || "Проект не указан",
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Вертикаль",
        accessor: (d: PbtReportParts) =>
          d.vertical?.title || "Вертикаль не указана",
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Сотрудник",
        accessor: (d: PbtReportParts) => formatFullName(d.user),
        Cell: (props: CellProps<PbtReportParts>) => (
          <AvatarCell
            value={props.row.original.user?.surname}
            name={props.row.original.user?.name}
            avatar={props.row.original.user?.avatar}
            subtitle={props.row.original.user?.position?.title}
          />
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Загрузка на проекте (в процентах)",
        Footer: (info: FooterProps<PbtReportParts>) => {
          const total = useMemo(
            () =>
              info.rows.reduce((sum, row) => row.values.workPercent + sum, 0),
            [info.rows]
          );
          return <>Всего: {formatPercent(total)}</>;
        },
        accessor: "workPercent",
        Cell: (props: CellProps<PbtReportParts>) => (
          <SingleRowCell>
            {formatPercent(props.row.original.workPercent)}
          </SingleRowCell>
        ),
      },
      {
        Header: "Действие",
        Cell: (props: any) => (
          <div className="flex gap-x-2">
            <PrimaryButton
              icon={PencilIcon({
                className: "h-4 w-4",
                "aria-hidden": true,
              })}
              disabled={isAdding}
              onClick={() => clickEditButton(props.row.original)}
            ></PrimaryButton>

            <PrimaryButton
              icon={TrashIcon({
                className: "h-4 w-4",
                "aria-hidden": true,
              })}
              disabled={isAdding || isEditing}
              onClick={() => clickDeleteButton(props.row.original)}
            ></PrimaryButton>
          </div>
        ),
      },
    ],
    [clickDeleteButton, clickEditButton, isAdding, isEditing]
  );

  const { data: userData } = useMe({
    fetchPolicy: "network-only",
  });

  const input = {
    userIds: [],
    departmentsIds: [],
  };

  if (userData?.me?.user?.headOfDepartment?.length) {
    input.departmentsIds = userData.me.user.headOfDepartment.map((e) => e.id);
    input.userIds = [userData.me.user.id];
  } else if (userData?.me?.user?.subordinates.length) {
    input.userIds = userData.me.user.subordinates
      .map((e) => e.id)
      .concat([userData.me.user.id]);
  } else {
    input.userIds = [userData?.me?.user?.id];
  }

  const { loading, data: pbtReportsData } = useQuery<
    findAllPbtReports,
    findAllPbtReportsVariables
  >(FIND_ALL_PBT_REPORTS_QUERY, {
    variables: {
      input: {
        ...input,
      },
    },
  });

  const pbtReports = useMemo(
    () => pbtReportsData?.findAllPbtReports?.pbtReports,
    [pbtReportsData?.findAllPbtReports?.pbtReports]
  );

  return !loading ? (
    <>
      <Helmet>
        <title> Загруженность на проектах | Артикс Портал</title>
      </Helmet>
      {(isAdding || isEditing) && (
        <div className="mb-2">
          {isAdding && <PbtReportCreate onClose={hideAddingForm} />}
          {isEditing && (
            <PbtReportEdit
              onClose={hideEditingForm}
              pbtReport={currentPbtReport}
            />
          )}
        </div>
      )}

      {showDeletePbtReportModal && (
        <Modal
          show={showDeletePbtReportModal}
          showFn={setShowDeletePbtReportModal}
        >
          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <PbtReportDelete
              pbtReport={currentPbtReport}
              closeModal={setShowDeletePbtReportModal}
            />
          </div>
        </Modal>
      )}

      {/*showImportPbtReportModal && (
        <Modal
          show={showImportPbtReportModal}
          showFn={setShowImportPbtReportModal}
        >
          <div className="inline-block align-bottom bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-6xl sm:w-full max-h-screen">
            <PBTVertificationModule
              post_url={'pbt_import'}
              headerText={'Верификация и импорт ПБТ-отчета'}
            />
          </div>
      </Modal>
      )*/}

      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {pbtReports && (
                <Table
                  columns={columns}
                  data={pbtReports || []}
                  skipColumns={["Вертикаль", "Проект"]}
                  initialState={{
                    pageSize: 20,
                    pageIndex: 0,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};

export default PbtReport;
