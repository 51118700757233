import { ScrollUpButton } from "./Button";
import { Navbar } from "./Navbar";

export const Layout: React.FC = ({ children }) => (
  <>
    <Navbar />
    <main className="pt-16">{children}</main>
    <ScrollUpButton />
  </>
);
