import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  listLegalnames,
  listLegalnamesVariables,
} from "../../../graphql/__generated-types__/listLegalnames";
import { LIST_LEGALNAMES_QUERY } from "../../../graphql/queries";
import {
  DataLoader,
  PrimaryButton,
  SelectColumnFilter,
  SingleRowCell,
  StatusPill,
  Table,
} from "../../../components";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import AdminLegalnameCreate from "./create/create";
import AdminLegalnameEdit from "./edit";
import AdminLegalnameDelete from "./delete";
import { LayoutButtonsContext } from "../AdminLayout";
import { LegalnameParts } from "graphql/__generated-types__/LegalnameParts";
import { CellProps } from "react-table";
import { Helmet } from "react-helmet-async";
import { UserRole } from "graphql/__generated-types__/globalTypes";
import { useMe } from "hooks";
import { formatPhone } from "utils";
import { legalnameTypeStatusMap } from "config/mappings";

const AdminLegalnames = () => {
  const { setLayoutButtons } = useContext(LayoutButtonsContext);

  const user = useMe();

  const hideEditingForm = useCallback(() => {
    setIsEditing(false);
    setCurrentLegalname(null);
  }, []);

  const clickAddButton = useCallback(() => {
    hideEditingForm();
    setIsAdding(true);
  }, [hideEditingForm]);

  useEffect(() => {
    setLayoutButtons(
      <div className="flex gap-x-2">
        {(user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
          user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
          <PrimaryButton
            icon={PlusIcon({
              className: "h-4 w-4",
              "aria-hidden": true,
            })}
            id="legalnameAddButton"
            onClick={() => clickAddButton()}
          >
            Добавить
          </PrimaryButton>
        )}
      </div>
    );
    return () => {
      setLayoutButtons(null);
    };
  }, [clickAddButton, setLayoutButtons, user?.data?.me?.user?.roles]);

  const [showDeleteLegalnameModal, setShowDeleteLegalnameModal] =
    useState(false);
  const [currentLegalname, setCurrentLegalname] =
    useState<LegalnameParts>(null);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const hideAddingForm = useCallback(
    (reOpen = false) => {
      setIsAdding(false);
      setCurrentLegalname(null);
      reOpen && clickAddButton();
    },
    [setIsAdding, setCurrentLegalname, clickAddButton]
  );

  const clickDeleteButton = useCallback(
    (legalname: LegalnameParts) => {
      setShowDeleteLegalnameModal(!showDeleteLegalnameModal);
      setCurrentLegalname(legalname);
    },
    [setShowDeleteLegalnameModal, setCurrentLegalname, showDeleteLegalnameModal]
  );

  const clickEditButton = useCallback(
    (legalname: LegalnameParts) => {
      hideAddingForm();
      hideEditingForm();
      setIsEditing(true);
      setCurrentLegalname(legalname);
      const legalnameAddButton = document.getElementById("legalnameAddButton");
      if (legalnameAddButton) {
        legalnameAddButton.scrollIntoView();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    [hideAddingForm, hideEditingForm, setIsEditing, setCurrentLegalname]
  );

  const { loading, data: legalnameData } = useQuery<
    listLegalnames,
    listLegalnamesVariables
  >(LIST_LEGALNAMES_QUERY, {
    variables: {
      input: {
        includeSellers: true,
        includeProjects: true,
      },
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: "Название",
        accessor: (d: LegalnameParts) => d.title,
        Cell: (props: CellProps<LegalnameParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Валюта",
        accessor: (d: LegalnameParts) => {
          return d.currency;
        },
        Cell: (
          props: CellProps<LegalnameParts, LegalnameParts["currency"]>
        ) => <SingleRowCell>{props.cell.value}</SingleRowCell>,
        id: "currency",
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "НДС",
        accessor: (d: LegalnameParts) => {
          return d.isVat ? "Да" : "Нет";
        },
        Cell: (props: CellProps<LegalnameParts, "Да" | "Нет">) => (
          <StatusPill
            isPositive={props.row.original.isVat}
            value={props.row.original.isVat ? "Да" : "Нет"}
          />
        ),
        id: "isVat",
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Наше ЮЛ",
        accessor: (d: LegalnameParts) => {
          return d.isArtics ? "Да" : "Нет";
        },
        Cell: (props: CellProps<LegalnameParts, "Да" | "Нет">) => (
          <StatusPill
            isPositive={props.row.original.isArtics}
            value={props.row.original.isArtics ? "Да" : "Нет"}
          />
        ),
        id: "isArtics",
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Селлеры ЮЛ",
        Cell: (props: CellProps<LegalnameParts, string>) => {
          if (props.row.original?.sellers) {
            return (
              <SingleRowCell>
                {props.row.original?.sellers?.map((seller) => (
                  <p>{seller?.title}</p>
                ))}
              </SingleRowCell>
            );
          } else return null;
        },
        disableGlobalFilter: true,
      },
      {
        Header: "Тип ЮЛ",
        accessor: (d: LegalnameParts) =>
          d?.legalnameType
            ? legalnameTypeStatusMap[d?.legalnameType]
            : "Статус договора не указан",
        Cell: (props: CellProps<LegalnameParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "ИНН",
        accessor: (d: LegalnameParts) => d?.ITN,
        Cell: (props: CellProps<LegalnameParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "ОГРН",
        accessor: (d: LegalnameParts) => d?.PSRN,
        Cell: (props: CellProps<LegalnameParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Телефон клиента",
        accessor: (d: LegalnameParts) => formatPhone(d?.phone),
        Cell: (props: CellProps<LegalnameParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Номер электронного средства платежа",
        accessor: (d: LegalnameParts) => d?.epayNumber,
        Cell: (props: CellProps<LegalnameParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Код страны регистрации ЮЛ в соответствии с ОКСМ",
        accessor: (d: LegalnameParts) => d?.oksmNumber,
        Cell: (props: CellProps<LegalnameParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Действие",
        Cell: (props: CellProps<LegalnameParts>) => (
          <div className="flex gap-x-2">
            {user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
            user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN) ? (
              <>
                <PrimaryButton
                  icon={PencilIcon({
                    className: "h-4 w-4",
                    "aria-hidden": true,
                  })}
                  disabled={isAdding}
                  onClick={() => clickEditButton(props.row.original)}
                ></PrimaryButton>

                <PrimaryButton
                  icon={TrashIcon({
                    className: "h-4 w-4",
                    "aria-hidden": true,
                  })}
                  disabled={isAdding || isEditing}
                  onClick={() => clickDeleteButton(props.row.original)}
                ></PrimaryButton>
              </>
            ) : (
              <SingleRowCell>Н/Д</SingleRowCell>
            )}
          </div>
        ),
      },
    ],
    [
      clickDeleteButton,
      clickEditButton,
      isAdding,
      isEditing,
      user?.data?.me?.user?.roles,
    ]
  );

  const legalnames = useMemo(
    () => legalnameData?.listLegalnames?.legalnames,
    [legalnameData?.listLegalnames?.legalnames]
  );

  return !loading ? (
    <>
      <Helmet>
        <title> Юр. Лица | Артикс Портал</title>
      </Helmet>
      {(isAdding || isEditing) && (
        <div className="mb-2">
          {isAdding &&
            (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
              user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
              <AdminLegalnameCreate onClose={hideAddingForm} />
            )}
          {isEditing &&
            (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
              user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
              <AdminLegalnameEdit
                onClose={hideEditingForm}
                legalname={currentLegalname}
              />
            )}
        </div>
      )}

      {showDeleteLegalnameModal &&
        (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
          user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
          <AdminLegalnameDelete
            show={showDeleteLegalnameModal}
            showFn={setShowDeleteLegalnameModal}
            legalname={currentLegalname}
            closeModal={setShowDeleteLegalnameModal}
          />
        )}

      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <Table
                columns={columns}
                skipColumns={[]}
                data={legalnames}
                initialState={{
                  pageSize: 20,
                  pageIndex: 0,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};

export default AdminLegalnames;
