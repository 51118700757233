import { useMutation } from "@apollo/client";
import { POST_FRAGMENT } from "graphql/fragments";
import { CREATE_POST_MUTATION } from "graphql/mutations/createPost";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createPost,
  createPostVariables,
} from "../../../../../graphql/__generated-types__/createPost";
import { IPostForm, PostForm } from "../components";

const PostCreate = ({ onClose, path }) => {
  const navigate = useNavigate();
  const [createPost] = useMutation<
    createPost,
    createPostVariables
  >(CREATE_POST_MUTATION, {
    onCompleted: (createPostData) => {
      if (createPostData?.createPost?.ok) {
        navigate(path);
      } else {
        toast.error(createPostData?.createPost?.error);
      }
    },
    update(cache, { data: { createPost } }) {
      if (createPost?.post?.id) {
        const newPostRef = cache.writeFragment({
          // @ts-ignore
          id: cache.identify(createPost?.post),
          data: createPost?.post,
          fragment: POST_FRAGMENT,
          fragmentName: "PostParts",
        });
        cache.modify({
          id: "ROOT_QUERY",
          fields: {
            findAllPosts(prev) {
              return {
                ...prev,
                posts: [
                  newPostRef,
                  ...prev.posts,
                ],
              };
            },
          },
        });
      }
    },
  });

  const onSubmit = async (data: IPostForm) => {
    const responseData = await createPost({
      variables: {
        input: {
          ...data,
        },
      },
    });
    if (responseData?.data?.createPost?.ok) {
      onClose(true);
    }
  };
  return (
    <PostForm
      post={null}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default PostCreate;
