import { gql } from "@apollo/client";
import { RANDOM_COFFEE_FRAGMENT } from "graphql/fragments";

export const UPDATE_RANDOM_COFFEE_MUTATION = gql`
  mutation updateRandomCoffee($input: UpdateRandomCoffeeInput!) {
    updateRandomCoffee(input: $input) {
      ok
      error
      randomCoffee {
        ...RandomCoffeeParts
      }
    }
  }
  ${RANDOM_COFFEE_FRAGMENT}
`;
