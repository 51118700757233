import { useMutation } from "@apollo/client";
import {
  deletePost,
  deletePostVariables,
} from "../../../../../graphql/__generated-types__/deletePost";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { DELETE_POST_MUTATION } from "graphql/mutations/deletePost";
import { PostDeleteForm } from "../components";

const PostDelete = ({ post, closeModal, path }) => {
  const navigate = useNavigate();
  const [deletePost] = useMutation<
    deletePost,
    deletePostVariables
  >(DELETE_POST_MUTATION, {
    onCompleted: (deletePostData) => {
      if (
        deletePostData &&
        deletePostData?.deletePost?.ok
      ) {
        navigate(path);
      } else {
        toast.error(deletePostData.deletePost.error);
      }
    },
    update(cache, { data: { deletePost } }) {
      if (deletePost?.ok) {
        cache.evict({ id: cache.identify(post) });
        cache.gc();
      }
    },
  });

  const onSubmit = async () => {
    await deletePost({
      variables: {
        input: {
          id: post.id,
        },
      },
    });
    closeModal();
  };
  const onClose = () => {
    closeModal();
  };

  return <PostDeleteForm onSubmit={onSubmit} onClose={onClose} />;
};
export default PostDelete;
