import { useMutation } from "@apollo/client";
import { DeleteModal } from "components";
import { DELETE_BUYING_CASHBACK_TRANSACTION_MUTATION } from "graphql/mutations/deleteBuyingCashbackTransaction";
import { BuyingCashbackTransactionParts } from "graphql/__generated-types__/BuyingCashbackTransactionParts";
import {
  deleteBuyingCashbackTransaction,
  deleteBuyingCashbackTransactionVariables,
} from "graphql/__generated-types__/deleteBuyingCashbackTransaction";
import { serviceMenuRoutes } from "navigation/routes";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

interface IBuyingCashbackTransactionDeleteProps {
  show: boolean;
  showFn: React.Dispatch<React.SetStateAction<boolean>>;
  buyingCashbackTransaction: BuyingCashbackTransactionParts;
}

const BuyingCashbackTransactionDelete: React.FC<
  IBuyingCashbackTransactionDeleteProps
> = ({ show, showFn, buyingCashbackTransaction }) => {
  const navigate = useNavigate();

  const [deleteBuyingCashbackTransaction] = useMutation<
    deleteBuyingCashbackTransaction,
    deleteBuyingCashbackTransactionVariables
  >(DELETE_BUYING_CASHBACK_TRANSACTION_MUTATION, {
    onCompleted: (data) => {
      if (data?.deleteBuyingCashbackTransaction?.ok) {
        navigate(serviceMenuRoutes.buyingCashbackTransactions.path);
      } else {
        toast.error(data?.deleteBuyingCashbackTransaction?.error);
      }
    },
    update(cache, { data: { deleteBuyingCashbackTransaction } }) {
      if (deleteBuyingCashbackTransaction?.ok) {
        cache.evict({ id: cache.identify(buyingCashbackTransaction as any) });
        cache.gc();
      }
    },
  });

  const onSubmit = async () => {
    await deleteBuyingCashbackTransaction({
      variables: {
        input: {
          id: buyingCashbackTransaction.id,
        },
      },
    });
    showFn(false);
  };

  return (
    <DeleteModal
      show={show}
      showFn={showFn}
      headerText={"Удаление запись биллингового кэшбека"}
      text={"Вы уверены, что хотите удалить транзакцию кэшбека?"}
      onSubmit={onSubmit}
      onClose={() => showFn(false)}
    />
  );
};
export default BuyingCashbackTransactionDelete;
