import { gql } from "@apollo/client";
import { INVOICE_FRAGMENT } from "graphql/fragments";

export const FIND_ALL_INVOICES_QUERY = gql`
  query findAllInvoices($input: FindAllInvoicesInput!) {
    findAllInvoices(input: $input) {
      ok
      error
      invoices {
          ...InvoiceParts
      }
      totalPages
      totalResults
    }
  }
  ${INVOICE_FRAGMENT}
`;