import {gql} from "@apollo/client";
import { POST_FRAGMENT } from "graphql/fragments";

export const UPDATE_POST_MUTATION = gql`
  mutation updatePost($input: UpdatePostInput!) {
    updatePost(input: $input) {
        ok
        error
        post {
            ...PostParts
        }
    }
  }
  ${POST_FRAGMENT}
`;
