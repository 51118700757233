import { useMutation } from "@apollo/client";
import { DeleteModal } from "components";
import { DELETE_CONTRACT_MUTATION } from "graphql/mutations/deleteContract";
import {
  deleteContract,
  deleteContractVariables,
} from "graphql/__generated-types__/deleteContract";
import { adminMenuRoutes } from "navigation/routes";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const InitialContractDelete = ({ show, showFn, contract, closeModal }) => {
  const navigate = useNavigate();

  const [deleteContract] = useMutation<deleteContract, deleteContractVariables>(
    DELETE_CONTRACT_MUTATION,
    {
      onCompleted: (deleteContractData) => {
        if (deleteContractData?.deleteContract?.ok) {
          navigate(adminMenuRoutes?.initial_contracts?.path);
        } else {
          toast.error(deleteContractData?.deleteContract?.error);
        }
      },
      update(cache, { data: { deleteContract } }) {
        if (deleteContract?.ok) {
          cache.evict({ id: cache.identify(contract) });
          cache.gc();
        }
      },
    }
  );

  const onSubmit = async () => {
    await deleteContract({
      variables: {
        input: {
          id: contract.id,
        },
      },
    });
    closeModal();
  };

  return (
    <DeleteModal
      show={show}
      showFn={showFn}
      headerText="Удаление договора"
      text="Вы уверены, что хотите удалить данный изначальрный договор? Данное действие не может быть отменено"
      onClose={closeModal}
      onSubmit={onSubmit}
    />
  );
};
export default InitialContractDelete;
