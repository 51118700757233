import ArticsLogo from "assets/artics-logo-square.png";
import { Link } from "react-router-dom";

const NotFound = () => (
  <div className="min-h-screen pt-16 pb-12 flex flex-col bg-white">
    <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex-shrink-0 flex justify-center">
        <a href="/" className="inline-flex">
          <span className="sr-only">Portal</span>
          <img className="h-12 w-auto" src={ArticsLogo} alt="" />
        </a>
      </div>
      <div className="py-16">
        <div className="text-center">
          <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">
            ошибка 404
          </p>
          <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
            Страница не найдена.
          </h1>
          <p className="mt-2 text-base text-gray-500">
            Проверьте ссылку, возможно вы опечатались.
          </p>
          <div className="mt-6">
            <Link
              to="/"
              className="text-base font-medium text-indigo-600 hover:text-indigo-500"
            >
              Вернуться на главную<span aria-hidden="true"> &rarr;</span>
            </Link>
          </div>
        </div>
      </div>
    </main>
  </div>
);

export default NotFound;
