import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  DataLoader,
  LabeledMonthInput,
  LabeledTextInput,
  LabeledTextInputWithTrailingAddon,
  PrimaryButton,
  ReactSelectInput,
} from "../../../../components";
import {
  listClientProjects,
  listClientProjectsVariables,
} from "../../../../graphql/__generated-types__/listClientProjects";
import {
  FIND_ALL_USERS_QUERY,
  LIST_CLIENT_PROJECTS_QUERY,
  LIST_LEGALNAMES_QUERY,
  LIST_VENDORS_QUERY,
} from "../../../../graphql/queries";
import {
  listLegalnames,
  listLegalnamesVariables,
} from "../../../../graphql/__generated-types__/listLegalnames";
import {
  listVendors,
  listVendorsVariables,
} from "../../../../graphql/__generated-types__/listVendors";
import { useMe } from "../../../../hooks";
import { formatFullName } from "../../../../utils";
import {
  findAllUsers,
  findAllUsersVariables,
} from "../../../../graphql/__generated-types__/findAllUsers";
import { DepartmentPlanParts } from "graphql/__generated-types__/DepartmentPlanParts";

export interface IDepartmentPlanForm {
  monthYear: string;
  projectVerticalId: number;
  userId: number;
  vendorId: number;
  legalnameId: number;
  departmentId: number;
  turnover: number;
  margin: number;
  budgetProbability: number;
}

interface IDepartmentPlanProps {
  departmentPlan?: DepartmentPlanParts;
  onSubmit: (data: any) => void;
  onClose: () => void;
}

export const DepartmentPlanForm: React.FC<IDepartmentPlanProps> = ({
  departmentPlan,
  onSubmit,
  onClose,
}) => {
  const { data: userData } = useMe();
  const headOfDepartment = !!userData?.me?.user?.headOfDepartment?.length;

  const groupHead =
    !headOfDepartment && !!userData?.me?.user?.subordinates?.length;

  const [showDepartmentChoice, setShowDepartmentChoice] = useState(false);

  const label = departmentPlan
    ? "Редактирование данных плана"
    : "Добавление данных";

  const { data: projectsData, loading: projectsDataLoading } = useQuery<
    listClientProjects,
    listClientProjectsVariables
  >(LIST_CLIENT_PROJECTS_QUERY, {
    variables: {
      input: {
        includeClients: true,
        includeVerticals: true,
      },
    },
  });

  const { data: legalnamesData, loading: legalnamesDataLoading } = useQuery<
    listLegalnames,
    listLegalnamesVariables
  >(LIST_LEGALNAMES_QUERY, {
    variables: {
      input: {},
    },
  });

  const { data: vendorsData, loading: vendorsLoading } = useQuery<
    listVendors,
    listVendorsVariables
  >(LIST_VENDORS_QUERY, {
    variables: {
      input: {},
    },
  });

  const { data: employeesData, loading: employeesLoading } = useQuery<
    findAllUsers,
    findAllUsersVariables
  >(FIND_ALL_USERS_QUERY, {
    variables: {
      input: {
        departmentIds: userData?.me?.user?.headOfDepartment?.map((e) => e.id),
        exceptEmployeeIds: [userData?.me?.user?.id],
      },
    },
    skip: !headOfDepartment,
  });

  const currentDate = new Date();

  const { handleSubmit, control, setValue } = useForm<IDepartmentPlanForm>({
    mode: "onSubmit",
    shouldUnregister: true,
    defaultValues: departmentPlan
      ? {
          ...departmentPlan,
          projectVerticalId: departmentPlan.projectVerticalId,
          vendorId: departmentPlan.vendor.id,
          legalnameId: departmentPlan.legalname.id,
          monthYear: new Date(departmentPlan.monthYear).toISOString(),
          userId: departmentPlan?.user?.id,
          departmentId: departmentPlan?.department?.id,
        }
      : {
          monthYear: new Date(
            Date.UTC(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              1,
              0,
              0,
              0
            )
          ).toISOString(),
          projectVerticalId: null,
          vendorId: null,
          userId: null,
          departmentId: null,
          legalnameId: null,
          budgetProbability: null,
          turnover: null,
          margin: null,
        },
  });

  return !projectsDataLoading &&
    !vendorsLoading &&
    !legalnamesDataLoading &&
    !employeesLoading ? (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {label && label}
            </h3>
          </div>

          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <Controller
                  control={control}
                  rules={{ required: "Нужно заполнить дату" }}
                  name="monthYear"
                  render={({ field, fieldState }) => (
                    <LabeledMonthInput
                      labelText="Месяц"
                      field={field}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-4">
                <Controller
                  control={control}
                  name="projectVerticalId"
                  rules={{
                    required: "Нужно заполнить Клиент-Проект-Вертикаль",
                  }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={
                        projectsData &&
                        projectsData.listClientProjects.projects
                          .map((p) => {
                            return p?.verticals?.map((v) => {
                              return {
                                label: `${p.client?.title} : ${p.title} : ${v?.title}`,
                                value: v.id,
                              };
                            });
                          })
                          .flat()
                      }
                      name="projectVerticalId"
                      defaultValue={field.value}
                      value={field.value}
                      labelText="Клиент-Проект-Вертикаль"
                      placeholder="Выберите проект из списка"
                      fieldState={fieldState}
                      onSelect={(e) => setValue("projectVerticalId", e)}
                    />
                  )}
                />
              </div>
              {/* Если юзер руководитель отдела(-ов)*/}
              {(headOfDepartment || groupHead) && (
                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <Controller
                    control={control}
                    name="userId"
                    rules={{ required: "Нужно заполнить сотрудника" }}
                    render={({ field, fieldState }) => (
                      <ReactSelectInput
                        options={[
                          {
                            label: formatFullName(userData.me.user),
                            value: userData.me.user.id,
                          },
                        ].concat(
                          groupHead
                            ? userData?.me?.user?.subordinates?.map((s) => ({
                                label: formatFullName(s),
                                value: s.id,
                              }))
                            : employeesData?.findAllUsers?.users?.map((u) => ({
                                label: formatFullName(u),
                                value: u.id,
                              }))
                        )}
                        defaultValue={field.value}
                        name="userId"
                        value={field.value}
                        labelText="Сотрудник"
                        onSelect={(e) => {
                          setValue("userId", e);
                          if (
                            headOfDepartment &&
                            e.value === userData?.me?.user?.id
                          ) {
                            if (
                              userData?.me?.user?.headOfDepartment.length > 1
                            ) {
                              setShowDepartmentChoice(true);
                            } else {
                              setValue(
                                "departmentId",
                                userData?.me?.user?.headOfDepartment[0].id
                              );
                            }
                          } else {
                            setShowDepartmentChoice(false);
                            setValue(
                              "departmentId",
                              userData?.me?.user?.department?.id
                            );
                          }
                        }}
                        placeholder="Выберите сотрудника из списка"
                        fieldState={fieldState}
                      />
                    )}
                  />
                </div>
              )}

              {showDepartmentChoice && (
                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <Controller
                    control={control}
                    name="departmentId"
                    rules={{ required: "Нужно заполнить отдел" }}
                    render={({ field, fieldState }) => (
                      <ReactSelectInput
                        options={userData?.me?.user?.headOfDepartment.map(
                          (dep) => ({ label: dep.title, value: dep.id })
                        )}
                        name="departmentId"
                        value={field.value}
                        labelText="Отдел"
                        onSelect={(e) => setValue("departmentId", e)}
                        placeholder="Выберите отдел из списка"
                        fieldState={fieldState}
                      />
                    )}
                  />
                </div>
              )}

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="vendorId"
                  rules={{ required: "Нужно заполнить площадку" }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={
                        vendorsData &&
                        vendorsData.listVendors.vendors.map((v) => {
                          return {
                            label: v.title,
                            value: v.id,
                          };
                        })
                      }
                      name="vendorId"
                      defaultValue={field.value}
                      value={field.value}
                      labelText="Площадка"
                      placeholder="Выберите площадку из списка"
                      fieldState={fieldState}
                      onSelect={(e) => setValue("vendorId", e)}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="legalnameId"
                  rules={{ required: "Нужно заполнить юр лицо" }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={
                        legalnamesData &&
                        legalnamesData.listLegalnames.legalnames.map((l) => {
                          return {
                            label: l.title,
                            value: l.id,
                          };
                        })
                      }
                      defa
                      name="legalnameId"
                      defaultValue={field.value}
                      value={field.value}
                      labelText="Юр лицо"
                      placeholder="Выберите юр лицо из списка"
                      fieldState={fieldState}
                      onSelect={(e) => setValue("legalnameId", e)}
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="budgetProbability"
                  rules={{ required: "Нужно заполнить вероятность бюджета" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInputWithTrailingAddon
                      labelText="Вероятность бюджета"
                      addonText="%"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      step="0.01"
                      min="0"
                      max="100"
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="turnover"
                  rules={{ required: "Нужно заполнить оборот" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Оборот"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      step="0.01"
                    />
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="margin"
                  rules={{ required: "Нужно заполнить маржу" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Маржа"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      type="number"
                      step="0.01"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-x-3">
        <Button type="button" onClick={() => onClose()}>
          Отмена
        </Button>
        <PrimaryButton type="submit">Сохранить</PrimaryButton>
      </div>
    </form>
  ) : (
    <DataLoader />
  );
};
