import { gql } from "@apollo/client";
import { INVOICE_FRAGMENT } from "graphql/fragments";

export const CREATE_INVOICE_MUTATION = gql`
  mutation createInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      ok
      error
      invoice {
        ...InvoiceParts
      }
    }
  }
  ${INVOICE_FRAGMENT}
`;