import { gql } from "@apollo/client";

export const DELETE_BUYING_CASHBACK_RULE_MUTATION = gql`
  mutation deleteBuyingCashbackRule($input: DeleteBuyingCashbackRuleInput!) {
    deleteBuyingCashbackRule(input: $input) {
      ok
      error
    }
  }
`;
