import { gql } from "graphql.macro";
import { CLIENT_FRAGMENT, LEGALNAME_FRAGMENT, VERTICAL_FRAGMENT } from "../fragments";

export const LIST_CLIENT_PROJECTS_QUERY = gql`
  query listClientProjects($input: ListClientProjectsInput!) {
    listClientProjects(input: $input) {
      ok
      error
      projects {
        id
        title
        client {
          ...ClientParts
        }
        verticals {
          ...VerticalParts
        }
        legalnames {
          ...LegalnameParts
        }
      }
    }
  }
  ${VERTICAL_FRAGMENT}
  ${LEGALNAME_FRAGMENT}
  ${CLIENT_FRAGMENT}
`;
