import { Controller, useFieldArray, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { InvoiceParts } from "graphql/__generated-types__/InvoiceParts";
import { useQuery } from "@apollo/client";
import {
  findAllContracts,
  findAllContractsVariables,
} from "graphql/__generated-types__/findAllContracts";
import {
  Button,
  DataLoader,
  LabeledDateInput,
  LabeledTextInput,
  LabeledTextInputWithTrailingAddon,
  LabeledToggleInput,
  PrimaryButton,
  ReactSelectInput,
} from "components";
import {
  ActSidesRole,
  ContractDocType,
} from "graphql/__generated-types__/globalTypes";
import { actSidesRoleStatusMap } from "config/mappings";
import { XIcon } from "@heroicons/react/outline";
import { isNumber } from "underscore";
import { classNames } from "utils";
import { FIND_ALL_CONTRACTS_QUERY } from "graphql/queries/findAllContracts";

export interface IInvoiceForm {
  invoiceNumber: string;
  invoiceDate: string;
  serviceStartDate: string;
  serviceEndDate: string;
  customerRole: ActSidesRole;
  contractorRole: ActSidesRole;
  invoiceSumAmount?: number;
  isVat: boolean;
  finalContractInvoiceId?: number;
  breakdown: boolean;
  initialInvoices: [
    {
      invoiceNumber: string;
      invoiceDate: string;
      initialContractInvoiceId?: number;
      invoiceSumAmount: number;
      isVat: boolean;
      finalContractInvoiceId?: number;
    }
  ];
}

interface IInvoiceFormProps {
  invoice?: InvoiceParts;
  onSubmit: (data: any) => void;
  onClose: () => void;
}

export const InvoiceForm: React.FC<IInvoiceFormProps> = ({
  invoice,
  onSubmit,
  onClose,
}) => {
  const { handleSubmit, control, setValue, getValues } = useForm<IInvoiceForm>({
    mode: "onSubmit",
    shouldUnregister: true,
    defaultValues: invoice
      ? {
          ...invoice,
          invoiceNumber: invoice?.invoiceNumber,
          invoiceDate: invoice?.invoiceDate,
          contractorRole: ActSidesRole[invoice?.contractorRole],
          customerRole: ActSidesRole[invoice?.customerRole],
          serviceStartDate: invoice?.serviceStartDate,
          serviceEndDate: invoice?.serviceEndDate,
          finalContractInvoiceId: invoice?.finalContract?.id,
          isVat: invoice?.isVat,
          invoiceSumAmount: invoice?.invoiceSumAmount,
          breakdown: invoice?.initialInvoices?.length ? true : false,
          initialInvoices: invoice
            ? invoice?.initialInvoices?.map((initInvoice) => ({
                ...initInvoice,
                invoiceNumber: initInvoice?.invoiceNumber,
                invoiceDate: initInvoice?.invoiceDate,
                invoiceSumAmount: initInvoice?.invoiceSumAmount,
                isVat: initInvoice?.isVat,
                initialContract: initInvoice?.initialContract,
                initialContractInvoiceId: initInvoice?.initialContract?.id,
                finalContractInvoiceId: invoice?.finalContract?.id,
              }))
            : [
                {
                  invoiceNumber: null,
                  invoiceDate: new Date(
                    new Date().setUTCHours(0, 0, 0, 0)
                  ).toISOString(),
                  finalContractInvoiceId: null,
                  invoiceSumAmount: 0,
                  isVat: true,
                },
              ],
        }
      : {
          invoiceDate: new Date(
            new Date().setUTCHours(0, 0, 0, 0)
          ).toISOString(),
          serviceStartDate: new Date(
            new Date().setUTCHours(0, 0, 0, 0)
          ).toISOString(),
          serviceEndDate: new Date(
            new Date().setUTCHours(0, 0, 0, 0)
          ).toISOString(),
          finalContractInvoiceId: null,
          isVat: true,
          invoiceSumAmount: 0,
          initialInvoices: [
            {
              invoiceNumber: null,
              invoiceDate: new Date(
                new Date().setUTCHours(0, 0, 0, 0)
              ).toISOString(),
              initialContractInvoiceId: null,
              invoiceSumAmount: 0,
              isVat: true,
              finalContractInvoiceId: null,
            },
          ],
        },
  });

  const label = invoice ? "Редактирование акта" : "Добавление акта";

  const { data: finalContractsData, loading: finalContractsLoading } = useQuery<
    findAllContracts,
    findAllContractsVariables
  >(FIND_ALL_CONTRACTS_QUERY, {
    variables: { input: { contractDocType: ContractDocType.FINAL_CONTRACT } },
  });

  const { data: initialContractsData, loading: initialContractsLoading } =
    useQuery<findAllContracts, findAllContractsVariables>(
      FIND_ALL_CONTRACTS_QUERY,
      {
        variables: {
          input: { contractDocType: ContractDocType.INITIAL_CONTRACT },
        },
      }
    );

  const finalContractsOptions =
    !finalContractsLoading &&
    finalContractsData?.findAllContracts?.contracts?.map((fContract) => ({
      label: `${fContract?.contractNumber} | ${fContract?.customerLegalname?.title}`,
      value: fContract?.id,
    }));

  const initialContractsOptions =
    !initialContractsLoading &&
    initialContractsData?.findAllContracts?.contracts?.map((iContract) => ({
      label: `${iContract?.contractNumber} | ${iContract?.customerLegalname?.title}`,
      value: iContract?.id,
    }));

  const { fields, append, remove } = useFieldArray({
    control,
    name: "initialInvoices",
  });

  const getSumm = () => {
    let sum = 0;
    getValues("initialInvoices").forEach((item) => {
      sum = sum + item.invoiceSumAmount;
    });
    return sum;
  };

  const [isBreakdown, setBreakdown] = useState(getValues().breakdown);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountCalculate, setTotalAmountCalculate] = useState(getSumm());

  useEffect(() => {
    isBreakdown && setTotalAmount(getValues().invoiceSumAmount);
  }, [getValues, isBreakdown]);

  return !finalContractsLoading && !initialContractsLoading ? (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:gap-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {label}
          </h3>
          <div className="mt-6 md:grid md:grid-cols-12  md:gap-6">
            {/* Номер акта */}
            <div className="col-span-3">
              <Controller
                control={control}
                name="invoiceNumber"
                rules={{
                  required: "Нужно заполнить номер акта",
                }}
                render={({ field, fieldState }) => (
                  <LabeledTextInput
                    labelText="Номер акта"
                    value={field.value === null ? "" : field.value}
                    onChange={field.onChange}
                    name={field.name}
                    fieldState={fieldState}
                  />
                )}
              />
            </div>

            {/* Дата акта */}
            <div className="col-span-3">
              <Controller
                control={control}
                name="invoiceDate"
                rules={{
                  required: "Нужно заполнить дату акта",
                }}
                render={({ field, fieldState }) => (
                  <LabeledDateInput
                    labelText="Дата акта"
                    field={field}
                    name={field.name}
                    fieldState={fieldState}
                  />
                )}
              />
            </div>

            {/* Признак разбивки */}
            <div className="col-span-2">
              <Controller
                control={control}
                name="breakdown"
                render={({ field, fieldState }) => (
                  <LabeledToggleInput
                    id="breakdown"
                    labelText="Разбивка изначальных актов"
                    checked={field.value}
                    defaultChecked={getValues().breakdown}
                    field={field}
                    name={field.name}
                    onChange={(e) => {
                      setValue(field.name, !!e);
                      setBreakdown(!!e);
                      if (isBreakdown)
                        setTotalAmount(getValues().invoiceSumAmount);
                    }}
                    fieldState={fieldState}
                  />
                )}
              />
            </div>

            <div className="col-span-4"></div>

            {/* Дата начала оказания услуг */}
            <div className="col-span-3">
              <Controller
                control={control}
                name="serviceStartDate"
                rules={{
                  required: "Нужно заполнить дату начала оказания услуг",
                }}
                render={({ field, fieldState }) => (
                  <LabeledDateInput
                    labelText="Дата начала оказания услуг"
                    field={field}
                    name={field.name}
                    fieldState={fieldState}
                  />
                )}
              />
            </div>

            {/* Дата окончания оказания услуг */}
            <div className="col-span-3">
              <Controller
                control={control}
                name="serviceEndDate"
                rules={{
                  required: "Нужно заполнить дату окончания оказания услуг",
                }}
                render={({ field, fieldState }) => (
                  <LabeledDateInput
                    labelText="Дата окончания оказания услуг"
                    field={field}
                    name={field.name}
                    fieldState={fieldState}
                  />
                )}
              />
            </div>

            {/* Роль исполнителя */}
            <div className="col-span-3">
              <Controller
                control={control}
                name="contractorRole"
                rules={{ required: "Нужно заполнить роль исполнителя" }}
                render={({ field, fieldState }) => (
                  <ReactSelectInput
                    name="contractorRole"
                    labelText="Роль исполнителя"
                    options={Object.values(ActSidesRole).map((cs) => {
                      return {
                        label: actSidesRoleStatusMap[cs],
                        value: cs,
                      };
                    })}
                    fieldState={fieldState}
                    value={field.value}
                    onSelect={(e) => setValue("contractorRole", e)}
                    placeholder={"Выберите роль исполнителя из списка"}
                  />
                )}
              />
            </div>

            {/* Роль заказчика */}
            <div className="col-span-3">
              <Controller
                control={control}
                name="customerRole"
                rules={{ required: "Нужно заполнить роль заказчика" }}
                render={({ field, fieldState }) => (
                  <ReactSelectInput
                    name="customerRole"
                    labelText="Роль заказчика"
                    options={Object.values(ActSidesRole).map((cs) => {
                      return {
                        label: actSidesRoleStatusMap[cs],
                        value: cs,
                      };
                    })}
                    fieldState={fieldState}
                    value={field.value}
                    onSelect={(e) => setValue("customerRole", e)}
                    placeholder={"Выберите роль заказчика из списка"}
                  />
                )}
              />
            </div>

            {/* Конечный договор */}
            <div className="col-span-3">
              <Controller
                control={control}
                name="finalContractInvoiceId"
                rules={{ required: "Нужно выбрать конечный договор" }}
                render={({ field, fieldState }) => (
                  <ReactSelectInput
                    name="finalContractInvoiceId"
                    labelText="Конечный договор"
                    options={finalContractsOptions}
                    fieldState={fieldState}
                    value={field.value}
                    onSelect={(e) => setValue("finalContractInvoiceId", e)}
                    placeholder={"Выберите конечный договор из списка"}
                  />
                )}
              />
            </div>

            {/* Стоимость услуг */}
            <div className="col-span-3">
              <Controller
                control={control}
                name="invoiceSumAmount"
                render={({ field, fieldState }) => (
                  <LabeledTextInputWithTrailingAddon
                    id="invoiceSumAmount"
                    name={field.name}
                    labelText="Стоимость услуг"
                    addonText="Руб."
                    value={field.value ?? null}
                    onChange={(e) => {
                      const parsedValue = !isNaN(parseFloat(e.target.value))
                        ? parseFloat(e.target.value)
                        : "";
                      field.onChange(parsedValue);
                      if (isNumber(parsedValue)) {
                        setTotalAmount(parsedValue);
                      } else setTotalAmount(0);
                    }}
                    fieldState={fieldState}
                    type="number"
                    min="0"
                    step="0.01"
                  />
                )}
              />
            </div>

            {/* Признак "НДС" */}
            <div className="col-span-2">
              <Controller
                control={control}
                name="isVat"
                render={({ field, fieldState }) => (
                  <LabeledToggleInput
                    labelText={`Признак "НДС"`}
                    checked={field.value}
                    defaultChecked={true}
                    field={field}
                    name={field.name}
                    onChange={(e) => setValue("isVat", !!e)}
                    fieldState={fieldState}
                  />
                )}
              />
            </div>

            {/* Динамические изначальные акты */}
            {/* Условие - показывать только при роли заказчика - РД (Рекламодатель) */}
            {isBreakdown && (
              <div className="col-span-full">
                {fields.map((item, idx) => (
                  <div
                    className="grid grid-cols-12 gap-x-4 gap-y-1 mt-4"
                    key={item.id}
                  >
                    <>
                      {/* Изначальный договор */}
                      <div className="col-span-4">
                        <Controller
                          control={control}
                          name={
                            `initialInvoices.${idx}.initialContractInvoiceId` as any
                          }
                          rules={{
                            required: "Нужно выбрать изначальный договор",
                          }}
                          render={({ field, fieldState }) => (
                            <ReactSelectInput
                              id={`initialInvoices[${idx}]initialContractInvoiceId`}
                              name={field.name}
                              labelText="Изначальный договор"
                              options={initialContractsOptions}
                              fieldState={fieldState}
                              value={field.value}
                              onSelect={(e) => setValue(field.name, e)}
                              placeholder={
                                "Выберите изначальный договор из списка"
                              }
                            />
                          )}
                        />
                      </div>

                      {/* Стоимость услуг */}
                      <div className="col-span-3">
                        <Controller
                          control={control}
                          name={
                            `initialInvoices.${idx}.invoiceSumAmount` as any
                          }
                          rules={{
                            required:
                              "Стоимость услуг обязательная для изначального акта",
                          }}
                          render={({ field, fieldState }) => (
                            <LabeledTextInputWithTrailingAddon
                              id={`initialInvoices[${idx}]invoiceSumAmount`}
                              name={field.name}
                              labelText="Стоимость услуг"
                              addonText="Руб."
                              value={field.value ?? null}
                              onChange={(e) => {
                                const parsedValue = !isNaN(
                                  parseFloat(e.target.value)
                                )
                                  ? parseFloat(e.target.value)
                                  : "";
                                field.onChange(parsedValue);
                                setTotalAmountCalculate(getSumm());
                              }}
                              fieldState={fieldState}
                              type="number"
                              min="0"
                              step="0.01"
                            />
                          )}
                        />
                      </div>

                      {/* Признак "НДС" */}
                      <div className="col-span-1">
                        <Controller
                          control={control}
                          name={`initialInvoices.${idx}.isVat` as any}
                          render={({ field, fieldState }) => (
                            <LabeledToggleInput
                              id={`initialInvoices[${idx}]isVat`}
                              labelText="С НДС"
                              checked={field.value}
                              defaultChecked={true}
                              field={field}
                              name={field.name}
                              onChange={(e) => setValue(field.name, !!e)}
                              fieldState={fieldState}
                            />
                          )}
                        />
                      </div>

                      <div className="col-span-1 self-center">
                        <PrimaryButton
                          icon={XIcon({
                            className: "h-4 w-4",
                          })}
                          onClick={() => remove(idx)}
                        ></PrimaryButton>
                      </div>
                    </>
                  </div>
                ))}
                <span
                  className="mt-2 cursor-pointer text-sm text-red-800"
                  onClick={() =>
                    append({
                      invoiceNumber: null,
                      invoiceDate: new Date(
                        new Date().setUTCHours(0, 0, 0, 0)
                      ).toISOString(),
                      initialContractInvoiceId: null,
                      invoiceSumAmount: 0,
                      isVat: true,
                    })
                  }
                >
                  Добавить
                </span>
                <span
                  className={classNames(
                    `mt-2 cursor-pointer text-lg flex`,
                    totalAmount - totalAmountCalculate < 0
                      ? `text-red-800`
                      : `text-black`
                  )}
                >
                  Нераспределенная сумма: {totalAmount - totalAmountCalculate}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-x-3">
        <Button type="button" onClick={() => onClose()}>
          Отмена
        </Button>
        <PrimaryButton type="submit">Сохранить</PrimaryButton>
      </div>
    </form>
  ) : (
    <DataLoader />
  );
};
