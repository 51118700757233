import {gql} from "@apollo/client";

export const DELETE_PBT_CALCULATOR_MUTATION = gql`
  mutation deletePbtCalculator($input: DeletePbtCalculatorInput!) {
    deletePbtCalculator(input: $input) {
      ok
      error
    }
  }
`;
