import { UserRole } from "graphql/__generated-types__/globalTypes";
import { useMe } from "hooks";
import { adminMenuRoutes } from "navigation/routes";
import { createContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { classNames } from "utils";

interface AdminLayoutProps {
  title?: string;
}

interface ILayoutButtonsContext {
  layoutButtons: React.ReactNode;
  setLayoutButtons: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}

export const LayoutButtonsContext = createContext<ILayoutButtonsContext>({
  layoutButtons: null,
  setLayoutButtons: () => {},
});

const AdminLayout: React.FC<AdminLayoutProps> = ({ children, title }) => {
  const [layoutButtons, setLayoutButtons] = useState<React.ReactNode>();
  const location = useLocation();

  const user = useMe();

  return (
    <LayoutButtonsContext.Provider value={{ layoutButtons, setLayoutButtons }}>
      <div className="h-screen flex overflow-hidden bg-gray-100">
        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:flex-shrink-0 fixed h-full">
          <div className="flex flex-col w-64">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 pb-4 bg-white overflow-y-auto">
              <div className="mt-5 flex-grow flex flex-col">
                <nav className="flex-1 px-2 bg-white space-y-1">
                  {Object.entries(adminMenuRoutes).map(
                    ([_, { path, title }], itemIdx) => (
                      ((user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN) || user?.data?.me?.user?.roles.includes(UserRole.HR_ADMIN))
                        && (["Сотрудники", "Отделы", "Должности"].includes(title))) ? (
                          <>
                        <Link
                          key={itemIdx}
                          to={path}
                          className={classNames(
                            location.pathname.includes(path)
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                          )}
                        >
                          {title}
                        </Link>
                        </>
                      ) : (
                        <Link
                          key={itemIdx}
                          to={path}
                          className={classNames(
                            location.pathname.includes(path)
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                          )}
                        >
                          {title}
                        </Link>
                      )
                    )
                  )}
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-0 flex-1 overflow-hidden md:ml-64">
          <main className="flex-1 relative overflow-y-auto focus:outline-none">
            <div className="py-6">
              <div className="max-w-8xl flex justify-between px-4 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">
                  {title}
                </h1>
                {layoutButtons}
              </div>
              <div className="max-w-8xl mx-auto py-4 px-4 sm:px-6">
                {children}
              </div>
            </div>
          </main>
        </div>
      </div>
    </LayoutButtonsContext.Provider>
  );
};

export default AdminLayout;
