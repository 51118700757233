import { gql } from "@apollo/client";
import {CASE_FRAGMENT} from "../fragments";

export const FIND_ALL_CASES_QUERY = gql`
  query findAllCases($input: FindAllCasesInput!) {
    findAllCases(input: $input) {
      ok
      error
      battleCases {
        ...CaseParts
      }
      totalPages
      totalResults
    }
  }
  ${CASE_FRAGMENT}
`;
