import { useMutation } from "@apollo/client";
import { CREATE_EMPLOYEE_POSITION_MUTATION } from "graphql/mutations";
import {
  createEmployeePosition,
  createEmployeePositionVariables,
} from "graphql/__generated-types__/createEmployeePosition";
import { adminMenuRoutes } from "navigation/routes";
import {
  EmployeePositionsForm,
  IEmployeePositionsForm,
} from "pages/Admin/EmployeePositions/components";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AdminPositionsCreate: React.FC = () => {
  const navigate = useNavigate();

  const [createEmployeePosition] = useMutation<
    createEmployeePosition,
    createEmployeePositionVariables
  >(CREATE_EMPLOYEE_POSITION_MUTATION, {
    onCompleted: (data) => {
      if (data && data.createEmployeePosition.ok)
        navigate(adminMenuRoutes.positions.path);
      else toast.error(data.createEmployeePosition.error);
    },
  });

  const onSubmit = (data: IEmployeePositionsForm) => {
    createEmployeePosition({
      variables: {
        input: {
          ...data,
        },
      },
    });
  };
  return <EmployeePositionsForm positionData={null} onSubmit={onSubmit} />;
};

export default AdminPositionsCreate;
