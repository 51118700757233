import {gql} from "@apollo/client";
import { SELLER_FRAGMENT } from "graphql/fragments"

export const FIND_ONE_SELLER_QUERY = gql`
  query findOneSeller($input: FindOneSellerInput!) {
    findOneSeller(input: $input) {
      ok
      error
      seller {
        ...SellerParts
      }
    }
  }
  ${SELLER_FRAGMENT}
`;
