import { useMutation } from "@apollo/client";
import { DataLoader } from "components";
import { VENDOR_FRAGMENT } from "graphql/fragments";
import { UPDATE_VENDOR_MUTATION } from "graphql/mutations/updateVendor";
import { listVendors_listVendors_vendors } from "graphql/__generated-types__/listVendors";
import {
  updateVendor,
  updateVendorVariables,
} from "graphql/__generated-types__/updateVendor";
import { adminMenuRoutes } from "navigation/routes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AdminVendorForm, IVendorForm } from "../components";

interface IAdminVendorEditProps {
  vendor: listVendors_listVendors_vendors;
  onClose: () => void;
}

const AdminVendorEdit: React.FC<IAdminVendorEditProps> = ({
  vendor,
  onClose,
}) => {
  const navigate = useNavigate();

  const [updateVendor] = useMutation<updateVendor, updateVendorVariables>(
    UPDATE_VENDOR_MUTATION,
    {
      onCompleted: (updateVendorsData) => {
        if (updateVendorsData?.updateVendor?.ok) {
          navigate(adminMenuRoutes.vendors.path);
        } else {
          toast.error(updateVendorsData?.updateVendor?.error);
        }
      },
      update(cache, { data: { updateVendor } }) {
        if (updateVendor?.vendor.id) {
          cache.writeFragment({
            // @ts-ignore
            id: cache.identify(updateVendor?.vendor),
            data: updateVendor?.vendor,
            fragment: VENDOR_FRAGMENT,
            fragmentName: "VendorParts",
          });
        }
      },
    }
  );

  const onSubmit = async (data: IVendorForm) => {
    const responseData = await updateVendor({
      variables: {
        input: {
          ...data,
          id: +vendor.id,
          isOrdNeeded: !!data?.isOrdNeeded,
        },
      },
    });
    if (responseData?.data?.updateVendor?.ok) {
      onClose();
    }
  };

  return vendor ? (
    <AdminVendorForm vendor={vendor} onSubmit={onSubmit} onClose={onClose} />
  ) : (
    <DataLoader />
  );
};

export default AdminVendorEdit;
