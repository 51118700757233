import { gql } from "@apollo/client";
import { BUYING_CASHBACK_RULE_FRAGMENT } from "../fragments";

export const LIST_BUYING_CASHBACK_RULES_QUERY = gql`
  query listBuyingCashbackRules($input: ListBuyingCashbackRulesInput!) {
    listBuyingCashbackRules(input: $input) {
      ok
      error
      buyingCashbackRules {
        ...BuyingCashbackRuleParts
      }
    }
  }
  ${BUYING_CASHBACK_RULE_FRAGMENT}
`;
