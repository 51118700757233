import {gql} from "@apollo/client";
import { BUYING_TYPE_FRAGMENT } from "graphql/fragments";

export const UPDATE_BUYING_TYPE_MUTATION = gql`
  mutation updateBuyingType($input: UpdateBuyingTypeInput!) {
    updateBuyingType(input: $input) {
      ok
      error
      buyingType {
        ...BuyingTypeParts
      }
    }
  }
  ${BUYING_TYPE_FRAGMENT}
`;
