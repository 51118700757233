import { gql } from "@apollo/client";
import { RANDOM_COFFEE_FRAGMENT } from "graphql/fragments";

export const CREATE_RANDOM_COFFEE_MUTATION = gql`
  mutation createRandomCoffee {
    createRandomCoffee {
      ok
      error
      randomCoffee {
        ...RandomCoffeeParts
      }
    }
  }
  ${RANDOM_COFFEE_FRAGMENT}
`;
