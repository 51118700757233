import { gql } from "@apollo/client";

export const SELLER_FRAGMENT = gql`
  fragment SellerParts on Seller {
    id
    title
    legalnames {
      id
      title
    }
  }
`;
export const PROCESS_FRAGMENT = gql`
  fragment ProcessParts on Process {
    id
    process {
      title
      schemaLink
      isArchive
    }
  }
`;

export const POSITION_FRAGMENT = gql`
  fragment PositionParts on EmployeePosition {
    id
    title
    description
    processes {
      ...ProcessParts
    }
  }
  ${PROCESS_FRAGMENT}
`;


export const DEPARTMENT_FRAGMENT = gql`
  fragment DepartmentParts on EmployeeDepartment {
    id
    title
    description
    order
    departmentHead {
      id
      name
      surname
    }
  }
`;

export const USERLOG_FRAGMENT = gql`
  fragment UserLogsParts on UserLog {
    id
    surname
  }
`;
export const USER_FRAGMENT = gql`
  fragment UserParts on User {
    id
    name
    surname
    surnameAccented
    middlename
    avatar
    grades
    gender
    roles
    office
    position {
      ...PositionParts
    }
    headOfDepartment {
      id
      title
    }
    headOfVertical {
      id
      title
    }
    department {
      ...DepartmentParts
    }
    email
    googleEmail
    phone
    telegram
    birthdate
    employmentDate
    employeeHead {
      id
      name
      surname
      avatar
    }
    subordinates {
      id
      name
      surname
      avatar
      isActive
    }
    userLogs {
      ...UserLogsParts
    }
    isActive
    isRemote
    isPM
    isAM
    priorityInsideDepartment
    presentationLink
    responsibility
    holidayFrom
    holidayTo
		isAmbassador
  }
  ${POSITION_FRAGMENT}
  ${DEPARTMENT_FRAGMENT}
  ${USERLOG_FRAGMENT}
`;

export const LOGINIP_FRAGMENT = gql`
  fragment LoginIpParts on LoginIp {
    id
    email
    ip
    time
  }
`;

export const VERTICAL_FRAGMENT = gql`
  fragment VerticalParts on Vertical {
    id
    title
    verticalHead {
      ...UserParts
    }
  }
  ${USER_FRAGMENT}
`;

export const CLIENT_INDUSTRY_FRAGMENT = gql`
  fragment ClientIndustryParts on ClientIndustry {
    id
    title
  }
`;

export const CLIENT_FRAGMENT = gql`
  fragment ClientParts on Client {
    id
    title
    industry {
      ...ClientIndustryParts
    }
    projects {
      id
      title
    }
  }
  ${CLIENT_INDUSTRY_FRAGMENT}
`;

export const CLIENT_PROJECT_FRAGMENT = gql`
  fragment ClientProjectParts on ClientProject {
    id
    title
    client {
      ...ClientParts
    }
    verticals {
      ...VerticalParts
    }
    legalnames {
      title
    }
  }
  ${CLIENT_FRAGMENT}
  ${VERTICAL_FRAGMENT}
`;

export const LEGALNAME_FRAGMENT = gql`
  fragment LegalnameParts on Legalname {
    id
    title
    currency
    isVat
    isArtics
    sellers {
      ...SellerParts
    }
    projects {
      ...ClientProjectParts
    }
    legalnameType                      
    ITN
    PSRN
    phone
    epayNumber
    oksmNumber
  }
  ${SELLER_FRAGMENT}
  ${CLIENT_PROJECT_FRAGMENT}
`;

export const PBT_REPORT_FRAGMENT = gql`
  fragment PbtReportParts on PbtReport {
    id
    monthYear
    workPercent
    vertical {
      ...VerticalParts
    }
    project {
      ...ClientProjectParts
    }
    user {
      ...UserParts
    }
  }
  ${VERTICAL_FRAGMENT}
  ${CLIENT_PROJECT_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const BUYING_FORMAT_FRAGMENT = gql`
  fragment BuyingFormatParts on BuyingFormat {
    id
    title
  }
`;

export const BUYING_TYPE_FRAGMENT = gql`
  fragment BuyingTypeParts on BuyingType {
    id
    title
  }
`;

export const CATEGORY_FRAGMENT = gql`
  fragment CategoryParts on VendorCategory {
    id
    title
    isCat1
    isCat2
    isCat3
  }
`;

export const VENDOR_FRAGMENT = gql`
  fragment VendorParts on Vendor {
    id
    title
    category1 {
      ...CategoryParts
    }
    category2 {
      ...CategoryParts
    }
    category3 {
      ...CategoryParts
    }
    isOrdNeeded
  }
  ${CATEGORY_FRAGMENT}
`;

export const DEPARTMENT_PLAN_FRAGMENT = gql`
  fragment DepartmentPlanParts on DepartmentPlan {
    id
    monthYear
    projectVerticalId
    budgetProbability
    turnover
    margin
    vertical {
      ...VerticalParts
    }
    project {
      ...ClientProjectParts
    }
    department {
      ...DepartmentParts
    }
    legalname {
      ...LegalnameParts
    }
    vendor {
      ...VendorParts
    }
    user {
      ...UserParts
    }
  }
  ${VERTICAL_FRAGMENT}
  ${CLIENT_PROJECT_FRAGMENT}
  ${DEPARTMENT_FRAGMENT}
  ${LEGALNAME_FRAGMENT}
  ${VENDOR_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const CASE_FRAGMENT = gql`
  fragment CaseParts on Case {
    id
    title
    description
    picture
    presentation
    startDateTime
    isWinner
    isParticipant
    hasVoted
    totalScore
    isArchive
    participants {
      ...UserParts
    }
  }
  ${USER_FRAGMENT}
`;

export const KPI_TYPE_FRAGMENT = gql`
  fragment KpiTypeParts on KpiType {
    id
    title
  }
`;

export const PM_REPORT_FRAGMENT = gql`
  fragment PmReportParts on PmReport {
    id
    monthYear
    projectVerticalId
    mediaPlanMargin
    vertical {
      ...VerticalParts
    }
    project {
      ...ClientProjectParts
    }
    user {
      ...UserParts
    }
    vendor {
      ...VendorParts
    }
    kpiType {
      ...KpiTypeParts
    }
    reportDay
    buyingPeriod
    budgetPlanVat
    budgetFactNet
    kpiPlan
    kpiFact
    clientAgencyFeePerc
    clientDiscountPerc
    clientAutomationSystemType
    clientAutomationSystemPerc
    clientHiddenFeePerc
    clientVatPerc
    vendorMoneybackPerc
    vendorDiscountPerc
    vendorVatPerc
  }
  ${VERTICAL_FRAGMENT}
  ${CLIENT_PROJECT_FRAGMENT}
  ${USER_FRAGMENT}
  ${VENDOR_FRAGMENT}
  ${KPI_TYPE_FRAGMENT}
`;

export const CERTIFICATE_FRAGMENT = gql`
  fragment CertificateParts on EmployeeCertificate {
    id
    vendor {
      id
      title
    }
    employee {
      id
      surname
      name
      avatar
      position {
        id
        title
      }
    }
    certificateCategory
    fileLink
    validUntil
    isExpired
    passed  
  }
`;

export const BUYING_CONDITION_FRAGMENT = gql`
  fragment BuyingConditionParts on BuyingCondition {
    id
    title
    addedBy {
      ...UserParts
    }
    category {
      ...CategoryParts
    }
    client {
      ...ClientParts
    }
    seller {
      ...SellerParts
    }
    vendor {
      ...VendorParts
    }
    buyingFormat {
      ...BuyingFormatParts
    }
    buyingType {
      ...BuyingTypeParts
    }
    contractStatus
    taskLink
    svnLink
    articsLegalname {
      ...LegalnameParts
    }
    CPM
    CPMComment
    clientDiscount
    clientDiscountComment
    agencyDiscount
    agencyDiscountComment
    moneyback
    moneybackComment
    bonus
    bonusComment
    discountBefore
    discountBeforeComment
    discountAfter
    discountAfterComment
    totalMargin
    paymentDelay
    paymentDelayComment
    comments
  }
  ${CATEGORY_FRAGMENT}
  ${USER_FRAGMENT}
  ${CLIENT_FRAGMENT}
  ${LEGALNAME_FRAGMENT}
  ${SELLER_FRAGMENT}
  ${VENDOR_FRAGMENT}
  ${BUYING_FORMAT_FRAGMENT}
  ${BUYING_TYPE_FRAGMENT}
`;

export const BUYING_CASHBACK_RULE_FRAGMENT = gql`
  fragment BuyingCashbackRuleParts on BuyingCashbackRule {
    id
    client {
      ...ClientParts
    }
    vendor {
      ...VendorParts
    }
    cashbackIntervals {
      minTurnover
      maxTurnover
      validFrom
      validTo
      cashbackPerc
    }
    fallbackCashbackPerc
    buyingConditionType
  }
  ${CLIENT_FRAGMENT}
  ${VENDOR_FRAGMENT}
`;

export const BUYING_CASHBACK_TRANSACTION_FRAGMENT = gql`
  fragment BuyingCashbackTransactionParts on BuyingCashbackTransaction {
    id
    clientSum
    buyingConditionType
    client {
      ...ClientParts
    }
    vendor {
      ...VendorParts
    }
    transactionType
    cashbackSum
    cashbackPeriod
    turnover
    addedBy {
      ...UserParts
    }
    createdAt
  }
  ${CLIENT_FRAGMENT}
  ${VENDOR_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const RANDOM_COFFEE_FRAGMENT = gql`
  fragment RandomCoffeeParts on RandomCoffeeMeeting {
    id
    inviter {
      ...UserParts
    }
    invitee {
      ...UserParts
    }
    isInviterCompleted
    isInviteeCompleted
    inviterScore
    inviterFeedback
    inviteeScore
    inviteeFeedback
    isActive
    createdAt
    deadline
    whoAmI
  }
  ${USER_FRAGMENT}
`;

export const POST_FRAGMENT = gql`
  fragment PostParts on Post {
    id
    header
    underHeader
    text
    postType
    imageURL
    addedBy {
      ...UserParts
    }
    createdAt
  }
  ${USER_FRAGMENT}
`;

export const CONTRACT_FRAGMENT = gql`
fragment ContractParts on Contract {
  id
  createdAt
  addedBy {
    ...UserParts
  }
  contractNumber
  contractDate
  customerLegalname {
        ...LegalnameParts
      }
  contractorLegalname {
        ...LegalnameParts
      }
  isVatIncluded
  contractType
  contractSubjectType
  contractActionType
  isORD
  metaInfo
  contractSumAmount
  contractDocType
  finalContract {
    id
    contractNumber
    contractDate
    customerLegalname {
          ...LegalnameParts
        }
    contractorLegalname {
          ...LegalnameParts
        }
    isVatIncluded
    isORD
    metaInfo
    contractSumAmount
  }
  clientProjects {
    ...ClientProjectParts
  }
}
${USER_FRAGMENT}
${CLIENT_PROJECT_FRAGMENT}
${LEGALNAME_FRAGMENT}
`;

export const INVOICE_FRAGMENT = gql`
fragment InvoiceParts on Invoice {
  id
  invoiceNumber            
  invoiceDate              
  serviceStartDate         
  serviceEndDate           
  customerRole             
  contractorRole           
  invoiceSumAmount         
  isVat                    
  initialContract{
    ...ContractParts
  }
  finalContract{
    ...ContractParts
  }
  initialInvoices{
    invoiceNumber            
    invoiceDate    
    invoiceSumAmount         
    isVat                    
    initialContract{
      ...ContractParts
    }
  }
  createdAt
}
${CONTRACT_FRAGMENT}
`;

export const PBT_CALCULATOR_BUYING_CONDITION_FRAGMENT = gql`
  fragment PbtCalculatorBuyingConditionParts on PbtCalculatorBuyingCondition {
    id
    budget
    akDiscount
    overallDiscount
    pbtCalculator {
      id
      title
    }
    pbtBuyingCondition {
      ...BuyingConditionParts
    }
    createdAt
  }
  ${BUYING_CONDITION_FRAGMENT}
`;

export const PBT_CALCULATOR_POSITION_FRAGMENT = gql`
  fragment PbtCalculatorPositionParts on PbtCalculatorPosition {
    id
    salary
    workload
    pbtCalculator {
      id
      title
    }
    employeePosition {
      ...PositionParts
    }
    createdAt
  }
  ${POSITION_FRAGMENT}
`;

export const PBT_CALCULATOR_FRAGMENT = gql`
  fragment PbtCalculatorParts on PbtCalculator {
    id
    title
    periodFrom
    periodTo
    addedBy {
      ...UserParts
    }
    pbtCalculatorBuyingConditions {
      ...PbtCalculatorBuyingConditionParts
    }
    pbtCalculatorPositions {
      ...PbtCalculatorPositionParts
    }
    createdAt
  }
  ${USER_FRAGMENT}
  ${PBT_CALCULATOR_BUYING_CONDITION_FRAGMENT}
  ${PBT_CALCULATOR_POSITION_FRAGMENT}
`;

export const FOT_RATIO_FRAGMENT = gql`
  fragment FotratioParts on Fotratio {
    id
    monthYear
    value
  }
`;
