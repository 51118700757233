import {gql} from "@apollo/client";
import { PBT_CALCULATOR_FRAGMENT } from "graphql/fragments";

export const FIND_ONE_PBT_CALCULATOR_QUERY = gql`
  query findOnePbtCalculator($input: FindOnePbtCalculatorInput!) {
    findOnePbtCalculator(input: $input) {
      ok
      error
      pbtCalculator {
        ...PbtCalculatorParts
      }
    }
  }
  ${PBT_CALCULATOR_FRAGMENT}
`;
