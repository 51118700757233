import { useReactiveVar } from "@apollo/client";
import { isLoggedInVar } from "services/apollo";
import { LoggedInRouter, LoggedOutRouter } from "navigation";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  toast.configure({ limit: 5, theme: "colored", hideProgressBar: true });
  return isLoggedIn ? <LoggedInRouter /> : <LoggedOutRouter />;
};

export default App;
