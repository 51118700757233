import {gql} from "@apollo/client";

export const DELETE_SELLER_MUTATION = gql`
  mutation deleteSeller($input: DeleteSellerInput!) {
    deleteSeller(input: $input) {
      ok
      error
    }
  }
`;
