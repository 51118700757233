import {gql} from "@apollo/client";
import {CASE_FRAGMENT} from "../fragments";

export const UPDATE_START_VOTING_FOR_CASE_MUTATION = gql`
  mutation startVoting($input: StartVotingInput!) {
    startVoting(input: $input) {
      ok
      error
      battleCase {
        ...CaseParts
      }
    }
  }
  ${CASE_FRAGMENT}
`;
