import { useNavigate } from "react-router-dom";
import { StoreObject, useMutation } from "@apollo/client";
import {
  createLegalname,
  createLegalnameVariables,
} from "../../../../graphql/__generated-types__/createLegalname";
import { CREATE_LEGALNAME_MUTATION } from "../../../../graphql/mutations";
import { adminMenuRoutes } from "../../../../navigation/routes";
import { toast } from "react-toastify";
import { LEGALNAME_FRAGMENT } from "../../../../graphql/fragments";
import { AdminLegalnameForm, ILegalnameForm } from "../components";

const AdminLegalnameCreate = ({ onClose }) => {
  const navigate = useNavigate();

  const [createLegalname] = useMutation<
    createLegalname,
    createLegalnameVariables
  >(CREATE_LEGALNAME_MUTATION, {
    onCompleted: (createLegalnameData) => {
      if (createLegalnameData?.createLegalname?.ok) {
        navigate(adminMenuRoutes.legalnames.path);
      } else {
        toast.error(createLegalnameData?.createLegalname?.error);
      }
    },
    update(cache, { data: { createLegalname } }) {
      if (createLegalname?.legalname?.id) {
        const newLegalnameRef = cache.writeFragment({
          id: cache.identify(
            createLegalname?.legalname as unknown as StoreObject
          ),
          data: createLegalname?.legalname,
          fragment: LEGALNAME_FRAGMENT,
          fragmentName: "LegalnameParts",
        });

        cache.modify({
          id: "ROOT_QUERY",
          fields: {
            listLegalnames(prev) {
              return {
                ...prev,
                legalnames: [newLegalnameRef, ...prev.legalnames],
              };
            },
          },
        });
      }
    },
  });

  const onSubmit = async (data: ILegalnameForm) => {
    const responseData = await createLegalname({
      variables: {
        input: {
          ...data,
          sellerId: +data?.sellerId
        },
      },
    });
    if (responseData?.data?.createLegalname?.ok) {
      onClose(true);
    }
  };

  return (
    <AdminLegalnameForm
      legalname={null}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};
export default AdminLegalnameCreate;
