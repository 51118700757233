import {gql} from "@apollo/client";

export const DELETE_CASE_MUTATION = gql`
  mutation deleteCase($input: DeleteCaseInput!) {
    deleteCase(input: $input) {
      ok
      error
    }
  }
`;
