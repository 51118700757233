import { StoreObject, useMutation } from "@apollo/client";
import { PBT_CALCULATOR_FRAGMENT } from "graphql/fragments";
import { CREATE_PBT_CALCULATOR_MUTATION } from "graphql/mutations/createPbtCalculator";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createPbtCalculator,
  createPbtCalculatorVariables,
} from "../../../../graphql/__generated-types__/createPbtCalculator";
import { serviceMenuRoutes } from "../../../../navigation/routes";
import { IPbtCalculatorForm, PbtCalculatorForm } from "../components";

const PbtCalculatorCreate = () => {
  const navigate = useNavigate();
  const [createPbtCalculator] = useMutation<
    createPbtCalculator,
    createPbtCalculatorVariables
  >(CREATE_PBT_CALCULATOR_MUTATION, {
    onCompleted: (createPbtCalculatorData) => {
      if (createPbtCalculatorData?.createPbtCalculator?.ok) {
        navigate(serviceMenuRoutes?.pbtCalculators?.path);
      } else {
        toast.error(createPbtCalculatorData?.createPbtCalculator?.error);
      }
    },
    update(cache, { data: { createPbtCalculator } }) {
      if (createPbtCalculator?.pbtCalculator?.id) {
        const newPbtCalculatorRef = cache.writeFragment({
          // @ts-ignore
          id: cache.identify(
            createPbtCalculator?.pbtCalculator as unknown as StoreObject
          ),
          data: createPbtCalculator?.pbtCalculator,
          fragment: PBT_CALCULATOR_FRAGMENT,
          fragmentName: "PbtCalculatorParts",
        });
        cache.modify({
          id: "ROOT_QUERY",
          fields: {
            findAllPbtCalculators(prev) {
              return {
                ...prev,
                pbtCalculators: [newPbtCalculatorRef, ...prev.pbtCalculators],
              };
            },
          },
        });
      }
    },
  });

  const onSubmit = async (data: IPbtCalculatorForm) => {
    await createPbtCalculator({
      variables: {
        input: {
          title: data.title,
          periodFrom: data.periodFrom,
          periodTo: data.periodTo,
          pbtCalculatorPositionIds: data?.pbtCalculatorPositions?.map(
            (pbtCalculatorPositionId) => pbtCalculatorPositionId?.pbtPositionId
          ),
          pbtCalculatorPositionSalaries: data?.pbtCalculatorPositions?.map(
            (pbtCalculatorPositionSalary) =>
              parseFloat(
                pbtCalculatorPositionSalary?.salary
                  .toString()
                  .replace(/[a-zA-Z]/g, "")
                  .replace(/\s/g, "")
                  .replace(/[,]/g, ".")
              )
          ),
          pbtCalculatorPositionWorkloads: data?.pbtCalculatorPositions?.map(
            (pbtCalculatorPositionWorkload) =>
              parseFloat(
                pbtCalculatorPositionWorkload?.workload
                  .toString()
                  .replace(/[a-zA-Z]/g, "")
                  .replace(/\s/g, "")
                  .replace(/[,]/g, ".")
              )
          ),
          pbtCalculatorBuyingConditionsAkDiscounts:
            data?.pbtCalculatorBuyingConditions?.map(
              (pbtCalculatorBuyingCondition) =>
                parseFloat(
                  pbtCalculatorBuyingCondition?.akDiscount
                    .toString()
                    .replace(/[a-zA-Z]/g, "")
                    .replace(/\s/g, "")
                    .replace(/[,]/g, ".")
                )
            ),
          pbtCalculatorBuyingConditionsBudgets:
            data?.pbtCalculatorBuyingConditions?.map(
              (pbtCalculatorBuyingCondition) =>
                parseFloat(
                  pbtCalculatorBuyingCondition?.budget
                    .toString()
                    .replace(/[a-zA-Z]/g, "")
                    .replace(/\s/g, "")
                    .replace(/[,]/g, ".")
                )
            ),
          pbtCalculatorBuyingConditionsOverallDiscounts:
            data?.pbtCalculatorBuyingConditions?.map(
              (pbtCalculatorBuyingCondition) =>
                parseFloat(
                  pbtCalculatorBuyingCondition?.overallDiscount
                    .toString()
                    .replace(/[a-zA-Z]/g, "")
                    .replace(/\s/g, "")
                    .replace(/[,]/g, ".")
                )
            ),
          pbtCalculatorBuyingConditionsIds:
            data?.pbtCalculatorBuyingConditions?.map(
              (pbtCalculatorBuyingCondition) =>
                pbtCalculatorBuyingCondition?.pbtBuyingConditionId
                  ? !isNaN(
                      Math.round(
                        ((parseFloat(
                          pbtCalculatorBuyingCondition?.pbtBuyingConditionId
                            ?.toString()
                            .substring(
                              0,
                              pbtCalculatorBuyingCondition?.pbtBuyingConditionId
                                ?.toString()
                                .indexOf("|")
                            )
                        ) ?? parseFloat("0")) +
                          Number.EPSILON) *
                          100
                      ) / 100 ?? parseFloat("0")
                    )
                    ? Math.round(
                        ((parseFloat(
                          pbtCalculatorBuyingCondition?.pbtBuyingConditionId
                            ?.toString()
                            .substring(
                              0,
                              pbtCalculatorBuyingCondition?.pbtBuyingConditionId
                                ?.toString()
                                .indexOf("|")
                            )
                        ) ?? parseFloat("0")) +
                          Number.EPSILON) *
                          100
                      ) / 100 ?? parseFloat("0")
                    : parseFloat("0")
                  : parseFloat("0")
            ),
        },
      },
    });
  };

  return <PbtCalculatorForm pbtCalculator={null} onSubmit={onSubmit} />;
};

export default PbtCalculatorCreate;
