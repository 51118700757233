import {
  ApolloClient,
  InMemoryCache,
  makeVar,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { LOCALSTORAGE_TOKEN } from "../config/constants";

const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
export const isLoggedInVar = makeVar(Boolean(token));
export const authTokenVar = makeVar(token);

export const logoutUser = () => {
  localStorage.removeItem(LOCALSTORAGE_TOKEN);
  window.location.replace("/");
};
/* 
const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_BACKEND_WS_HOST}/graphql`,
  options: {
    reconnect: true,
    connectionParams: {
      "x-jwt": authTokenVar() || "",
    },
  },
}); */

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BACKEND_HOST}/graphql`,
  // uri: `https://api.portal.artics.ru/graphql`,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "x-jwt": authTokenVar() || "",
    },
  };
});

/* const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
); */

export const client = new ApolloClient({
  link: authLink.concat(httpLink), //link: splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          findAllUsers: {
            keyArgs: ["input", ["keyword" ]],
            merge(
              existing,
              incoming,
              {
                args: {
                  input: {page = 1, take = 20},
                },
              }
            ) {
              const merged = existing?.users ? existing?.users?.slice(0) : [];
              if (incoming) {
                for (let i = 0; i < incoming.users.length; ++i) {
                  merged[(page) * take + i] = incoming.users[i];
                }
                return {...incoming, users: merged};
              }
              return {...existing, users: merged};

            }
          },
          findManyPosts: {
            // The keyArgs list and merge function are the same as above.
            keyArgs: ["input", ["postType"]],
            merge(
              existing,
              incoming,
              {
                args: {
                  input: {skip = 0},
                },
              }
            ) {
              const merged = existing?.posts ? existing?.posts?.slice(0) : [];
              if (incoming) {
                for (let i = 0; i < incoming.posts.length; ++i) {
                  merged[skip + i] = incoming.posts[i];
                }
                return {...incoming, posts: merged};
              }
              return {...existing, posts: merged};
            },
          },
          isLoggedIn: {
            read() {
              return isLoggedInVar();
            },
          },
          token: {
            read() {
              return authTokenVar();
            },
          },
        },
      },
    },
  }),
});
