import {gql} from "@apollo/client";
import {CASE_FRAGMENT, } from "graphql/fragments";

export const FIND_ONE_CASE_QUERY = gql`
  query findOneCase($input: FindOneCaseInput!) {
    findOneCase(input: $input) {
      ok
      error
      battleCase {
        ...CaseParts
      }
    }
  }
  ${CASE_FRAGMENT}
`;
