import { gql } from "@apollo/client";
import { BUYING_TYPE_FRAGMENT } from "graphql/fragments";

export const FIND_ALL_BUYING_TYPE_QUERY = gql`
  query findAllBuyingTypes($input: FindAllBuyingTypesInput!) {
    findAllBuyingTypes(input: $input) {
      ok
      error
      buyingTypes {
          ...BuyingTypeParts
      }
      totalPages
      totalResults
    }
  }
  ${BUYING_TYPE_FRAGMENT}
`;
