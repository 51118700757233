import "react-datepicker/dist/react-datepicker.css";
import AvatarTemplate from "assets/user-template.png";
import React, {
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  useRef,
  useState,
} from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import { Switch } from "@headlessui/react";
import { classNames } from "utils";
import { DataLoader } from "./DataLoader";
import { UPLOAD_ROUTE } from "config/constants";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { ControllerFieldState, ControllerRenderProps } from "react-hook-form";
import Select from "react-select";
import ImageCropper from "pages/Home/components/PostService/components/imageCropper";

registerLocale("ru", ru);

interface ICustomLabeledInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  labelText: string;
  fieldState?: ControllerFieldState;
  field?: ControllerRenderProps<any, any>;
}

interface ICustomLabeledTextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  labelText: string;
  fieldState?: ControllerFieldState;
  field?: ControllerRenderProps;
}

export const LabeledTextInput: React.FC<ICustomLabeledInputProps> = ({
  labelText,
  fieldState,
  name,
  type = "text",
  ...rest
}) => {
  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {labelText}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          name={name}
          type={type}
          id={name}
          className={classNames(
            fieldState?.invalid &&
              "border-red-300 text-red-900 placeholder-red-300",
            "mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          )}
          aria-invalid={fieldState?.invalid || "false"}
          aria-describedby={`${name}-error`}
          {...rest}
        />
        {fieldState?.invalid && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden={!fieldState?.invalid}
            />
          </div>
        )}
      </div>
      {fieldState?.error?.message && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {fieldState?.error?.message}
        </p>
      )}
    </>
  );
};

export const LabeledTextareaInput: React.FC<ICustomLabeledTextareaProps> = ({
  labelText,
  fieldState,
  name,
  rows = 4,
  ...rest
}) => {
  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {labelText}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <textarea
          name={name}
          id={name}
          rows={rows}
          className={classNames(
            fieldState?.invalid &&
              "border-red-300 text-red-900 placeholder-red-300",
            "shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
          )}
          aria-invalid={fieldState?.invalid || "false"}
          aria-describedby={`${name}-error`}
          {...rest}
        />
        {fieldState?.invalid && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden={!fieldState?.invalid}
            />
          </div>
        )}
      </div>
      {fieldState?.error?.message && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {fieldState?.error?.message}
        </p>
      )}
    </>
  );
};

interface ILabeledTextInputWithAddonProps extends ICustomLabeledInputProps {
  addonText: string;
}

export const LabeledTextInputWithAddon: React.FC<
  ILabeledTextInputWithAddonProps
> = ({ labelText, name, addonText, fieldState, ...rest }) => {
  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {labelText}
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
          {addonText}
        </span>
        <input
          type="text"
          name={name}
          id={name}
          className={classNames(
            fieldState?.invalid &&
              "border-red-300 text-red-900 placeholder-red-300",
            "flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300"
          )}
          aria-invalid={fieldState?.invalid || "false"}
          aria-describedby={`${name}-error`}
          {...rest}
        />
        {fieldState?.invalid && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden={!fieldState?.invalid}
            />
          </div>
        )}
      </div>
      {fieldState?.error?.message && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {fieldState?.error?.message}
        </p>
      )}
    </>
  );
};

export const LabeledTextInputWithTrailingAddon: React.FC<
  ILabeledTextInputWithAddonProps
> = ({ labelText, name, addonText, fieldState, ...rest }) => {
  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {labelText}
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <input
          type="text"
          name={name}
          id={name}
          className={classNames(
            fieldState?.invalid &&
              "border-red-300 text-red-900 placeholder-red-300",
            "flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-l-md focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300"
          )}
          aria-invalid={fieldState?.invalid || "false"}
          aria-describedby={`${name}-error`}
          {...rest}
        />
        <div className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
          <span className="text-gray-500 sm:text-sm">{addonText}</span>
        </div>
        {fieldState?.invalid && (
          <div className="relative inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden={!fieldState?.invalid}
            />
          </div>
        )}
      </div>
      {fieldState?.error?.message && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {fieldState?.error?.message}
        </p>
      )}
    </>
  );
};

export const LabeledDateInput: React.FC<ICustomLabeledInputProps> = ({
  labelText,
  name,
  fieldState,
  field,
  ...rest
}) => {
  const [startDate, setStartDate] = useState(
    field?.value ? new Date(field.value) : new Date()
  );
  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {labelText}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <DatePicker
          locale="ru"
          dateFormat="dd.MM.yyyy"
          id={name}
          name={name}
          selected={startDate}
          //@ts-ignore
          onChange={(date: Date): void => {
            // @ts-ignore: Unreachable code error
            setStartDate(date);
            field?.onChange(
              // @ts-ignore: Unreachable code error
              date ? new Date(date.setUTCHours(0, 0, 0, 0)) : null
            );
          }}
          aria-invalid={fieldState?.invalid || "false"}
          aria-describedby={`${name}-error`}
          wrapperClassName="w-full"
          className={classNames(
            fieldState?.invalid &&
              "border-red-300 text-red-900 placeholder-red-300",
            "mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          )}
          {...rest}
        />
      </div>
      {fieldState?.error?.message && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {fieldState?.error?.message}
        </p>
      )}
    </>
  );
};

export const LabeledMonthInput: React.FC<ICustomLabeledInputProps> = ({
  labelText,
  name,
  fieldState,
  field,
  ...rest
}) => {
  const [startDate, setStartDate] = useState(
    field?.value ? new Date(field.value) : new Date()
  );
  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {labelText}
      </label>
      <div className="relative rounded-md shadow-sm">
        <DatePicker
          locale="ru"
          dateFormat="MM.yyyy"
          id={name}
          name={name}
          showMonthYearPicker
          showFullMonthYearPicker
          selected={startDate}
          // @ts-ignore: Unreachable code error
          onChange={(date) => {
            // @ts-ignore: Unreachable code error
            setStartDate(date);
            field?.onChange(
              // @ts-ignore: Unreachable code error
              date ? new Date(date.setUTCHours(0, 0, 0, 0)) : null
            );
          }}
          aria-invalid={fieldState?.invalid || "false"}
          aria-describedby={`${name}-error`}
          wrapperClassName="w-full"
          className={classNames(
            fieldState?.invalid &&
              "border-red-300 text-red-900 placeholder-red-300",
            "mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          )}
          {...rest}
        />
      </div>
      {fieldState?.error?.message && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {fieldState?.error?.message}
        </p>
      )}
    </>
  );
};

interface IAvatarInputProps extends ICustomLabeledInputProps {
  avatar?: string;
  alt?: string;
}

export const AvatarInput: React.FC<IAvatarInputProps> = ({
  labelText = "Фото",
  name,
  avatar,
  alt,
  fieldState,
  ...rest
}) => {
  const [avatarState, setAvatarState] = useState(avatar);
  const [uploading, setUploading] = useState(false);

  const hiddenFileInput = useRef(null);

  const handleChangeClick = () => {
    hiddenFileInput.current.click();
  };

  const uploadFile = async (event) => {
    try {
      let fileToUpload = null;
      if (event?.target?.files) fileToUpload = Array.from(event.target.files);
      setUploading(true);
      const formBody = new FormData();
      formBody.append("file", fileToUpload[0]);
      const { url } = await (
        await fetch(`${UPLOAD_ROUTE}/avatar`, {
          method: "POST",
          body: formBody,
        })
      ).json();
      setAvatarState(url);
      setUploading(false);
      rest.field && rest.field.onChange(url);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {labelText}
      </label>
      <div className="mt-1 flex items-center space-x-5">
        {!uploading ? (
          <img
            src={avatarState || AvatarTemplate}
            alt={alt}
            className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100 object-cover"
          />
        ) : (
          <div className="relative inline-block h-10 w-10">
            <DataLoader size={20} />
          </div>
        )}

        <button
          type="button"
          onClick={handleChangeClick}
          className="bg-white py-2.5 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Изменить
        </button>
        <input
          ref={hiddenFileInput}
          style={{ display: "none" }}
          type="file"
          onChange={uploadFile}
          name={name}
          {...rest}
        />
      </div>
      {fieldState?.error?.message && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {fieldState?.error?.message}
        </p>
      )}
    </>
  );
};

export const ImageDragAndDropInput: React.FC<IImageInputProps> = ({
  name,
  image,
  alt,
  fieldState,
  ...rest
}) => {
  const [uploading, setUploading] = useState(false);

  const hiddenFileInput = useRef(null);

  const handleChangeClick = () => {
    hiddenFileInput.current.click();
  };

  const uploadFile = async (event) => {
    try {
      let fileToUpload = null;
      if (event?.target?.files) fileToUpload = Array.from(event.target.files);
      setUploading(true);
      const formBody = new FormData();
      formBody.append("file", fileToUpload[0]);
      const { url } = await (
        await fetch(`${UPLOAD_ROUTE}/post`, {
          method: "POST",
          body: formBody,
        })
      ).json();
      setUploading(false);
      rest.field && rest.field.onChange(url);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  return (
    <>
      <div
        className="mt-1 row-span-2 items-center text-center space-x-5"
        onClick={handleChangeClick}
      >
        {!uploading ? (
          <p>Загрузить изображение можно тут</p>
        ) : (
          <div className="relative inline-block h-10 w-10">
            <DataLoader size={20} />
          </div>
        )}
        <input
          ref={hiddenFileInput}
          style={{ display: "none" }}
          type="file"
          onChange={uploadFile}
          name={name}
          {...rest}
        />
      </div>
      {fieldState?.error?.message && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {fieldState?.error?.message}
        </p>
      )}
    </>
  );
};

interface IImageInputProps extends ICustomLabeledInputProps {
  image?: string;
  alt?: string;
}

export const ImageInput: React.FC<IImageInputProps> = ({
  labelText = "Изображение",
  name,
  image,
  alt,
  fieldState,
  ...rest
}) => {
  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [croppedImage, setCroppedImage] = useState(undefined);

  const hiddenFileInput = useRef(null);

  const handleChangeClick = () => {
    hiddenFileInput.current.click();
  };

  const uploadFile = async (event) => {
    try {
      if (event?.target?.files && event?.target?.files?.length > 0) {
        const reader = new FileReader();

        reader.addEventListener("load", () => {
          const image = reader.result;

          setImageToCrop(image);
        });
        reader.readAsDataURL(event.target.files[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const uploadFinalImage = async () => {
    try {
      if (croppedImage) {
        const fileToUpload = dataURLtoFile(
          croppedImage,
          `Post-${new Date()
            .toISOString()
            .replace(/(\d)[\s.]+(?=\d)/g, "$1")}.png`
        );
        const formBody = new FormData();
        formBody.append("file", fileToUpload);
        const { url } = await (
          await fetch(`${UPLOAD_ROUTE}/post`, {
            method: "POST",
            body: formBody,
          })
        ).json();
        rest.field && rest.field.onChange(url);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <label
        htmlFor={name}
        className="my-5 inline-block text-sm w-full text-center font-medium text-gray-700"
      >
        {labelText}:{" "}
        <button
          type="button"
          onClick={handleChangeClick}
          className="ml-5 bg-white items-center py-2.5 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Изменить
        </button>
        <button
          type="button"
          onClick={uploadFinalImage}
          className="
          ml-5 px-4 py-2 text-sm inline-flex gap-x-2 items-center border border-transparent font-medium rounded shadow-sm
          text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
           "
        >
          Подтвердить указанный размер
        </button>
      </label>

      <div className="mt-1 row-span-1 items-center text-center border-gray-800">
        <ImageCropper
          imageToCrop={imageToCrop || image}
          onImageCropped={(croppedImage) => setCroppedImage(croppedImage)}
        />
        <input
          ref={hiddenFileInput}
          style={{ display: "none" }}
          type="file"
          onChange={uploadFile}
          name={name}
          {...rest}
        />
      </div>
      {fieldState?.error?.message && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {fieldState?.error?.message}
        </p>
      )}
    </>
  );
};

interface IInputOption {
  value: string | number;
  label: string;
}

interface ISingleSelectInputProps
  extends InputHTMLAttributes<HTMLSelectElement> {
  options: IInputOption[];
  labelText: string;
  fieldState?: ControllerFieldState;
  field?: ControllerRenderProps;
  ref?: any;
}

export const SingleSelectInput: React.FC<ISingleSelectInputProps> = ({
  labelText,
  name,
  fieldState,
  options,
  ref,
  ...rest
}) => {
  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {labelText}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <select
          name={name}
          id={name}
          className={classNames(
            fieldState?.invalid &&
              "border-red-300 text-red-900 placeholder-red-300",
            "mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          )}
          aria-invalid={fieldState?.invalid || "false"}
          aria-describedby={`${name}-error`}
          {...rest}
        >
          {!rest.defaultValue && <option>Выберите значение из списка</option>}
          {options &&
            options.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
        </select>
        {fieldState?.invalid && (
          <div className="absolute inset-y-0 right-0 pr-8 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden={!fieldState?.invalid}
            />
          </div>
        )}
      </div>
      {fieldState?.error?.message && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {fieldState?.error?.message}
        </p>
      )}
    </>
  );
};

export const LabeledToggleInput: React.FC<ICustomLabeledInputProps> = ({
  labelText,
  name,
  value,
  ...rest
}) => {
  const [enabled, setEnabled] = useState<boolean>(rest.checked!);
  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {labelText}
      </label>
      <div className="flex items-center h-10">
        <Switch
          checked={enabled}
          onChange={(value) => {
            setEnabled(value);
            // @ts-ignore
            rest.field && rest.onChange(value);
          }}
          className={classNames(
            enabled ? "bg-red-600" : "bg-gray-200",
            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          )}
        >
          <span className="sr-only">{labelText}</span>
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
            )}
          />
        </Switch>
      </div>
    </>
  );
};

export const ReactSelectInput = ({
  labelText,
  name,
  fieldState,
  options,
  value,
  onSelect,
  isMulti = false,
  ...rest
}) => {
  const [selectOptions] = useState(options);
  const [currentValue, setCurrentValue] = useState(
    value ? selectOptions?.find((o) => o?.value === value) : null
  );

  const handleChange = (target) => {
    if (target) {
      if (isMulti) {
        onSelect(target);
      } else {
        setCurrentValue({
          value: target.value,
          label: target.label,
        });
        onSelect(target.value);
      }
    } else {
      setCurrentValue(null);
      onSelect(null);
    }
  };

  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {labelText}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <Select
          name={name}
          id={name}
          options={selectOptions}
          onChange={handleChange}
          value={currentValue}
          className="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          isMulti={isMulti}
          {...rest}
        />
        {fieldState?.invalid && (
          <div className="absolute inset-y-0 right-0 pr-8 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden={!fieldState?.invalid}
            />
          </div>
        )}
      </div>
      {fieldState?.error?.message && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {fieldState?.error?.message}
        </p>
      )}
    </>
  );
};

interface ICasePictureInputProps extends ICustomLabeledInputProps {
  picture?: string;
  alt?: string;
}

export const CasePictureInput: React.FC<ICasePictureInputProps> = ({
  labelText = "Картинка кейса",
  name,
  picture,
  alt,
  fieldState,
  ...rest
}) => {
  const [pictureState, setPictureState] = useState(picture);
  const [uploading, setUploading] = useState(false);

  const hiddenFileInput = useRef(null);

  const handleChangeClick = () => {
    hiddenFileInput.current.click();
  };

  const uploadPicture = async (event) => {
    try {
      let pictureToUpload = null;
      if (event?.target?.files) {
        pictureToUpload = Array.from(event.target.files);
      }
      setUploading(true);
      const formBody = new FormData();
      formBody.append("file", pictureToUpload[0]);
      const { url } = await (
        await fetch(`${UPLOAD_ROUTE}/case-picture`, {
          method: "POST",
          body: formBody,
        })
      ).json();
      setPictureState(url);
      setUploading(false);
      rest.field && rest.field.onChange(url);
    } catch (error) {
      console.log(error);
      setUploading(false);
    }
  };

  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {labelText}
      </label>
      <div className="mt-1 flex items-center space-x-5">
        {!uploading ? (
          <img
            src={pictureState}
            alt={alt}
            className="inline-block h-10 w-10 overflow-hidden bg-gray-100 object-cover"
          />
        ) : (
          <div className="relative inline-block h-10 w-10">
            <DataLoader size={20} />
          </div>
        )}

        <button
          type="button"
          onClick={handleChangeClick}
          className="bg-white py-2.5 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Изменить
        </button>

        <input
          ref={hiddenFileInput}
          style={{ display: "none" }}
          type="file"
          onChange={uploadPicture}
          name={name}
          {...rest}
        />
      </div>
      {fieldState?.error?.message && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {fieldState?.error?.message}
        </p>
      )}
    </>
  );
};

interface ICasePresentationInputProps extends ICustomLabeledInputProps {
  presentation?: string;
}

export const CasePresentationInput: React.FC<ICasePresentationInputProps> = ({
  labelText = "Презентация кейса",
  name,
  presentation,
  fieldState,
  ...rest
}) => {
  const [presentationState, setPresentationState] = useState(presentation);
  const [uploading, setUploading] = useState(false);

  const hiddenFileInput = useRef(null);

  const handleChangeClick = () => {
    hiddenFileInput.current.click();
  };

  const uploadPresentation = async (event) => {
    try {
      let fileToUpload = null;
      if (event?.target?.files) {
        fileToUpload = Array.from(event.target.files);
      }
      setUploading(true);
      const formBody = new FormData();
      formBody.append("file", fileToUpload[0]);
      const { url } = await (
        await fetch(`${UPLOAD_ROUTE}/case-presentation`, {
          method: "POST",
          body: formBody,
        })
      ).json();
      setPresentationState(url);
      setUploading(false);
      rest.field && rest.field.onChange(url);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };
  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {labelText}
      </label>

      <div className="mt-1 flex items-center space-x-5">
        {!uploading ? (
          <div>
            {!presentationState ? (
              <span>Презентация не загружена</span>
            ) : (
              <span>Презентация загружена</span>
            )}
          </div>
        ) : (
          <div className="relative inline-block h-10 w-10">
            <DataLoader size={20} />
          </div>
        )}

        <button
          type="button"
          onClick={handleChangeClick}
          className="bg-white py-2.5 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Изменить
        </button>
        <input
          ref={hiddenFileInput}
          style={{ display: "none" }}
          type="file"
          onChange={uploadPresentation}
          name={name}
          {...rest}
        />
      </div>
      {fieldState?.error?.message && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {fieldState?.error?.message}
        </p>
      )}
    </>
  );
};
