import { useQuery } from "@apollo/client";
import {
  listSellers,
  listSellersVariables,
} from "../../../graphql/__generated-types__/listSellers";
import { LIST_SELLERS_QUERY } from "../../../graphql/queries";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import {
  DataLoader,
  PrimaryButton,
  SingleRowCell,
  Table,
} from "../../../components";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import AdminSellerCreate from "./create";
import AdminSellerDelete from "./delete";
import AdminSellerEdit from "./edit";
import { LayoutButtonsContext } from "../AdminLayout";
import { SellerParts } from "graphql/__generated-types__/SellerParts";
import { CellProps } from "react-table";
import { Helmet } from "react-helmet-async";
import { useMe } from "hooks";
import { UserRole } from "graphql/__generated-types__/globalTypes";

const AdminSellers = () => {
  const { setLayoutButtons } = useContext(LayoutButtonsContext);

  const user = useMe();

  const hideEditingForm = useCallback(() => {
    setIsEditing(false);
    setCurrentSeller(null);
  }, []);

  const clickAddButton = useCallback(() => {
    hideEditingForm();
    setIsAdding(true);
  }, [hideEditingForm]);

  useEffect(() => {
    setLayoutButtons(
      <div className="flex gap-x-2">
        {(user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
          user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
          <PrimaryButton
            icon={PlusIcon({
              className: "h-4 w-4",
              "aria-hidden": true,
            })}
            id="sellerAddButton"
            onClick={() => clickAddButton()}
          >
            Добавить
          </PrimaryButton>
        )}
      </div>
    );
    return () => {
      setLayoutButtons(null);
    };
  }, [clickAddButton, setLayoutButtons, user?.data?.me?.user?.roles]);

  const [showDeleteSellerModal, setShowDeleteSellerModal] = useState(false);
  const [currentSeller, setCurrentSeller] = useState<SellerParts>(null);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const hideAddingForm = useCallback(
    (reOpen = false) => {
      setIsAdding(false);
      setCurrentSeller(null);
      reOpen && clickAddButton();
    },
    [setIsAdding, setCurrentSeller, clickAddButton]
  );

  const clickDeleteButton = useCallback(
    (seller: SellerParts) => {
      setShowDeleteSellerModal(!showDeleteSellerModal);
      setCurrentSeller(seller);
    },
    [setShowDeleteSellerModal, showDeleteSellerModal]
  );

  const clickEditButton = useCallback(
    (seller: SellerParts) => {
      hideAddingForm();
      hideEditingForm();
      setIsEditing(true);
      setCurrentSeller(seller);
      const sellerAddButton = document.getElementById("sellerAddButton");
      if (sellerAddButton) {
        sellerAddButton.scrollIntoView();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    [hideAddingForm, hideEditingForm, setIsEditing, setCurrentSeller]
  );

  const { loading, data: sellersData } = useQuery<
    listSellers,
    listSellersVariables
  >(LIST_SELLERS_QUERY, {
    variables: { input: {} },
  });

  const columns = useMemo(
    () => [
      {
        Header: "Название",
        accessor: (d: SellerParts) => d.title,
        Cell: (props: CellProps<SellerParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Юр. лицо",
        Cell: (props: CellProps<SellerParts, string>) => {
          if (props.row.original?.legalnames) {
            return (
              <div>
                {props.row.original?.legalnames?.length
                  ? props.row.original?.legalnames?.map((legalname) => (
                      <p>{legalname?.title}</p>
                    ))
                  : "Нет привязанных ЮЛ"}
              </div>
            );
          } else return null;
        },
      },
      {
        Header: "Действие",
        Cell: (props: CellProps<SellerParts>) => (
          <div className="flex gap-x-2">
            {user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
            user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN) ? (
              <>
                <PrimaryButton
                  icon={PencilIcon({
                    className: "h-4 w-4",
                    "aria-hidden": true,
                  })}
                  disabled={isAdding}
                  onClick={() => clickEditButton(props.row.original)}
                ></PrimaryButton>

                <PrimaryButton
                  icon={TrashIcon({
                    className: "h-4 w-4",
                    "aria-hidden": true,
                  })}
                  disabled={isAdding || isEditing}
                  onClick={() => clickDeleteButton(props.row.original)}
                ></PrimaryButton>
              </>
            ) : (
              <SingleRowCell>Н/Д</SingleRowCell>
            )}
          </div>
        ),
      },
    ],
    [
      clickDeleteButton,
      clickEditButton,
      isAdding,
      isEditing,
      user?.data?.me?.user?.roles,
    ]
  );

  const sellers = useMemo(
    () => sellersData?.listSellers?.sellers,
    [sellersData?.listSellers?.sellers]
  );

  return !loading ? (
    <>
      <Helmet>
        <title> Селлеры | Артикс Портал</title>
      </Helmet>
      {(isAdding || isEditing) && (
        <div className="mb-2">
          {isAdding &&
            (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
              user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
              <AdminSellerCreate onClose={hideAddingForm} />
            )}
          {isEditing &&
            (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
              user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
              <AdminSellerEdit
                onClose={hideEditingForm}
                seller={currentSeller}
              />
            )}
        </div>
      )}

      {showDeleteSellerModal &&
        (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
          user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
          <AdminSellerDelete
            show={showDeleteSellerModal}
            showFn={setShowDeleteSellerModal}
            seller={currentSeller}
            closeModal={setShowDeleteSellerModal}
          />
        )}

      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <Table
                columns={columns}
                skipColumns={[]}
                data={sellers}
                initialState={{
                  pageSize: 20,
                  pageIndex: 0,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};

export default AdminSellers;
