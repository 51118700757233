import { gql } from "@apollo/client";
import { INVOICE_FRAGMENT } from "graphql/fragments";

export const UPDATE_INVOICE_MUTATION = gql`
  mutation updateInvoice($input: UpdateInvoiceInput!) {
    updateInvoice(input: $input) {
      ok
      error
      invoice {
        ...InvoiceParts
      }
    }
  }
  ${INVOICE_FRAGMENT}
`;