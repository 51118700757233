import { StoreObject, useMutation } from "@apollo/client";
import { BUYING_FORMAT_FRAGMENT } from "graphql/fragments";
import { UPDATE_BUYING_FORMAT_MUTATION } from "graphql/mutations/updateBuyingFormat";
import { BuyingFormatParts } from "graphql/__generated-types__/BuyingFormatParts";
import {
  updateBuyingFormat,
  updateBuyingFormatVariables,
} from "graphql/__generated-types__/updateBuyingFormat";
import { adminMenuRoutes } from "navigation/routes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataLoader } from "../../../../components";
import { BuyingFormatsForm, IBuyingFormatsForm } from "../components";

interface IBuyingFormatEdit {
  buyingFormat: BuyingFormatParts;
  onClose: () => void;
}

const BuyingFormatEdit: React.FC<IBuyingFormatEdit> = ({
  buyingFormat,
  onClose,
}) => {
  const navigate = useNavigate();

  const [updateBuyingFormat] = useMutation<
    updateBuyingFormat,
    updateBuyingFormatVariables
  >(UPDATE_BUYING_FORMAT_MUTATION, {
    onCompleted: (updateBuyingFormatData) => {
      if (updateBuyingFormatData?.updateBuyingFormat?.ok) {
        navigate(adminMenuRoutes.buyingFormats.path);
      } else {
        toast.error(updateBuyingFormatData.updateBuyingFormat.error);
      }
    },
    update(cache, { data: { updateBuyingFormat } }) {
      if (
        updateBuyingFormat.buyingFormat &&
        updateBuyingFormat.buyingFormat.id
      ) {
        cache.writeFragment({
          id: cache.identify(
            updateBuyingFormat?.buyingFormat as unknown as StoreObject
          ),
          data: updateBuyingFormat.buyingFormat,
          fragment: BUYING_FORMAT_FRAGMENT,
          fragmentName: "BuyingFormatParts",
        });
      }
    },
  });

  const onSubmit = async (data: IBuyingFormatsForm) => {
    const responseData = await updateBuyingFormat({
      variables: {
        input: {
          ...data,
          id: +buyingFormat.id,
        },
      },
    });
    if (responseData.data.updateBuyingFormat.ok) {
      onClose();
    }
  };

  return buyingFormat ? (
    <BuyingFormatsForm
      buyingFormat={buyingFormat}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  ) : (
    <DataLoader />
  );
};

export default BuyingFormatEdit;
