import {
		listEmployeeDepartments_listEmployeeDepartments_departments_employees_position_processes
} from "graphql/__generated-types__/listEmployeeDepartments";
import {UserParts_position_processes} from "../../../graphql/__generated-types__/UserParts";

interface IProcessInline {
		process?: listEmployeeDepartments_listEmployeeDepartments_departments_employees_position_processes | UserParts_position_processes;
}

const ProcessInline: React.FC<IProcessInline> = ({ process }) => {
		return (
			<div className="flex justify-between items-center w-full">
							<div className="flex items-center gap-4">
									<span>
											<a
												href={process?.process?.schemaLink}
												target="_blank"
												rel="noreferrer"
												className="underline"
											>
												{process?.process?.title}
											</a>
									</span>
							</div>
			</div>
		);
};

export default ProcessInline;
