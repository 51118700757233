import { gql } from "@apollo/client";
import { BUYING_CASHBACK_TRANSACTION_FRAGMENT } from "../fragments";

export const LIST_BUYING_CASHBACK_TRANSACTIONS_QUERY = gql`
  query listBuyingCashbackTransactions(
    $input: ListBuyingCashbackTransactionsInput!
  ) {
    listBuyingCashbackTransactions(input: $input) {
      ok
      error
      buyingCashbackTransactions {
        ...BuyingCashbackTransactionParts
      }
    }
  }
  ${BUYING_CASHBACK_TRANSACTION_FRAGMENT}
`;
