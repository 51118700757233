import {gql} from "@apollo/client";
import {LEGALNAME_FRAGMENT} from "../fragments";

export const UPDATE_LEGALNAME_MUTATION = gql`
  mutation updateLegalname($input: UpdateLegalnameInput!) {
    updateLegalname(input: $input) {
      ok
      error
      legalname {
        ...LegalnameParts
      }
    }
  }
  ${LEGALNAME_FRAGMENT}
`;
