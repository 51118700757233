import {gql} from "@apollo/client";
import {CLIENT_FRAGMENT} from "../fragments";

export const UPDATE_CLIENT_MUTATION = gql`
  mutation updateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      ok
      error
      client {
        ...ClientParts
      }
    }
  }
  ${CLIENT_FRAGMENT}
`;
