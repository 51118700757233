import {gql} from "@apollo/client";
import { BUYING_FORMAT_FRAGMENT } from "graphql/fragments";

export const CREATE_BUYING_FORMAT_MUTATION = gql`
  mutation createBuyingFormat($input: CreateBuyingFormatInput!) {
    createBuyingFormat(input: $input) {
      ok
      error
      buyingFormat {
        ...BuyingFormatParts
      }
    }
  }
  ${BUYING_FORMAT_FRAGMENT}
`;
