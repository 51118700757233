import { useContext, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { FIND_ALL_USERS_QUERY } from "graphql/queries";
import {
  findAllUsers,
  findAllUsersVariables,
  findAllUsers_findAllUsers_users,
} from "graphql/__generated-types__/findAllUsers";
import {
  DataLoader,
  Table,
  AvatarCell,
  DoubleRowCell,
  SelectColumnFilter,
  StackedAvatarsCell,
  StatusPill,
  PrimaryButton,
  SingleRowCell,
} from "components";
import { formatDate, formatPhone, formatTelegram } from "utils";
import AvatarTemplate from "assets/user-template.png";
import { UserAddIcon } from "@heroicons/react/outline";
import { adminPagesRoutes } from "navigation/routes";
import { LayoutButtonsContext } from "../AdminLayout";
import { CellProps } from "react-table";
import { UserParts } from "graphql/__generated-types__/UserParts";
import { Helmet } from "react-helmet-async";
import { UserRole } from "graphql/__generated-types__/globalTypes";
import { useMe } from "hooks";
import { userGradesMap } from "config/mappings";

const AdminUsers = () => {
  const { setLayoutButtons } = useContext(LayoutButtonsContext);

  const user = useMe();

  useEffect(() => {
    setLayoutButtons(
      (user?.data?.me?.user?.roles.includes(UserRole.HR_ADMIN) ||
        user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
        <>
          <div className="flex gap-x-2">
            <Link to={adminPagesRoutes.userCreate.path}>
              <PrimaryButton
                icon={UserAddIcon({
                  className: "h-4 w-4",
                  "aria-hidden": true,
                })}
              >
                Добавить
              </PrimaryButton>
            </Link>
          </div>
        </>
      )
    );
    return () => {
      setLayoutButtons(null);
    };
  }, [setLayoutButtons, user?.data?.me?.user?.roles]);

  const { loading, data: userData } = useQuery<
    findAllUsers,
    findAllUsersVariables
  >(FIND_ALL_USERS_QUERY, {
    variables: {
      input: {
        departmentIds: [],
      },
    },
  });

  const getArchiveSurname = (oldSurnames, currSurname) => {
    return [
      ...new Set(
        oldSurnames
          .filter((archiveSurname) => archiveSurname?.surname !== currSurname)
          .map((aSurname) => ` ${aSurname.surname}`)
      ),
    ];
  };

  const columns = useMemo(
    () => [
      {
        Header: "Сотрудник",
        accessor: "surname",
        Cell: (props: CellProps<UserParts, string>) =>
          user?.data?.me?.user?.roles.includes(UserRole.HR_ADMIN) ||
          user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN) ? (
            <>
              <Link to={`/admin/users/${props.row.original.id}/edit`}>
                <AvatarCell
                  value={`${props.cell.value} 
                  ${
                    getArchiveSurname(
                      props.row.original.userLogs,
                      props.cell.value
                    ).length > 0
                      ? `(бывш. ${getArchiveSurname(
                          props.row.original.userLogs,
                          props.cell.value
                        )})`
                      : ``
                  }`}
                  name={props.row.original.name}
                  avatar={props.row.original.avatar || AvatarTemplate}
                  subtitle={props.row.original.email}
                />
              </Link>
            </>
          ) : (
            <AvatarCell
              value={props.cell.value}
              name={props.row.original.name}
              avatar={props.row.original.avatar || AvatarTemplate}
              subtitle={props.row.original.email}
            />
          ),
      },
      {
        Header: "Отдел",
        accessor: (d: UserParts) => d.department?.title || "Отдел не указан",
        Cell: (props: CellProps<UserParts, string>) => (
          <DoubleRowCell
            value={props.cell.value}
            firstRow={
              props?.row?.original?.position?.title
                ? props?.row?.original?.grades?.length
                  ? `${
                      props?.row?.original?.position?.title
                    } (${props?.row?.original?.grades?.map(
                      (grade) => userGradesMap[grade]
                    )})`
                  : props?.row?.original?.position?.title
                : "Должность не указана"
            }
          />
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Офис",
        accessor: (d: UserParts) => {
          if (d.office === "MSK") return "Москва";
          if (d.office === "SPB") return "Санкт-Петербург";
          return "Не указан";
        },
        Cell: (props: CellProps<UserParts, "Москва" | "Санкт-Петербург">) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Руководитель",
        accessor: "employeeHead.surname",
        Cell: (props: CellProps<findAllUsers_findAllUsers_users, string>) => (
          <AvatarCell
            value={props.cell.value}
            name={props.row.original.employeeHead?.name}
            avatar={props.row.original.employeeHead?.avatar}
            subtitle={props.row.original.employeeHead?.position?.title}
          />
        ),
      },
      {
        Header: "Подчиненные",
        accessor: "subordinates",
        Cell: (props: CellProps<UserParts, UserParts["subordinates"]>) =>
          props.row.original.subordinates?.length > 0 ? (
            <StackedAvatarsCell
              value={props.row.original.subordinates.filter((s) => s.isActive)}
            />
          ) : null,
      },
      {
        Header: "День рождения",
        accessor: (d: UserParts) =>
          d.birthdate ? formatDate(d.birthdate) : null,
        Cell: (props: CellProps<UserParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Телефон",
        accessor: (d: UserParts) => (d.phone ? formatPhone(d.phone) : null),
        Cell: (props: CellProps<UserParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Telegram",
        accessor: (d: UserParts) => d.telegram && formatTelegram(d.telegram),
        Cell: (props: CellProps<UserParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Статус",
        accessor: (d: UserParts) => {
          return d.isActive ? "Работает" : "Не работает";
        },
        Cell: (props: CellProps<UserParts, "Работает" | "Не работает">) => (
          <StatusPill
            isPositive={props.row.original.isActive}
            value={props.cell.value}
          />
        ),
        id: "isActive",
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Приоритет внутри отдела",
        accessor: (d: UserParts) =>
          d?.priorityInsideDepartment && d?.priorityInsideDepartment,
        Cell: (props: CellProps<UserParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
    ],
    [user?.data?.me?.user?.roles]
  );

  const people = useMemo(
    () => userData?.findAllUsers.users,
    [userData?.findAllUsers.users]
  );
  return !loading ? (
    <>
      <Helmet>
        <title> Сотрудники | Артикс Портал</title>
      </Helmet>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <Table
                columns={columns}
                data={people}
                initialState={{
                  filters: [
                    {
                      id: "isActive",
                      value: "Работает",
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};

export default AdminUsers;
