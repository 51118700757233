import { StoreObject, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataLoader } from "../../../../components";
import { UPDATE_LEGALNAME_MUTATION } from "../../../../graphql/mutations";
import { adminMenuRoutes } from "../../../../navigation/routes";
import { LEGALNAME_FRAGMENT } from "../../../../graphql/fragments";
import {
  updateLegalname,
  updateLegalnameVariables,
} from "../../../../graphql/__generated-types__/updateLegalname";
import { AdminLegalnameForm, ILegalnameForm } from "../components";
import { LegalnameParts } from "graphql/__generated-types__/LegalnameParts";

interface IAdminLegalnameEdit {
  legalname: LegalnameParts;
  onClose: () => void;
}

const AdminLegalnameEdit: React.FC<IAdminLegalnameEdit> = ({
  legalname,
  onClose,
}) => {
  const navigate = useNavigate();

  const [updateLegalname] = useMutation<
    updateLegalname,
    updateLegalnameVariables
  >(UPDATE_LEGALNAME_MUTATION, {
    onCompleted: (updateLegalnameData) => {
      if (updateLegalnameData?.updateLegalname?.ok) {
        navigate(adminMenuRoutes.legalnames.path);
      } else {
        toast.error(updateLegalnameData?.updateLegalname?.error);
      }
    },
    update(cache, { data: { updateLegalname } }) {
      if (updateLegalname?.legalname?.id) {
        cache.writeFragment({
          id: cache.identify(
            updateLegalname?.legalname as unknown as StoreObject
          ),
          data: updateLegalname?.legalname,
          fragment: LEGALNAME_FRAGMENT,
          fragmentName: "LegalnameParts",
        });
      }
    },
  });

  const onSubmit = async (data: ILegalnameForm) => {
    const responseData = await updateLegalname({
      variables: {
        input: {
          ...data,
          id: +legalname.id,
          sellerId: +data?.sellerId,
        },
      },
    });
    if (responseData?.data?.updateLegalname?.ok) {
      onClose();
    }
  };

  return legalname ? (
    <AdminLegalnameForm
      legalname={legalname}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  ) : (
    <DataLoader />
  );
};

export default AdminLegalnameEdit;
