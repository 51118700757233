import { Helmet } from "react-helmet-async";
import {useQuery} from "@apollo/client";
import { LIST_EMPLOYEE_DEPARTMENTS_QUERY } from "graphql/queries";
import {DepartmentContainer, SearchBar} from "./components";
import {DataLoader, Modal} from "components";
import {useEffect, useState} from "react";
import useHotkeys from "@reecelucas/react-use-hotkeys";
import {
    listEmployeeDepartments,
    listEmployeeDepartmentsVariables
} from "graphql/__generated-types__/listEmployeeDepartments";
import {SearchedPerson} from "./components/SearchedPerson";

const Team = () => {
  const [skip, setSkip] = useState(false);
  const [data, setData] = useState<listEmployeeDepartments>();
  const [showSearchModal, setShowSearchModal] = useState(false);
  const { data: departmentsData, loading } = useQuery<
    listEmployeeDepartments,
    listEmployeeDepartmentsVariables
  >(LIST_EMPLOYEE_DEPARTMENTS_QUERY, {
    variables: {
      input: { includeUserData: true, includeEmpty: false },
    },
    fetchPolicy: "cache-first",
    skip: skip

  });


  useEffect(() => {
        // check whether data exists
        if (!loading && !!departmentsData && !skip) {
            setSkip(true)
            setData(departmentsData);
        }
    }, [departmentsData, loading, skip])

  useHotkeys(["Meta+k"], () => {
    setShowSearchModal(!showSearchModal);
  });

  return (
    <>
      <Helmet>
        <title>Команда | Артикс Портал</title>
      </Helmet>
      <div className="bg-white">
        {loading ? (
          <DataLoader />
        ) : (
          <>
              <div className="sticky top-12 z-10 bg-white">
                {showSearchModal && (
                  <Modal show={showSearchModal} showFn={setShowSearchModal}>
                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                      <SearchBar hideKbTooltip={true} />
                    </div>
                  </Modal>
                )}
                <div className="mx-auto max-w-7xl sm:px-6 sm:py-8 lg:px-8 py-6 px-4 md:flex md:space-x-4 space-y-4 md:space-y-0">
                  <div className="w-full md:w-3/5 ">
                    <SearchBar />
                  </div>
                  <div className="w-full md:w-2/5">
                    <label
                      htmlFor="departmentSelect"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Быстрый переход к отделу
                    </label>
                    <select
                      id="departmentSelect"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                      onChange={(e) => {
                        const id = e.target.value;
                        const yOffset = -220;
                        const element = document.getElementById(id);
                        const y =
                          element.getBoundingClientRect().top +
                          window.pageYOffset +
                          yOffset;

                        window.scrollTo({ top: y, behavior: "smooth" });
                      }}
                    >
                      <option disabled>Выберите отдел из списка</option>
                      {data?.listEmployeeDepartments?.departments?.map(
                        (department, idx) => (
                          <option key={idx} value={department.id}>
                            {department.title}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            <div className="mx-auto max-w-7xl sm:px-6 sm:py-8 lg:px-8 lg:py-12 py-6 px-4 ">
              <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-12">
                {data?.listEmployeeDepartments?.departments?.map(
                  (department) => (
                    <DepartmentContainer
                      key={department.id}
                      departmentData={department}
                    />
                  )
                )}
              </div>
            </div>
          </>
        )}
        <SearchedPerson/>
      </div>
    </>
  );
}

export default Team;
