import { useQuery } from "@apollo/client";
import {
  Button,
  DataLoader,
  LabeledTextInput,
  PrimaryButton,
  SingleSelectInput,
} from "components";
import { FIND_ALL_USERS_QUERY } from "graphql/queries";
import {
  findAllUsers,
  findAllUsersVariables,
} from "graphql/__generated-types__/findAllUsers";
import { findEmployeeDepartment } from "graphql/__generated-types__/findEmployeeDepartment";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export interface IEmployeeDepartmentsForm {
  title: string;
  departmentHeadId: number;
  description?: string;
  order: number;
}

interface IEmployeeDepartmentsFormProps {
  departmentData: findEmployeeDepartment | null;
  onSubmit: (data: any) => void;
}

export const EmployeeDepartmentsForm: React.FC<
  IEmployeeDepartmentsFormProps
> = ({ departmentData, onSubmit }) => {
  const navigate = useNavigate();

  const { data: userData, loading: userLoading } = useQuery<
    findAllUsers,
    findAllUsersVariables
  >(FIND_ALL_USERS_QUERY, {
    variables: {
      input: {
        departmentIds: [],
        isActive: true,
      },
    },
  });

  const { handleSubmit, control } = useForm<IEmployeeDepartmentsForm>({
    mode: "onSubmit",
    shouldUnregister: true,
    defaultValues: {
      ...departmentData?.findEmployeeDepartment?.department,
    },
  });

  return !userLoading ? (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Информация об отделе
            </h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-6 lg:col-span-3">
                <Controller
                  control={control}
                  name="title"
                  rules={{ required: "Нужно заполнить название отдела" }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Название отдела"
                      value={field.value || ""}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-6 lg:col-span-3">
                <Controller
                  control={control}
                  name="departmentHeadId"
                  defaultValue={
                    departmentData?.findEmployeeDepartment?.department
                      ?.departmentHead?.id
                  }
                  render={({ field, fieldState }) => (
                    <SingleSelectInput
                      options={
                        userData &&
                        userData.findAllUsers.users.map((user) => ({
                          label: `${user.surname} ${user.name}`,
                          value: user.id,
                        }))
                      }
                      defaultValue={field.value}
                      labelText="Руководитель отдела"
                      onChange={(e) => field.onChange(+e.target.value)}
                      placeholder="Выберите руководителя отдела из списка"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                <Controller
                  control={control}
                  name="description"
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Описание отдела"
                      value={field.value || ""}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                <Controller
                  control={control}
                  name="order"
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Очередность"
                      value={field.value}
                      name={field.name}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseInt(e.target.value, 10))
                          ? parseInt(e.target.value, 10)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      fieldState={fieldState}
                      type="number"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-x-3">
        <Button type="button" onClick={() => navigate(-1)}>
          Отмена
        </Button>
        <PrimaryButton type="submit">Сохранить</PrimaryButton>
      </div>
    </form>
  ) : (
    <DataLoader />
  );
};
