
// import { ExcelModal } from "components";

const InvoiceExcel = ({ show, showFn, closeModal, link, post_url }) => {

  const onSubmit = async () => {
    closeModal();
    window.location.reload();
  };

  return (
      <></>
    // <ExcelModal
    //   show={show}
    //   showFn={showFn}
    //   headerText="Экспорт актов"
    //   link={link}
    //   onClose={closeModal}
    //   onSubmit={onSubmit}
    //   type={null}
    //   post_url={post_url}
    // />
  );
};
export default InvoiceExcel;