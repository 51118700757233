import { useQuery } from "@apollo/client";
import {
  DataLoader,
  Table,
  AvatarCell,
  SelectColumnFilter,
  PrimaryButton,
  SingleRowCell,
} from "components";
import { LIST_EMPLOYEE_DEPARTMENTS_QUERY } from "graphql/queries";
import { useContext, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  listEmployeeDepartments,
  listEmployeeDepartmentsVariables,
  listEmployeeDepartments_listEmployeeDepartments_departments,
} from "graphql/__generated-types__/listEmployeeDepartments";
import { PlusIcon } from "@heroicons/react/outline";
import { adminPagesRoutes } from "navigation/routes";
import { LayoutButtonsContext } from "../AdminLayout";
import { CellProps } from "react-table";
import { DepartmentParts } from "graphql/__generated-types__/DepartmentParts";
import { Helmet } from "react-helmet-async";
import { useMe } from "hooks";
import { UserRole } from "graphql/__generated-types__/globalTypes";

const AdminEmployeeDepartments = () => {
  const { setLayoutButtons } = useContext(LayoutButtonsContext);

  const user = useMe();

  useEffect(() => {
    setLayoutButtons(
      (user?.data?.me?.user?.roles.includes(UserRole.HR_ADMIN) ||
        user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
        <>
          <div className="flex gap-x-2">
            <Link to={adminPagesRoutes.departmentsCreate.path}>
              <PrimaryButton
                icon={PlusIcon({
                  className: "h-4 w-4",
                  "aria-hidden": true,
                })}
              >
                Добавить
              </PrimaryButton>
            </Link>
          </div>
        </>
      )
    );
    return () => {
      setLayoutButtons(null);
    };
  }, [setLayoutButtons, user?.data?.me?.user?.roles]);

  const { loading, data: departmentsData } = useQuery<
    listEmployeeDepartments,
    listEmployeeDepartmentsVariables
  >(LIST_EMPLOYEE_DEPARTMENTS_QUERY, {
    variables: {
      input: {
        includeUserData: true,
        includeEmpty: true,
      },
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: "Отдел",
        accessor: "title",
        Cell: (props: CellProps<DepartmentParts, string>) =>
          user?.data?.me?.user?.roles.includes(UserRole.HR_ADMIN) ||
          user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN) ? (
            <>
              <Link to={`/admin/departments/${props.row.original.id}/edit`}>
                <SingleRowCell>{props.cell.value}</SingleRowCell>
              </Link>
            </>
          ) : (
            <SingleRowCell>{props.cell.value}</SingleRowCell>
          ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Описание",
        accessor: "description",
        Cell: (props: CellProps<DepartmentParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "Руководитель отдела",
        accessor: "departmentHead.surname",
        Cell: (
          props: CellProps<
            listEmployeeDepartments_listEmployeeDepartments_departments,
            string
          >
        ) => (
          <AvatarCell
            value={props.cell.value}
            name={props.row.original.departmentHead?.name}
            avatar={props.row.original.departmentHead?.avatar}
            subtitle={props.row.original.departmentHead?.position?.title}
          />
        ),
      },
    ],
    [user?.data?.me?.user?.roles]
  );

  const departments = useMemo(
    () => departmentsData?.listEmployeeDepartments.departments,
    [departmentsData?.listEmployeeDepartments.departments]
  );
  return !loading ? (
    <>
      <Helmet>
        <title> Отделы | Артикс Портал</title>
      </Helmet>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <Table
                columns={columns}
                data={departments}
                initialState={{ pageSize: 10, pageIndex: 0 }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};

export default AdminEmployeeDepartments;
