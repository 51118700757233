import {gql} from "@apollo/client";
import {PM_REPORT_FRAGMENT} from "../fragments";

export const UPDATE_PM_REPORT_MUTATION = gql`
  mutation updatePmReport($input: UpdatePmReportInput!) {
    updatePmReport(input: $input) {
      ok
      error
      pmReport {
        ...PmReportParts
      }
    }
  }
  ${PM_REPORT_FRAGMENT}
`;
