import { vendorIconMap, vendorIconMapForMissingCertificates } from "config/mappings";
import type { listEmployeeDepartments_listEmployeeDepartments_departments_employees_certificates } from "graphql/__generated-types__/listEmployeeDepartments";
import moment from "moment";
import { classNames } from "utils";

interface ICertificateInline {
  certificate: listEmployeeDepartments_listEmployeeDepartments_departments_employees_certificates;
}

const CertificateInline: React.FC<ICertificateInline> = ({ certificate }) => {
  return (
    <div className="flex justify-between items-center w-full">
      <div className="flex items-center gap-4">
        <img
          className="h-4 w-4"
          src={`/services/${
            vendorIconMap[certificate.certificateCategory]?.icon
          }`}
          alt={certificate?.vendor?.title}
        />
        <span>
          <a
            href={certificate?.fileLink}
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            {vendorIconMapForMissingCertificates[certificate?.vendor?.title]?.title}
          </a>
        </span>
      </div>
      <div>
        <span
          className={classNames(
            certificate.validUntil ?  new Date(certificate.validUntil) < new Date()
              ? "bg-red-100 text-red-800"
              : "bg-green-100 text-green-800" : "bg-green-100 text-green-800",
            "w-28 inline-flex justify-center items-center px-2.5 py-0.5 rounded-full text-xs font-medium "
          )} 
        >
          { certificate.validUntil ? 
          ("До " + moment(certificate.validUntil).format("DD.MM.YYYY")) : 
          ("Без даты")  }
        </span>
      </div>
    </div>
  );
};

export default CertificateInline;
