import { StoreObject, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  updateSeller,
  updateSellerVariables,
} from "../../../../graphql/__generated-types__/updateSeller";
import { UPDATE_SELLER_MUTATION } from "../../../../graphql/mutations";
import { adminMenuRoutes } from "../../../../navigation/routes";
import { SELLER_FRAGMENT } from "../../../../graphql/fragments";
import { AdminSellerForm, ISellerForm } from "../components";
import { DataLoader } from "../../../../components";
import { SellerParts } from "graphql/__generated-types__/SellerParts";

interface IAdminSellerEditProps {
  seller: SellerParts;
  onClose: () => void;
}

const AdminSellerEdit: React.FC<IAdminSellerEditProps> = ({
  seller,
  onClose,
}) => {
  const navigate = useNavigate();

  const [updateSeller] = useMutation<updateSeller, updateSellerVariables>(
    UPDATE_SELLER_MUTATION,
    {
      onCompleted: (updateSellerData) => {
        if (updateSellerData?.updateSeller?.ok) {
          navigate(adminMenuRoutes.sellers.path);
        } else {
          toast.error(updateSellerData?.updateSeller?.error);
        }
      },
      update(cache, { data: { updateSeller } }) {
        if (updateSeller?.seller?.id) {
          cache.writeFragment({
            id: cache.identify(updateSeller?.seller as unknown as StoreObject),
            data: updateSeller?.seller,
            fragment: SELLER_FRAGMENT,
            fragmentName: "SellerParts",
          });
        }
      },
    }
  );

  const onSubmit = async (data: ISellerForm) => {
    const responseData = await updateSeller({
      variables: {
        input: {
          ...data,
          id: +seller.id,
        },
      },
    });
    if (responseData?.data?.updateSeller?.ok) {
      onClose();
    }
  };

  return seller ? (
    <AdminSellerForm seller={seller} onSubmit={onSubmit} onClose={onClose} />
  ) : (
    <DataLoader />
  );
};
export default AdminSellerEdit;
