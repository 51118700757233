import { gql } from "@apollo/client";
import { BUYING_CASHBACK_TRANSACTION_FRAGMENT } from "graphql/fragments";

export const CREATE_BUYING_CASHBACK_TRANSACTION_MUTATION = gql`
  mutation createBuyingCashbackTransaction(
    $input: CreateBuyingCashbackTransactionInput!
  ) {
    createBuyingCashbackTransaction(input: $input) {
      ok
      error
      buyingCashbackTransaction {
        ...BuyingCashbackTransactionParts
      }
    }
  }
  ${BUYING_CASHBACK_TRANSACTION_FRAGMENT}
`;
