import {gql} from "@apollo/client";

export const DELETE_PBT_REPORT_MUTATION = gql`
  mutation deletePbtReport($input: DeletePbtReportInput!) {
    deletePbtReport(input: $input) {
      ok
      error
    }
  }
`;
