import { gql } from "@apollo/client";
import { POSITION_FRAGMENT } from "graphql/fragments";

export const UPDATE_EMPLOYEE_POSITION_MUTATION = gql`
  mutation updateEmployeePosition($input: UpdateEmployeePositionInput!) {
    updateEmployeePosition(input: $input) {
      ok
      error
      position {
        ...PositionParts
      }
    }
  }
  ${POSITION_FRAGMENT}
`;
