import { gql } from "@apollo/client";
import { CERTIFICATE_FRAGMENT, USER_FRAGMENT } from "graphql/fragments";

export const LIST_EMPLOYEE_DEPARTMENTS_SERVICE_QUERY = gql`
  query listEmployeeDepartmentsForService($input: ListEmployeeDepartmentsInput!) {
    listEmployeeDepartmentsForService(input: $input) {
      ok
      error
      departments {
        id
        title
        description
        departmentHead {
          id
          name
          surname
          avatar
          position {
            id
            title
          }
        }
        employees {
          ...UserParts
          certificates {
            ...CertificateParts
          }
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${CERTIFICATE_FRAGMENT}
`;
