import {gql} from "@apollo/client";
import { POST_FRAGMENT } from "graphql/fragments";

export const CREATE_POST_MUTATION = gql`
  mutation createPost($input: CreatePostInput!) {
    createPost(input: $input) {
      ok
      error
      post {
        ...PostParts
      }
    }
  }
  ${POST_FRAGMENT}
`;
