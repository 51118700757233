import {gql} from "@apollo/client";
import {CLIENT_FRAGMENT} from "../fragments";

export const LIST_CLIENTS_QUERY = gql`
  query listClients($input: ListClientsInput!) {
    listClients(input: $input) {
      ok
      error
      clients {
        ...ClientParts
      }
    }
  }
  ${CLIENT_FRAGMENT}
`;
