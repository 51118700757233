import { useQuery } from "@apollo/client";
import { DataLoader } from "components";
import { FIND_ALL_USERS_QUERY } from "graphql/queries";
import { GET_CURRENT_RANDOM_COFFEE_QUERY } from "graphql/queries/getCurrentRandomCoffee";
import {
  findAllUsers,
  findAllUsersVariables,
} from "graphql/__generated-types__/findAllUsers";
import { getCurrentRandomCoffee } from "graphql/__generated-types__/getCurrentRandomCoffee";
import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { ActiveRandomCoffee, NewRandomCoffee } from "./components";

const RandomCoffee = () => {
  const {
    data: randomCoffeeData,
    loading: randomCoffeeLoading,
    refetch,
  } = useQuery<getCurrentRandomCoffee>(GET_CURRENT_RANDOM_COFFEE_QUERY);

  const { loading: usersLoading, data: userData } = useQuery<
    findAllUsers,
    findAllUsersVariables
  >(FIND_ALL_USERS_QUERY, {
    variables: {
      input: {
        departmentIds: [],
        isActive: true,
      },
    },
  });

  const randomCoffee = useMemo(
    () => randomCoffeeData?.getCurrentRandomCoffee?.randomCoffee,
    [randomCoffeeData?.getCurrentRandomCoffee?.randomCoffee]
  );

  return !randomCoffeeLoading ? (
    randomCoffeeData?.getCurrentRandomCoffee?.ok ? (
      <>
        <Helmet>
          <title> Random Coffee | Артикс Портал</title>
        </Helmet>
        <ActiveRandomCoffee randomCoffee={randomCoffee} />
      </>
    ) : !usersLoading ? (
      <>
        <Helmet>
          <title> Random Coffee | Артикс Портал</title>
        </Helmet>
        <NewRandomCoffee
          users={userData.findAllUsers.users}
          refetchCurrentRandomCoffee={refetch}
        />
      </>
    ) : (
      <DataLoader />
    )
  ) : (
    <DataLoader />
  );
};

export default RandomCoffee;
