import { useNavigate } from "react-router";
import { useMutation } from "@apollo/client";
import {
  deleteCase,
  deleteCaseVariables,
} from "../../../../graphql/__generated-types__/deleteCase";
import { DELETE_CASE_MUTATION } from "../../../../graphql/mutations";
import { serviceMenuRoutes } from "../../../../navigation/routes";
import { toast } from "react-toastify";
import { CaseDeleteForm } from "../components/CaseDeleteForm";

const CaseDelete = ({ battleCase, closeModal }) => {
  const navigate = useNavigate();
  const [deleteCase] = useMutation<deleteCase, deleteCaseVariables>(
    DELETE_CASE_MUTATION,
    {
      onCompleted: (deleteCaseData) => {
        if (deleteCaseData?.deleteCase?.ok) {
          navigate(serviceMenuRoutes.cases.path);
        } else {
          toast.error(deleteCaseData?.deleteCase?.error);
        }
      },
      update(cache, { data: { deleteCase } }) {
        if (deleteCase?.ok) {
          cache.evict({ id: cache.identify(battleCase) });
          cache.gc();
        }
      },
    }
  );

  const onSubmit = async () => {
    await deleteCase({
      variables: {
        input: {
          id: battleCase.id,
        },
      },
    });
    closeModal();
  };

  const onClose = () => {
    closeModal();
  };

  return <CaseDeleteForm onSubmit={onSubmit} onClose={onClose} />;
};

export default CaseDelete;
