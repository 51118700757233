import { gql } from "@apollo/client";
import { BUYING_CONDITION_FRAGMENT } from "graphql/fragments";

export const UPDATE_BUYING_CONDITION_MUTATION = gql`
  mutation updateBuyingCondition($input: UpdateBuyingConditionInput!) {
    updateBuyingCondition(input: $input) {
      ok
      error
      buyingCondition {
        ...BuyingConditionParts
      }
    }
  }
  ${BUYING_CONDITION_FRAGMENT}
`;
