import { useQuery } from "@apollo/client";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import {
  DataLoader,
  PrimaryButton,
  SelectColumnFilter,
  SingleRowCell,
  StatusPill,
  Table,
} from "components";
import { LIST_VENDORS_QUERY } from "graphql/queries";
import { UserRole } from "graphql/__generated-types__/globalTypes";
import {
  listVendors,
  listVendorsVariables,
  listVendors_listVendors_vendors,
} from "graphql/__generated-types__/listVendors";
import { VendorParts } from "graphql/__generated-types__/VendorParts";
import { useMe } from "hooks";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { CellProps } from "react-table";
import { LayoutButtonsContext } from "../AdminLayout";
import AdminVendorCreate from "./create/create";
import AdminVendorDelete from "./delete/delete";
import AdminVendorEdit from "./edit/edit";

const AdminVendors = () => {
  const { setLayoutButtons } = useContext(LayoutButtonsContext);

  const user = useMe();

  const hideEditingForm = useCallback(() => {
    setIsEditing(false);
    setCurrentVendor(null);
  }, []);

  const clickAddButton = useCallback(() => {
    hideEditingForm();
    setIsAdding(true);
  }, [hideEditingForm]);

  useEffect(() => {
    setLayoutButtons(
      <div className="flex gap-x-2">
        {(user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
          user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
          <PrimaryButton
            icon={PlusIcon({
              className: "h-4 w-4",
              "aria-hidden": true,
            })}
            id="vendorAddButton"
            onClick={() => clickAddButton()}
          >
            Добавить
          </PrimaryButton>
        )}
      </div>
    );
    return () => {
      setLayoutButtons(null);
    };
  }, [clickAddButton, setLayoutButtons, user?.data?.me?.user?.roles]);

  const [showDeleteVendorModal, setShowDeleteVendorModal] = useState(false);
  const [currentVendor, setCurrentVendor] =
    useState<listVendors_listVendors_vendors>(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { data: vendorsData, loading: vendorsLoading } = useQuery<
    listVendors,
    listVendorsVariables
  >(LIST_VENDORS_QUERY, {
    variables: { input: {} },
  });

  const hideAddingForm = useCallback(
    (reOpen = false) => {
      setIsAdding(false);
      setCurrentVendor(null);
      reOpen && clickAddButton();
    },
    [setIsAdding, setCurrentVendor, clickAddButton]
  );

  const clickDeleteButton = useCallback(
    (vendor: listVendors_listVendors_vendors) => {
      setShowDeleteVendorModal(!showDeleteVendorModal);
      setCurrentVendor(vendor);
    },
    [setShowDeleteVendorModal, setCurrentVendor, showDeleteVendorModal]
  );

  const clickEditButton = useCallback(
    (vendor: listVendors_listVendors_vendors) => {
      hideAddingForm();
      hideEditingForm();
      setIsEditing(true);
      setCurrentVendor(vendor);
      const vendorAddButton = document.getElementById("vendorAddButton");
      if (vendorAddButton) {
        vendorAddButton.scrollIntoView();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    [hideAddingForm, hideEditingForm, setIsEditing, setCurrentVendor]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Наименование площадки",
        accessor: (d: VendorParts) => d?.title,
        Cell: (props: CellProps<VendorParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
      },
      {
        Header: "К1",
        cellClassName: "w-12 text-center",
        accessor: (d: VendorParts) => d?.category1?.title,
        Cell: (props: CellProps<VendorParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "К2",
        cellClassName: "w-12 text-center",
        accessor: (d: VendorParts) => d?.category2?.title,
        Cell: (props: CellProps<VendorParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "К3",
        cellClassName: "w-12 text-center",
        accessor: (d: VendorParts) => d?.category3?.title,
        Cell: (props: CellProps<VendorParts, string>) => (
          <SingleRowCell>{props.cell.value}</SingleRowCell>
        ),
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Селлеры",
        cellClassName: "w-10 text-center",
        Cell: (props: CellProps<listVendors_listVendors_vendors>) => {
          if (props.row.original?.sellers?.length) {
            return (
              <SingleRowCell>
                {props.row.original?.sellers?.map((seller) => (
                  <p>{seller?.title}</p>
                ))}
              </SingleRowCell>
            );
          } else return null;
        },
      },
      {
        Header: "ЮЛ Селлера",
        cellClassName: "w-18 text-center",
        Cell: (props: CellProps<listVendors_listVendors_vendors>) => {
          if (props.row.original?.sellers?.length) {
            return (
              <SingleRowCell>
                {props.row.original?.sellers?.map((seller) =>
                  seller?.legalnames?.map((legalname) => (
                    <p>{legalname?.title}</p>
                  ))
                )}
              </SingleRowCell>
            );
          } else return null;
        },
      },
      {
        Header: "Типы закупки",
        cellClassName: "w-10 text-center",
        Cell: (props: CellProps<listVendors_listVendors_vendors>) => {
          if (props.row.original?.buyingTypes?.length) {
            return (
              <SingleRowCell>
                {props.row.original?.buyingTypes?.map((buyingType) => (
                  <p>{buyingType?.title}</p>
                ))}
              </SingleRowCell>
            );
          } else return null;
        },
      },
      {
        Header: "Форматы закупки",
        cellClassName: "w-10 text-center",
        Cell: (props: CellProps<listVendors_listVendors_vendors>) => {
          if (props.row.original?.buyingFormats?.length) {
            return (
              <SingleRowCell>
                {props.row.original?.buyingFormats?.map((buyingFormat) => (
                  <p>{buyingFormat?.title}</p>
                ))}
              </SingleRowCell>
            );
          } else return null;
        },
      },
      {
        Header: "Надо ли передавать в ОРД",
        accessor: (d: VendorParts) => {
          return d?.isOrdNeeded ? "Да" : "Нет";
        },
        Cell: (props: CellProps<VendorParts, "Да" | "Нет">) => (
          <StatusPill
            isPositive={props.row.original.isOrdNeeded}
            value={props.row.original.isOrdNeeded ? "Да" : "Нет"}
          />
        ),
        id: "isOrdNeeded",
        Filter: SelectColumnFilter,
        filter: "exact",
      },
      {
        Header: "Действие",
        Cell: (props: CellProps<listVendors_listVendors_vendors>) => (
          <div className="flex gap-x-2">
            {user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
            user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN) ? (
              <>
                <PrimaryButton
                  icon={PencilIcon({
                    className: "h-4 w-4",
                    "aria-hidden": true,
                  })}
                  disabled={isAdding}
                  onClick={() => clickEditButton(props.row.original)}
                ></PrimaryButton>

                <PrimaryButton
                  icon={TrashIcon({
                    className: "h-4 w-4",
                    "aria-hidden": true,
                  })}
                  disabled={isAdding || isEditing}
                  onClick={() => clickDeleteButton(props.row.original)}
                ></PrimaryButton>
              </>
            ) : (
              <SingleRowCell>Н/Д</SingleRowCell>
            )}
          </div>
        ),
      },
    ],
    [
      clickDeleteButton,
      clickEditButton,
      isAdding,
      isEditing,
      user?.data?.me?.user?.roles,
    ]
  );

  const vendors = useMemo(
    () => vendorsData?.listVendors?.vendors,
    [vendorsData?.listVendors?.vendors]
  );

  return !vendorsLoading ? (
    <>
      <Helmet>
        <title> Площадки | Артикс Портал</title>
      </Helmet>
      {(isAdding || isEditing) && (
        <div className="mb-2">
          {isAdding &&
            (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
              user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
              <AdminVendorCreate onClose={hideAddingForm} />
            )}
          {isEditing &&
            (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
              user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
              <AdminVendorEdit
                onClose={hideEditingForm}
                vendor={currentVendor}
              />
            )}
        </div>
      )}

      {showDeleteVendorModal &&
        (user?.data?.me?.user?.roles.includes(UserRole.BUYING_ADMIN) ||
          user?.data?.me?.user?.roles.includes(UserRole.GLOBAL_ADMIN)) && (
          <AdminVendorDelete
            show={showDeleteVendorModal}
            showFn={setShowDeleteVendorModal}
            vendor={currentVendor}
            closeModal={setShowDeleteVendorModal}
          />
        )}

      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <Table
                columns={columns}
                skipColumns={[]}
                data={vendors}
                initialState={{
                  pageSize: 20,
                  pageIndex: 0,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <DataLoader />
  );
};

export default AdminVendors;
