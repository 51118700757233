import { useQuery } from "@apollo/client";
import { XIcon } from "@heroicons/react/outline";
import {
  Button,
  PrimaryButton,
  DataLoader,
  ReactSelectInput,
  LabeledTextInputWithTrailingAddon,
  LabeledMonthInput,
} from "components";
import { conditionStatusMap } from "config/mappings";
import { LIST_CLIENTS_QUERY, LIST_VENDORS_QUERY } from "graphql/queries";
import { BuyingCashbackRuleParts } from "graphql/__generated-types__/BuyingCashbackRuleParts";
import { BuyingConditionType } from "graphql/__generated-types__/globalTypes";
import {
  listClients,
  listClientsVariables,
} from "graphql/__generated-types__/listClients";
import {
  listVendors,
  listVendorsVariables,
} from "graphql/__generated-types__/listVendors";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { classNames } from "utils";

export interface IBuyingCashbackRuleForm {
  fallbackCashbackPerc: number;
  clientId: number;
  vendorId: number;
  buyingConditionType: BuyingConditionType;
  cashbackIntervals: [
    {
      validFrom: string;
      validTo: string;
      minTurnover: number;
      maxTurnover: number;
      maxTurnoverChecked: boolean;
      cashbackPerc: number;
    }
  ];
}

interface IBuyingCashbackRuleFormProps {
  buyingCashbackRule?: BuyingCashbackRuleParts;
  onSubmit: (data: any) => void;
  onClose: () => void;
}

export const BuyingCashbackRuleForm: React.FC<IBuyingCashbackRuleFormProps> = ({
  buyingCashbackRule,
  onSubmit,
  onClose,
}) => {
  const { handleSubmit, control, setValue, getValues, register } =
    useForm<IBuyingCashbackRuleForm>({
      mode: "onSubmit",
      shouldUnregister: true,
      defaultValues: buyingCashbackRule
        ? {
            ...buyingCashbackRule,
            fallbackCashbackPerc:
              buyingCashbackRule?.fallbackCashbackPerc * 100,
            clientId: buyingCashbackRule?.client?.id,
            vendorId: buyingCashbackRule?.vendor?.id,
            buyingConditionType: buyingCashbackRule?.buyingConditionType,
            cashbackIntervals: buyingCashbackRule
              ? buyingCashbackRule?.cashbackIntervals?.map((interval) => ({
                  ...interval,
                  cashbackPerc: interval?.cashbackPerc * 100,
                }))
              : [
                  {
                    minTurnover: 0,
                    validFrom: new Date(
                      new Date(new Date().setUTCDate(1)).setUTCHours(0, 0, 0, 0)
                    ).toISOString(),
                    validTo: new Date(
                      new Date(new Date().setUTCDate(1)).setUTCHours(0, 0, 0, 0)
                    ).toISOString(),
                    maxTurnover: 0,
                    maxTurnoverChecked: false,
                    cashbackPerc: 0,
                  },
                ],
          }
        : {
            clientId: null,
            vendorId: null,
            fallbackCashbackPerc: 0,
            cashbackIntervals: [
              {
                minTurnover: 0,
                validFrom: new Date(
                  new Date(new Date().setUTCDate(1)).setUTCHours(0, 0, 0, 0)
                ).toISOString(),
                validTo: new Date(
                  new Date(new Date().setUTCDate(1)).setUTCHours(0, 0, 0, 0)
                ).toISOString(),
                maxTurnover: 0,
                maxTurnoverChecked: false,
                cashbackPerc: 0,
              },
            ],
          },
    });

  const { data: clientsData, loading: clientsLoading } = useQuery<
    listClients,
    listClientsVariables
  >(LIST_CLIENTS_QUERY, {
    variables: { input: {} },
  });

  const { data: vendorsData, loading: vendorsLoading } = useQuery<
    listVendors,
    listVendorsVariables
  >(LIST_VENDORS_QUERY, {
    variables: { input: {} },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "cashbackIntervals",
  });

  const label = buyingCashbackRule
    ? "Редактирование условия биллингового кэшбека"
    : "Добавление условия биллингового кэшбека";

  return !clientsLoading && !vendorsLoading ? (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {label}
            </h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              {/* Клиенты */}
              <div className="col-span-full sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="clientId"
                  rules={{
                    required: "Нужно выбрать клиента",
                  }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={clientsData?.listClients?.clients?.map(
                        (client) => {
                          return {
                            label: client?.title,
                            value: client?.id,
                          };
                        }
                      )}
                      defaultValue={field.value}
                      name="clientId"
                      value={field.value}
                      labelText="Клиент"
                      onSelect={(e) => {
                        setValue("clientId", e);
                      }}
                      placeholder="Выберите клиента из списка"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              {/* Площадки */}
              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="vendorId"
                  rules={{
                    required: "Нужно выбрать площадку",
                  }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={vendorsData?.listVendors?.vendors?.map(
                        (vendor) => {
                          return {
                            label: vendor?.title,
                            value: vendor?.id,
                          };
                        }
                      )}
                      defaultValue={field.value}
                      name="vendorId"
                      value={field.value}
                      labelText="Площадка"
                      onSelect={(e) => {
                        setValue("vendorId", e);
                      }}
                      placeholder="Выберите площадку из списка"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              {/* Тип оплаты */}
              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <Controller
                  control={control}
                  name="buyingConditionType"
                  rules={{
                    required: "Нужно заполнить тип оплаты",
                  }}
                  render={({ field, fieldState }) => (
                    <ReactSelectInput
                      options={Object.values(BuyingConditionType).map((cs) => {
                        return {
                          label: conditionStatusMap[cs],
                          value: cs,
                        };
                      })}
                      defaultValue={field.value}
                      name="buyingConditionType"
                      value={field.value}
                      labelText="Тип оплаты"
                      onSelect={(e) => setValue("buyingConditionType", e)}
                      placeholder="Тип оплаты"
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              {/* Кэшбек от оборота */}
              <div className="col-span-full">
                <span className="block text-sm font-medium text-gray-700">
                  Условия кэшбека от оборота:
                </span>
                {fields.map((item, idx) => (
                  <div
                    className="grid grid-cols-12 gap-x-4 gap-y-1 mt-4"
                    key={item.id}
                  >
                    <>
                      {/* Дата начала действия правила */}
                      <div className="col-span-5">
                        <Controller
                          control={control}
                          rules={{
                            required:
                              "Нужно заполнить дату начала действия правила",
                          }}
                          name={`cashbackIntervals.${idx}.validFrom` as any}
                          render={({ field, fieldState }) => (
                            <LabeledMonthInput
                              id={`cashbackIntervals.${idx}.validFrom`}
                              labelText="Дата начала действия правила"
                              field={field}
                              name={field.name}
                              fieldState={fieldState}
                            />
                          )}
                        />
                      </div>

                      {/* Дата окончания действия правила */}
                      <div className="col-span-5">
                        <Controller
                          control={control}
                          rules={{
                            required:
                              "Нужно заполнить дату окончания действия правила",
                          }}
                          name={`cashbackIntervals.${idx}.validTo` as any}
                          render={({ field, fieldState }) => (
                            <LabeledMonthInput
                              id={`cashbackIntervals.${idx}.validTo`}
                              labelText="Дата окончания действия правила"
                              field={field}
                              name={field.name}
                              fieldState={fieldState}
                            />
                          )}
                        />
                      </div>
                      <div className="col-span-2"></div>

                      {/* Оборот ОТ */}
                      <div className="col-span-full sm:col-span-3">
                        <div className="flex rounded-md shadow-sm">
                          <Controller
                            control={control}
                            name={`cashbackIntervals.${idx}.minTurnover` as any}
                            rules={{
                              required: "Нужно указать минимальный оборот",
                              min: 0,
                            }}
                            render={({ field }) => (
                              <>
                                <div className="col-span-full">
                                  <div className="flex rounded-md">
                                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                      От
                                    </span>
                                    <input
                                      id={`cashbackIntervals[${idx}]minTurnover`}
                                      type="number"
                                      min="0"
                                      {...register(
                                        `cashbackIntervals.${idx}.minTurnover` as any
                                      )}
                                      className={
                                        "flex-1 min-w-0 block w-full px-3 py-2 rounded-none focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300"
                                      }
                                      name={field.name}
                                      step="1"
                                      onChange={(e) => {
                                        const parsedValue = !isNaN(
                                          parseInt(e.target.value)
                                        )
                                          ? parseInt(e.target.value)
                                          : parseInt("0");
                                        field.onChange(parsedValue);
                                      }}
                                    />
                                    <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                      руб.
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          />
                        </div>
                      </div>

                      {/* Оборот ДО */}
                      <div className="col-span-full sm:col-span-3">
                        <div className="flex rounded-md">
                          <Controller
                            control={control}
                            name={`cashbackIntervals.${idx}.maxTurnover` as any}
                            render={({ field }) => (
                              <>
                                <div className="col-span-full">
                                  <div className="flex rounded-md shadow-sm">
                                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                      До
                                    </span>
                                    <input
                                      id={`cashbackIntervals[${idx}]maxTurnover`}
                                      type="number"
                                      {...register(
                                        `cashbackIntervals.${idx}.maxTurnover` as any
                                      )}
                                      className={
                                        "flex-1 min-w-0 block w-full px-3 py-2 rounded-none focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300"
                                      }
                                      defaultValue={field.value}
                                      name={field.name}
                                      onChange={(e) => {
                                        const parsedValue = !isNaN(
                                          parseInt(e.target.value)
                                        )
                                          ? parseInt(e.target.value)
                                          : parseInt("0");
                                        field.onChange(parsedValue);
                                      }}
                                      value={
                                        field.value
                                          ? field.value
                                          : parseInt("0")
                                      }
                                      disabled={
                                        field.value === 0
                                          ? false
                                          : field.value
                                          ? false
                                          : true
                                      }
                                    />
                                    <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                      руб.
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          />
                        </div>
                      </div>

                      {/* Checkbox */}
                      <div className="col-span-full sm:col-span-1">
                        <Controller
                          control={control}
                          name={
                            `cashbackIntervals.${idx}.maxTurnoverChecked` as any
                          }
                          render={({ field }) => (
                            <>
                              <div className="flex grid-cols-2">
                                <div>
                                  <div className="text-sm text-gray-900">
                                    До ∞
                                  </div>
                                </div>
                                <div className="mx-1">
                                  <input
                                    id={`cashbackIntervals[${idx}]maxTurnoverChecked`}
                                    type="checkbox"
                                    onClick={() => {
                                      field.onChange(!field.value);
                                      !getValues().cashbackIntervals[idx]
                                        .maxTurnoverChecked
                                        ? document
                                            .getElementById(
                                              `cashbackIntervals[${idx}]maxTurnover`
                                            )
                                            .removeAttribute("disabled")
                                        : document
                                            .getElementById(
                                              `cashbackIntervals[${idx}]maxTurnover`
                                            )
                                            .setAttribute("disabled", "true");
                                      getValues().cashbackIntervals[idx]
                                        .maxTurnoverChecked &&
                                        setValue(
                                          `cashbackIntervals.${idx}.maxTurnover` as any,
                                          parseInt("0")
                                        );
                                    }}
                                    checked={field.value}
                                    value={field.value}
                                    defaultChecked={
                                      getValues().cashbackIntervals[idx]
                                        ?.maxTurnover
                                        ? false
                                        : true
                                    }
                                    {...register(
                                      `cashbackIntervals.${idx}.maxTurnoverChecked` as any
                                    )}
                                    className={classNames(
                                      field.value
                                        ? "bg-red-600"
                                        : "bg-gray-200",
                                      "inline-flex flex-shrink-0 w-9 h-9 text-red-600 bg-gray-100 rounded border-gray-300 focus:ring-red-500 dark:focus:ring-red-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    )}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        />
                      </div>

                      {/* Процент кэшбека */}
                      <div className="col-span-full sm:col-span-3">
                        <div className="flex rounded-md">
                          <Controller
                            control={control}
                            name={
                              `cashbackIntervals.${idx}.cashbackPerc` as any
                            }
                            rules={{
                              required: "Нужно указать процент кэшбека",
                              min: 0,
                              max: 100,
                            }}
                            render={({ field }) => (
                              <>
                                <div className="col-span-full">
                                  <div className="flex rounded-md shadow-sm">
                                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                      Кэшбек
                                    </span>
                                    <input
                                      id={`cashbackIntervals[${idx}]cashbackPerc`}
                                      type="number"
                                      min="0"
                                      {...register(
                                        `cashbackIntervals.${idx}.cashbackPerc` as any
                                      )}
                                      className={
                                        "flex-1 min-w-0 block w-full px-3 py-2 rounded-none focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300"
                                      }
                                      defaultValue={field.value}
                                      name={field.name}
                                      step="0.1"
                                      onChange={(e) => {
                                        const parsedValue = !isNaN(
                                          parseFloat(e.target.value)
                                        )
                                          ? parseFloat(e.target.value)
                                          : parseFloat("0");
                                        field.onChange(parsedValue);
                                      }}
                                    />
                                    <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                      %
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          />
                        </div>
                      </div>

                      <div className="col-span-1 self-center">
                        <PrimaryButton
                          icon={XIcon({
                            className: "h-4 w-4",
                          })}
                          onClick={() => remove(idx)}
                        ></PrimaryButton>
                      </div>
                    </>
                  </div>
                ))}

                <span
                  className="mt-2 cursor-pointer text-sm text-red-800"
                  onClick={() =>
                    append({
                      minTurnover: 0,
                      validFrom: new Date(
                        new Date(new Date().setUTCDate(1)).setUTCHours(
                          0,
                          0,
                          0,
                          0
                        )
                      ).toISOString(),
                      validTo: new Date(
                        new Date(new Date().setUTCDate(1)).setUTCHours(
                          0,
                          0,
                          0,
                          0
                        )
                      ).toISOString(),
                      maxTurnover: 0,
                      cashbackPerc: 0,
                      maxTurnoverChecked: false,
                    })
                  }
                >
                  Добавить
                </span>
              </div>

              {/* Кэшбек по-умолчанию */}
              <div className="col-span-full">
                <Controller
                  control={control}
                  name="fallbackCashbackPerc"
                  render={({ field, fieldState }) => (
                    <LabeledTextInputWithTrailingAddon
                      labelText="Кэшбек в остальных случаях"
                      addonText="%"
                      value={field.value ?? ""}
                      onChange={(e) => {
                        const parsedValue = !isNaN(parseFloat(e.target.value))
                          ? parseFloat(e.target.value)
                          : "";
                        field.onChange(parsedValue);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      defaultValue="0"
                      type="number"
                      min="0"
                      step="0.1"
                      max="100"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-x-3">
        <Button type="button" onClick={() => onClose()}>
          Отмена
        </Button>
        <PrimaryButton type="submit">Сохранить</PrimaryButton>
      </div>
    </form>
  ) : (
    <DataLoader />
  );
};
