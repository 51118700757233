import { StoreObject, useMutation } from "@apollo/client";
import { BUYING_CASHBACK_TRANSACTION_FRAGMENT } from "graphql/fragments";
import { UPDATE_BUYING_CASHBACK_TRANSACTION_MUTATION } from "graphql/mutations/updateBuyingCashbackTransaction";
import { BuyingCashbackTransactionParts } from "graphql/__generated-types__/BuyingCashbackTransactionParts";
import {
  updateBuyingCashbackTransaction,
  updateBuyingCashbackTransactionVariables,
} from "graphql/__generated-types__/updateBuyingCashbackTransaction";
import { serviceMenuRoutes } from "navigation/routes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataLoader } from "../../../../components";
import {
  BuyingCashbackTransactionForm,
  IBuyingCashbackTransactionForm,
} from "../components";

interface IBuyingCashbackTransactionEditProps {
  buyingCashbackTransaction: BuyingCashbackTransactionParts;
  onClose: () => void;
  editMode: boolean;
}

const BuyingCashbackTransactionEdit: React.FC<
  IBuyingCashbackTransactionEditProps
> = ({ buyingCashbackTransaction, editMode, onClose }) => {
  const navigate = useNavigate();

  const [updateBuyingCashbackTransaction] = useMutation<
    updateBuyingCashbackTransaction,
    updateBuyingCashbackTransactionVariables
  >(UPDATE_BUYING_CASHBACK_TRANSACTION_MUTATION, {
    onCompleted: (data) => {
      if (data?.updateBuyingCashbackTransaction?.ok) {
        navigate(serviceMenuRoutes.buyingCashbackTransactions.path);
      } else {
        toast.error(data?.updateBuyingCashbackTransaction?.error);
      }
    },
    update(cache, { data: { updateBuyingCashbackTransaction } }) {
      if (updateBuyingCashbackTransaction?.buyingCashbackTransaction?.id) {
        cache.writeFragment({
          id: cache.identify(
            updateBuyingCashbackTransaction?.buyingCashbackTransaction as unknown as StoreObject
          ),
          data: updateBuyingCashbackTransaction.buyingCashbackTransaction,
          fragment: BUYING_CASHBACK_TRANSACTION_FRAGMENT,
          fragmentName: "BuyingCashbackTransactionParts",
        });
      }
    },
  });

  const onSubmit = async (data: IBuyingCashbackTransactionForm) => {
    const response = await updateBuyingCashbackTransaction({
      variables: {
        input: {
          ...data,
          id: +buyingCashbackTransaction.id,
          transactionType: buyingCashbackTransaction.transactionType,
          turnover: data.turnover 
          ? parseFloat(data.turnover?.toString().replace(/[a-zA-Z]/g, '').replace(/\s/g, '').replace(/[,]/g, '.'))
          : null,
        },
      },
    });
    if (response?.data?.updateBuyingCashbackTransaction?.ok) {
      onClose();
    }
  };

  return buyingCashbackTransaction ? (
    <BuyingCashbackTransactionForm
      editMode={editMode}
      buyingCashbackTransaction={buyingCashbackTransaction}
      mode={buyingCashbackTransaction.transactionType}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  ) : (
    <DataLoader />
  );
};

export default BuyingCashbackTransactionEdit;
