import { StoreObject, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { adminMenuRoutes } from "../../../../navigation/routes";
import {
  createBuyingFormat,
  createBuyingFormatVariables,
} from "graphql/__generated-types__/createBuyingFormat";
import { CREATE_BUYING_FORMAT_MUTATION } from "graphql/mutations/createBuyingFormat";
import { BUYING_FORMAT_FRAGMENT } from "graphql/fragments";
import { BuyingFormatsForm, IBuyingFormatsForm } from "../components";

const BuyingFormatCreate = ({ onClose }) => {
  const navigate = useNavigate();
  const [createBuyingFormat] = useMutation<
    createBuyingFormat,
    createBuyingFormatVariables
  >(CREATE_BUYING_FORMAT_MUTATION, {
    onCompleted: (createBuyingFormatData) => {
      if (
        createBuyingFormatData &&
        createBuyingFormatData.createBuyingFormat.ok
      ) {
        navigate(adminMenuRoutes.buyingFormats.path);
      } else {
        toast.error(createBuyingFormatData.createBuyingFormat.error);
      }
    },
    update(cache, { data: { createBuyingFormat } }) {
      if (createBuyingFormat?.buyingFormat?.id) {
        const newBuyingFormatRef = cache.writeFragment({
          id: cache.identify(
            createBuyingFormat?.buyingFormat as unknown as StoreObject
          ),
          data: createBuyingFormat?.buyingFormat,
          fragment: BUYING_FORMAT_FRAGMENT,
          fragmentName: "BuyingFormatParts",
        });
        cache.modify({
          id: "ROOT_QUERY",
          fields: {
            findAllBuyingFormats(prev) {
              return {
                ...prev,
                buyingFormats: [newBuyingFormatRef, ...prev.buyingFormats],
              };
            },
          },
        });
      }
    },
  });

  const onSubmit = async (data: IBuyingFormatsForm) => {
    const responseData = await createBuyingFormat({
      variables: {
        input: {
          ...data,
        },
      },
    });
    if (responseData.data.createBuyingFormat.ok) {
      onClose(true);
    }
  };
  return <BuyingFormatsForm onSubmit={onSubmit} onClose={onClose} />;
};

export default BuyingFormatCreate;
