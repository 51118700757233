import {gql} from "@apollo/client";
import {PM_REPORT_FRAGMENT,} from "graphql/fragments";

export const FIND_ONE_PM_REPORT_QUERY = gql`
  query findOnePmReport($input: FindOnePmReportInput!) {
    findOnePmReport(input: $input) {
      ok
      error
      pmReport {
        ...PmReportParts
      }
    }
  }
  ${PM_REPORT_FRAGMENT}
`;
