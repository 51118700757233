import { useMutation } from "@apollo/client";
import { DeleteModal } from "components";
import { DELETE_BUYING_FORMAT_MUTATION } from "graphql/mutations/deleteBuyingFormat";
import {
  deleteBuyingFormat,
  deleteBuyingFormatVariables,
} from "graphql/__generated-types__/deleteBuyingFormat";
import { adminMenuRoutes } from "navigation/routes";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const BuyingFormatDelete = ({ show, showFn, buyingFormat, closeModal }) => {
  const navigate = useNavigate();
  const [deleteBuyingFormat] = useMutation<
    deleteBuyingFormat,
    deleteBuyingFormatVariables
  >(DELETE_BUYING_FORMAT_MUTATION, {
    onCompleted: (deleteBuyingFormatData) => {
      if (
        deleteBuyingFormatData &&
        deleteBuyingFormatData.deleteBuyingFormat.ok
      ) {
        navigate(adminMenuRoutes.buyingFormats.path);
      } else {
        toast.error(deleteBuyingFormatData.deleteBuyingFormat.error);
      }
    },
    update(cache, { data: { deleteBuyingFormat } }) {
      if (deleteBuyingFormat?.ok) {
        cache.evict({ id: cache.identify(buyingFormat) });
        cache.gc();
      }
    },
  });

  const onSubmit = async () => {
    await deleteBuyingFormat({
      variables: {
        input: {
          id: buyingFormat.id,
        },
      },
    });
    closeModal();
  };
  const onClose = () => {
    closeModal();
  };

  return (
    <DeleteModal
      show={show}
      showFn={showFn}
      headerText={"Удаление формата закупки"}
      text={"Вы уверены, что хотите удалить формат закупки?"}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};
export default BuyingFormatDelete;
