import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import {
  createPbtReport,
  createPbtReportVariables,
} from "../../../../graphql/__generated-types__/createPbtReport";
import { CREATE_PBT_REPORT_MUTATION } from "../../../../graphql/mutations";
import { IPbtReportForm, PbtReportForm } from "../components";
import { useMe } from "../../../../hooks";
import { PBT_REPORT_FRAGMENT } from "../../../../graphql/fragments";

const PbtReportCreate = ({ onClose }) => {
  const { data: userData } = useMe();
  const [createPbtReport] = useMutation<
    createPbtReport,
    createPbtReportVariables
  >(CREATE_PBT_REPORT_MUTATION, {
    onCompleted: (createPbtReportData) => {
      if (createPbtReportData && createPbtReportData.createPbtReport.ok) {
        window.location.reload();
      } else {
        toast.error(createPbtReportData.createPbtReport.error);
      }
    },
    update(cache, { data: { createPbtReport } }) {
      if (createPbtReport?.pbtReport?.id) {
        const newPbtReportRef = cache.writeFragment({
          // @ts-ignore
          id: cache.identify(createPbtReport?.pbtReport),
          data: createPbtReport?.pbtReport,
          fragment: PBT_REPORT_FRAGMENT,
          fragmentName: "PbtReportParts",
        });
        cache.modify({
          id: "ROOT_QUERY",
          fields: {
            findAllPbtReports(prev) {
              return {
                ...prev,
                pbtReports: [newPbtReportRef, ...prev.pbtReports],
              };
            },
          },
        });
      }
    },
  });

  const onSubmit = async (data: IPbtReportForm) => {
    const responseData = await createPbtReport({
      variables: {
        input: {
          monthYear: data.monthYear,
          workPercent: data.workPercent,
          projectId: +data.projectVerticalId.split("_")[0],
          verticalId: +data.projectVerticalId.split("_")[1],
          userId: +data.userId ?? +userData?.me?.user?.id,
        },
      },
    });
    if (responseData.data.createPbtReport.ok) {
      onClose(true);
    }
  };
  return (
    <PbtReportForm pbtReport={null} onSubmit={onSubmit} onClose={onClose} />
  );
};

export default PbtReportCreate;
