import { Controller, useForm } from "react-hook-form";
import React from "react";
import {
  Currency,
  LegalnameType,
} from "../../../../graphql/__generated-types__/globalTypes";
import {
  Button,
  DataLoader,
  LabeledTextInput,
  LabeledToggleInput,
  PrimaryButton,
  SingleSelectInput,
} from "components";
import { LegalnameParts } from "graphql/__generated-types__/LegalnameParts";
import { useQuery } from "@apollo/client";
import { LIST_SELLERS_QUERY } from "graphql/queries";
import {
  listSellers,
  listSellersVariables,
} from "graphql/__generated-types__/listSellers";
import { legalnameTypeStatusMap } from "config/mappings";

export interface ILegalnameForm {
  title: string;
  currency: Currency;
  isVat: boolean;
  isArtics: boolean;
  sellerId?: number;
  projectIds?: number[];
  legalnameType?: LegalnameType;
  ITN?: string;
  PSRN?: string;
  phone?: string;
  epayNumber?: string;
  oksmNumber?: string;
}

interface ILegalnameFormProps {
  legalname?: LegalnameParts;
  onSubmit: (data: any) => void;
  onClose: () => void;
}

export const AdminLegalnameForm: React.FC<ILegalnameFormProps> = ({
  legalname,
  onSubmit,
  onClose,
}) => {
  const label = legalname
    ? "Редактирование юридического лица"
    : "Добавление юридического лица";

  const { data: sellersData, loading: sellersLoading } = useQuery<
    listSellers,
    listSellersVariables
  >(LIST_SELLERS_QUERY, { variables: { input: {} } });

  const { handleSubmit, control, setValue } = useForm<ILegalnameForm>({
    mode: "onSubmit",
    shouldUnregister: true,
    defaultValues: legalname
      ? {
          ...legalname,
          sellerId: legalname?.sellers?.map((seller) => seller?.id)[0],
          projectIds: legalname?.projects?.map((project) => project?.id),
        }
      : {
          title: null,
          currency: null,
          isVat: false,
          isArtics: false,
          sellerId: null,
          projectIds: [],
        },
  });

  const sellersOptions =
    !sellersLoading &&
    sellersData?.listSellers?.sellers?.map((seller) => ({
      label: `${seller?.title}`,
      value: seller?.id,
    }));

  return !sellersLoading ? (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {label}
            </h3>
          </div>

          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-8 sm:col-span-8 lg:col-span-8">
                <Controller
                  control={control}
                  name="title"
                  rules={{
                    required: "Нужно заполнить наименования юридического лица",
                  }}
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Наименование юридического лица"
                      value={field.value === null ? "" : field.value}
                      onChange={field.onChange}
                      name={field.name}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-4 sm:col-span-4 lg:col-span-4">
                <Controller
                  control={control}
                  name="currency"
                  rules={{ required: "Нужно заполнить валюту" }}
                  render={({ field, fieldState }) => (
                    <SingleSelectInput
                      labelText="Валюта"
                      options={Object.values(Currency).map((e) => {
                        return { label: e, value: e };
                      })}
                      fieldState={fieldState}
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={"Выберите валюту из списка"}
                    />
                  )}
                />
              </div>

              <div className="col-span-4 sm:col-span-4 lg:col-span-4">
                <Controller
                  control={control}
                  name="isVat"
                  render={({ field, fieldState }) => (
                    <LabeledToggleInput
                      labelText="Плательщик НДС"
                      checked={field.value}
                      field={field}
                      name={field.name}
                      onChange={(e) => setValue("isVat", !!e)}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <div className="col-span-4 sm:col-span-4 lg:col-span-4">
                <Controller
                  control={control}
                  name="isArtics"
                  render={({ field, fieldState }) => (
                    <LabeledToggleInput
                      labelText="Юридическое лицо Artics IS"
                      checked={field.value}
                      field={field}
                      name={field.name}
                      onChange={(e) => setValue("isArtics", !!e)}
                    />
                  )}
                />
              </div>

              <div className="col-span-4 sm:col-span-4 lg:col-span-4">
                <Controller
                  control={control}
                  name="sellerId"
                  render={({ field, fieldState }) => (
                    <SingleSelectInput
                      name="sellerId"
                      options={sellersOptions}
                      fieldState={fieldState}
                      value={field.value}
                      onChange={field.onChange}
                      labelText="Селлер"
                      placeholder="Выберите селлера из списка"
                    />
                  )}
                />
              </div>

              <div className="col-span-12 sm:col-span-12 lg:col-span-12">
                <hr className="my-8 h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
              </div>

              <div className="col-span-4 sm:col-span-4 lg:col-span-4">
                <Controller
                  control={control}
                  name="legalnameType"
                  rules={{ required: "Нужно заполнить тип ЮЛ" }}
                  render={({ field, fieldState }) => (
                    <SingleSelectInput
                      labelText="Тип ЮЛ"
                      options={Object.values(LegalnameType).map((e) => {
                        return {
                          label: legalnameTypeStatusMap[e],
                          value: e,
                        };
                      })}
                      fieldState={fieldState}
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={"Выберите тип ЮЛ из списка"}
                    />
                  )}
                />
              </div>

              <div className="col-span-4 sm:col-span-4 lg:col-span-4">
                <Controller
                  control={control}
                  name="ITN"
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="ИНН"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      defaultValue={field.value}
                      type="number"
                    />
                  )}
                />
              </div>

              <div className="col-span-4 sm:col-span-4 lg:col-span-4">
                <Controller
                  control={control}
                  name="PSRN"
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="ОГРН"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      defaultValue={field.value}
                      type="number"
                    />
                  )}
                />
              </div>

              <div className="col-span-4 sm:col-span-4 lg:col-span-4">
                <Controller
                  control={control}
                  name="phone"
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Телефон"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      defaultValue={field.value}
                      type="number"
                    />
                  )}
                />
              </div>

              <div className="col-span-4 sm:col-span-4 lg:col-span-4">
                <Controller
                  control={control}
                  name="epayNumber"
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Номер эл. платежа"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      defaultValue={field.value}
                      type="number"
                    />
                  )}
                />
              </div>

              <div className="col-span-4 sm:col-span-4 lg:col-span-4">
                <Controller
                  control={control}
                  name="oksmNumber"
                  render={({ field, fieldState }) => (
                    <LabeledTextInput
                      labelText="Код страны"
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      name={field.name}
                      fieldState={fieldState}
                      defaultValue={field.value}
                      type="number"
                    />
                  )}
                />
              </div>
            </div>

            <div className="flex justify-end gap-x-3 mt-5">
              <Button type="button" onClick={() => onClose()}>
                Отмена
              </Button>
              <PrimaryButton type="submit">Сохранить</PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </form>
  ) : (
    <DataLoader />
  );
};
