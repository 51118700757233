import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect, useState } from "react";
import { classNames } from "utils";

interface IButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size?: "xs" | "sm" | "base" | "lg" | "xl";
  icon?: React.ReactNode;
}

export const Button: FC<IButtonProps> = ({
  children,
  className,
  size = "base",
  ...rest
}) => {
  return (
    <button
      className={classNames(
        size === "xs" ? "px-2.5 py-1.5 text-xs" : "",
        size === "sm" ? "px-3 py-2 text-sm leading-4" : "",
        size === "base" ? "px-4 py-2 text-sm" : "",
        size === "lg" ? "px-4 py-2 text-base" : "",
        size === "xl" ? "px-6 py-3 text-base" : "",
        "relative inline-flex items-center border border-gray-300 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50",
        className || ""
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

export const PrimaryButton: FC<IButtonProps> = ({
  children,
  size = "base",
  icon,
  className,
  ...rest
}) => (
  <button
    className={classNames(
      size === "xs" ? "px-2.5 py-1.5 text-xs" : "",
      size === "sm" ? "px-3 py-2 text-sm leading-4" : "",
      size === "base" ? "px-4 py-2 text-sm" : "",
      size === "lg" ? "px-4 py-2 text-base" : "",
      size === "xl" ? "px-6 py-3 text-base" : "",
      "inline-flex gap-x-2 items-center border border-transparent font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500",
      className || ""
    )}
    {...rest}
  >
    {icon}
    {children}
  </button>
);

export const SecondaryButton: FC<IButtonProps> = ({
  children,
  size = "base",
  icon,
  className,
  ...rest
}) => (
  <button
    className={classNames(
      size === "xs" ? "px-2.5 py-1.5 text-xs" : "",
      size === "sm" ? "px-3 py-2 text-sm leading-4" : "",
      size === "base" ? "px-4 py-2 text-sm" : "",
      size === "lg" ? "px-4 py-2 text-base" : "",
      size === "xl" ? "px-6 py-3 text-base" : "",
      "inline-flex gap-x-2 items-center border border-transparent font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500",
      className || ""
    )}
    {...rest}
  >
    {icon}
    {children}
  </button>
);

export const PageButton: FC<IButtonProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <button
      className={classNames(
        "relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50",
        className || ""
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

export const ScrollUpButton = () => {
  const [showArrow, setShowArrow] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1000) setShowArrow(true);
      else setShowArrow(false);
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [showArrow]);
  return (
    <span
      className={classNames(
        showArrow ? "opacity-100" : "opacity-0",
        "w-12 h-12 cursor-pointer fixed left-5 bottom-5 p-3 rounded-full  text-white bg-red-600 hover:bg-red-500 transition ease-in-out duration-150 inline-flex justify-center items-center"
      )}
      onClick={() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }}
    >
      <FontAwesomeIcon icon={faArrowUp} size="lg" />
    </span>
  );
};
